import React, { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../../Components/Footer'
import Header from '../../Components/Header'
import HeaderTop from '../../Components/HeaderTop'



export default function Dashboard() {

    // const dispatch = useDispatch()

    // useEffect(() => {
    //     dispatch(HostelGetAll())
    //     dispatch(AnnouncementGetAll())
    //     dispatch(ServicesGetAll())
    // }, [])



    return (
        <>
            <HeaderTop />
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}

// import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
// import { Outlet } from 'react-router-dom';
// import Header from '../../Components/Header';
// import HeaderTop from '../../Components/HeaderTop';


// const Footer = lazy(() => import('../../Components/Footer'));

// export default function Dashboard() {
//     // const [isFooterVisible, setIsFooterVisible] = useState(false);
//     // const footerRef = useRef(null);

//     // useEffect(() => {
//     //     const observer = new IntersectionObserver(
//     //         (entries) => {
//     //             entries.forEach(entry => {
//     //                 if (entry.isIntersecting) {
//     //                     setIsFooterVisible(true);
//     //                     observer.disconnect(); 
//     //                 }
//     //             });
//     //         },
//     //         {
//     //             root: null, 
//     //             rootMargin: '0px',
//     //             threshold: 0.1,
//     //         }
//     //     );

//     //     if (footerRef.current) {
//     //         observer.observe(footerRef.current);
//     //     }

//     //     return () => {
//     //         if (footerRef.current) {
//     //             observer.unobserve(footerRef.current);
//     //         }
//     //     };
//     // }, []);

//     return (
//         <>
//             <HeaderTop />
//             <Header />
//             <Outlet />
//             <Footer />
//         </>
//     );
// }
