import React, { useState } from 'react'
import {
    Typography,
    Box,
    Button,
    TextField,
    InputLabel,
    MenuItem, 
    Select, 
    FormControl,
    Card,
    CardContent,
  } from "@mui/material";
  import { DatePicker } from '@mui/x-date-pickers/DatePicker';
  import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
  import moment from "moment";
  import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import dayjs from 'dayjs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactUsMain() {

  const [loading, setLoading] = useState(false)
    const countries = [
        "United States",
        "Canada",
        "United Kingdom",
        "Australia",
        "India",
        "Singapore",
        // Add more countries as needed
      ];

    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        reset
      } = useForm({
        mode: "onTouched",
        defaultValues : {
          name : '',
          dob : null,
          nationality : '',
          contact: '',
          email: '',
          line: '',
          wechat: '',
          whatsapp: '',
          remarks: ''
        }
      });

      const onSubmit = async (data) => {
        const obj = {
            name: data?.name,
            dateOfBirth: moment(data?.dob.$d).toDate(), 
            nationality: data?.nationality,
            contactNo: data?.contact,
            email: data?.email,
            line: data?.line,
            weChat: data?.wechat,
            whatsApp: data?.whatsapp,
            remarks: data?.remarks,
        };
    
        console.log(obj, 'data');
    
        try {
            setLoading(true)
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}add-contact-us`,
                obj // No need to wrap it in `{ obj }`
            );
            console.log(response);
    
            if (response.status === 200) {
        
                reset();

                setLoading(false)

                toast.success('Contacted successfully!', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
            }
        } catch (error) {
            console.log(error);
            setLoading(false)
            toast.error('Something went wrong!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
        }
    };

  return (
    <>
    <ToastContainer />
    <Box sx={{width : '100%'}}>
        {/* <Box sx={{display : 'flex', justifyContent : 'center', mt : 3, mb : 3}}>
            <Typography sx={{fontSize : '1.5rem', fontWeight : 700, color : '#003893', border : '1px solid #003893', px : 5, py : 1, borderRadius : '40px'}}>Contact Us</Typography>
        </Box> */}

        <Box sx={{width : '100%'}}>
            <Box sx={{mx : {xs : 2, sm : 3, lg : 12}, mt : {xs : 3, sm : 4, lg : 10}, mb : {xs : 3, sm : 4, lg : 10}}}>
            <Typography sx={{fontWeight : 600, fontSize : '1.5rem'}}>Vinga International Student Hostel</Typography>
            </Box>

            <Box sx={{mt : 5, display : 'flex', gap : {xs : 3, lg : 8}, flexWrap : 'wrap', justifyContent : 'space-between', width : '100%', px : {xs : 2, lg : 12}}}>
                <Box sx={{display : 'flex', flexDirection : {xs : 'column', lg : 'row'},gap : 2, width : {xs : '90%', lg : '46%'}}}>
                        <Typography sx={{fontWeight : 700}}>Address:</Typography>
                            <Box>
                                <Typography>Vinga Hall @ Rangoon Road: No.99 Rangoon Road, Singapore 218384</Typography>
                                <Typography>Vinga Hall @ Boon Keng: 10/12 Beng Wan Road, Singapore 339842</Typography>
                            </Box>
                </Box>

                <Box sx={{display : 'flex', gap : 3, width : {xs : '90%', lg : '46%'}}}>
                        <Typography sx={{fontWeight : 700}}>Email:</Typography>
                            <Box>
                                <Typography>info@dredugroup.com</Typography>
                            </Box>
                </Box>

                <Box sx={{display : 'flex', gap : 3, width : {xs : '90%', lg : '46%'}}}>
                        <Typography sx={{fontWeight : 700}}>Tel:</Typography>
                            <Box>
                                <Typography>+65 6962 6868 / +65 6677 6789</Typography>
                            </Box>
                </Box>

                <Box sx={{display : 'flex', gap : 3, width : {xs : '90%', lg : '46%'}}}>
                        <Typography sx={{fontWeight : 700}}>Website:</Typography>
                            <Box sx={{display : 'flex', flexDirection : 'column', textDecoration : 'underline'}}>
                                <a href='https://singapore-v.com/' target="_blank" style={{}}>singapore-v.com</a>
                                <a href='https://www.dredugroup.com/' target="_blank">www.dredugroup.com</a>
                                <a href='https://www.vinga.com.sg/' target="_blank">www.vinga.com.sg</a>
                            </Box>
                </Box>
            </Box>

            
        </Box>


        
    </Box>


<Box sx={{mt : 10, backgroundColor : '#f8f8f8', pt : 5}}>
                <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{display : 'flex', flexWrap : 'wrap', width : '100%', justifyContent : 'center', gap : {xs : 1, lg : 5}}}>
            <Box style={{ padding: "25px 0px 0px 0px", width :  '45%'}}>
              <InputLabel
                sx={{
                  mb: 1,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
                {errors?.name ? 'Name*' : 'Name*'}
              </InputLabel>
              <Controller
                name="name"
                rules={{ required: true }}
                control={control}
                label
                render={({ field }) => (
                  <TextField
                    placeholder="Enter your name"
                    sx={{ 
                      mt: 0, 
                      backgroundColor : 'rgb(249, 250, 251)',
                      borderRadius: 0, 
                      "& .MuiInputBase-input": {
                        color: errors?.name ? "red" : "black",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: errors?.name ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: errors?.name ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: errors?.name ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                      },
                    }}
                    error={Boolean(errors?.name) ? true : false}
                    {...field}
                  />
                )}
              />
              {errors?.name ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>Please enter name</Typography> : null }
            </Box>
    {/* ------------------------------------------------------------------------------------------------------------------------- */}
            <Box style={{ padding: "0px 0px 0px", width : '45%' }}>
              <InputLabel
                sx={{
                  mb: 1,
                  mt: 3,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: 'black',
                }}
              >
                {errors?.dob ? 'Date of Birth*' : 'Date of Birth*'}
              </InputLabel>
              <Controller
                name="dob"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    {...field}
                    // label="Select Date and Time"

                    renderInput={(props) => (
                      <TextField
                        {...props}
                        error={Boolean(errors?.dob) ? true : false}
                        fullWidth
                        inputMode="none"
                      />
                    )}
                    sx={{
                      backgroundColor : 'rgb(249, 250, 251)',
                      width: "100%",
                      ".MuiSvgIcon-root": { fill: "#1A2461" },
                      "& .MuiInputBase-input": {
                        color: errors?.dob ? "red" : "black",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: errors?.dob ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: errors?.dob ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: errors?.dob ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                      },
                    }}
                  />
                  </LocalizationProvider>
                )}
              />
              {errors?.dob ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>Please select date of birth</Typography> : null }
            </Box>
{/* --------------------------------------------------------------------------------------------------------------------------- */}
            
            <Box style={{ padding: "0px 0px 0px", width : '45%' }}>
              <InputLabel
                sx={{
                  mb: 1,
                  mt: 3,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
               {errors?.nationality ? 'Nationality*' : 'Nationality*'}
              </InputLabel>
              <Controller
                    name="nationality"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                    
                        <Select
                            {...field}
                            sx={{
                            width:'100%',
                            backgroundColor: "rgb(249, 250, 251)",
                            borderRadius: 1,
                            "& .MuiInputBase-input": {
                                color: errors?.nationality ? "red" : "black",
                            },
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                borderColor: errors?.nationality ? "red" : "rgba(0, 0, 0, 0.23)",
                                },
                                "&:hover fieldset": {
                                borderColor: errors?.nationality ? "red" : "rgba(0, 0, 0, 0.23)",
                                },
                                "&.Mui-focused fieldset": {
                                borderColor: errors?.nationality ? "red" : "rgba(0, 0, 0, 0.23)",
                                },
                            },
                            }}
                            error={Boolean(errors?.nationality) ? true : false}
                        >
                            <MenuItem value="">
                            <em>Select your nationality</em>
                            </MenuItem>
                            {countries.map((country, index) => (
                            <MenuItem key={index} value={country}>
                                {country}
                            </MenuItem>
                            ))}
                        </Select>                    
                        )}
                    />
              {errors?.nationality ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>Please select nationality</Typography> : null }
            </Box>
            {/* ---------------------------------------------------------------------------------------------------------------- */}
            
            <Box style={{ padding: "0px 0px 0px", width : '45%' }}>
              <InputLabel
                sx={{
                  mb: 1,
                  mt: 3,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
               {errors?.contact ? 'Contact No*' : 'Contact No*'}
              </InputLabel>
              <Controller
                name="contact"
                rules={{ required: 'Please enter contact number', pattern: {
                  value: /^[0-9]*$/,
                  message: "Please enter a valid contact number",
                },
                maxLength: {
                  value: 15,
                  message: "Contact number cannot exceed 15 digits",
                }, }}
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder="Enter your contact no"
                    sx={{ 
                      mt: 0, 
                      backgroundColor : 'rgb(249, 250, 251)',
                      borderRadius: 0, 
                      "& .MuiInputBase-input": {
                        color: errors?.contact ? "red" : "black",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: errors?.contact ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: errors?.contact ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: errors?.contact ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                      },
                    }}
                    error={Boolean(errors?.contact) ? true : false}
                    {...field}
                  />
                )}
              />
              {errors?.contact ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>{errors.contact.message}</Typography> : null }
            </Box>
            {/* ----------------------------------------------------------------------------------------------------------- */}
            <Box style={{ padding: "0px 0px 0px", width : '45%' }}>
              <InputLabel
                sx={{
                  mb: 1,
                  mt: 3,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
               {errors?.email ? 'Email*' : 'Email*'}
              </InputLabel>
              <Controller
                name="email"
                rules={{ required: true,
                    pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Please enter a valid email address",
                  }, }}
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder="Enter your email"
                    sx={{ 
                      mt: 0, 
                      backgroundColor : 'rgb(249, 250, 251)',
                      borderRadius: 0, 
                      "& .MuiInputBase-input": {
                        color: errors?.email ? "red" : "black",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: errors?.email ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: errors?.email ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: errors?.email ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                      },
                    }}
                    error={Boolean(errors?.email) ? true : false}
                    {...field}
                  />
                )}
              />
              {errors?.email ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>Please enter email</Typography> : null }
            </Box>

            {/* ------------------------------------------------------------------------------------------------------------------- */}
            <Box style={{ padding: "0px 0px 0px", width : '45%' }}>
              <InputLabel
                sx={{
                  mb: 1,
                  mt: 3,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
               {errors?.line ? 'LINE*' : 'LINE'}
              </InputLabel>
              <Controller
                name="line"
                rules={{ required: false }}
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder="Enter your line no"
                    sx={{ 
                      mt: 0, 
                      backgroundColor : 'rgb(249, 250, 251)',
                      borderRadius: 0, 
                      "& .MuiInputBase-input": {
                        color: errors?.line ? "red" : "black",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: errors?.line ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: errors?.line ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: errors?.line ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                      },
                    }}
                    error={Boolean(errors?.line) ? true : false}
                    {...field}
                  />
                )}
              />
              {errors?.line ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>Please enter line no</Typography> : null }
            </Box>

{/* ------------------------------------------------------------------------------------------------------------------------ */}
            <Box style={{ padding: "0px 0px 0px", width : '45%' }}>
              <InputLabel
                sx={{
                  mb: 1,
                  mt: 3,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
               {errors?.wechat ? 'WeChat*' : 'WeChat'}
              </InputLabel>
              <Controller
                name="wechat"
                rules={{ required: false }}
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder="Enter your wechat no"
                    sx={{ 
                      mt: 0, 
                      backgroundColor : 'rgb(249, 250, 251)',
                      borderRadius: 0, 
                      "& .MuiInputBase-input": {
                        color: errors?.wechat ? "red" : "black",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: errors?.wechat ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: errors?.wechat ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: errors?.wechat ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                      },
                    }}
                    error={Boolean(errors?.wechat) ? true : false}
                    {...field}
                  />
                )}
              />
              {errors?.wechat ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>Please enter wechat no</Typography> : null }
            </Box>
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Box style={{ padding: "0px 0px 0px", width : '45%' }}>
              <InputLabel
                sx={{
                  mb: 1,
                  mt: 3,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
               {errors?.whatsapp ? 'Whatsapp No*' : 'Whatsapp No'}
              </InputLabel>
              <Controller
                name="whatsapp"
                rules={{ required: false }}
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder="Enter your whatsapp no"
                    sx={{ 
                      mt: 0, 
                      backgroundColor : 'rgb(249, 250, 251)',
                      borderRadius: 0, 
                      "& .MuiInputBase-input": {
                        color: errors?.whatsapp ? "red" : "black",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: errors?.whatsapp ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: errors?.whatsapp ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: errors?.whatsapp ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                      },
                    }}
                    error={Boolean(errors?.whatsapp) ? true : false}
                    {...field}
                  />
                )}
              />
              {errors?.whatsapp ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>Please enter whatsapp no</Typography> : null }
            </Box>


            <Box style={{ padding: "0px 0px 0px", width : '92%' }}>
              <InputLabel
                sx={{
                  mb: 1,
                  mt: 3,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
               {errors?.remarks ? 'Other remarks*' : 'Other remarks'}
              </InputLabel>
              <Controller
                name="remarks"
                rules={{ required: false }}
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={4}
                    placeholder="Enter your remarks"
                    sx={{ 
                      mt: 0, 
                      backgroundColor : 'rgb(249, 250, 251)',
                      borderRadius: 0, 
                      "& .MuiInputBase-input": {
                        color: errors?.remarks ? "red" : "black",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: errors?.remarks ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: errors?.remarks ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: errors?.remarks ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                      },
                    }}
                    error={Boolean(errors?.remarks) ? true : false}
                    {...field}
                  />
                )}
              />
              {errors?.remarks ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>Please enter remarks</Typography> : null }
            </Box>



            
            {/* <Divider style={{marginTop : '15px'}}/> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "10px 0px 30px 0px",
                width : '90%'
              }}
            >
              <Button
                variant="contained"
                disabled={loading}
                type="submit"
                sx={{
                  padding: "10px 20px 10px 20px",
                  mt: 2,
                  width: "30%",
                  fontSize: 20,
                  backgroundColor: "rgb(14, 94, 132)",
                  ":hover": { bgcolor: "rgb(14, 94, 132)" },
                }}
              >
                Submit
              </Button>
            </Box>
            </Box>
          </form>
                </Box>
                </>
  )
}

export default ContactUsMain