import React, { useEffect, useState } from 'react'
import { Typography, Stack, Box, Button, Modal, TextField, InputLabel } from '@mui/material'
import { ReactComponent as DoubleArrowIcon } from "../../../Icons/DoubleArrowIcon.svg"
import { ReactComponent as CelebrationIcon } from "../../../Icons/CelebrationIcon.svg"
import { ReactComponent as ApplicationProcessFileIcon } from "../../../Icons/ApplicationProcessFileIcon.svg"
import { useForm, Controller } from 'react-hook-form'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { storeData } from '../../../Redux/Feature/ApplicationSlice'
import { useDispatch, useSelector} from 'react-redux'
import { removeData } from '../../../Redux/Feature/ApplicationSlice'

const steps = ["Agreement", "Payment", "Success"];

export default function ApplicationSteps({ setFlowCount, email, setEmail }) {
    const style = {
        position: 'absolute',
        top:'50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs : '95%', sm : '90%',lg :'70%'},
        bgcolor: 'background.paper',
        borderRadius : '10px',
        boxShadow: 24,
        display : 'flex',
        flexDirection : {xs : 'column', sm : 'row',lg : 'row'}
      };

    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [showOtp, setShowOtp] = useState(false)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues,
        watch,
        setValue,
        reset
      } = useForm({
        mode: "onTouched",
        shouldUnregister: false,
      });

      // const [email, setEmail] = useState('')
    
      useEffect(() => {
        dispatch(removeData())
      },[])


      const handleOtpGenerate = async (data) => {
        setEmail(data.email)
        try {
          setLoading(true)
          await axios.post(`${process.env.REACT_APP_API_URL}/email-otp`, {email : data.email}).then((response) => {
            console.log(response, 'data from email otp')

            if(response.status == 200 && response.data.message == 'Email already exist'){
              setFlowCount(2)
              dispatch(storeData(response.data.data))
            }

            if(response.status === 200){
                setShowOtp(true)
                setValue('email', '')
            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
            setLoading(false)
          }).catch((error) => {
            // setFlowCount(2)
            setLoading(false)
            if(error.response.status == 400 && error.response.data.message == 'User already exit'){
              navigate('/login')
            }else if(error.response.status == 400 && error.response.data.message == 'OTP already sent to your email'){
              setShowOtp(true)
              setValue('email', '')
              toast.error(error.response.data.message, {
                position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              })
            }else{
              toast.error(error.response.data.message, {
                position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              })
            }
            
          })
        } catch (error) {
          console.log(error)
          setLoading(false)
        }
      }

const onSubmit = async (data) => {
    try {
      setLoading(true)
        await axios.post(`${process.env.REACT_APP_API_URL}/email-verify-otp`, {email, otp : data.otp}).then((response) => {
          console.log(response)
          if(response.status === 200){
            setFlowCount(2)
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
          })
          }
          
         setLoading(false)
        }).catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        })
        })
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }

    return (
        <>
        <ToastContainer />
      <Modal
      sx={{backdropFilter : 'blur(5px)'}}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{width : {xs : '100%', sm : '40%', lg : '40%'}, borderRadius : {xs : '10px 10px 0px 0px', sm : '10px 0px 0px 10px'}}} component='img' src='https://prestigelife.in/assets/img/Rooms/Prestige%20Living%20-%202%20Bed%20with%20Balcony-22.jpg'></Box>
          <Box sx={{p : 2, width : {xs : '100%', lg : '60%'}, mt : {xs : 1, lg : 5}}}>
            {showOtp ? (<>
                <form onSubmit={handleSubmit(onSubmit)}>
            <InputLabel
                      sx={{
                        mb: 1,
                        fontSize: "18px",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      {errors?.otp ? "Otp*" : "Otp"}
                    </InputLabel>
          <Controller 
          name='otp'
          control={control}
          rules={{
            required: 'Otp is required'
        }}
          render={({field}) => (
              <TextField
                      placeholder="Enter otp"
                      error={Boolean(errors.otp) ? true : false}
                      {...field}
                      sx={{
                        width: "100%",
                        backgroundColor: "rgb(249, 250, 251)",
                        borderRadius: 0,
                        "& .MuiInputBase-input": {
                          color: errors?.otp ? "red" : "black",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: errors?.otp
                              ? "#ff5e74"
                              : "rgba(0, 0, 0, 0.23)",
                          },
                          "&:hover fieldset": {
                            borderColor: errors?.otp
                              ? "#ff5e74"
                              : "rgba(0, 0, 0, 0.23)",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: errors?.otp
                              ? "#ff5e74"
                              : "rgba(0, 0, 0, 0.23)",
                          },
                        },
                      }}
              />
          )}
          />
          {errors?.otp ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please enter otp
                      </Typography>
                    ) : null}


                    <Box sx={{display : 'flex', justifyContent : 'flex-end', mt:3}}>
                    <Button type='submit' variant='contained' disabled={loading}>{loading ? 'loading' : 'Submit'}</Button>
                    </Box>
          </form>
            </>) : (<>
                <form onSubmit={handleSubmit(handleOtpGenerate)}>
            <InputLabel
                      sx={{
                        mb: 1,
                        fontSize: "18px",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      {errors?.email ? "Email*" : "Email"}
                    </InputLabel>
          <Controller 
          name='email'
          control={control}
          rules={{
            required: 'Email is required',
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: 'Invalid email address',
            },
        }}
          render={({field}) => (
              <TextField
              type='email'
                      placeholder="Enter email"
                      error={Boolean(errors.email) ? true : false}
                      {...field}
                      sx={{
                        width: "100%",
                        backgroundColor: "rgb(249, 250, 251)",
                        borderRadius: 0,
                        "& .MuiInputBase-input": {
                          color: errors?.email ? "red" : "black",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: errors?.email
                              ? "#ff5e74"
                              : "rgba(0, 0, 0, 0.23)",
                          },
                          "&:hover fieldset": {
                            borderColor: errors?.email
                              ? "#ff5e74"
                              : "rgba(0, 0, 0, 0.23)",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: errors?.email
                              ? "#ff5e74"
                              : "rgba(0, 0, 0, 0.23)",
                          },
                        },
                      }}
              />
          )}
          />
          {errors?.email ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please enter email
                      </Typography>
                    ) : null}


                    <Box sx={{display : 'flex', justifyContent : 'flex-end', mt:3}}>
                    <Button sx={{
            bgcolor: '#009688', // Background color
            color: 'white', // Text color
            '&.Mui-disabled': {
                bgcolor: '#a6a6a6', // Background color for disabled state
                color: 'white', // Text color for disabled state
            }
        }} type='submit' variant='contained' disabled={loading}>{loading ? 'loading..' : 'Verify'}</Button>
                    </Box>
          </form>
            </>)}
            
        </Box>
        </Box>
       
      </Modal>

            <Box padding={{ md: "42px 60px", xs: "30px 20px" }}>
                <Stack gap="7px">
                    <Typography variant="h4_1">Hostel application process</Typography>
                    <Typography variant="h9_4" color="neutral_4">Your application with ours has 6 primary steps.</Typography>
                </Stack>
                <Box mt={5} columnGap={2} rowGap={2} sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    <Stack p={2} gap={1} sx={{ boxShadow: "2px 31px 25px -3px rgba(0,0,0,0.08)", width: "215px", height: "215px", backgroundColor: "#122646", borderRadius: "24px" }}>
                        <Typography variant="h9_4" color="#AAAAAA">Step 1</Typography>
                        <Typography variant="h9_4" color="#FFFFFF">Choose Type</Typography>
                        <Stack>
                            <Typography variant="h9_5" color="#CED6FF">Student Hostel Type</Typography>
                            <Typography variant="h9_5" color="#CED6FF">Entry Date</Typography>
                            <Typography variant="h9_5" color="#CED6FF">Landing package services & other info</Typography>
                        </Stack>
                        <Stack height="100%" alignItems="end" justifyContent="end">
                            <DoubleArrowIcon />
                        </Stack>
                    </Stack>
                    <Stack p={2} gap={1} sx={{ boxShadow: "2px 31px 25px -3px rgba(0,0,0,0.08)", width: "215px", height: "215px", backgroundColor: "#122646", borderRadius: "24px" }}>
                        <Typography variant="h9_4" color="#AAAAAA">Step 2</Typography>
                        <Typography variant="h9_4" color="#FFFFFF">Information Form</Typography>
                        <Stack>
                            <Typography variant="h9_5" color="#CED6FF">Personal information <br /> such as
                                contact type<br />
                                school attended<br />
                                uploadIPA, etc.</Typography>
                        </Stack>
                        <Stack height="100%" alignItems="end" justifyContent="end">
                            <DoubleArrowIcon />
                        </Stack>
                    </Stack>
                    <Stack p={2} gap={1} sx={{ boxShadow: "2px 31px 25px -3px rgba(0,0,0,0.08)", width: "215px", height: "215px", backgroundColor: "#122646", borderRadius: "24px" }}>
                        <Typography variant="h9_4" color="#AAAAAA">Step 3</Typography>
                        <Typography variant="h9_4" color="#FFFFFF">Confirm Payment</Typography>
                        <Stack>
                            <Typography variant="h9_5" color="#CED6FF">Only includes deposit<br />
                                deposit, registration fee,<br />
                                supporting fee and others<br />
                                excluding rent fees</Typography>
                        </Stack>
                        <Stack height="100%" alignItems="end" justifyContent="end">
                            <DoubleArrowIcon />
                        </Stack>
                    </Stack>
                    <Stack p={2} gap={1} sx={{ boxShadow: "2px 31px 25px -3px rgba(0,0,0,0.08)", width: "215px", height: "215px", backgroundColor: "#122646", borderRadius: "24px" }}>
                        <Typography variant="h9_4" color="#AAAAAA">Step 4</Typography>
                        <Typography variant="h9_4" color="#FFFFFF">Pending Confirmation</Typography>
                        <Stack>
                            <Typography variant="h9_5" color="#CED6FF">Within 24 hours not<br />
                                including weekends</Typography>
                        </Stack>
                        <Stack height="100%" alignItems="end" justifyContent="end">
                            <DoubleArrowIcon />
                        </Stack>
                    </Stack>
                    <Stack p={2} gap={1} sx={{ boxShadow: "2px 31px 25px -3px rgba(0,0,0,0.08)", width: "215px", height: "215px", backgroundColor: "#122646", borderRadius: "24px" }}>
                        <Typography variant="h9_4" color="#AAAAAA">Step 5</Typography>
                        <Typography variant="h9_4" color="#FFFFFF">Contract signing &
                            Payment Submission</Typography>
                        <Stack>
                            <Typography variant="h9_5" color="#CED6FF">Confirm and sign the format<br />
                                contract pay rent fees</Typography>
                        </Stack>
                        <Stack height="100%" alignItems="end" justifyContent="end">
                            <DoubleArrowIcon />
                        </Stack>
                    </Stack>
                    <Stack p={2} gap={1} sx={{ boxShadow: "2px 31px 25px -3px rgba(0,0,0,0.08)", width: "215px", height: "215px", backgroundColor: "#122646", borderRadius: "24px" }}>
                        <Typography variant="h9_4" color="#AAAAAA">Step 6</Typography>
                        <Stack height="100%" justifyContent="center" textAlign="center">
                            <Typography variant="h7_1" color="#FFFFFF">Fly to Singapore, Start a new life</Typography>
                        </Stack>
                        <Stack alignItems="end" justifyContent="end">
                            <CelebrationIcon />
                        </Stack>
                    </Stack>
                </Box>
                <Stack mt={15} p="30px" rowGap={2} direction={{ sm: "row", xs: "column" }} justifyContent="space-between" alignItems="center" sx={{ border: ".5px solid #1A2461", borderRadius: "20px" }}>
                    <Stack direction="row" gap={3}>
                        <ApplicationProcessFileIcon />
                        <Stack>
                            <Typography variant="h5">Application Process</Typography>
                            <Typography variant="h9_4">Login to access your application</Typography>
                        </Stack>
                    </Stack>
                    <Button onClick={() => handleOpen()} sx={{ height: "40px" }} variant="standard">Fill form</Button>
                </Stack>
            </Box>
        </>
    )
}
