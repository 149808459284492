import { Typography, Stack, Box, Button, Divider, Grid, Select, InputLabel, IconButton, TextField, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { ReactComponent as EditIcon } from "../../../Icons/EditIcon.svg"
import { ReactComponent as AddContactIcon } from "../../../Icons/AddContactIcon.svg"
import { useForm, Controller } from 'react-hook-form'
import ClearIcon from '@mui/icons-material/Clear';


export default function Details() {

    const [block, setBlock] = useState(1)
    const { formState: { errors }, handleSubmit, control } = useForm({
        mode: "onTouched"
    })

    const initialEmergencycontact = { name: "", contact_no: "" }
    const [EmergencyContact, setEmergencyContact] = useState([initialEmergencycontact])
    const [Details, setDetails] = useState({ name: "", nationality: "", contact_no: "", email: "" })

    const onSubmit = data => {
        const { name, nationality, contact_no, email } = data

        setDetails({ name: name, nationality: nationality, contact_no: contact_no, email: email })

        setEmergencyContact((prev) => {
            const dataas = EmergencyContact.map((itm, index) => ({
                Emergency_name: data[`Emergency_name${index + 1}`],
                Emergency_contact: data[`Emergency_contact${index + 1}`],
            }))
            return dataas
        })


    }


    console.log(EmergencyContact);
    console.log(Details);

    const handleDeleteContact = (ind) => {
        const data = EmergencyContact.filter((itm, index) => (ind !== index))
        setEmergencyContact(data)
    }

    const EditBlock = () => {
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container justifyContent={{ md: "start", xs: "center" }} columnGap={15}>
                    <Grid xs={12} md={4.5}>
                        <InputLabel sx={{ mt: 3, mb: 1,fontSize: "16px",
                        fontWeight: 600,
                        color: "black" }} >{errors?.name ? "Name*" : "Name"}</InputLabel>
                        <Controller
                            name="name"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => <TextField
                            placeholder="Enter Name"
                            error={Boolean(errors.name) ? true : false} {...field} 
                            sx={{
                                width: "100%",
                                backgroundColor: "rgb(249, 250, 251)",
                                borderRadius: 0,
                                "& .MuiInputBase-input": {
                                  color: errors?.name ? "red" : "black",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: errors?.name
                                      ? "#ff5e74"
                                      : "rgba(0, 0, 0, 0.23)",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: errors?.name
                                      ? "#ff5e74"
                                      : "rgba(0, 0, 0, 0.23)",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: errors?.name
                                      ? "#ff5e74"
                                      : "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              }}
                            />}
                        />
                        {errors?.name ? (
                                <Typography
                                  style={{ color: "#ff5e74", margin: "0.3rem" }}
                                >
                                  Please enter name
                                </Typography>
                              ) : null}

                        <InputLabel sx={{ mt: 3, mb: 1, fontSize: "16px",
                        fontWeight: 600,
                        color: "black" }} >{errors?.email ? "Email*" : "Email"}</InputLabel>
                        <Controller
                            name="email"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => <TextField
                            placeholder='Enter Email' 
                            error={Boolean(errors.email) ? true : false} {...field}
                            sx={{
                                width: "100%",
                                backgroundColor: "rgb(249, 250, 251)",
                                borderRadius: 0,
                                "& .MuiInputBase-input": {
                                  color: errors?.email ? "red" : "black",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: errors?.email
                                      ? "#ff5e74"
                                      : "rgba(0, 0, 0, 0.23)",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: errors?.email
                                      ? "#ff5e74"
                                      : "rgba(0, 0, 0, 0.23)",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: errors?.email
                                      ? "#ff5e74"
                                      : "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              }} />}
                        />
                        {errors?.email ? (
                                <Typography
                                  style={{ color: "#ff5e74", margin: "0.3rem" }}
                                >
                                  Please enter email
                                </Typography>
                              ) : null}

                        <InputLabel sx={{ mt: 3, mb: 1,fontSize: "16px",
                        fontWeight: 600,
                        color: "black" }} >{errors?.contact_no ? "Contact Number*" : "Contact Number"}</InputLabel>
                        <Controller
                            name="contact_no"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => <TextField
                            placeholder='Enter contact no'
                            error={Boolean(errors.contact_no) ? true : false} {...field}
                            sx={{
                                width: "100%",
                                backgroundColor: "rgb(249, 250, 251)",
                                borderRadius: 0,
                                "& .MuiInputBase-input": {
                                  color: errors?.contact_no ? "red" : "black",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: errors?.contact_no
                                      ? "#ff5e74"
                                      : "rgba(0, 0, 0, 0.23)",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: errors?.contact_no
                                      ? "#ff5e74"
                                      : "rgba(0, 0, 0, 0.23)",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: errors?.contact_no
                                      ? "#ff5e74"
                                      : "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              }}
                            />}
                        />
                        {errors?.contact_no ? (
                                <Typography
                                  style={{ color: "#ff5e74", margin: "0.3rem" }}
                                >
                                  Please enter contact number
                                </Typography>
                              ) : null}

                        <InputLabel sx={{ mt: 3, mb: 1,fontSize: "16px",
                        fontWeight: 600,
                        color: "black" }} >{errors?.nationality ? 'Nationality*' : 'Nationality'}</InputLabel>
                        <Controller
                            name="nationality"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => <Select 
                            {...field}
                            error={Boolean(errors.nationality) ? true : false} 
                            sx={{
                                width: "100%",
                                backgroundColor: "rgb(249, 250, 251)",
                                borderRadius: 0,
                                "& .MuiInputBase-input": {
                                  color: errors?.nationality ? "red" : "black",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: errors?.nationality
                                      ? "#ff5e74"
                                      : "rgba(0, 0, 0, 0.23)",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: errors?.nationality
                                      ? "#ff5e74"
                                      : "rgba(0, 0, 0, 0.23)",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: errors?.nationality
                                      ? "#ff5e74"
                                      : "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              }}
                            >
                                <MenuItem value={1}>Indian</MenuItem>
                            </Select>}
                        />
                        {errors?.nationality ? (
                                <Typography
                                  style={{ color: "#ff5e74", margin: "0.3rem" }}
                                >
                                  Please select nationality
                                </Typography>
                              ) : null}

                    </Grid>
                    <Grid xs={12} md={4.5} mt={3} border=".5px solid #737373" sx={{ height: "fit-content", padding: "10px", borderRadius: "10px" }}>
                        {EmergencyContact?.map((item, index) => {
                            return (
                                <Box sx={{ mt: ((EmergencyContact.length) == (index + 1)) ? 5 : 0 }}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                                        <Typography variant="h7_1">Emergency Contact {index + 1}</Typography>
                                        {index > 0 && <IconButton onClick={() => handleDeleteContact(index)}> <ClearIcon sx={{ color: "#f37777", fontSize: "30px" }} /></IconButton>}
                                    </Stack>
                                    <InputLabel sx={{ mt: 3, mb: 1 }} >{Boolean(errors[`Emergency_name${index + 1}`]) ? "Name*" : "Name"}</InputLabel>
                                    <Controller
                                        name={`Emergency_name${index + 1}`}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <TextField
                                        placeholder = 'Enter Name'
                                        error={Boolean(errors[`Emergency_name${index + 1}`]) ? true : false} {...field} 
                                        sx={{
                                            width: "100%",
                                            backgroundColor: "rgb(249, 250, 251)",
                                            borderRadius: 0,
                                            "& .MuiInputBase-input": {
                                              color: Boolean(errors[`Emergency_name${index + 1}`]) ? "red" : "black",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                              "& fieldset": {
                                                borderColor: Boolean(errors[`Emergency_name${index + 1}`])
                                                  ? "#ff5e74"
                                                  : "rgba(0, 0, 0, 0.23)",
                                              },
                                              "&:hover fieldset": {
                                                borderColor: Boolean(errors[`Emergency_name${index + 1}`])
                                                  ? "#ff5e74"
                                                  : "rgba(0, 0, 0, 0.23)",
                                              },
                                              "&.Mui-focused fieldset": {
                                                borderColor: Boolean(errors[`Emergency_name${index + 1}`])
                                                  ? "#ff5e74"
                                                  : "rgba(0, 0, 0, 0.23)",
                                              },
                                            },
                                          }}
                                        />}
                                    />
                                    {Boolean(errors[`Emergency_name${index + 1}`]) ? <Typography
                                  style={{ color: "#ff5e74", margin: "0.3rem" }}
                                >
                                  Please select name
                                </Typography> : null}
                                    <InputLabel sx={{ mt: 3, mb: 1 }} >{Boolean(errors[`Emergency_contact${index + 1}`]) ? 'Contact Number*' : 'Contact Number'}</InputLabel>
                                    <Controller
                                        name={`Emergency_contact${index + 1}`}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <TextField 
                                        placeholder = 'Enter contact number'
                                        error={Boolean(errors[`Emergency_contact${index + 1}`]) ? true : false} {...field}
                                        sx={{
                                            width: "100%",
                                            backgroundColor: "rgb(249, 250, 251)",
                                            borderRadius: 0,
                                            "& .MuiInputBase-input": {
                                              color: Boolean(errors[`Emergency_contact${index + 1}`]) ? "red" : "black",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                              "& fieldset": {
                                                borderColor: Boolean(errors[`Emergency_contact${index + 1}`])
                                                  ? "#ff5e74"
                                                  : "rgba(0, 0, 0, 0.23)",
                                              },
                                              "&:hover fieldset": {
                                                borderColor: Boolean(errors[`Emergency_contact${index + 1}`])
                                                  ? "#ff5e74"
                                                  : "rgba(0, 0, 0, 0.23)",
                                              },
                                              "&.Mui-focused fieldset": {
                                                borderColor: Boolean(errors[`Emergency_contact${index + 1}`])
                                                  ? "#ff5e74"
                                                  : "rgba(0, 0, 0, 0.23)",
                                              },
                                            },
                                          }}
                                        />}
                                    />
                                    {Boolean(errors[`Emergency_contact${index + 1}`]) ? <Typography
                                  style={{ color: "#ff5e74", margin: "0.3rem" }}
                                >
                                  Please select contact number
                                </Typography> : null}
                                </Box>
                            )
                        })}
                        <Button onClick={() => setEmergencyContact((prev) => ([...prev, initialEmergencycontact]))} sx={{ mt: 3 }} startIcon={<AddContactIcon fill="#FFFFFF" />} variant="standard">Add Another Contact</Button>
                    </Grid>
                </Grid>
                <Stack mt={3} direction="row" alignItems="center" gap="30px">
                    <Button type="submit" variant="standard">Save</Button>
                    <Button onClick={() => { setBlock(1) }} variant="outlined">Cancel</Button>
                </Stack>
            </form>
        )
    }

    const ReviewBlock = () => (
        <Grid container columnGap={15}>
            <Grid>
                <InputLabel sx={{ mt: 3, mb: 1 }} >Name</InputLabel>
                <Typography variant='h7_1' color="#1A2461">Johnathan Doe</Typography>
                <InputLabel sx={{ mt: 3, mb: 1 }} >Email</InputLabel>
                <Typography variant='h7_1' color="#1A2461">johnathandoe@mail.com</Typography>
                <InputLabel sx={{ mt: 3, mb: 1 }} >Contact Number</InputLabel>
                <Typography variant='h7_1' color="#1A2461">+65 654 987 321</Typography>
                <InputLabel sx={{ mt: 3, mb: 1 }} >Nationality</InputLabel>
                <Typography variant='h7_1' color="#1A2461">Indian</Typography>
            </Grid>
            <Grid mt={3} xs={12} lg={4} border=".5px solid #737373" sx={{ height: "fit-content", padding: "10px", borderRadius: "10px" }}>
                <Typography variant="h7_1">Emergency Contact 1</Typography>
                <InputLabel sx={{ mt: 3, mb: 1 }} >Name</InputLabel>
                <Typography variant='h7_1' color="#1A2461">Johnathan Doe</Typography>
                <InputLabel sx={{ mt: 3, mb: 1 }} >Contact Number</InputLabel>
                <Typography variant='h7_1' color="#1A2461">+65 654 987 321</Typography>
            </Grid>
        </Grid>
    )




    return (
        <Box>
            <Stack mb={1} direction="row" alignItems="center" justifyContent={{xs :"space-between", sm : 'space-between', md : 'space-between', lg : 'start', xl : 'start'}} gap="100px">
                <Typography variant="h7_1">Personal details</Typography>
                <Button onClick={() => setBlock(2)} startIcon={<EditIcon fill="#FFFFFF" />} variant="contained">Edit</Button>
            </Stack>
            <Divider />
            {block == 1 && <ReviewBlock />}
            {block == 2 && <EditBlock />}
        </Box>
    )
}
