import { Divider, Typography, Button,TableContainer,
    Table,
    IconButton,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination
  } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import axios from "axios";
import { ReactComponent as DeleteIcon } from "../../../Icons/DeleteTable.svg";
import { ReactComponent as EditIcon } from "../../../Icons/EditIcon.svg";
import AlertModal from "../../../CommonComponents/AlertModal";
import { useNavigate } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import LinearProgress from '@mui/material/LinearProgress';
import CachedIcon from '@mui/icons-material/Cached';

function Queries() {
    const navigate = useNavigate()

    const [page, setPage] = useState(0); // Initialize to 0 for zero-based indexing
    const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page
    const [totalItems, setTotalItems] = useState(0); // State to hold the total count of items
    const [loading, setLoading] = useState(false)
    const [queryData, setQueryData] = useState([])

    const fetchQueries = async () => {
        setLoading(true)
        await axios.get(`${process.env.REACT_APP_API_URL}get-contacts?page=${
          page + 1
        }&limit=${rowsPerPage}`).then((response) => {
          setTotalItems(response.data.pagination.totalItems || 0)
            setQueryData(response?.data?.data || [])
            setLoading(false)
        }).catch((error) => {
          setLoading(false)
            console.log(error)
        })
    }

     // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page change
  };


    useEffect(() => {
        fetchQueries()
    }, [page, rowsPerPage])

    const [deleteModal, setDeleteModal] = useState(null);
    const [deleteId, setDeleteId] = useState(null);

    let deleteData = {
      label: "Confirm Delete Query",
      descriptionOne: "Please confirm that you wish to delete the Query.",
      buttonOne: {
        variant: "outlined",
        color: "#0E5E84",
        backgroundColor: "#FFFFFF",
        text: "Cancel",
      },
      buttonTwo: {
        variant: "contained",
        color: "#FFFFFF",
        backgroundColor: "#912018",
        text: "Confirm",
      },
    };

    const handleClose = () => {
      setDeleteModal(null);
      setDeleteId(null);
    };
  
    const deleteQuery = async () => {
      try {
          await axios.delete(
            `${process.env.REACT_APP_API_URL}delete-contact/${deleteId}`
          );
          fetchQueries();
          setDeleteModal(null);
          setDeleteId(null);
      } catch (error) {
        console.log(error);
      }
    };

  return (
    <Box>
       {deleteModal && (
        <AlertModal
          modalDatas={deleteData}
          confirmFunction={deleteQuery}
          closeFunction={handleClose}
        />
      )}
        <Box sx={{display : 'flex', justifyContent : 'space-between'}}>
        <Typography sx={{fontSize : '1.2rem', fontWeight : 700}}>Queries</Typography>
        <Typography variant="h7_1" sx={{cursor : 'pointer'}} title='Reload' onClick={() => fetchQueries()}><CachedIcon /></Typography>
        </Box>
        <Divider sx={{mt : 2, mb : 3}}/>

        <TableContainer sx={{
        mt : 3.5,
          borderRadius: "5px",
          border: "1px solid #e5e5e5",
          boxShadow: "0px 0px 30px 20px #f4f4f4",
        }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                      <Typography variant="h9_1" color="#333333">
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Mobile
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Query subject
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Actions
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {loading && (
              <TableRow>
                <TableCell colSpan={5} sx={{p :0, borderBottom : '0px'}}>
              <LinearProgress color="primary" />
              </TableCell>
              </TableRow>
            )}
                {queryData.length === 0 ? (
                  <TableRow>
                  <TableCell colSpan={5} sx={{borderBottom: "0.5px solid #dcdddc", py: 2.7}}>
                {!loading && (
                <Typography>No data found</Typography>
                )}
                </TableCell>
                </TableRow>
                  ) : (queryData.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{border: "0px",
                      backgroundColor: index % 2 === 0 ? "#f8f8f8" : "white"}}
                    //   onClick={() => navigate(`/Admin/view-query/${item._id}`)}
                    >
                      <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                        <Typography variant="h9_4" color="#333333">
                          {item?.name || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                        <Typography variant="h9_4" color="#333333">
                            {item?.email || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                        <Typography variant="h9_4" color="#333333">
                            {item?.querySubject?.slice(0, 20) + '...' || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                        <IconButton
                          onClick={() =>
                            navigate(`/Admin/view-query/${item._id}`)
                          }
                          sx={{ marginRight: "10px" }}
                        >
                          <RemoveRedEyeIcon fill="black" />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setDeleteId(item._id);
                            setDeleteModal(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )))}
                </TableBody>
              </Table>

              <TablePagination
          component="div"
          count={totalItems} 
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />

            </TableContainer>

    </Box>
  )
}

export default Queries