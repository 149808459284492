import React from 'react'
import { Typography, Box, Grid, Stack, Button } from '@mui/material'
import emailSuccessIcon from '../Icons/emailSuccess.svg';


export default function SuccessPage() {
    return (
        <Grid mt={7} xs={12} sm={8} md={5} lg={3.7} p={6} sx={{ border: ".5px solid #1A2461", borderRadius: "20px", height: "auto" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack>
                    <Typography mb={.5} variant="h4_1">Email sent</Typography>
                    <Typography variant="h9_4">Reset link sent to email</Typography>
                </Stack>
                <Box component="img" src={emailSuccessIcon} maxWidth="180px" maxHeight="180px"></Box>
            </Stack>
            <Button sx={{ mt: 1, width: "100%", backgroundColor: "#003893", '&:hover': { backgroundColor: "#003893" }, fontSize: "16px", fontWeight: 700, color: "#FFFFFF", padding: "10px 20px", borderRadius: "8px", letterSpacing: ".7px" }}>Return to homepage</Button>
        </Grid >
    )
}
