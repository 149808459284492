import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  Grid,
  Divider,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Checkbox,
  TextField,
  Card,
  CardContent,
  useMediaQuery
} from "@mui/material";
import { ReactComponent as DownArrowIcon } from "../../../../Icons/DownArrow.svg";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { useForm, Controller } from "react-hook-form";
import dayjs from "dayjs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ReactComponent as FormIcon } from "../../../../Icons/CommonFormIcon.svg";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useDispatch} from "react-redux";
import axios from "axios";



export default function Services({
  setStepperCount,
  setServicesValue,
  ServicesValue,
  email
}) {
  const isMobile = useMediaQuery('(max-width:600px)');
  
  const { ServicesData, OtherServiceData } = useSelector((state) => state.CommonData);
  const Data = useSelector((state) => state.ApplicationData.data)

  console.log('Service data from application', ServicesData)
  console.log('OtherService data from application', OtherServiceData)
  const {
    formState: { errors },
    control,
    handleSubmit,
    watch,
  } = useForm({
    mode: "onTouched",
  });

  useEffect(() => {
    window.scrollTo({
      top : 0,
      left : 0,
      behavior : 'smooth'
    })
  }, [])
  
  const {
    otherServices,
    fligt_no,
    arrival_date,
    arrival_time,
    landingServices,
  } = ServicesValue;

  console.log( 'otherServices serviceValue', otherServices)
  console.log('landingServices serviceValue',landingServices)
  // const handleChangeOtherServices = (e, item) => {
  //   const { checked, name } = e.target;
  //   const findData = ServicesData?.other_chargeble_Service?.filter(
  //     (itm, index) => itm?.id === item?.id
  //   );
  //   const removeData = otherServices.filter(
  //     (itm, index) => itm?.id !== item?.id
  //   );

  //   if (name == "other") {
  //     if (checked) {
  //       setServicesValue((prev) => ({
  //         ...prev,
  //         otherServices: [...prev[`otherServices`], findData[0]],
  //       }));
  //     } else {
  //       setServicesValue((prev) => ({ ...prev, otherServices: removeData }));
  //     }
  //   } else if (name == "landing_Services_Check") {
  //     setServicesValue((prev) => ({
  //       ...prev,
  //       landingServices: checked ? ServicesData?.landing_services_package : "",
  //     }));
  //   }
  // };

  const [selectedLandingServices, setSelectedLandingServices] = useState([]);
  const [selectedOtherServices, setSelectedOtherServices] = useState([]);

  useEffect(() => {
    setSelectedLandingServices(landingServices)
    setSelectedOtherServices(otherServices)
  },[])

  const handleLandingServicesChange = (serviceId) => (e) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedLandingServices((prev) => [...prev, serviceId]);
    } else {
      setSelectedLandingServices((prev) => prev.filter((id) => id !== serviceId));
    }
    // setServicesValue((prev) => ({
    //   ...prev,
    //   // landingServices: checked ? ServicesData.find((service) => service._id === serviceId) : null,
    //   landingServices : {...prev, selectedLandingServices}
    // }));
  };

  const handleOtherServicesChange = (itemId) => (e) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedOtherServices((prev) => [...prev, itemId]);
    } else {
      setSelectedOtherServices((prev) => prev.filter((id) => id !== itemId));
    }
    // const selectedItems = OtherServiceData.filter((item) => selectedOtherServices.includes(item._id));
    // console.log(selectedItems, 'selectedItems')
    // setServicesValue((prev) => ({
    //   ...prev,
    //   otherServices: selectedOtherServices,
    // }));
  };

  // console.log(selectedOtherServices, 'otherServices')
  // console.log(selectedLandingServices, 'landingServices')


  const onSubmit = async (data) => {
    console.log("dfsd", data);

    setServicesValue((prev) => ({
      ...prev,
      fligt_no: data?.fligt_no,
      arrival_date: data?.arrival_date,
      arrival_time: data?.arrival_time,
      otherServices : selectedOtherServices,
      landingServices : selectedLandingServices
    }));

    setStepperCount(4);
    // HandleSubmitSteps()
    const Obj = {
      fligt_no: data?.fligt_no,
      arrival_date: data?.arrival_date,
      arrival_time: data?.arrival_time,
      otherServices : selectedOtherServices,
      landingServices : selectedLandingServices
    }
    const formData = new FormData()
    formData.append("email" , email)
    formData.append("ServicesValue", JSON.stringify(Obj))
    await axios.post(`${process.env.REACT_APP_API_URL}add-application-step-data`, formData)
    .then((response) => console.log(response))
    .catch((error) => console.log(error))
  };



  return (
    <>
      {" "}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card
          sx={{ width: "90%", border: "0px solid #E5E7EB", boxShadow: "none" }}
        >
          <CardContent style={{ padding: 0, margin: 0 }}>
            <Box
              sx={{
                color: "black",
                margin: "10px 40px 10px 0px",
                cursor: "pointer",
                "&:hover": {
                  color: "black",
                },
              }}
            >
              <ArrowBackIcon
                sx={{
                  border: "1px solid grey",
                  padding: "2px 3px",
                  fontSize: "2rem",
                  borderRadius: "3px",
                }}
                onClick={() => {
                  setStepperCount(2);
                }}
              />
            </Box>
            <Typography
              style={{
                fontSize: "1.7rem",
                fontWeight: 700,
                margin: "30px 0px 40px 0px",
              }}
            >
              Services
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card
          sx={{
            width: "90%",
            border: "1px solid #E5E7EB",
            borderRadius: "6px",
            mb: "30px",
          }}
        >
          <CardContent style={{ padding: 0, margin: 0 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-between",
                      alignItems: "center",
                      backgroundColor: "#FCFCFD",
                      padding: 2,
                      border: "1px solid #E5E7EB",
                      borderRadius: "6px 6px 0px 0px",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-between",
                        alignItems: "center",
                        backgroundColor: "#FCFCFD",
                        width: "100%",
                      }}
                    >
                      <FormIcon style={{ marginRight: "10px" }} />
                      <Typography variant="h6_1">Flight Details</Typography>
                    </Box>
                  </Box>
                  
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                    gap: "20px",
                    mt: "30px",
                    mb: "30px",
                  }}
                >
                  <Stack sx={{ width: {xs : "90%", lg : "45%" }}}>
                    <InputLabel
                      sx={{
                        mb: 1,
                        fontSize: "18px",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      {errors?.fligt_no ? "Flight number*" : "Flight number"}
                    </InputLabel>
                    <Controller
                      name="fligt_no"
                      rules={{ required: true }}
                      defaultValue={fligt_no}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          placeholder="Enter flight number"
                          error={Boolean(errors?.fligt_no) ? true : false}
                          {...field}
                          sx={{
                            width: "100%",
                            backgroundColor: "rgb(249, 250, 251)",
                            borderRadius: 0,
                            "& .MuiInputBase-input": {
                              color: errors?.fligt_no ? "red" : "black",
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: errors?.fligt_no
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&:hover fieldset": {
                                borderColor: errors?.fligt_no
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: errors?.fligt_no
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                    {errors?.fligt_no ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please enter flight number
                      </Typography>
                    ) : null}
                  </Stack>

                  <Stack sx={{ width: {xs : "90%", lg : "45%" }}}>
      <InputLabel
        sx={{
          mb: 1,
          fontSize: "18px",
          fontWeight: 600,
          color: "black",
        }}
      >
        {errors?.arrival_date ? "Arrival date and time*" : "Arrival date and time"}
      </InputLabel>
      <Controller
        name="arrival_date"
        defaultValue={arrival_date ? dayjs(arrival_date) : null}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <DateTimePicker
            {...field}
            sx={{
              width: "100%",
              backgroundColor: "rgb(249, 250, 251)",
              ".MuiSvgIcon-root": { fill: "#1A2461" },
              "& .MuiInputBase-input": {
                color: errors?.arrival_date ? "red" : "black",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: errors?.arrival_date ? "red" : "rgba(0, 0, 0, 0.23)",
                },
                "&:hover fieldset": {
                  borderColor: errors?.arrival_date ? "red" : "rgba(0, 0, 0, 0.23)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: errors?.arrival_date ? "red" : "rgba(0, 0, 0, 0.23)",
                },
              },
            }}
            //label="Select Date and Time"
            renderInput={(params) => <TextField {...params} />}
            format="DD/MM/YYYY HH:mm"
            fullWidth
          />
        )}
      />
      {errors?.arrival_date && (
        <Typography style={{ color: "#ff5e74", margin: "0.3rem" }}>
          Please select arrival date and time
        </Typography>
      )}
    </Stack>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    backgroundColor: "#FCFCFD",
                    padding: 2,
                    border: "1px solid #E5E7EB",
                    borderRadius: "6px 6px 0px 0px",
                  }}
                >
                  <FormIcon style={{ marginRight: "10px" }} />
                  <Typography variant="h6_1">
                    Landing Services (Optional)
                  </Typography>
                </Box>

               


{ServicesData.map((service, index) => (
        <Stack
          key={service._id}
          gap="10px"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            border: "0.5px solid #1A2461",
            padding: { xs: '14px 5px', lg: '14px 23px' },
            borderRadius: "8px",
            margin: "30px 20px 30px 20px",
          }}
        >
          <Checkbox
            //name={`landing_Services_Check_${index + 1}`}
            checked={selectedLandingServices.includes(service._id)}
            onChange={handleLandingServicesChange(service._id)}
          />
          <Stack>
            <Typography sx={{fontSize : '24px', fontWeight : 700}} color="text_blue">
              {service.title}
            </Typography>
            <Typography variant="h9_4">
              {parse(service.description)}
            </Typography>
          </Stack>
          <Typography
            flexGrow={1}
            textAlign="end"
            variant={{ xs: "h7_2", sm: "h6_1", md: "h6_1", lg: "h6_1", xl: "h6_1" }}
            color="neutral_3"
          >
            S$ {service.amount}
          </Typography>
        </Stack>
      ))}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    backgroundColor: "#FCFCFD",
                    padding: 2,
                    border: "1px solid #E5E7EB",
                    borderRadius: "6px 6px 0px 0px",
                  }}
                >
                  <FormIcon style={{ marginRight: "10px" }} />
                  <Typography variant="h6_1">
                    Other Chargeable Services (Optional)
                  </Typography>
                </Box>

                {OtherServiceData?.map((item, index) => (
                  <Stack
                    gap="10px"
                    direction="row"
                    alignItems="center"
                    justifyContent="start"
                    sx={{
                      border: "0.5px solid #1A2461",
                      padding: {xs : '15px 5px', lg : '14px 23px'},
                      borderRadius: "8px",
                      margin: "30px 20px 30px 20px",
                    }}
                  >
                    <Box>
                    <Checkbox
                     // name={`other_${index + 1}`}
                      checked={selectedOtherServices.includes(item._id)}
                      onChange={handleOtherServicesChange(item._id)}
                    />
                    </Box>
                    <Box>
                    <Typography variant="h9_1" color="text_blue">
                      {item?.title}
                    </Typography>
                    <Typography variant="h9_4">
                      {parse(item?.description)}
                    </Typography>
                    </Box>
                    <Typography
                      flexGrow={1}
                      textAlign="end"
                      variant={{ xs: "h7_2", sm: "h6_1", md: "h6_1", lg: "h6_1", xl: "h6_1" }}
                      color="neutral_3"
                    >
                      S$ {item.amount}
                    </Typography>
                  </Stack>
                ))}
               

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    border: "1px solid #E5E7EB",
                    padding: "20px",
                    margin: "50px 0px 0px 0px",
                  }}
                >
                  <Button
                    onClick={() => {
                      setStepperCount(2);
                    }}
                    variant="standard"
                  >
                    Back
                  </Button>
                  <Button type="submit" variant="standard">
                    Continue
                  </Button>
                </Stack>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
