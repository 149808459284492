import React, { useState } from 'react'
import { Typography, Stack, Box, Button, Divider, Grid, Select, InputLabel, IconButton, TextField, MenuItem, Checkbox } from '@mui/material'
import { ReactComponent as CorrectIcon } from "../../../../Icons/Account/CorrectIcon.svg"


export default function ChecklistItems() {



    return (
        <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center" padding="42px 60px" sx={{ borderBottom: ".5px solid #1A2461" }}>
                <Stack gap="10px">
                    <Typography variant="h4_1">Furniture Equipment checklist</Typography>
                    <Typography variant="h9_4" color="neutral_4">Select  yes or no for the mentioned items</Typography>
                </Stack>
            </Stack>
            <Grid px={{ md: 10, xs: 3 }} mt={3} mb={6} container rowGap={7}>
                <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                    <Stack my={1} direction="row" justifyContent="space-between" alignItems="center">
                        <Stack gap="8px">
                            <Typography variant="h9_1" color="#1A2461">1. Room door Lock</Typography>
                        </Stack>
                        <Stack direction="row" gap="40px">
                            <Typography variant="h9_1">S$ 200</Typography>
                            <Typography variant="h9_4" sx={{ backgroundColor: "#F5F8FA", textAlign: "center", alignContent: "center", borderRadius: "8px", width: "80px", height: "30px" }}>Yes</Typography>
                            {/* <Typography variant="h9_4" sx={{ backgroundColor: "#52AD33", display:"flex",alignItems:"center",justifyContent:"center",color:"#FFFFFF", borderRadius: "8px", width: "80px", height: "30px" }}><CorrectIcon/>Yes</Typography> */}
                            {/* <Typography variant="h9_4" sx={{ backgroundColor: "#F5F8FA", textAlign: "center", alignContent: "center", borderRadius: "8px", width: "80px", height: "30px" }}>No</Typography> */}
                            <Typography variant="h9_4" sx={{ backgroundColor: "#C81919", display: "flex", alignItems: "center", justifyContent: "center", color: "#FFFFFF", borderRadius: "8px", width: "80px", height: "30px" }}><CorrectIcon />Yes</Typography>

                        </Stack>
                    </Stack>
                    <Divider />
                    <Stack my={1} direction="row" justifyContent="space-between" alignItems="center">
                        <Stack gap="8px">
                            <Typography variant="h9_1" color="#1A2461">2. Room Key</Typography>
                        </Stack>
                        <Stack direction="row" gap="40px">
                            <Typography variant="h9_1">S$ 50</Typography>
                            <Typography variant="h9_4" sx={{ backgroundColor: "#F5F8FA", textAlign: "center", alignContent: "center", borderRadius: "8px", width: "80px", height: "30px" }}>Yes</Typography>
                            {/* <Typography variant="h9_4" sx={{ backgroundColor: "#52AD33", display:"flex",alignItems:"center",justifyContent:"center",color:"#FFFFFF", borderRadius: "8px", width: "80px", height: "30px" }}><CorrectIcon/>Yes</Typography> */}

                            {/* <Typography variant="h9_4" sx={{ backgroundColor: "#F5F8FA", textAlign: "center", alignContent: "center", borderRadius: "8px", width: "80px", height: "30px" }}>No</Typography> */}
                            <Typography variant="h9_4" sx={{ backgroundColor: "#C81919", display: "flex", alignItems: "center", justifyContent: "center", color: "#FFFFFF", borderRadius: "8px", width: "80px", height: "30px" }}><CorrectIcon />Yes</Typography>

                        </Stack>
                    </Stack>
                    <Divider />
                    <Stack my={1} direction="row" justifyContent="space-between" alignItems="center">
                        <Stack gap="8px">
                            <Typography variant="h9_1" color="#1A2461">3. Room Switch</Typography>
                        </Stack>
                        <Stack direction="row" gap="40px">
                            <Typography variant="h9_1">S$ 80</Typography>
                            <Typography variant="h9_4" sx={{ backgroundColor: "#F5F8FA", textAlign: "center", alignContent: "center", borderRadius: "8px", width: "80px", height: "30px" }}>Yes</Typography>
                            {/* <Typography variant="h9_4" sx={{ backgroundColor: "#52AD33", display:"flex",alignItems:"center",justifyContent:"center",color:"#FFFFFF", borderRadius: "8px", width: "80px", height: "30px" }}><CorrectIcon/>Yes</Typography> */}

                            <Typography variant="h9_4" sx={{ backgroundColor: "#F5F8FA", textAlign: "center", alignContent: "center", borderRadius: "8px", width: "80px", height: "30px" }}>No</Typography>
                            {/* <Typography variant="h9_4" sx={{ backgroundColor: "#C81919", display:"flex",alignItems:"center",justifyContent:"center",color:"#FFFFFF", borderRadius: "8px", width: "80px", height: "30px" }}><CorrectIcon/>Yes</Typography> */}

                        </Stack>
                    </Stack>
                    <Divider />
                    <Stack my={1} direction="row" justifyContent="space-between" alignItems="center">
                        <Stack gap="8px">
                            <Typography variant="h9_1" color="#1A2461">4. Light bulb</Typography>
                        </Stack>
                        <Stack direction="row" gap="40px">
                            <Typography variant="h9_1">S$ 150</Typography>
                            <Typography variant="h9_4" sx={{ backgroundColor: "#F5F8FA", textAlign: "center", alignContent: "center", borderRadius: "8px", width: "80px", height: "30px" }}>Yes</Typography>
                            {/* <Typography variant="h9_4" sx={{ backgroundColor: "#52AD33", display:"flex",alignItems:"center",justifyContent:"center",color:"#FFFFFF", borderRadius: "8px", width: "80px", height: "30px" }}><CorrectIcon/>Yes</Typography> */}

                            <Typography variant="h9_4" sx={{ backgroundColor: "#F5F8FA", textAlign: "center", alignContent: "center", borderRadius: "8px", width: "80px", height: "30px" }}>No</Typography>
                            {/* <Typography variant="h9_4" sx={{ backgroundColor: "#C81919", display:"flex",alignItems:"center",justifyContent:"center",color:"#FFFFFF", borderRadius: "8px", width: "80px", height: "30px" }}><CorrectIcon/>Yes</Typography> */}

                        </Stack>
                    </Stack>
                    <Divider />
                </Grid>


                <Stack my={5} direction="row" alignItems="center" justifyContent="start">
                    <Button variant="standard">Complete</Button>
                </Stack>
            </Grid>

        </Box>
    )
}
