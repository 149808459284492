import React, {useEffect, useState} from 'react'
import { Box, Stack, Button, Typography, Grid, useMediaQuery } from '@mui/material'
import IntroBgImg from "../../Icons/IntroBgImg.svg"
import AboutUsImg from "../../Icons/AboutUsImg.svg"
import PlantsImg from "../../Icons/Intro/PlantsImg.svg"
import { ReactComponent as OurMissionIcon } from "../../Icons/Intro/OurMissionIcon.svg"
import { ReactComponent as ValuePropositionIcon } from "../../Icons/Intro/ValuePropositionIcon.svg"
import { ReactComponent as CommityValueIcon } from "../../Icons/Intro/CommityValueIcon.svg"
import AffordabilityImg from "../../Icons/Intro/Affordability.svg"
import ConvenienceImg from "../../Icons/Intro/Convenience.svg"
import ComfortableImg from "../../Icons/Intro/Comfortable.svg"
import { DocumentTitleChange } from '../../CommonComponents/Title'
import './Intro.css'
// import IntroBgImg from "../../assets/intro.webp"
// import AboutUsImg from "../../assets/hand.webp"

export default function Intro() {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width:600px) and (max-width:960px)');

    const [data, setData] = useState([
        {
            image : 'https://s1.1zoom.me/big0/643/Singapore_Marina_Bay_484042.jpg',
            title : 'Excellent Location',
            description : 'The hostels are close to the metro stations and have convenient transportation, making it convenient for students to attend classes and participate in on-campus activities.'
        },
        {
            image : 'https://ik.imagekit.io/tvlk/generic-asset/dgXfoyh24ryQLRcGq00cIdKHRmotrWLNlvG-TxlcLxGkiDwaUSggleJNPRgIHCX6/hotel/asset/10023195-c239d73187d7c74d477e635a1be50ca3.jpeg',
            title : 'Fully Equipped',
            description : 'The hostels are equipped with basic furniture such as beds, desks, and wardrobes, and provide Internet access so that students can study and rest comfortably.'
        },
        {
            image : 'https://edudelphi.com/wp-content/uploads/2021/11/cyber-security-best-practices-1280x720-1.jpg',
            title : 'Security',
            description : 'We attach great importance to the safety of students. There is a security monitoring system in the hostel building, and dedicated management personnel are responsible for maintaining order and safety.'
        },
        {
            image : 'https://www.xwa.edu.sg/wp-content/uploads/happy-university-students.jpg',
            title : 'Social space',
            description : 'The hostels provide shared social space and leisure space for communication and interaction between students.'
        },
        {
            image : 'https://www.xwa.edu.sg/wp-content/uploads/blog-personalised-learning-header.jpg',
            title : 'Learning support',
            description : 'The hostels have study spaces that provide a quiet study environment to help students concentrate on their studies.'
        },
        {
            image : 'https://www.science.nus.edu.sg/wp-content/uploads/2022/09/1920_1a.jpeg',
            title : 'Social activities',
            description : 'We regularly organize various activities to provide students with a rich and colorful campus life experience and promote communication and friendship between each other.'
        },
        {
            image : 'https://onecms-res.cloudinary.com/image/upload/s--wFcKg50E--/c_crop,h_900,w_1600,x_0,y_9/c_fill,g_auto,h_676,w_1200/f_auto,q_auto/v1/mediacorp/cna/image/2024/01/23/springcleaning_1.png',
            title : 'Management and service',
            description : 'A professional management team provides students with round-the-clock service and support to resolve residential issues and needs.'
        },
    ])
    // useEffect(() => {
    //     DocumentTitleChange('Intro')
    // }, [])
    return (
        <Box>
            {/* {isMobile || isTablet ? (<>
                <Box component="img" src={IntroBgImg} sx={{ objectFit: "cover"}}></Box>
                <Stack p={5} gap={4} direction="column" alignItems="center" justifyContent="center" sx={{ background: "#003893", top: "13%", right: "5%", }}>
                    <Typography p={2} width="fit-content" variant="h6_1" color="#FFFFFF" border="1px solid #FFFFFF" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px">OUR STORY</Typography>
                    <Typography sx={{ lineHeight: "28px" }} variant="h9_4" color="#FFFFFF">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consectetur ultrices ornare. Sed consectetur massa tellus, in auctor diam euismod vitae. Donec facilisis, tortor ac auctor lobortis,
                        leo odio faucibus metus, tempus accumsan ipsum purus ut libero. Nam iaculis leo eu metus feugiat maximus. Sed eu massa pretium, bibendum elit gravida, condimentum sem.
                    </Typography>
                    <Typography sx={{ lineHeight: "28px" }} variant="h9_4" color="#FFFFFF">Vestibulum aliquet lacus maximus enim convallis, nec tempus ex interdum. Mauris in magna ut odio laoreet rhoncus at ac sem.
                        Phasellus sit amet elit magna. Nulla tempor id mauris id bibendum. Fusce volutpat tortor at justo molestie, efficitur imperdiet sapien ultrices. Morbi finibus mauris quis pretium aliquam. Sed vel quam a ipsum gravida suscipit.
                        Nullam a nisl ac nibh fermentum eleifend eget vel sapien. Sed fringilla metus sed odio sagittis, id vehicula orci faucibus. Suspendisse quis tortor vitae nisl condimentum condimentum a eget libero. Etiam sem eros, efficitur et hendrerit vel, efficitur eget ligula. Maecenas porttitor rutrum tincidunt.
                        Sed malesuada consequat bibendum. Nunc sit amet pharetra orci.</Typography>
                </Stack>
            </>) : (<>
                <Stack pb={7} px={7} sx={{ position: "relative" }}>
                <Box sx={{
                    position: "relative",
                    height: "100%",
                    width: "100%",
                    overflow: "hidden",
                }}>
                    <Box component="img" src={IntroBgImg} sx={{ objectFit: "cover"}}></Box>
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: "100%",
                            width: "100%",
                            background: "linear-gradient(to left, rgb(255, 255, 255) 35%, rgba(0, 0, 0, 0) 61%)",
                        }}
                    />
                </Box>
                <Stack p={5} gap={4} direction="column" alignItems="center" justifyContent="center" sx={{ background: "#003893", maxWidth: "800px", borderRadius: "20px", position: "absolute", top: "13%", right: "5%", }}>
                    <Typography p={2} width="fit-content" variant="h6_1" color="#FFFFFF" border="1px solid #FFFFFF" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px">OUR STORY</Typography>
                    <Typography sx={{ lineHeight: "28px" }} variant="h9_4" color="#FFFFFF">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consectetur ultrices ornare. Sed consectetur massa tellus, in auctor diam euismod vitae. Donec facilisis, tortor ac auctor lobortis,
                        leo odio faucibus metus, tempus accumsan ipsum purus ut libero. Nam iaculis leo eu metus feugiat maximus. Sed eu massa pretium, bibendum elit gravida, condimentum sem.
                    </Typography>
                    <Typography sx={{ lineHeight: "28px" }} variant="h9_4" color="#FFFFFF">Vestibulum aliquet lacus maximus enim convallis, nec tempus ex interdum. Mauris in magna ut odio laoreet rhoncus at ac sem.
                        Phasellus sit amet elit magna. Nulla tempor id mauris id bibendum. Fusce volutpat tortor at justo molestie, efficitur imperdiet sapien ultrices. Morbi finibus mauris quis pretium aliquam. Sed vel quam a ipsum gravida suscipit.
                        Nullam a nisl ac nibh fermentum eleifend eget vel sapien. Sed fringilla metus sed odio sagittis, id vehicula orci faucibus. Suspendisse quis tortor vitae nisl condimentum condimentum a eget libero. Etiam sem eros, efficitur et hendrerit vel, efficitur eget ligula. Maecenas porttitor rutrum tincidunt.
                        Sed malesuada consequat bibendum. Nunc sit amet pharetra orci.</Typography>
                </Stack>
            </Stack>
            </>)} */}
            

            {/* About Us */}

            <Box sx={{ backgroundColor: "", p :{xs : 3, lg : 7}, pt : 2, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Typography p={2} mt={5} width="fit-content" variant="h6_1" color="primary" border="1px solid #27BAC4" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px">ABOUT US</Typography>
                <Stack sx={{display : 'flex', flexDirection : 'row', flexWrap : {xs : 'wrap', lg : 'nowrap'}, gap: '50px'}} mt={5}>
                    <Box component="img" src={AboutUsImg} sx={{ width: "100%", height: "100%" }}></Box>
                    <Stack mt={0} rowGap={4} justifyContent="center">
                        <Typography>Welcome to Vinga Hall!</Typography>
                        
                        <Typography sx={{ lineHeight: "28px" }} variant="h9_4" >Since 2008, we have extensive operating experience in student hostel management to provide students with a warm, safe and comfortable living environment. The needs and safety of students are our top priority, creating an ideal learning and living space for everyone.</Typography>
                        
                        <Typography sx={{ lineHeight: "28px" }} variant="h9_4" >We provide One-Stop service, including room disinfection and cleaning services twice a week, laundry services twice a week, wake-up services from Monday to Friday, roll call and reporting to parents at 10 o'clock every night. We also provide guardian services, guidance for further studies, psychological counseling, arranging tutoring, organizing indoor or outing activities on weekends, and helping students manage pocket money. Students can also choose meal packages, and our full-time chef provide delicious and nutritious meals every day.</Typography>
                    </Stack>
                </Stack>
            </Box>

           

           
                {/* <Stack mt={{xs : 0, sm : 2, lg : 5}} p={{xs : 3, lg : 7}} direction={{xs : 'column-reverse', lg : 'row'}} columnGap={10}>
                <Stack justifyContent="center" gap={{xs : 3.5, lg: 2.5}} sx={{mt : {xs : 0, sm : 4,lg : 0}}}>
                    <Stack gap={{xs : 1.5 , lg :1}}>
                        <Typography variant="h3" sx={{ display: "flex", gap: "20px", fontWeight: 700, alignItems : 'center' }}><OurMissionIcon />  Our Mission</Typography>
                        <Typography variant="h7_3">To provide the love and support our students need to develop their potential to the fullest.To provide the love and support our students need to develop their potential to the fullest.</Typography>
                    </Stack>
                    <Stack gap={{xs : 1.5 , lg :1}}>
                        <Typography variant="h3" sx={{ display: "flex", gap: "20px", fontWeight: 700, alignItems : 'center' }}><ValuePropositionIcon /> Value Proposition</Typography>
                        <Typography variant="h7_3">To provide the love and support our students need to develop their potential to the fullest.</Typography>
                    </Stack>
                    <Stack gap={{xs : 1.5 , lg :1}}>
                        <Typography variant="h3" sx={{ display: "flex", gap: "20px", fontWeight: 700, alignItems : 'center' }}><CommityValueIcon />Community Value</Typography>
                        <Typography variant="h7_3">To provide the love and support our students need to develop their potential to the fullest. </Typography>
                    </Stack>
                </Stack>
                <Box component="img" src={PlantsImg} sx={{ width: {xs : '500px', sm : '100%', lg : '500px'}, height: "450px", objectFit : {xs : 'initial', sm : 'cover', lg : 'initial'} }}></Box>
            </Stack> */}
            

            {/* Why choose Us */}

            <Box sx={{ backgroundColor: "#E9F8F9", p: {xs : 2, lg : 7}, display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "center" }}>
                <Typography sx={{mt : 4}} p={2} width="fit-content" variant="h6_1" color="primary" border="1px solid #27BAC4" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px">WHY CHOOSE US</Typography>
                    
                    <Box sx={{display : 'flex', justifyContent : 'space-between', flexDirection : {xs : 'column', lg : 'row'}, mt : 5}}>
                    <Box px={{xs : 0, lg: 3, display : 'flex', gap : 50, flexWrap : 'wrap', justifyContent : 'center'}}>
                        {data.map((con, i) => (
                            <>
                        <Box key={i} sx={{width : {xs : '100%', sm : '70%', md : '70%', lg : '30%'}}}>
                        <Box component="img" sx={{width : '100%', borderRadius : 'px'}} src={con.image}></Box>
                        <Typography variant="h3" pb={{xs : 0.3, lg :1}} my={2} sx={{ width: "fit-content", fontWeight: 700, borderBottom: "3px solid grey" }}>{con.title}</Typography>
                        <Typography px={0} textAlign='justify'>{con.description}</Typography>
                        </Box>
                        </>
                        ))}
                        
                    </Box>
                    {/* <Box mt={{xs : 5, lg : 0}} px={{xs : 0, lg: 3}} justifyContent="center" textAlign="center">
                        <Box component="img" sx={{width : '100%'}} src={ConvenienceImg}></Box>
                        <Typography variant="h3" pb={{xs : 0.3, lg :1}} my={2} sx={{ width: "fit-content", fontWeight: 700, borderBottom: "3px solid grey" }}>Convenience</Typography>

                        <Typography px={0} textAlign='justify'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consectetur ultrices ornare. Sed consectetur massa tellus, in auctor diam euismod vitae. Donec facilisis, tortor ac auctor lobortis, leo odio faucibus metus, tempus accumsan ipsum purus ut libero.</Typography>
                    </Box>
                    <Box mt={{xs : 5, lg : 0}} px={{xs : 0, lg: 3}} justifyContent="center" textAlign="center">
                        <Box component="img" sx={{width : '100%'}} src={ComfortableImg}></Box>
                        <Typography variant="h3" pb={{xs : 0.3, lg :1}} my={2} sx={{ width: "fit-content", fontWeight: 700, borderBottom: "3px solid grey" }}>Comfortable</Typography>

                        <Typography px={0} textAlign='justify'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consectetur ultrices ornare. Sed consectetur massa tellus, in auctor diam euismod vitae. Donec facilisis, tortor ac auctor lobortis, leo odio faucibus metus, tempus accumsan ipsum purus ut libero.</Typography>
                    </Box> */}
               

                    </Box>
            </Box>
            
            <Box sx={{mt : 5, mb : 3, display : 'flex', justifyContent : 'center', alignItems : 'center', width : '100%'}}>
            <Box sx={{width : '92%', height : {xs : '250px', sm : '450px', md : '580px', lg : '720px'}}}>
            <iframe src="https://drive.google.com/file/d/1ZYqukvjduDiUXG-pQSeiKPLiljQ6STru/preview" style={{width:"100%", height:"100%"}} allow="autoplay"></iframe>
            </Box>
            </Box>


            <Stack mb={7} mt={7} direction={{xs : 'column' , lg : "row"}} justifyContent="space-between" alignItems="center" mx={{xs : 2, lg : 7}} p={4} sx={{ backgroundColor: "#1A2461", borderRadius: "20px" }}>
                <Stack gap="10px">
                    <Typography p={1} width="fit-content" variant="h9_5" color="#FFFFFF" border="1px solid #FFFFFF" padding="10px 30px" borderRadius="50px" letterSpacing="1.1px">Apply Now</Typography>
                    <Typography variant="h2" color="#FFFFFF" sx={{ fontWeight: 500 }}>Start a new life in Singapore!</Typography>
                    {/* <Typography variant="h4_2" color="#FFFFFF">We make it happen</Typography> */}
                </Stack>
                <Button variant="contained" sx={{width : {xs : '100%', sm : '55%', lg : '12%'}, marginTop : {xs : '15px', lg : 0}}}>Fill form</Button>
            </Stack>
        </Box >
    )
}
