import React, { useState, useRef } from "react";
import {
  Typography,
  Box,
  Divider,
  Button,
  TextField,
  InputLabel,
  Card,
  CardContent
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { ReactComponent as FormIcon } from "../../../Icons/CommonFormIcon.svg";

export default function AddMainAmount() {
  const navigate = useNavigate();
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"], // toggled buttons
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"], // superscript/subscript
    ],
  };

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    watch,
    reset,
  } = useForm({
    mode: "onTouched",
  });


  const onSubmit = async (data) => {
    console.log(data);


    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}add-main-amount`,
        data
      );
      if(response.status === 200){
          navigate('/Admin/Charges')
      }
      console.log(response);
      // reset()
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Card
          sx={{
            width: "80%",
            border: "1px solid #E5E7EB",
            borderRadius: "6px",
          }}
        >
          <CardContent style={{ padding: 0, margin: 0 }}>
          <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                backgroundColor: "#FCFCFD",
                padding: 2,
                border: "1px solid #E5E7EB",
                borderRadius: "6px 6px 0px 0px",
              }}
            >
              <FormIcon style={{ marginRight: "10px" }} />
              <Typography variant="h6_1">Add Main Amounts</Typography>
            </Box>
        
        <form onSubmit={handleSubmit(onSubmit)}>

        <Box style={{ padding: "0px 17px 17px", margin : '20px 0px 0px 0px' }}>
              <InputLabel
                sx={{
                  mb: 1,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
                {errors?.title ? 'Title*' : 'Title'}
              </InputLabel>
              <Controller
                name="title"
                rules={{ required: true,
                 }}
                control={control}
                render={({ field }) => (
                  <TextField
                    error={Boolean(errors?.title) ? true : false}
                    {...field}
                    placeholder="Enter title"
                    sx={{ 
                      mt: 0, 
                      backgroundColor : 'rgb(249, 250, 251)',
                      borderRadius: 0, 
                      "& .MuiInputBase-input": {
                        color: errors?.title ? "red" : "black",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: errors?.title ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: errors?.title ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: errors?.title ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                      },
                    }}
                  />
                )}
              />
              {errors?.title ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>Please enter amount</Typography> : null }
              </Box>

         
        <Box style={{ padding: "25px 17px 17px 17px" }}>
        <InputLabel
                sx={{
                  mb: 1,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
                {errors?.description ? 'Description*' : 'Description'}
              </InputLabel>
              <Controller
                name="description"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <ReactQuill
                    style={{ height: "180px" }}
                    modules={modules}
                    maxLength={3000}
                    {...field}
                  />
                )}
              />
              {errors?.description ? <Typography style={{color : '#ff5e74', margin : '3.3rem 0rem 0rem 0.3rem'}}>Please enter description</Typography> : null }
              </Box>
          
              <Box style={{ padding: "0px 17px 17px", margin : '60px 0px 0px 0px' }}>
              <InputLabel
                sx={{
                  mb: 1,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
                {errors?.amount ? 'Amount*' : 'Amount'}
              </InputLabel>
              <Controller
                name="amount"
                rules={{ required: true, 
                     pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter a valid number",
                  },
                 }}
                control={control}
                render={({ field }) => (
                  <TextField
                    error={Boolean(errors?.amount) ? true : false}
                    {...field}
                    placeholder="Enter amount"
                    sx={{ 
                      mt: 0, 
                      backgroundColor : 'rgb(249, 250, 251)',
                      borderRadius: 0, 
                      "& .MuiInputBase-input": {
                        color: errors?.amount ? "red" : "black",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: errors?.amount ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: errors?.amount ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: errors?.amount ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                      },
                    }}
                  />
                )}
              />
              {errors?.amount ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>Please enter amount</Typography> : null }
              </Box>
            
              <Divider style={{marginTop : '15px'}}/>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "0px 17px 13px",
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{
                  padding: "10px 20px 10px 20px",
                  mt: 2,
                  width: "20%",
                  fontSize: 20,
                  backgroundColor: "rgb(14, 94, 132)",
                  ":hover": { bgcolor: "rgb(14, 94, 132)" },
                }}
              >
                Confirm
              </Button>
            </Box>
            
        </form>
        </CardContent>
        </Card>
      </Box>
    </>
  );
}
