import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/system'
import { Divider, Typography, Card, CardContent } from '@mui/material'
import { ReactComponent as FormIcon } from "../../../Icons/CommonFormIcon.svg";

function ViewQueries() {
    const {id} = useParams()
    const [data, setData] = useState({})

    const fetchContactById = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}get-contact-by-id/${id}`)
        .then((response) => {
            setData(response?.data?.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        if(id) {
            fetchContactById()
        }
    }, [])

    console.log(data, 'data from query')

  return (
    <Box>
        <Typography sx={{fontSize : '1.2rem', fontWeight : 700}}>View Query</Typography>
        <Divider sx={{mt : 2, mb : 3}}/>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Card sx={{
            width: "80%",
            border: "1px solid #E5E7EB",
            borderRadius: "6px",
          }}>
                <CardContent style={{ padding: 0, margin: 0 }}>
                
                <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                backgroundColor: "#f9faf9",
                padding: 2,
                border: "1px solid #E5E7EB",
                borderRadius: "6px 6px 0px 0px",

              }}
            >
              <FormIcon style={{ marginRight: "10px" }} />
              <Typography variant="h6_1">Name</Typography>
            </Box>
            <Typography sx={{padding: 3, py : 5, fontSize : '1.2rem'}}>{data?.name}</Typography>


            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                backgroundColor: "#f9faf9",
                padding: 2,
                border: "1px solid #E5E7EB",
                borderRadius: "6px 6px 0px 0px",
              }}
            >
              <FormIcon style={{ marginRight: "10px" }} />
              <Typography variant="h6_1">Email</Typography>
            </Box>
            <Typography sx={{padding: 3, py : 5,fontSize : '1.2rem'}}>{data?.email}</Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                backgroundColor: "#f9faf9",
                padding: 2,
                border: "1px solid #E5E7EB",
               
              }}
            >
              <FormIcon style={{ marginRight: "10px" }} />
              <Typography variant="h6_1">Mobile</Typography>
            </Box>
            <Typography sx={{padding: 3, py : 5,fontSize : '1.2rem'}}>{data?.mobile}</Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                backgroundColor: "#f9faf9",
                padding: 2,
                border: "1px solid #E5E7EB",
               
              }}
            >
              <FormIcon style={{ marginRight: "10px" }} />
              <Typography variant="h6_1">Query Subject</Typography>
            </Box>
            <Typography sx={{padding: 3, py : 5,fontSize : '1.2rem'}}>{data?.querySubject}</Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                backgroundColor: "#f9faf9",
                padding: 2,
                border: "1px solid #E5E7EB",
                
              }}
            >
              <FormIcon style={{ marginRight: "10px" }} />
              <Typography variant="h6_1">Message</Typography>
            </Box>
            <Typography sx={{padding: 3, py : 6,fontSize : '1.2rem'}}>{data?.message}</Typography>


                
            
            </CardContent>
            </Card>
        </Box>

    </Box>
  )
}

export default ViewQueries