import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  InputLabel,
  TextField,
  IconButton,
  Card,
  CardContent,
  useMediaQuery,
  Select,
  MenuItem,
  FormControl,
  styled,
  Button,
  Modal,
} from "@mui/material";
import { ReactComponent as AddContactIcon } from "../../../../Icons/AddContactIcon.svg";
import { ReactComponent as UploadFileIcon } from "../../../../Icons/UploadFileIcon.svg";
import { ReactComponent as ImgIcon } from "../../../../Icons/ImgIcon.svg";
import ClearIcon from "@mui/icons-material/Clear";
import { useForm, Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { ReactComponent as FormIcon } from "../../../../Icons/CommonFormIcon.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { HostelGetAll } from '../../../../Redux/Feature/CommonSlice'
import { useDispatch } from 'react-redux'

export default function Information({
  setStepperCount,
  setPersonalData,
  PersonalData,
  email,
  setFlowCount,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    borderRadius: "10px",
    p: 6,
  };

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(HostelGetAll())
  },[])

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const StyledPhoneInput = styled(PhoneInput)(({ theme, error }) => ({
  //   '& .form-control': {
  //     width: '100%',
  //     height: '54px',
  //     padding: '15px 55px',
  //     fontSize: '16px',
  //     backgroundColor: 'rgb(249, 250, 251)',
  //     borderRadius: '7px',
  //     border: `1px solid ${error ? '#ff5e74' : 'rgba(0, 0, 0, 0.23)'}`,
  //     '&:focus': {
  //       borderColor: error ? '#ff5e74' : 'rgba(0, 0, 0, 0.23)',
  //     },
  //   },
  //   '& .flag-dropdown': {
  //     backgroundColor: 'rgb(249, 250, 251)',
  //     borderRadius: '7px 0 0 7px',
  //     borderColor: error ? '#ff5e74' : 'rgba(0, 0, 0, 0.23)',
  //   },
  // }));

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    });
  }, []);

  const ipaFile = useRef();
  const isMobile = useMediaQuery("(max-width:600px)");
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
    setValue,
  } = useForm({
    mode: "onTouched",
    shouldUnregister: false,
  });

  const initial_Emergency_contact = {
    Emergency_name: "",
    Emergency_contact_no: "",
    Emergency_email: "",
    Emergency_whatsapp: "",
    Emergency_wechat: "",
    Emergency_line: "",
    Emergency_kakaotalk: "",
  };

  const { Details, Emergency_contact } = PersonalData;

  const [loading, setLoading] = useState(true)
  

  
  const Imgwatch = watch("ipa");
  const EmailWatch = watch("email");

  useEffect(() => {
    if(Details){
      setValue("ipa", Details?.ipaFile);
      setLoading(false)
    }
  }, [Details]);


  if(loading){
    return <p>Loading...</p>
  }

  console.log(ipaFile, "ipafile from information");
  const onSubmit = async (data) => {
    const {
      name,
      dob,
      nationality,
      gender,
      passport_no,
      school,
      contact_no,
      wechat,
      whatsapp,
      line,
      kakaotalk,
      note,
      ipa,
    } = data;

    const Emerdataas = Emergency_contact.map((itm, index) => ({
      Emergency_name: data[`Emergency_name_${index + 1}`],
      Emergency_contact_no: data[`Emergency_contactno_${index + 1}`],
      Emergency_email: data[`Emergency_email_${index + 1}`],
      Emergency_whatsapp: data[`Emergency_whatsapp_${index + 1}`],
      Emergency_wechat: data[`Emergency_wechat_${index + 1}`],
      Emergency_line: data[`Emergency_line_${index + 1}`],
      Emergency_kakaotalk: data[`Emergency_kakaotalk_${index + 1}`],
    }));

    setPersonalData({
      Details: {
        name: name,
        dob: dob,
        nationality: nationality,
        gender: gender,
        passport_no: passport_no,
        school: school,
        contact_no: contact_no,
        wechat: wechat,
        whatsapp: whatsapp,
        line: line,
        kakaotalk: kakaotalk,
        note: note,
        ipaFile: ipa,
      },
      Emergency_contact: Emerdataas,
    });

    const Obj = {
      Details: {
        name: name,
        dob: dob,
        nationality: nationality,
        gender: gender,
        passport_no: passport_no,
        school: school,
        contact_no: contact_no,
        wechat: wechat,
        whatsapp: whatsapp,
        line: line,
        kakaotalk: kakaotalk,
        note: note,
        ipaFile: ipa,
      },
      Emergency_contact: Emerdataas,
    };
    setStepperCount(2);

    const formData = new FormData();
    formData.append("email", email);
    formData.append("PersonalData", JSON.stringify(Obj));
    formData.append("ipaFile", ipa[0]);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}add-application-step-data`,
        formData
      )
      .then((response) => console.log(response))
      .catch((error) => console.log(error));

    // HandleSubmitSteps()
  };

  const handleImage = () => {
    ipaFile.current.click();
  };

  const handleDeleteContact = (ind) => {
    const updatedEmergencyContacts = Emergency_contact.filter(
      (_, index) => index !== ind
    );

    // Clear the values of the removed emergency contact fields
    setValue(`Emergency_name_${ind + 1}`, "");
    setValue(`Emergency_contactno_${ind + 1}`, "");
    setValue(`Emergency_email_${ind + 1}`, "");
    setValue(`Emergency_whatsapp_${ind + 1}`, "");
    setValue(`Emergency_wechat_${ind + 1}`, "");
    setValue(`Emergency_line_${ind + 1}`, "");
    setValue(`Emergency_kakaotalk_${ind + 1}`, "");

    setPersonalData((prev) => ({
      ...prev,
      Emergency_contact: updatedEmergencyContacts,
    }));
  };
  console.log(Imgwatch);

  console.log(Emergency_contact);
  console.log(PersonalData);

  console.log(errors.dob, "errors date of birth");

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <ToastContainer />
        <Card
          sx={{ width: "90%", border: "0px solid #E5E7EB", boxShadow: "none" }}
        >
          <CardContent style={{ padding: 0, margin: 0 }}>
            <Box
              sx={{
                color: "black",
                margin: "10px 40px 10px 0px",
                cursor: "pointer",
                "&:hover": {
                  color: "black",
                },
              }}
            >
              <ArrowBackIcon
                sx={{
                  border: "1px solid grey",
                  padding: "2px 3px",
                  fontSize: "2rem",
                  borderRadius: "3px",
                }}
                onClick={() => {
                  setFlowCount(1);
                }}
              />
            </Box>
            <Typography
              style={{
                fontSize: "1.7rem",
                fontWeight: 700,
                margin: "30px 0px 40px 0px",
              }}
            >
              Applicant Information
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card
          sx={{
            width: "90%",
            border: "1px solid #E5E7EB",
            borderRadius: "6px",
            mb: "30px",
          }}
        >
          <CardContent style={{ padding: 0, margin: 0 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-between",
                      alignItems: "center",
                      backgroundColor: "#FCFCFD",
                      padding: 2,
                      border: "1px solid #E5E7EB",
                      borderRadius: "6px 6px 0px 0px",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-between",
                        alignItems: "center",
                        backgroundColor: "#FCFCFD",
                        width: "100%",
                      }}
                    >
                      <FormIcon style={{ marginRight: "10px" }} />
                      <Typography variant="h6_1">Personal Details</Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                    gap: "20px",
                    mt: "20px",
                    mb: "30px",
                  }}
                >
                  <Stack sx={{ width: { xs: "90%", lg: "45%" } }}>
                    <InputLabel
                      sx={{
                        mb: 1,
                        fontSize: "18px",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      {errors?.name ? "Name*" : "Name*"}
                    </InputLabel>
                    <Controller
                      name="name"
                      control={control}
                      defaultValue={Details?.name}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <TextField
                          placeholder="Enter name"
                          error={Boolean(errors?.name) ? true : false}
                          {...field}
                          sx={{
                            width: "100%",
                            backgroundColor: "rgb(249, 250, 251)",
                            borderRadius: 0,
                            "& .MuiInputBase-input": {
                              color: errors?.name ? "red" : "black",
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: errors?.name
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&:hover fieldset": {
                                borderColor: errors?.name
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: errors?.name
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                    {errors?.name ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please enter name
                      </Typography>
                    ) : null}
                  </Stack>

                  <Stack sx={{ width: { xs: "90%", lg: "45%" } }}>
                    <InputLabel
                      sx={{
                        mb: 1,
                        fontSize: "18px",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      {errors?.dob ? "Date of Birth*" : "Date of Birth*"}
                    </InputLabel>
                    <Controller
                      name="dob"
                      control={control}
                      defaultValue={Details?.dob ? dayjs(Details?.dob) : null}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              {...field}
                              sx={{
                                width: "100%",
                                backgroundColor: "rgb(249, 250, 251)",
                                ".MuiSvgIcon-root": { fill: "#1A2461" },
                                "& .MuiInputBase-input": {
                                  color: errors?.dob ? "red" : "black", // Text color based on error state
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: errors?.dob
                                      ? "red"
                                      : "rgba(0, 0, 0, 0.23)", // Default border color
                                  },
                                  "&:hover fieldset": {
                                    borderColor: errors?.dob
                                      ? "red"
                                      : "rgba(0, 0, 0, 0.87)", // Hover border color
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: errors?.dob
                                      ? "red"
                                      : "rgba(0, 0, 0, 0.87)", // Focused border color
                                  },
                                },
                              }}
                              format="LL"
                              fullWidth
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                    {errors?.dob && (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please select Date of Birth
                      </Typography>
                    )}
                  </Stack>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                    gap: "20px",
                    mt: "20px",
                    mb: "30px",
                  }}
                >
                  <Stack sx={{ width: { xs: "90%", lg: "45%" } }}>
                    <InputLabel
                      sx={{
                        mb: 1,
                        fontSize: "18px",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      {errors?.nationality ? "Nationality*" : "Nationality*"}
                    </InputLabel>
                    <Controller
                      name="nationality"
                      control={control}
                      defaultValue={Details?.nationality}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <FormControl
                          sx={{
                            width: "100%",
                            backgroundColor: "rgb(249, 250, 251)",
                            borderRadius: 0,
                            "& .MuiInputBase-input": {
                              color: errors?.nationality ? "red" : "black",
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: errors?.nationality
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&:hover fieldset": {
                                borderColor: errors?.nationality
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: errors?.nationality
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                            },
                          }}
                        >
                          <Select
                            {...field}
                            displayEmpty
                            error={Boolean(errors.nationality)}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="" disabled>
                              Select Nationality
                            </MenuItem>
                            <MenuItem value="Indian">Indian</MenuItem>
                            <MenuItem value="American">American</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                    {errors?.nationality ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please enter nationality
                      </Typography>
                    ) : null}
                  </Stack>

                  <Stack sx={{ width: { xs: "90%", lg: "45%" } }}>
                    <InputLabel
                      sx={{
                        mb: 1,
                        fontSize: "18px",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      {errors?.gender ? "Gender*" : "Gender*"}
                    </InputLabel>
                    <Controller
                      name="gender"
                      control={control}
                      defaultValue={Details?.gender || ""}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <FormControl
                          sx={{
                            width: "100%",
                            backgroundColor: "rgb(249, 250, 251)",
                            borderRadius: 0,
                            "& .MuiInputBase-input": {
                              color: errors?.gender ? "red" : "black",
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: errors?.gender
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&:hover fieldset": {
                                borderColor: errors?.gender
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: errors?.gender
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                            },
                          }}
                        >
                          <Select
                            {...field}
                            displayEmpty
                            error={Boolean(errors?.gender)}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="" disabled>
                              Select gender
                            </MenuItem>
                            <MenuItem value="Male">Male</MenuItem>
                            <MenuItem value="Female">Female</MenuItem>
                            {/* <MenuItem value="other">Other</MenuItem> */}
                          </Select>
                        </FormControl>
                      )}
                    />
                    {errors?.gender && (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please select gender
                      </Typography>
                    )}
                  </Stack>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                    gap: "20px",
                    mt: "20px",
                    mb: "30px",
                  }}
                >
                  <Stack sx={{ width: { xs: "90%", lg: "45%" } }}>
                    <InputLabel
                      sx={{
                        mb: 1,
                        fontSize: "18px",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      {errors?.passport_no
                        ? "Passport Number*"
                        : "Passport Number*"}
                    </InputLabel>
                    <Controller
                      name="passport_no"
                      control={control}
                      defaultValue={Details?.passport_no}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <TextField
                          placeholder="Enter passport number"
                          error={Boolean(errors.passport_no) ? true : false}
                          {...field}
                          sx={{
                            width: "100%",
                            backgroundColor: "rgb(249, 250, 251)",
                            borderRadius: 0,
                            "& .MuiInputBase-input": {
                              color: errors?.passport_no ? "red" : "black",
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: errors?.passport_no
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&:hover fieldset": {
                                borderColor: errors?.passport_no
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: errors?.passport_no
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                    {errors?.passport_no ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please enter passport number
                      </Typography>
                    ) : null}
                  </Stack>

                  <Stack sx={{ width: { xs: "90%", lg: "45%" } }}>
                    <InputLabel
                      sx={{
                        mb: 1,
                        fontSize: "18px",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      {errors?.school ? "School*" : "School"}
                    </InputLabel>
                    <Controller
                      name="school"
                      control={control}
                      defaultValue={Details?.school}
                      rules={{
                        required: false,
                      }}
                      render={({ field }) => (
                        <TextField
                          placeholder="Enter school"
                          error={Boolean(errors.school) ? true : false}
                          {...field}
                          sx={{
                            width: "100%",
                            backgroundColor: "rgb(249, 250, 251)",
                            borderRadius: 0,
                            "& .MuiInputBase-input": {
                              color: errors?.school ? "red" : "black",
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: errors?.school
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&:hover fieldset": {
                                borderColor: errors?.school
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: errors?.school
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                    {errors?.school ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please enter school
                      </Typography>
                    ) : null}
                  </Stack>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    backgroundColor: "#FCFCFD",
                    padding: 2,
                    border: "1px solid #E5E7EB",
                    borderRadius: "6px 6px 0px 0px",
                  }}
                >
                  <FormIcon style={{ marginRight: "10px" }} />
                  <Typography variant="h6_1">Contact</Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                    gap: "20px",
                    mt: "20px",
                    mb: "30px",
                  }}
                >
                  <Stack sx={{ width: { xs: "90%", lg: "45%" } }}>
                    <InputLabel
                      sx={{
                        mb: 1,
                        fontSize: "18px",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      {errors?.contact_no
                        ? "Contact number*"
                        : "Contact number*"}
                    </InputLabel>
                    <Controller
                      name="contact_no"
                      control={control}
                      defaultValue={Details?.contact_no}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="Enter contact number"
                          error={Boolean(errors.contact_no) ? true : false}
                          sx={{
                            width: "100%",
                            backgroundColor: "rgb(249, 250, 251)",
                            borderRadius: 0,
                            "& .MuiInputBase-input": {
                              color: errors?.contact_no ? "red" : "black",
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: errors?.contact_no
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&:hover fieldset": {
                                borderColor: errors?.contact_no
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: errors?.contact_no
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                    {errors?.contact_no ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please enter contact number
                      </Typography>
                    ) : null}
                  </Stack>

                  <Stack sx={{ width: { xs: "90%", lg: "45%" } }}>
                    <InputLabel
                      sx={{
                        mb: 1,
                        fontSize: "18px",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      {errors?.wechat ? "We Chat*" : "We Chat"}
                    </InputLabel>
                    <Controller
                      name="wechat"
                      defaultValue={Details?.wechat}
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="Enter wechat number"
                          error={Boolean(errors.wechat) ? true : false}
                          sx={{
                            width: "100%",
                            backgroundColor: "rgb(249, 250, 251)",
                            borderRadius: 0,
                            "& .MuiInputBase-input": {
                              color: errors?.wechat ? "red" : "black",
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: errors?.wechat
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&:hover fieldset": {
                                borderColor: errors?.wechat
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: errors?.wechat
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                    {errors?.wechat ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please enter wechat
                      </Typography>
                    ) : null}
                  </Stack>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                    gap: "20px",
                    mt: "20px",
                    mb: "30px",
                  }}
                >
                  <Stack sx={{ width: { xs: "90%", lg: "45%" } }}>
                    <InputLabel
                      sx={{
                        mb: 1,
                        fontSize: "18px",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      {errors?.whatsapp ? "Whatsapp*" : "Whatsapp"}
                    </InputLabel>
                    <Controller
                      name="whatsapp"
                      control={control}
                      defaultValue={Details?.whatsapp}
                      rules={{
                        required: false,
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="Enter whatsapp number"
                          error={Boolean(errors.whatsapp) ? true : false}
                          sx={{
                            width: "100%",
                            backgroundColor: "rgb(249, 250, 251)",
                            borderRadius: 0,
                            "& .MuiInputBase-input": {
                              color: errors?.whatsapp ? "red" : "black",
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: errors?.whatsapp
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&:hover fieldset": {
                                borderColor: errors?.whatsapp
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: errors?.whatsapp
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                    {errors?.whatsapp ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please enter whatsapp
                      </Typography>
                    ) : null}
                  </Stack>

                  <Stack sx={{ width: { xs: "90%", lg: "45%" } }}>
                    <InputLabel
                      sx={{
                        mb: 1,
                        fontSize: "18px",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      {errors?.line ? "LINE*" : "LINE"}
                    </InputLabel>
                    <Controller
                      name="line"
                      control={control}
                      defaultValue={Details?.line}
                      rules={{
                        required: false,
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="Enter line number"
                          error={Boolean(errors.line) ? true : false}
                          sx={{
                            width: "100%",
                            backgroundColor: "rgb(249, 250, 251)",
                            borderRadius: 0,
                            "& .MuiInputBase-input": {
                              color: errors?.line ? "red" : "black",
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: errors?.line
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&:hover fieldset": {
                                borderColor: errors?.line
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: errors?.line
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                    {errors?.line ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please enter Line
                      </Typography>
                    ) : null}
                  </Stack>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    gap: "20px",
                    mt: "20px",
                    mb: "30px",
                  }}
                >
                  <Stack sx={{ width: { xs: "90%", lg: "95%" } }}>
                    <InputLabel
                      sx={{
                        mb: 1,
                        fontSize: "18px",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      {errors?.kakaotalk ? "KakaoTalk*" : "KakaoTalk"}
                    </InputLabel>
                    <Controller
                      name="kakaotalk"
                      defaultValue={Details?.kakaotalk}
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="Enter kakaotalk number"
                          error={Boolean(errors.kakaotalk) ? true : false}
                          sx={{
                            width: "100%",
                            backgroundColor: "rgb(249, 250, 251)",
                            borderRadius: 0,
                            "& .MuiInputBase-input": {
                              color: errors?.kakaotalk ? "red" : "black",
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: errors?.kakaotalk
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&:hover fieldset": {
                                borderColor: errors?.kakaotalk
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: errors?.kakaotalk
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                    {errors?.kakaotalk ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please enter KakaoTalk
                      </Typography>
                    ) : null}
                  </Stack>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    backgroundColor: "#FCFCFD",
                    padding: 2,
                    border: "1px solid #E5E7EB",
                    borderRadius: "6px 6px 0px 0px",
                  }}
                >
                  <FormIcon style={{ marginRight: "10px" }} />
                  <Typography variant="h6_1">Emergency Contact</Typography>
                </Box>

                {Emergency_contact?.map((item, index) => (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "20px",
                      }}
                    >
                      <Card sx={{ width: "95%", border: "1px solid #E5E7EB" }}>
                        <CardContent
                          sx={{ padding: { xs: 1.5, lg: 3 }, marging: 0 }}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography variant={{ xs: "h6_1", lg: "h7_1" }}>
                              Contact {index + 1}
                            </Typography>
                            {index > 0 && (
                              <IconButton
                                onClick={() => handleDeleteContact(index)}
                              >
                                {" "}
                                <ClearIcon
                                  sx={{ color: "#f37777", fontSize: "30px" }}
                                />
                              </IconButton>
                            )}
                          </Stack>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              flexWrap: "wrap",
                              gap: "20px",
                              mt: "20px",
                              mb: "30px",
                            }}
                          >
                            <Stack sx={{ width: { xs: "100%", lg: "45%" } }}>
                              <InputLabel
                                sx={{
                                  mb: 1,
                                  fontSize: "18px",
                                  fontWeight: 600,
                                  color: "black",
                                }}
                              >
                                {Boolean(errors[`Emergency_name_${index + 1}`])
                                  ? "Name*"
                                  : "Name*"}
                              </InputLabel>
                              <Controller
                                name={"Emergency_name_" + (index + 1)}
                                control={control}
                                defaultValue={
                                  Emergency_contact[index]?.Emergency_name
                                }
                                rules={{
                                  required: true,
                                }}
                                render={({ field }) => (
                                  <TextField
                                    placeholder="Enter name"
                                    error={
                                      Boolean(
                                        errors[`Emergency_name_${index + 1}`]
                                      )
                                        ? true
                                        : false
                                    }
                                    {...field}
                                    sx={{
                                      width: "100%",
                                      backgroundColor: "rgb(249, 250, 251)",
                                      borderRadius: 0,
                                      "& .MuiInputBase-input": {
                                        color: Boolean(
                                          errors[`Emergency_name_${index + 1}`]
                                        )
                                          ? "red"
                                          : "black",
                                      },
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `Emergency_name_${index + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `Emergency_name_${index + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `Emergency_name_${index + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                      },
                                    }}
                                  />
                                )}
                              />
                              {Boolean(
                                errors[`Emergency_name_${index + 1}`]
                              ) ? (
                                <Typography
                                  style={{ color: "#ff5e74", margin: "0.3rem" }}
                                >
                                  Please enter name
                                </Typography>
                              ) : null}
                            </Stack>

                            <Stack sx={{ width: { xs: "100%", lg: "45%" } }}>
                              <InputLabel
                                sx={{
                                  mb: 1,
                                  fontSize: "18px",
                                  fontWeight: 600,
                                  color: "black",
                                }}
                              >
                                {Boolean(
                                  errors[`Emergency_contactno_${index + 1}`]
                                )
                                  ? "Contact Number*"
                                  : "Contact Number*"}
                              </InputLabel>
                              <Controller
                                name={"Emergency_contactno_" + (index + 1)}
                                defaultValue={
                                  Emergency_contact[index]?.Emergency_contact_no
                                }
                                control={control}
                                rules={{
                                  required: true,
                                }}
                                render={({ field }) => (
                                  <TextField
                                    placeholder="Enter contact number"
                                    {...field}
                                    error={Boolean(
                                      errors[`Emergency_contactno_${index + 1}`]
                                    )}
                                    sx={{
                                      width: "100%",
                                      backgroundColor: "rgb(249, 250, 251)",
                                      borderRadius: 0,
                                      "& .MuiInputBase-input": {
                                        color: Boolean(
                                          errors[
                                            `Emergency_contactno_${index + 1}`
                                          ]
                                        )
                                          ? "red"
                                          : "black",
                                      },
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `Emergency_contactno_${index + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `Emergency_contactno_${index + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `Emergency_contactno_${index + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                      },
                                    }}
                                  />
                                )}
                              />
                              {Boolean(
                                errors[`Emergency_contactno_${index + 1}`]
                              ) ? (
                                <Typography
                                  style={{ color: "#ff5e74", margin: "0.3rem" }}
                                >
                                  Please enter contact number
                                </Typography>
                              ) : null}
                            </Stack>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              flexWrap: "wrap",
                              gap: "20px",
                              mt: "20px",
                              mb: "30px",
                            }}
                          >
                            <Stack sx={{ width: { xs: "100%", lg: "45%" } }}>
                              <InputLabel
                                sx={{
                                  mb: 1,
                                  fontSize: "18px",
                                  fontWeight: 600,
                                  color: "black",
                                }}
                              >
                                {Boolean(errors[`Emergency_email_${index + 1}`])
                                  ? "Email*"
                                  : "Email*"}
                              </InputLabel>
                              <Controller
                                name={"Emergency_email_" + (index + 1)}
                                defaultValue={
                                  Emergency_contact[index]?.Emergency_email
                                }
                                control={control}
                                rules={{
                                  required: true,
                                  pattern: {
                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                    message: "Invalid email address",
                                  },
                                }}
                                render={({ field }) => (
                                  <TextField
                                    placeholder="Enter email"
                                    error={
                                      Boolean(
                                        errors[`Emergency_email_${index + 1}`]
                                      )
                                        ? true
                                        : false
                                    }
                                    {...field}
                                    sx={{
                                      width: "100%",
                                      backgroundColor: "rgb(249, 250, 251)",
                                      borderRadius: 0,
                                      "& .MuiInputBase-input": {
                                        color: Boolean(
                                          errors[`Emergency_email_${index + 1}`]
                                        )
                                          ? "red"
                                          : "black",
                                      },
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `Emergency_email_${index + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `Emergency_email_${index + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `Emergency_email_${index + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                      },
                                    }}
                                  />
                                )}
                              />
                              {Boolean(
                                errors[`Emergency_email_${index + 1}`]
                              ) ? (
                                <Typography
                                  style={{ color: "#ff5e74", margin: "0.3rem" }}
                                >
                                  Please enter email
                                </Typography>
                              ) : null}
                            </Stack>

                            <Stack sx={{ width: { xs: "100%", lg: "45%" } }}>
                              <InputLabel
                                sx={{
                                  mb: 1,
                                  fontSize: "18px",
                                  fontWeight: 600,
                                  color: "black",
                                }}
                              >
                                {Boolean(
                                  errors[`Emergency_whatsapp_${index + 1}`]
                                )
                                  ? "Whatsapp*"
                                  : "Whatsapp"}
                              </InputLabel>
                              <Controller
                                name={"Emergency_whatsapp_" + (index + 1)}
                                defaultValue={
                                  Emergency_contact[index]?.Emergency_whatsapp
                                }
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    placeholder="Enter whatsapp number"
                                    sx={{
                                      width: "100%",
                                      backgroundColor: "rgb(249, 250, 251)",
                                      borderRadius: 0,
                                      "& .MuiInputBase-input": {
                                        color: Boolean(
                                          errors[
                                            `Emergency_whatsapp_${index + 1}`
                                          ]
                                        )
                                          ? "red"
                                          : "black",
                                      },
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `Emergency_whatsapp_${index + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `Emergency_whatsapp_${index + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `Emergency_whatsapp_${index + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                      },
                                    }}
                                    error={Boolean(
                                      errors[`Emergency_whatsapp_${index + 1}`]
                                    )}
                                  />
                                )}
                              />
                              {Boolean(
                                errors[`Emergency_whatsapp_${index + 1}`]
                              ) ? (
                                <Typography
                                  style={{ color: "#ff5e74", margin: "0.3rem" }}
                                >
                                  Please enter whatsapp
                                </Typography>
                              ) : null}
                            </Stack>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              flexWrap: "wrap",
                              gap: "20px",
                              mt: "20px",
                              mb: "30px",
                            }}
                          >
                            <Stack sx={{ width: { xs: "100%", lg: "45%" } }}>
                              <InputLabel
                                sx={{
                                  mb: 1,
                                  fontSize: "18px",
                                  fontWeight: 600,
                                  color: "black",
                                }}
                              >
                                {Boolean(
                                  errors[`Emergency_wechat_${index + 1}`]
                                )
                                  ? "WeChat*"
                                  : "WeChat"}
                              </InputLabel>
                              <Controller
                                name={"Emergency_wechat_" + (index + 1)}
                                defaultValue={
                                  Emergency_contact[index]?.Emergency_wechat
                                }
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => (
                                  <TextField
                                    placeholder="Enter wechat number"
                                    {...field}
                                    error={Boolean(
                                      errors[`Emergency_wechat_${index + 1}`]
                                    )}
                                    sx={{
                                      width: "100%",
                                      backgroundColor: "rgb(249, 250, 251)",
                                      borderRadius: 0,
                                      "& .MuiInputBase-input": {
                                        color: Boolean(
                                          errors[
                                            `Emergency_wechat_${index + 1}`
                                          ]
                                        )
                                          ? "red"
                                          : "black",
                                      },
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `Emergency_wechat_${index + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `Emergency_wechat_${index + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `Emergency_wechat_${index + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                      },
                                    }}
                                  />
                                )}
                              />
                              {Boolean(
                                errors[`Emergency_wechat_${index + 1}`]
                              ) ? (
                                <Typography
                                  style={{ color: "#ff5e74", margin: "0.3rem" }}
                                >
                                  Please enter WeChat
                                </Typography>
                              ) : null}
                            </Stack>

                            <Stack sx={{ width: { xs: "100%", lg: "45%" } }}>
                              <InputLabel
                                sx={{
                                  mb: 1,
                                  fontSize: "18px",
                                  fontWeight: 600,
                                  color: "black",
                                }}
                              >
                                {Boolean(errors[`Emergency_line_${index + 1}`])
                                  ? "LINE*"
                                  : "LINE"}
                              </InputLabel>
                              <Controller
                                name={"Emergency_line_" + (index + 1)}
                                defaultValue={
                                  Emergency_contact[index]?.Emergency_line
                                }
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => (
                                  <TextField
                                    placeholder="Enter line number"
                                    {...field}
                                    error={Boolean(
                                      errors[`Emergency_line_${index + 1}`]
                                    )}
                                    sx={{
                                      width: "100%",
                                      backgroundColor: "rgb(249, 250, 251)",
                                      borderRadius: 0,
                                      "& .MuiInputBase-input": {
                                        color: Boolean(
                                          errors[`Emergency_line_${index + 1}`]
                                        )
                                          ? "red"
                                          : "black",
                                      },
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `Emergency_line_${index + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `Emergency_line_${index + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `Emergency_line_${index + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                      },
                                    }}
                                  />
                                )}
                              />
                              {Boolean(
                                errors[`Emergency_line_${index + 1}`]
                              ) ? (
                                <Typography
                                  style={{ color: "#ff5e74", margin: "0.3rem" }}
                                >
                                  Please enter LINE
                                </Typography>
                              ) : null}
                            </Stack>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              flexWrap: "wrap",
                              gap: "20px",
                              mt: "20px",
                              mb: "30px",
                            }}
                          >
                            <Stack sx={{ width: { xs: "100%", lg: "95%" } }}>
                              <InputLabel
                                sx={{
                                  mb: 1,
                                  fontSize: "18px",
                                  fontWeight: 600,
                                  color: "black",
                                }}
                              >
                                {Boolean(
                                  errors[`Emergency_kakaotalk_${index + 1}`]
                                )
                                  ? "KakaoTalk*"
                                  : "KakaoTalk"}
                              </InputLabel>
                              <Controller
                                name={"Emergency_kakaotalk_" + (index + 1)}
                                defaultValue={
                                  Emergency_contact[index]?.Emergency_kakaotalk
                                }
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => (
                                  <TextField
                                    placeholder="Enter kakaotalk number"
                                    {...field}
                                    error={Boolean(
                                      errors[`Emergency_kakaotalk_${index + 1}`]
                                    )}
                                    sx={{
                                      width: "100%",
                                      backgroundColor: "rgb(249, 250, 251)",
                                      borderRadius: 0,
                                      "& .MuiInputBase-input": {
                                        color: Boolean(
                                          errors[
                                            `Emergency_kakaotalk_${index + 1}`
                                          ]
                                        )
                                          ? "red"
                                          : "black",
                                      },
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `Emergency_kakaotalk_${index + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `Emergency_kakaotalk_${index + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `Emergency_kakaotalk_${index + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                      },
                                    }}
                                  />
                                )}
                              />
                              {Boolean(
                                errors[`Emergency_kakaotalk_${index + 1}`]
                              ) ? (
                                <Typography
                                  style={{ color: "#ff5e74", margin: "0.3rem" }}
                                >
                                  Please enter KakaoTalk
                                </Typography>
                              ) : null}
                            </Stack>
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                  </>
                ))}

                <Box
                  sx={{
                    margin: "30px 50px 30px 0px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    startIcon={<AddContactIcon fill="#FFFFFF" />}
                    onClick={() => {
                      setPersonalData((prev) => ({
                        ...prev,
                        Emergency_contact: [
                          ...prev[`Emergency_contact`],
                          initial_Emergency_contact,
                        ],
                      }));
                    }}
                    variant="standard"
                  >
                    Add Another Contact
                  </Button>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    backgroundColor: "#FCFCFD",
                    padding: 2,
                    border: "1px solid #E5E7EB",
                    borderRadius: "6px 6px 0px 0px",
                  }}
                >
                  <FormIcon style={{ marginRight: "10px" }} />
                  <Typography variant="h6_1">NOTE</Typography>
                </Box>

                <InputLabel sx={{ mt: 3, ml: 4 }} variant="standard">
                  Any Allergies to Food or Medication? If 'Yes' means explain
                  it. If no means please enter 'No'.
                </InputLabel>
                <Box sx={{ margin: "25px" }}>
                  <Controller
                    name="note"
                    defaultValue={Details?.note}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        sx={{
                          ".MuiInputBase-input": { minHeight: "200px" },
                        }}
                        error={Boolean(errors.alergies) ? true : false}
                        {...field}
                        multiline
                      />
                    )}
                  />
                  {errors?.note ? (
                    <Typography style={{ color: "#ff5e74", margin: "0.3rem" }}>
                      Please enter note
                    </Typography>
                  ) : null}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    backgroundColor: "#FCFCFD",
                    padding: 2,
                    border: "1px solid #E5E7EB",
                    borderRadius: "6px 6px 0px 0px",
                  }}
                >
                  <FormIcon style={{ marginRight: "10px" }} />
                  <Typography variant="h6_1">Upload IPA</Typography>
                </Box>

                {Boolean(Imgwatch?.length > 0) ? (
                  // Case when Imgwatch is an array with multiple items
                  Array.from(Imgwatch)?.map((item, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      padding="23px"
                      borderRadius="8px"
                      sx={{ border: ".5px solid #1A2461", margin: "20px" }}
                    >
                      <Typography
                        variant="h9_1"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <ImgIcon />
                        {item?.name || item?.originalname}
                      </Typography>
                      <ClearIcon
                        onClick={() => {
                          setValue("ipa", null);
                        }}
                        sx={{ color: "#1A2461" }}
                      />
                    </Stack>
                  ))
                ) : Imgwatch ? (
                  // Case when Imgwatch is a single file object
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    padding="23px"
                    borderRadius="8px"
                    sx={{ border: ".5px solid #1A2461", margin: "20px" }}
                  >
                    <Typography
                      variant="h9_1"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <ImgIcon />
                      {Imgwatch?.name || Imgwatch?.originalname}
                    </Typography>
                    <ClearIcon
                      onClick={() => {
                        setValue("ipa", null);
                      }}
                      sx={{ color: "#1A2461" }}
                    />
                  </Stack>
                ) : (
                  // Case when Imgwatch is null or empty
                  <Box
                    xs={12}
                    onClick={() => ipaFile.current.click()}
                    sx={{
                      border: Boolean(errors.ipa)
                        ? "1px dashed red"
                        : "1px dashed #1A2461",
                      height: "280px",
                      borderRadius: "8px",
                      backgroundColor: "#F9FBFF",
                      margin: "20px",
                    }}
                  >
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      height="100%"
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          height: "100px",
                          width: "100px",
                          borderRadius: "100px",
                          backgroundColor: "#F2F2F2",
                        }}
                      >
                        <UploadFileIcon fill="#1A2461" />
                      </Stack>
                      <Stack
                        mt={2}
                        direction="row"
                        alignItems="center"
                        gap="5px"
                      >
                        <Typography variant="h9_1">Click to upload</Typography>
                        <Typography variant="h9_5">or drag and drop</Typography>
                      </Stack>
                      <Typography variant="h9_5">
                        Allowed formats - PDF, JPEG, PNG
                      </Typography>
                    </Stack>
                  </Box>
                )}

                {/* ))
            ) : (
              
            )} */}

                <Controller
                  name="ipa"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <input
                      type="file"
                      onChange={(e) => {
                        field.onChange(e.target.files);
                      }}
                      ref={(e) => {
                        ipaFile.current = e;
                      }}
                      error={Boolean(errors.ipa) ? true : false}
                      hidden
                    />
                  )}
                />

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    border: "1px solid #E5E7EB",
                    padding: "20px",
                    margin: "50px 0px 0px 0px",
                  }}
                >
                  <Button
                    onClick={() => {
                      setFlowCount(1);
                    }}
                    variant="standard"
                  >
                    Back
                  </Button>
                  {/* {verfiyMail ? (<> */}
                  <Button type="submit" variant="standard">
                    Continue
                  </Button>
                  {/* </>) : (<>
          <Button disabled={!EmailWatch} onClick={() => {
            handleOtpGenerate()
            handleOpen()
            }} variant="standard">Verify Email</Button>
          </>)
        } */}
                </Stack>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
