import React from 'react'
import { Typography, Stack, Box, Grid, Divider, Radio, Select, MenuItem, InputLabel, Button, Checkbox, TextField, IconButton } from '@mui/material'
import ContractSuccessIcon from "../../../../Icons/ContractSuccess.svg"
import VisaIcon from "../../../../Icons/Visa.svg"

export default function Success({ setStepperCount }) {
  return (
    <>
      <Box px={{ md: 10, xs: 3 }}>
        <Typography variant="h6_1">Success</Typography>
        <Grid mt={3} mb={6} container rowGap={7}>
          <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
            <Divider />
            <Stack my={10} direction="column" justifyContent="space-between" alignItems="center" gap={1}>
              <Box mb={3} component="img" src={ContractSuccessIcon}></Box>
              <Typography variant="h6_1">Contract Successful!</Typography>
              <Typography variant='h7_2' >Fly to Singapore, Start a new life!</Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>

    </>
  )
}
