import React, { useState, useRef, useEffect } from "react";
import {
  Typography,
  Box,
  Divider,
  Button,
  Grid,
  TableContainer,
  Table,
  TextField,
  IconButton,
  InputLabel,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Stack,
  Card,
  CardContent
} from "@mui/material";
// import { ReactComponent as UploadFileIcon } from "../../Icons/UploadFileIcon.svg";
// import { ReactComponent as ImgIcon } from "../../Icons/ImgIcon.svg";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { ReactComponent as FormIcon } from "../../../Icons/CommonFormIcon.svg";

export default function EditServices() {

    const { id } = useParams()
  const navigate = useNavigate();

  const modules = {
    toolbar: [
      ["bold", "italic", "underline"], // toggled buttons
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"], // superscript/subscript
    ],
  };

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    watch,
    reset,
  } = useForm({
    mode: "onTouched",
    // defaultValues: {
    //   other_chargeble_Service: [{ id: 1, title: "", amount: "" }],
    //   landing_services_package: { name: "", amount: "" },
    // },
  });

//   const [Newsdata, setNewsdata] = useState({
//     title: "",
//     description: "",
//     img: "",
//   });

//   const initialOtherServices = { id: 1, title: "", amount: "" };
//   const [OtherServices, setOtherServices] = useState([initialOtherServices]);

//   const handleDeleteOtherServices = (ind) => {
//     const data = OtherServices.filter((itm, index) => ind != index);
//     setOtherServices(data);
//   };

//   const {
//     fields: OtherServicesFields,
//     append: AppendOtherServices,
//     remove: removeOtherServices,
//   } = useFieldArray({
//     control,
//     name: "other_chargeble_Service",
//   });
  // const { fields: landing_services_package } = useFieldArray({
  //     control,
  //     name: 'landing_services_package'
  // });

  const fetchOtherServiceDataById = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}getServiceById/${id}`)
        console.log('service by id', response)
        if(response.status === 200){
            setValue('title', response?.data?.data?.title)
            setValue('description', response?.data?.data?.description)
            setValue('amount', response?.data?.data?.amount)
        }
    } catch (error) {
        console.log(error)
    }
  }

  useEffect(() => {
    fetchOtherServiceDataById()
  },[])

  const onSubmit = async (data) => {
    console.log(data);

    // const datas = {
    //   landing_services_package: data?.landing_services_package,
    //   other_chargeble_Service: data?.other_chargeble_Service,
    // };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}editService/${id}`,
        data
      );
      console.log(response);
      if(response.status === 200){
          navigate('/Admin/Services')
      }
      // reset()
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Card
          sx={{
            width: "80%",
            border: "1px solid #E5E7EB",
            borderRadius: "6px",
          }}
        >
          <CardContent style={{ padding: 0, margin: 0 }}>
          <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                backgroundColor: "#FCFCFD",
                padding: 2,
                border: "1px solid #E5E7EB",
                borderRadius: "6px 6px 0px 0px",
              }}
            >
              <FormIcon style={{ marginRight: "10px" }} />
              <Typography variant="h6_1">Edit service package</Typography>
            </Box>
        
        <form onSubmit={handleSubmit(onSubmit)}>

        <Box style={{ padding: "0px 17px 17px", margin : '20px 0px 0px 0px' }}>
              <InputLabel
                sx={{
                  mb: 1,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
                {errors?.title ? 'Title*' : 'Title'}
              </InputLabel>
              <Controller
                name="title"
                rules={{ required: true,
                 }}
                control={control}
                render={({ field }) => (
                  <TextField
                    error={Boolean(errors?.title) ? true : false}
                    {...field}
                    placeholder="Enter title"
                    sx={{ 
                      mt: 0, 
                      backgroundColor : 'rgb(249, 250, 251)',
                      borderRadius: 0, 
                      "& .MuiInputBase-input": {
                        color: errors?.title ? "red" : "black",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: errors?.title ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: errors?.title ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: errors?.title ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                      },
                    }}
                  />
                )}
              />
              {errors?.title ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>Please enter amount</Typography> : null }
              </Box>

         
        <Box style={{ padding: "25px 17px 17px 17px" }}>
        <InputLabel
                sx={{
                  mb: 1,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
                {errors?.description ? 'Description*' : 'Description'}
              </InputLabel>
              <Controller
                name="description"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <ReactQuill
                    style={{ height: "180px" }}
                    modules={modules}
                    maxLength={3000}
                    {...field}
                  />
                )}
              />
              {errors?.description ? <Typography style={{color : '#ff5e74', margin : '3.3rem 0rem 0rem 0.3rem'}}>Please enter description</Typography> : null }
              </Box>
          
              <Box style={{ padding: "0px 17px 17px", margin : '60px 0px 0px 0px' }}>
              <InputLabel
                sx={{
                  mb: 1,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
                {errors?.amount ? 'Amount*' : 'Amount'}
              </InputLabel>
              <Controller
                name="amount"
                rules={{ required: true, 
                     pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter a valid number",
                  },
                 }}
                control={control}
                render={({ field }) => (
                  <TextField
                    error={Boolean(errors?.amount) ? true : false}
                    {...field}
                    placeholder="Enter amount"
                    sx={{ 
                      mt: 0, 
                      backgroundColor : 'rgb(249, 250, 251)',
                      borderRadius: 0, 
                      "& .MuiInputBase-input": {
                        color: errors?.amount ? "red" : "black",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: errors?.amount ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: errors?.amount ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: errors?.amount ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                      },
                    }}
                  />
                )}
              />
              {errors?.amount ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>{errors.amount.message || 'Please enter amount'}</Typography> : null }
              </Box>
            
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  backgroundColor: "#FCFCFD",
                  padding: 2,
                  border: "1px solid #E5E7EB",
                  marginTop: 7,
                }}
              >
                <FormIcon style={{ marginRight: "10px" }} />
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  Other chargeable services
                </Typography>
              </Box> */}
             
              {/* {OtherServicesFields?.map((item, index) => (
                <>
                    <Card sx={{margin : '17px'}}>
                        <CardContent>
                    <Box sx={{display : 'flex', justifyContent : 'flex-end', padding : '0px 5px 0px 0px', margin : '0px'}}>
                    {index > 0 && (
                      <IconButton onClick={() => removeOtherServices(index)}>
                        {" "}
                        <ClearIcon
                          sx={{ color: "#f37777", fontSize: "30px" }}
                        />
                      </IconButton>
                    )}
                    </Box>
                  
                    <Box sx={{display : 'flex', flexDirection : {xs : 'column', lg : 'row'}, gap : '5%', padding : '17px'}}>
                    <Box sx={{width : '100%'}}>
                    <InputLabel
                sx={{
                  mb: 1,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
                {errors?.other_chargeble_Service?.[index]?.title ? 'Title*' : 'Title'}
              </InputLabel>
                    <Controller
                      name={`other_chargeble_Service.${index}.title`}
                      defaultValue={item.title}
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          error={
                            Boolean(errors[`title_${index}`]) ? true : false
                          }
                          {...field}
                          placeholder="Enter title"
                    sx={{ 
                      mt: 0, 
                      backgroundColor : 'rgb(249, 250, 251)',
                      borderRadius: 0, 
                      "& .MuiInputBase-input": {
                        color: errors?.other_chargeble_Service?.[index]?.title ? "red" : "black",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: errors?.other_chargeble_Service?.[index]?.title ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: errors?.other_chargeble_Service?.[index]?.title ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: errors?.other_chargeble_Service?.[index]?.title ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                      },
                    }}
                        />
                      )}
                    />
                    {errors?.other_chargeble_Service?.[index]?.title ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>Please enter title</Typography> : null }
                    </Box>
                          
                    <Box sx={{width : '100%'}}>
                    <InputLabel
                            sx={{
                            mb: 1,
                            fontSize: "18px",
                            fontWeight: 600,
                            color: "black",
                            }}
                        >
                {errors?.other_chargeble_Service?.[index]?.amount ? 'Amount*' : 'Amount'}
              </InputLabel>
                    <Controller
                      name={`other_chargeble_Service.${index}.amount`}
                      defaultValue={item.amount}
                      rules={{ required: true, pattern: {
                        value: /^[0-9]+$/,
                        message: "Please enter a valid number",
                      }, }}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          error={
                            Boolean(errors[`amount_${index}`]) ? true : false
                          }
                          {...field}
                          placeholder="Enter amount"
                    sx={{ 
                      mt: 0, 
                      backgroundColor : 'rgb(249, 250, 251)',
                      borderRadius: 0, 
                      "& .MuiInputBase-input": {
                        color: errors?.other_chargeble_Service?.[index]?.amount ? "red" : "black",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: errors?.other_chargeble_Service?.[index]?.amount ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: errors?.other_chargeble_Service?.[index]?.amount ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: errors?.other_chargeble_Service?.[index]?.amount ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                      },
                    }}
                        />
                      )}
                    />
                    {errors?.other_chargeble_Service?.[index]?.amount ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>{errors.other_chargeble_Service?.[index]?.amount.message || 'Please enter amount'}</Typography> : null }
                    </Box>
                 </Box>
                 </CardContent>
                    </Card>
                </>
              ))}
           
            <Box sx={{padding : '17px', display : 'flex', justifyContent : 'flex-end'}}>
              <Button
                onClick={() =>
                  AppendOtherServices({
                    id: OtherServicesFields.length + 1,
                    title: "",
                    amount: "",
                  })
                }
                variant="contained"
              >
                Add more hostel type
              </Button>
              </Box> */}
            

              <Divider style={{marginTop : '15px'}}/>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "0px 17px 13px",
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{
                  padding: "10px 20px 10px 20px",
                  mt: 2,
                  width: "20%",
                  fontSize: 20,
                  backgroundColor: "rgb(14, 94, 132)",
                  ":hover": { bgcolor: "rgb(14, 94, 132)" },
                }}
              >
                Update
              </Button>
            </Box>
            
        </form>
        </CardContent>
        </Card>
      </Box>
    </>
  );
}
