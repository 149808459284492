import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as Close } from "../Icons/BorderAlertClose.svg";

const AlertModal = ({
  modalDatas,
  confirmFunction,
  closeFunction,
  isDraft,
}) => {
  let { label, descriptionOne, descriptionTwo, buttonOne, buttonTwo } =
    modalDatas;
  return (
    <>
      <Modal open={true}>
        <Box
          sx={{
            position: "absolute",
            border: "none",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              width: "40%",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                p: 2,
                borderBottom: "2px solid #F3F4F6",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h7_1">{label}</Typography>
              <Close
                style={{ cursor: "pointer" }}
                onClick={() => closeFunction()}
              />
            </Box>
            <Box sx={{ px: 2, py: 3, borderBottom: "2px solid #F3F4F6" }}>
              <Typography variant="h9_1">{descriptionOne}</Typography>
              {descriptionTwo != "" && (
                <Typography variant="h9_1" sx={{ mt: 3 }}>
                  {descriptionTwo}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                p: 2,
                display: "flex",
                justifyContent: isDraft ? "end" : "space-between",
              }}
            >
              <Button
                onClick={() => closeFunction()}
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  padding: "10px 20px",
                  color: "#FFF",
                  backgroundColor: "#0E5E84",
                }}
                variant="contained"
              >
                {buttonOne.text}
              </Button>
              <Button
                onClick={() => confirmFunction()}
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  padding: "10px 20px",
                  color: "#FFFFFF",
                  backgroundColor: "#912018",
                }}
                variant="contained"
              >
                {buttonTwo.text}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AlertModal;
