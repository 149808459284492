import React, {useState, useEffect} from 'react'
import { Typography, Stack, Box, Grid, Divider, Button, useMediaQuery } from '@mui/material'
import PaynowIcon from "../../../../Icons/PaynowIcon.svg"
import VisaIcon from "../../../../Icons/Visa.svg"
import axios from 'axios'

export default function Payment({ setStepperCount, HandleSubmitSteps, payment, setPayment, email}) {
    const isMobile = useMediaQuery('(max-width:600px)')

const [deposits, setDeposits] = useState([])
const [services, setServices] = useState([])
const [otherServices, setOtherServices] = useState([])
const [userServiceValues, setUserServiceValues] = useState({})
const [userId, setUserId] = useState('')

useEffect(() => {
    window.scrollTo({
      top : 0,
      left : 0,
      behavior : 'smooth'
    })
  }, [])

  

  

  const fetchUserServiceValues = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}get-user-service-value/${email}`)
    .then((response) => {
        console.log(response)
        setUserServiceValues(response?.data?.data || {})
        setUserId(response?.data?.id)
    }).catch((error) => {
        console.log(error)
    })
}

const fetchDepositPayments = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}get-deposits`)
    .then((response) => {
        console.log(response)
        setDeposits(response?.data?.data)
    }).catch((error) => {
        console.log(error)
    })
}

const fetchServices = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}getAllServices`)
    .then((response) => {
        console.log(response)
        setServices(response?.data?.data)
    }).catch((error) => {
        console.log(error)
    })
}

const fetchOtherServices = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}get-other-services`)
    .then((response) => {
        console.log(response)
        setOtherServices(response?.data?.data)
    }).catch((error) => {
        console.log(error)
    })
}

const [loading, setLoading] = useState(true)

useEffect(() => {
    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchUserServiceValues(),
                fetchDepositPayments(),
                fetchServices(),
                fetchOtherServices()
            ]);
        } catch (error) {
            console.log('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    fetchData();
}, []);


if(loading){
    return <p>Loading...</p>
}
// const othersTotal = deposits?.reduce((sum, item) => sum + item.amount,0)
// const Total = registrationPayment.common.reduce((sum, item) => sum + item.amount, 0)

const depositTotal = deposits?.reduce((sum, item) => sum + item.amount, 0)

const landingServiceTotal = services
  ?.filter((service) => userServiceValues?.landingServices?.includes(service._id))
  ?.reduce((sum, item) => sum + item.amount, 0);

  const otherServiceTotal = otherServices
  ?.filter((service) => userServiceValues?.otherServices?.includes(service._id))
  ?.reduce((sum, item) => sum + item.amount, 0);

  const OTHER_SERVICES_TOTAL = landingServiceTotal + otherServiceTotal
  const TOTAL_AMOUNT_TO_PAY = OTHER_SERVICES_TOTAL + depositTotal
  const formattedTotal = TOTAL_AMOUNT_TO_PAY.toLocaleString('en-US');

  

const handleSubmit = async () => {
    const formData = new FormData()
    formData.append('email', email)
    formData.append('stepperCount', 6)
    formData.append('payment', true)
    await axios.post(`${process.env.REACT_APP_API_URL}add-application-step-data`, formData)
    .then((response) => console.log(response))
    .catch((error) => console.log(error))

    await axios.get(`${process.env.REACT_APP_API_URL}get-payment/${userId}`)
    .then((response) => console.log(response))
    .catch((error) => console.log(error))
}


const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  console.log(services, 'services')

    return (
        <>
            <Box px={{ md: 10, xs: 3 }}>
                <Typography variant="h6_1">Registration payment</Typography>
                <Grid mt={3} mb={6} container rowGap={7}>
                    <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                        <Divider />
                        {deposits?.map((item, index) => (
                            <>
                            <Stack my={1} direction="row" justifyContent="space-between" alignItems="center">
                            <Stack gap="8px" width={{xs : '70%', lg: '80%'}}>
                                <Typography variant="h6_1">{item.title}</Typography>
                                <Typography variant='h9_4' >{stripHtml(item?.description || "-")}</Typography>
                            </Stack>
                            <Typography variant="h6_1">S$ {item.amount}</Typography>
                        </Stack>
                        <Divider />
                        </>
                        ))}
                        
                        {/* <Stack my={1} direction="row" justifyContent="space-between" alignItems="center">
                            <Stack gap="8px">
                                <Typography variant="h6_1">Registration fees</Typography>
                                <Typography variant='h9_4' >Registration fees to be done (Non-refundable)</Typography>
                            </Stack>
                            <Typography variant="h6_1">S$ 20</Typography>
                        </Stack>
                        <Divider />
                        <Stack my={1} direction="row" justifyContent="space-between" alignItems="center">
                            <Stack gap="8px">
                                <Typography variant="h6_1">Supporting fees</Typography>
                                <Typography variant='h9_4' >Supporting fees to be done (Non-refundable)</Typography>
                            </Stack>
                            <Typography variant="h6_1">S$ 20</Typography>
                        </Stack>
                        <Divider /> */}
                        <Stack my={1} direction="row" justifyContent="space-between" alignItems="center">
                            <Stack gap="8px">
                                <Typography variant="h6_1">Other Services</Typography>
                                {services?.filter((service) => userServiceValues?.landingServices?.includes(service._id)
                                )?.map((item, index) => (
                                    <Typography variant='h9_4' key={index}>
                                        {item.title} - S${item.amount}
                                    </Typography>
                                ))}

                                {otherServices?.filter((service) => userServiceValues?.otherServices.includes(service._id)
                                ).map((item, index) => (
                                    <Typography variant='h9_4' key={index}>
                                        {item.title} - S${item.amount}
                                    </Typography>
                                ))}



                                
                                {/* <Typography variant='h9_4' >Counselling - S$100</Typography> */}
                            </Stack>
                            <Typography variant="h6_1">S$ {OTHER_SERVICES_TOTAL}</Typography>
                        </Stack>
                        <Divider />
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Stack gap="8px">
                                <Typography variant="h6_1">Total</Typography>
                            </Stack>
                            <Typography variant="h6_1">S$ {formattedTotal}</Typography>
                        </Stack>
                        <Stack mt={10} direction="column" justifyContent="space-between" alignItems="center" gap="10px">
                            <Typography variant="h6_1">Pay Via</Typography>
                            <Stack gap="8px" direction={isMobile ? "column" :"row"} justifyContent="space-between" alignItems="center">
                                <Stack alignItems="center" justifyContent="center" height="44px" width="200px" border=".5px solid #1A2461" sx={{ borderRadius: "8px" }}>
                                    <Box margin="0 auto" component="img" src={VisaIcon}></Box>
                                </Stack>
                                <Stack alignItems="center" justifyContent="center" height="44px" width="200px" border=".5px solid #1A2461" sx={{ borderRadius: "8px" }}>
                                    <Box margin="0 auto" component="img" src={PaynowIcon}></Box>
                                </Stack>
                                <Stack alignItems="center" justifyContent="center" height="44px" width="200px" border=".5px solid #1A2461" sx={{ borderRadius: "8px" }}>
                                    <Box margin="0 auto" component="img" src={VisaIcon}></Box>
                                </Stack>
                                <Stack>
                                    <Button onClick={() => {
                                        setStepperCount(6)
                                        setPayment(true)
                                        handleSubmit()
                                        }}>Paid</Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>


                <Stack my={5} direction="row" alignItems="center" justifyContent="start">
                    <Button variant="standard" onClick={() =>  setStepperCount(4)}>Back</Button>
                </Stack>
            </Box>

        </>
    )
}
