// import React from "react";
// import { menus } from "../Data";
// import { Box, Typography } from "@mui/material";
// import { useNavigate, useLocation } from "react-router-dom";

// export default function Menu() {
//   const navigate = useNavigate();
//   const location = useLocation();

//   return (
//     <Box
//       columnGap="20px"
//       sx={{ display: { md: "flex", xs: "none" }, flexDirection: "row" }}
//     >
//       {menus?.map((item, index) => {
//         const isActive = location.pathname === item?.uri_link;
//         return (
//           <>
//             <Box
//               key={index}
//               onClick={() => navigate(item?.uri_link)}
//               mt="20px"
//               pt={3}
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 width: { lg: 90, md: 70, sm: 60 },
//                 height: "100px",
//                 borderBottom: isActive ? "3px solid white" : "none",
//                 "&:hover": {
//                   borderBottom: "3px solid white",
//                   transition: "0.2s",
//                 },
//                 cursor: "pointer",
//               }}
//             >
//               <Box display="flex" justifyContent="center">
//                 <Box component="img" src={item?.icon}></Box>
//               </Box>
//               <Typography
//                 textAlign="center"
//                 mt="10px"
//                 variant="h9_3"
//                 color="white"
//               >
//                 {item?.nav_bar_name}
//               </Typography>
//             </Box>
//           </>
//         );
//       })}
//     </Box>
//   );
// }

import React, { useEffect, useState } from "react";
import { menus } from "../Data";
import { Box, Typography, Drawer, IconButton, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as LogoIcon } from "../Icons/logo.svg";
import CloseIcon from '@mui/icons-material/Close';
import { DocumentTitleChange } from "../CommonComponents/Title";

export default function Menu() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const isActive = location.pathname

  useEffect(() => {
    let value = 'Home'
    if(isActive == '/'){
        value = 'Home'
    } else if (isActive == '/intro'){
        value = 'Intro'
    }else if (isActive == '/application'){
      value = 'Application'
    }else if(isActive == '/services'){
      value = 'Services'
    }else if(isActive == '/news'){
      value = 'News'
    }else if(isActive == '/visitor'){
      value = 'Visitor'
    }else if(isActive == '/links'){
      value = 'Links'
    }else if(isActive.startsWith('/news/')){
      value = 'News'
    }else if(isActive.startsWith('/hostelreview/')){
      value = 'Hostel Review'
    }
    DocumentTitleChange(value)
  },[isActive])

  const renderMenuItems = () => (
    <>
    
      {menus?.map((item, index) => {
        const isActive = location.pathname === item?.uri_link;
        return (
          <Box
            key={index}
            onClick={() => {
              navigate(item?.uri_link);
              setDrawerOpen(false);
            }}
            mt="20px"
            pt={3}
            sx={{
              
              display: "flex",
              flexDirection: "column",
              width: { lg: 90, md: 70, sm: 60 },
              height: "100px",
              borderBottom: isActive ? "3px solid white" : "none",
              "&:hover": {
                borderBottom: "3px solid white",
                backgroundColor : '#23a1a19c' ,
                transition: "0.2s",
              },
              cursor: "pointer",
            }}
          >
            <Box display="flex" justifyContent="center">
              <Box component="img" sx={{width : '30px', height : '30px'}} src={item?.icon}></Box>
            </Box>
            <Typography
              textAlign="center"
              mt="10px"
              variant="h9_3"
              color="white"
            >
              {item?.nav_bar_name}
            </Typography>
          </Box>
        );
      })}
     
    </>
  );

  return (
    <Box
      columnGap="20px"
      sx={{ display: "flex", flexDirection: "row" }}
    >
      {/* Drawer button for mobile screens */}
      <Box
        sx={{ display: { xs: "flex", md: "none" }}}
        alignItems="center"
      >
        <IconButton
        
          color="inherit"
          edge="start"
          onClick={handleDrawerToggle}
        >
          <MenuIcon style={{color : 'white', fontSize : '2rem'}}/>
        </IconButton>
      </Box>

      {/* Drawer for mobile screens */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{
          display: { xs: "block", md: "none"},
          height : '100%' ,
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: '100%' },
        }}
        BackdropProps={{
          style: {
            backdropFilter: 'blur(3px)',
          },
        }}
      >
        <Box sx={{backgroundColor : '#27bac4', p:2, display:'flex', alignItems : 'center', justifyContent : 'space-between'}}>
        <LogoIcon style={{ cursor: "pointer" }} onClick={() => navigate("/")} />
        <Button onClick={handleDrawerToggle}><CloseIcon sx={{fontSize : '2rem', color : 'white'}}/></Button>
      </Box>
        <Box sx={{backgroundColor: '#ffffff', height : '100%'}}>
        {menus?.map((item, index) => {
        const isActive = location.pathname === item?.uri_link;
        return (
          <Box
            key={index}
            onClick={() => {
              setDrawerOpen(false);
              navigate(item?.uri_link);
            }}
            mt="10px"
            // pt={3}
            sx={{
              backgroundColor : isActive ? '#e7e6e873' : '' ,
              margin : '20px',
              padding : '20px',
              borderRadius : '10px',
              display: "flex",
              justifyContent : 'flex-start',
              gap : '2.85rem',
              alignItems : 'center',
              flexDirection: "row",
              //width: { lg: 90, md: 70, sm: 80 },
              height: "80px",
             //borderBottom: isActive ? "3px solid white" : "none",
              "&:hover": {
                //borderBottom: "3px solid white",
                backgroundColor : '#dddcde' ,
                transition: "0.2s",
              },
              cursor: "pointer",
            }}
          >
            <Box display="flex" justifyContent="center">
              <Box component="img" width='100%' sx={{backgroundColor : '#009ca3', p:2, borderRadius : '15px'}} src={item?.icon}></Box>
            </Box>
            <Typography
              textAlign="center"
              sx={{
                fontSize : '1.3rem',
                fontWeight : 600,
                //width : '35%'
                letterSpacing : '1.2px'
              }}
              mt="0px"
              //variant="h7_3"
              color={isActive ? "#009ca3" : "black"}
            >
              {item?.nav_bar_name}
            </Typography>
          </Box>
        );
      })}
      </Box>
      </Drawer>

      {/* Menu for larger screens */}
      <Box
        columnGap="20px"
        sx={{ display: { xs: "none", md: "flex" }, flexDirection: "row" }}
      >
        {renderMenuItems()}
      </Box>
    </Box>
  );
}

