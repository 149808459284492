import { configureStore } from "@reduxjs/toolkit"
import CommonReducer from "../Feature/CommonSlice"
import ApplicationReducer from "../Feature/ApplicationSlice"
import AdminReducer from '../Feature/AdminSlice'
import ApplicationDataReducer from '../Feature/ApplicationData'

const store = configureStore({
    reducer: {
        CommonData: CommonReducer,
        ApplicationData : ApplicationReducer,
        AdminData : AdminReducer,
        ApplicationDatas : ApplicationDataReducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false,
    }),
})


export default store