// import React, { useEffect, useState } from "react";
// import {
//   Typography,
//   Stack,
//   Box,
//   Grid,
//   Divider,
//   Radio,
//   Select,
//   MenuItem,
//   InputLabel,
//   Button,
//   Accordion,
// } from "@mui/material";
// import { ReactComponent as DownArrowIcon } from "../../../../Icons/DownArrow.svg";
// import { useDispatch, useSelector } from "react-redux";

// import AccordionActions from "@mui/material/AccordionActions";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// export default function Room({ setStepperCount, setRoomsData }) {
//   const { HostelData } = useSelector((state) => state.CommonData);
//   const [hostelData, setHostelData] = useState(HostelData);

//   const [selectedHostelRooms, setSelectedHostelRoom] = useState(null);
//   const [contractLength, setContractLength] = useState(null);

//   useEffect(() => {
//     setRoomsData({
//       selectedHostelRooms: selectedHostelRooms,
//       contractLength: contractLength,
//     });
//   }, [selectedHostelRooms, contractLength]);

//   const handleRoomSelect = (hostelId, item, itm) => {
//     setSelectedHostelRoom({
//       hostelId: hostelId,
//       hostelTypeId: item.id,
//       roomId: itm.id,
//       HostelName: item.Hostel_type,
//       ...itm,
//     });
//   };

//   const isRoomSelected = (hostelId, itemId, roomId) => {
//     return (
//       selectedHostelRooms &&
//       selectedHostelRooms.hostelId === hostelId &&
//       selectedHostelRooms.hostelTypeId === itemId &&
//       selectedHostelRooms.roomId === roomId
//     );
//   };

//   console.log(hostelData, 'hostelData')
  
//   console.log(selectedHostelRooms, contractLength);

//   return (
//     <Box px={{ md: 10, xs: 3 }}>
//       <Typography variant="h6_1">Select Preferred room</Typography>
//       <Box my={5}>
//         {hostelData?.map((mainitem, index) => (
//           <Accordion key={mainitem?.id}>
//             <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//               <Typography variant="h7_1" color="button">
//                 {mainitem?.title}
//               </Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//               <Grid mt={3} mb={6} container rowGap={7}>
//                 {mainitem?.HostelTypes?.map((item, index) => (
//                   <Grid xs={12} key={item?.id}>
//                     {/* <Typography variant="h7_1" color="button">
//                       {item?.Hostel_type}
//                     </Typography> */}
//                     {/* <Divider sx={{ my: 2 }} /> */}
//                     {item?.room_type?.map((itm, ind) => (
//                       <Stack
//                       key={itm?.id}
//                         onClick={() =>
//                           handleRoomSelect(mainitem.id, item, itm)
//                         }
//                         mt={0}
//                         direction="row"
//                         alignItems="center"
//                         justifyContent="space-between"
//                         sx={{
//                           border: "0.5px solid #1A2461",
//                           padding: "10px 23px",
//                           borderRadius: "8px",
//                         }}
//                       >
//                         <Stack direction="row" alignItems="center">
//                           <Radio
//                            checked={isRoomSelected(mainitem.id, item.id, itm.id)}
//                           />
//                           <Typography variant="h7_1">
//                             {itm?.room_type_name}
//                           </Typography>
//                         </Stack>
//                       </Stack>
//                     ))}
//                   </Grid>
//                 ))}
//               </Grid>
//             </AccordionDetails>
//           </Accordion>
//         ))}
//       </Box>

//       <Typography variant="h7_1">Room details</Typography>
//       <Divider sx={{ my: 2 }} />
//       <Grid container justifyContent="space-between">
//         <Grid md={5.5}>
//           <Typography variant="h7_1">Contract Length</Typography>
//           <br />
//           <InputLabel sx={{ mt: 3 }} variant="standard">
//             {" "}
//             Contract Length
//           </InputLabel>
//           <Select fullWidth onChange={(e) => setContractLength(e.target.value)}>
//             <MenuItem value={1}>6 Months</MenuItem>
//             <MenuItem value={2}>12 Months</MenuItem>
//           </Select>
//         </Grid>
//         {Boolean(contractLength) && Boolean(selectedHostelRooms) && (
//           <Grid
//             md={5.5}
//             gap={1}
//             textAlign="center"
//             display="flex"
//             flexDirection="column"
//             alignItems="center"
//             justifyContent="center"
//           >
//             <Typography variant="h7_1">Selected room preview</Typography>
//             <Box
//               mt={1}
//               component="img"
//               sx={{
//                 width: { sm: 450, xs: "100%" },
//                 height: { sm: 450, xs: "auto" },
//                 objectFit: "cover",
//                 border: ".5px solid #1A2461",
//                 borderRadius: "8px",
//               }}
//               src="https://images.pexels.com/photos/271624/pexels-photo-271624.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
//             ></Box>
//             <Typography variant="h7_1" color="button">
//               {selectedHostelRooms?.HostelName}
//             </Typography>
//             <Typography variant="h7_1" color="#003893">
//               {selectedHostelRooms?.room_type_name}
//             </Typography>
//             {/* <Typography variant='h7_1' color="#003893">With window</Typography> */}
//             {contractLength === 1 && (
//               <Typography variant="h7_1" color="#27BAC4">
//                 S${selectedHostelRooms?.six_month_cont}/month
//               </Typography>
//             )}
//             {contractLength === 2 && (
//               <Typography variant="h7_1" color="#27BAC4">
//                 S${selectedHostelRooms?.twelve_month_cont}/month
//               </Typography>
//             )}
//           </Grid>
//         )}
//       </Grid>
//       <Stack my={5} mx={5} alignItems="end">
//         <Button
//           onClick={() => {
//             setStepperCount(2);
//           }}
//           variant="contained"
//           sx={{
//             padding: "10px 20px 10px 20px",
//             fontSize: "20px",
//             fontWeight: 700,
//           }}
//           disabled={!selectedHostelRooms || !contractLength}
//         >
//           Continue
//         </Button>
//       </Stack>
//     </Box>
//   );
// }

import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  Grid,
  Divider,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Accordion,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { storeRoomsData } from '../../../../Redux/Feature/ApplicationData'
import { storeRoomsDataAndSendAPI } from "../../../../Redux/Feature/ApplicationData";
import axios from "axios";


export default function Room({ setStepperCount, setRoomsData, RoomsData, setFlowCount, HandleSubmitSteps, email, PersonalData }) {
  const { HostelData } = useSelector((state) => state.CommonData);
  console.log(HostelData, 'hostelData')
  const navigate = useNavigate()
  const roomData = useSelector((state) => state.ApplicationDatas.RoomsData)

  console.log(roomData, 'redux room data')
  const [hostelData, setHostelData] = useState(HostelData);
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const dispatch = useDispatch()

  console.log(RoomsData, 'Room')
  console.log(expandedAccordion, 'accordion')

  const { control, handleSubmit, watch, setValue, formState: { errors } } = useForm({
    defaultValues: {
      contractLength: null,
      selectedRoom: null
    }
  });

  const watchRoomSelection = watch("selectedRoom");
  const watchContractLength = watch("contractLength");

  const handleRoomSelect = (hostelId, item, itm, title) => {
    setValue('selectedRoom', {
      hostelId: hostelId,
      hostelTypeId: item.id,
      roomId: itm.id,
      HostelName: `${title} - ${item.Hostel_type}`,
      ...itm,
    }, { shouldValidate: true });
    
  };

  useEffect(() => {
    window.scrollTo({
      top : 0,
      left : 0,
      behavior : 'smooth'
    })
  }, [])

  useEffect(() => {
    if (RoomsData?.contractLength) {
      setValue('contractLength', RoomsData.contractLength);
    }
    if (RoomsData?.selectedRoom) {
      setValue('selectedRoom', RoomsData.selectedRoom, { shouldValidate: true });
      setExpandedAccordion(RoomsData.selectedRoom.hostelId);
    }
  },[RoomsData, setValue])

  const isRoomSelected = (hostelId, itemId, roomId) => {
    return (
      watchRoomSelection &&
      watchRoomSelection.hostelId === hostelId &&
      watchRoomSelection.hostelTypeId === itemId &&
      watchRoomSelection.roomId === roomId
    );
  };

  const onSubmit = async (data) => {
    setRoomsData(data);
    // dispatch(storeRoomsDataAndSendAPI(data))
    setStepperCount(3);
    // HandleSubmitSteps()
    const formData = new FormData()
    formData.append("email" , email)
    formData.append("RoomsData", JSON.stringify(data))
    await axios.post(`${process.env.REACT_APP_API_URL}add-application-step-data`, formData)
    .then((response) => console.log(response))
    .catch((error) => console.log(error))
  };

  const gender = PersonalData?.Details?.gender
  console.log(watchRoomSelection, 'hostel Data')

  return (
    <>
    
    <Box px={{ md: 10, xs: 3 }}>
    <Box onClick={() => setStepperCount(1)} sx={{border : '0.5px solid #717171', padding : '4px 3px !important', width : '33px', borderRadius : '3px'}}>
   <ArrowBackIcon sx={{color : 'black'}}/>
    </Box>
      <Box sx={{mt : 5}}>
      <Typography  variant="h6_1">Select Preferred room</Typography>
      </Box>
      <Box my={5}>
      {hostelData?.map((mainitem) => (
  <Accordion 
    key={mainitem?.id}
    sx={{my : 2}}
    expanded={expandedAccordion === mainitem.id}
    onChange={() => setExpandedAccordion(expandedAccordion === mainitem.id ? null : mainitem.id)}
  >
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h7_1" color="button">
        {mainitem?.title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid mt={3} mb={6} container rowGap={7}>
        {mainitem?.HostelTypes?.map((item) => (
          <React.Fragment key={item?.id}>
            <Typography sx={{fontSize : '24px', fontWeight : 700, color : 'rgba(0, 126, 202, 1)'}}>
              {`${mainitem?.title} (${item?.Hostel_type})`}
            </Typography>
            <Grid xs={12}>
              {item?.room_type?.map((itm) => {
                const isDisabled = itm?.rooms?.filter((datas) => 
                  datas?.members.includes(gender) || datas?.members.length === 0
                ).reduce((sum, item) => sum + Number(item?.availableSlots || 0), 0) === 0;

                return (
                  <Stack
                    key={itm?.id}
                    onClick={() => !isDisabled && handleRoomSelect(mainitem.id, item, itm, mainitem.title)}
                    mt={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      border: "0.5px solid #1A2461",
                      padding: "10px 23px",
                      borderRadius: "8px",
                      margin: '10px 0px',
                      opacity: isDisabled ? 0.5 : 1,
                      cursor: isDisabled ? 'not-allowed' : 'pointer',
                      pointerEvents: isDisabled ? 'none' : 'auto',
                    }}
                  >
                    <Stack direction="row" alignItems="center">
                      <Controller
                        name="selectedRoom"
                        control={control}
                        rules={{ required: "Please select a room" }}
                        render={({ field }) => (
                          <Radio
                            {...field}
                            disabled={isDisabled}
                            checked={!isDisabled && isRoomSelected(mainitem.id, item.id, itm.id)}
                            onChange={() => 
                              field.onChange({
                                hostelId: mainitem.id,
                                hostelTypeId: item.id,
                                roomId: itm.id,
                                HostelName: `${mainitem.title} - ${item.Hostel_type}`,
                                ...itm,
                              })
                            }
                          />
                        )}
                      />
                      <Typography variant="h7_1" sx={{color: isDisabled ? 'grey' : 'inherit'}}>
                        {itm?.room_type_name}
                      </Typography>
                    </Stack>
                    {/* <Typography variant="h7_1" sx={{color: isDisabled ? 'grey' : 'inherit'}}>
                      Available: {itm?.rooms?.filter((datas) => 
                        datas?.members.includes(gender) || datas?.members.length === 0
                      ).reduce((sum, item) => sum + Number(item?.availableSlots || 0), 0)}
                    </Typography> */}
                  </Stack>
                );
              })}
              {errors.selectedRoom && (
                <Typography variant="body2" color="error">
                  {errors.selectedRoom.message}
                </Typography>
              )}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </AccordionDetails>
  </Accordion>
))}

      </Box>

      <Typography variant="h7_1">Room details</Typography>
      <Divider sx={{ my: 2 }} />
      <Grid container justifyContent="space-between">
        <Grid xs={12} md={5.5}>
          <Typography variant="h7_1">Contract Length</Typography>
          <br />
          <InputLabel sx={{ mt: 3 }} variant="standard">
            Contract Length
          </InputLabel>
          <Controller
            name="contractLength"
            control={control}
            rules={{ required: "Contract length is required" }}
            render={({ field }) => (
              <Select
                fullWidth
                {...field}
                onChange={(e) => field.onChange(e.target.value)}
              >
                <MenuItem value={1}>6 Months</MenuItem>
                <MenuItem value={2}>12 Months</MenuItem>
              </Select>
            )}
          />
          {errors.contractLength && (
            <Typography variant="body2" color="error">
              {errors.contractLength.message}
            </Typography>
          )}
        </Grid>
        {Boolean(watchContractLength) && Boolean(watchRoomSelection) && (
          <Grid
          sm={12}
            md={5.5}
            gap={1}
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography sx={{mt : {xs : 4, sm : 5, md : 0, lg : 0, xl : 0}}} variant="h7_1">Selected room preview</Typography>
            <Box
              mt={1}
              component="img"
              sx={{
                width: { sm: 450, xs: "100%" },
                height: { sm: 450, xs: "auto" },
                objectFit: "cover",
                border: ".5px solid #1A2461",
                borderRadius: "8px",
              }}
              src="https://images.pexels.com/photos/271624/pexels-photo-271624.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            ></Box>
            <Typography variant="h7_1" color="button">
              {watchRoomSelection?.HostelName}
            </Typography>
            <Typography variant="h7_1" color="#003893">
              {watchRoomSelection?.room_type_name}
            </Typography>
            {watchContractLength === 1 && (
              <Typography variant="h7_1" color="#27BAC4">
                S${watchRoomSelection?.six_month_cont}/month
              </Typography>
            )}
            {watchContractLength === 2 && (
              <Typography variant="h7_1" color="#27BAC4">
                S${watchRoomSelection?.twelve_month_cont}/month
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
      
      <Box my={5} mx={{xs : 0, sm : 2, lg : 5}} sx={{display : 'flex', justifyContent : 'space-between'}}>
      <Button
          onClick={() => setStepperCount(1)}
          variant="contained"
          sx={{
            padding: "10px 20px 10px 20px",
            fontSize: "20px",
            fontWeight: 700,
          }}
        >
          Back
        </Button>

        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          sx={{
            padding: "10px 20px 10px 20px",
            fontSize: "20px",
            fontWeight: 700,
          }}
          disabled={!watchRoomSelection || !watchContractLength}
        >
          Continue
        </Button>
      </Box>
    </Box>
    </>
  );
}
