import React, { useState } from 'react'
import { Typography, Box, Divider, Button, Stack, ListItem, List, ListItemText, CardActionArea, CardActions, Grid, Card, CardContent, CardMedia } from "@mui/material"
import parse from "html-react-parser";



export default function Service_facilities({ hostelData }) {

    return (
        <>
            <Box mt={6} mb={4} display="flex" justifyContent='center'>
                <Typography variant="h6_1" color="primary" border="1px solid #27BAC4" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px" width="fit-content">SERVICES & FACILITIES</Typography>
            </Box>

            <Grid container rowGap={5} columnGap={6} mt={5} pt={5} pb={10} justifyContent="center" marginBottom='0px'>
                {hostelData?.serviceFacility?.map((item, index) => (
                    <Grid md={4} lg={3}>
                        <Card sx={{ maxWidth: 363, height: '100%', p: 2, boxShadow : '0px 4px 20px 0px rgba(0, 0, 0, 0.15)',borderRadius: "20px", textAlign: "start" }}>
                       
                            <CardActionArea>
                            <Box sx={{position : 'relative'}}>
                                <CardMedia sx={{ borderRadius: "20px", height: "200px" }}
                                    component="img"
                                    image={process.env.REACT_APP_FILE_PATH + item?.img}
                                    alt="green iguana"
                                />
                                <CardContent sx={{height : '230px'}}>
                                   
                                    <Stack direction="row" gap="3px" justifyContent="center" alignItems="center">
                                        {/* <MealIcon /> */}
                                        <Typography variant="h5" component="div" color="#27BAC4">{item?.title}</Typography>
                                    </Stack>
                                    <Stack mt={3} gap="2px">
                                        <Typography variant="h8_2">{Boolean(item?.description) ? parse(item?.description) : ""} </Typography>
                                    </Stack>
                                </CardContent>
                                </Box>
                                <Box sx={{position : 'absolute', bottom : 0, right : 0, opacity : 0.2}} component='img' src={process.env.REACT_APP_FILE_PATH + item?.icon}></Box>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}
