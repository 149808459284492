import React, { useState, useRef } from 'react'
import { Typography, Box, Divider, Button, Stack, ListItem, List, ListItemText, CardActionArea, CardActions, Grid, Card, CardContent, CardMedia } from "@mui/material"
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


export default function Views({ hostelData }) {

    const boxRef = useRef(null);

    const handleScrollLeft = () => {
        if (boxRef.current) {
          boxRef.current.scrollTo({
            left: boxRef.current.scrollLeft - 395, 
            behavior: 'smooth',
          });
        }
      };
    
      
      const handleScrollRight = () => {
        if (boxRef.current) {
          boxRef.current.scrollTo({
            left: boxRef.current.scrollLeft + 395,
            behavior: 'smooth',
          });
        }
      };

    return (
        <>
            <Box py={6} sx={{ background: "#EFFEFF" }}>

                <Box display="flex" flexDirection={{xs : 'column', lg : 'row'}} sx={{gap : 3}} justifyContent='space-around' alignItems='center'>
                    <Box></Box>

                    <Box sx={{width : {xs : '42%',lg : '5%'}}}>
                    <Typography variant="h6_1" color="primary" border="1px solid #27BAC4" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px" width="fit-content">VIEWS</Typography>
                    </Box>

                    <Box sx={{width : {xs : '100%', lg : '10%'}, display : 'flex', justifyContent : 'space-between', gap : 2, px : {xs : 3, lg : 0}}}>
                    <Button onClick={handleScrollLeft} ><ChevronLeftIcon sx={{fontSize : '24px'}}/></Button>
                    <Button onClick={handleScrollRight}><ChevronRightIcon /></Button>
                    </Box>
                
                </Box>
                
                <Grid container rowGap={5} columnGap={6} mt={2} pt={5} pb={10} justifyContent="center" marginBottom='0px'>
                    {/* {images?.map((itm, index) => (
                    <Grid>
                        <Card sx={{ maxWidth: 363, p: 2, border: ".5px solid #737373", borderRadius: "20px", textAlign: "start" }}>
                            <CardActionArea>
                                <CardMedia sx={{ borderRadius: "20px", height: "200px" }}
                                    component="img"
                                    image={Meals}
                                    alt="green iguana"
                                />
                                <CardContent >
                                    <Stack direction="row" gap="3px" justifyContent="center" alignItems="center">
                                        <MealIcon />
                                        <Typography variant="h5" component="div" color="#27BAC4"> Meals</Typography>
                                    </Stack>
                                    <Stack mt={3} gap="2px" textAlign="center">
                                        <Typography variant="h8_2"> Mon - Fri </Typography>
                                        <Typography color="#737373" variant="h8_2"> Breakfast & Dinner </Typography>
                                        <Typography mt={2} variant="h8_2">Sat, Sun & Public Holiday </Typography>
                                        <Typography color="#737373" variant="h8_2"> Breakfast & Dinner </Typography>
                                    </Stack>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))} */}
                
                    <Box ref={boxRef} sx={{width : {xs : '90%',sm : '90%', lg : '80%'}, mb : 2,display : 'flex', gap : 5,justifyContent : 'space-between',height : '400px', overflow : 'auto',    scrollbarWidth: 'none', // Hide scrollbar for Firefox
        '&::-webkit-scrollbar': {
          display: 'none', // Hide scrollbar for Chrome, Safari, Edge
        },
    ':hover' : {cursor : 'pointer'}
    }}>
                    {hostelData?.Views?.map((item, index) => (
                            <Card sx={{ minWidth : 353 ,maxWidth: 363, height: '100%', borderRadius: "50px", textAlign: "start", position: "relative", boxShadow: "1px 120px 28px -7px rgba(0,0,0,0.08)" }}>
                                <Typography variant="h6_3" sx={{ position: "absolute", color: "#FFFFFF", zIndex: 1, bottom: 20, left: 30 }}>{item?.title}</Typography>
                                <CardActionArea>
                                <Box sx={{ position: 'relative', width: '100%', height: '400px' }}>
                                    <CardMedia sx={{ borderRadius: "20px", height: "400px", objectFit : 'cover'}}
                                        component="img"
                                        image={process.env.REACT_APP_FILE_PATH + item?.img}
                                        alt="green iguana"
                                    />
                                    <Box
                                        sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        height: '20%', 
                                        background: 'linear-gradient(transparent, rgba(0, 0, 0, 0.8))',
                                        borderRadius: "20px",
                                        }}
                                    />
                                    </Box>
                                </CardActionArea>
                                <div
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        height: "100%",
                                        width: "100%",
                                        background: 'linear-gradient(to top, rgba(0,0,0,.3), rgba(0,0,0,0))',
                                    }}
                                />
                            </Card>
                    ))}
                    </Box>
                    {/* <Grid md={4} lg={3}>
                        <Card sx={{ maxWidth: 363, height: '100%', borderRadius: "50px", textAlign: "start", position: "relative", boxShadow: "1px 120px 28px -7px rgba(0,0,0,0.08)" }}>
                            <Typography variant="h6_3" sx={{ position: "absolute", color: "#FFFFFF", zIndex: 1, bottom: 20, left: 30 }}>Room</Typography>
                            <CardActionArea>
                                <CardMedia sx={{ borderRadius: "20px", height: "100%" }}
                                    component="img"
                                    image={Views2}
                                    alt="green iguana"
                                />
                            </CardActionArea>
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    height: "100%",
                                    width: "100%",
                                    background: 'linear-gradient(to top, rgba(0,0,0,.1), rgba(0,0,0,0))',
                                }}
                            />
                        </Card>
                    </Grid>
                    <Grid md={4} lg={3}>
                        <Card sx={{ maxWidth: 363, height: '100%', borderRadius: "50px", textAlign: "start", position: "relative", boxShadow: "1px 120px 28px -7px rgba(0,0,0,0.08)" }}>
                            <Typography variant="h6_3" sx={{ position: "absolute", color: "#FFFFFF", zIndex: 1, bottom: 20, left: 30 }}>Room</Typography>
                            <CardActionArea>
                                <CardMedia sx={{ borderRadius: "20px", height: "100%" }}
                                    component="img"
                                    image={Views3}
                                    alt="green iguana"
                                />
                            </CardActionArea>
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    height: "100%",
                                    width: "100%",
                                    background: 'linear-gradient(to top, rgba(0,0,0,.2), rgba(0,0,0,0))',
                                }}
                            />
                        </Card>
                    </Grid> */}
                </Grid>
            </Box>
        </>
    )
}
