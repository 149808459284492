import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  Grid,
  Divider,
  InputLabel,
  Button
} from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ReactComponent as BackArrrow } from "../../../Icons/BorderBackIcon.svg";
import { ReactComponent as FormIcon } from "../../../Icons/CommonFormIcon.svg";
import { useNavigate } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

export default function ApplicationReview() {
  const { ServicesData } = useSelector((state) => state.CommonData);
  const navigate = useNavigate();
  const [PersonalData, setPersonalData] = useState({});
  const [ServicesValue, setServicesValue] = useState({});
  const [RoomsData, setRoomsData] = useState({});
  const [created_at, setCreated_at] = useState(null);
  const [status, setStatus] = useState('')

  const { Details, Emergency_contact } = PersonalData;
  const [loading, setLoading] = useState(true)

  const {
    otherServices,
    fligt_no,
    arrival_date,
    arrival_time,
    landingServices,
  } = ServicesValue;

  const { id } = useParams();
  const getAllAnnouncement = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}getApplicationById/${id}`
      );
      setStatus(response?.data?.data?.applicationStatus)
      setCreated_at(response?.data?.data?.createdAt);
      setRoomsData(response?.data?.data?.RoomsData);
      setServicesValue(response?.data?.data?.ServicesValue);
      setPersonalData(response?.data?.data?.PersonalData);
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };

  const handleAcceptRejectApplication = async (newState) => {
    await axios.post(`${process.env.REACT_APP_API_URL}application-accept-reject/${id}`, { applicationStatus : newState })
    .then((response) => {
      console.log(response)
      if(response.status === 200){
        getAllAnnouncement()
      }
    })
    .catch((error) => console.log(error))
  }


  useEffect(() => {
    getAllAnnouncement();
  }, []);

  if(loading){
    return <p>Loading...</p>
  }

  console.log(status, 'status')
  console.log(ServicesData);

  return (
    <Box pb={30} px={{ md: 10, xs: 3 }}>
      <Box>
        <BackArrrow
          onClick={() => navigate("/Admin/Application")}
          style={{ cursor: "pointer", marginBottom: "20px" }}
        />
        <Typography variant="h6_1">Applicant Information</Typography>
        <Typography>
          {` Created at :${" "}${moment(created_at).format(
            "DD MMM YYYY, HH:mm"
          )}`}
        </Typography>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          backgroundColor: "#FCFCFD",
          border: "1px solid #E5E7EB",
          padding: "20px",
          borderRadius: "6px 6px 0px 0px",
          marginTop: "20px",
        }}
      >
        <FormIcon style={{ marginRight: "10px" }} />
        <Typography variant="h7_1">Hostel & Room</Typography>
      </Stack>
      <Grid
        container
        xs={12}
        gap="2.8%"
        sx={{
          backgroundColor: "white",
          padding: "20px",
          borderBottom: "1px solid #E5E7EB",
          borderRight: "1px solid #E5E7EB",
          borderLeft: "1px solid #E5E7EB",
        }}
      >
        <Grid xs={12} sm={5.8} my={2}>
          <Typography
            sx={{
              color: "#6C737F",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Hostel Name
          </Typography>
          <Typography
            sx={{
              color: "#111927",
              fontSize: "16px",
              fontWeight: 600,
            }}
            mt={0.5}
          >
            {RoomsData?.selectedRoom?.HostelName}
          </Typography>
        </Grid>
        <Grid xs={12} sm={5.8} my={2}>
          <Typography
            sx={{
              color: "#6C737F",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Room Type
          </Typography>
          <Typography
            sx={{
              color: "#111927",
              fontSize: "16px",
              fontWeight: 600,
            }}
            mt={0.5}
          >
            {RoomsData?.selectedRoom?.room_type_name}
          </Typography>
        </Grid>
        <Divider
          sx={{
            borderTop: "1px solid #F3F4F6",
            width: "100%",
            marginTop: "10px",
          }}
        />
        <Grid xs={12} sm={5.8} my={5}>
          {RoomsData?.contractLength === 1 && (
            <>
              <Typography
                sx={{
                  color: "#6C737F",
                  fontSize: "18px",
                  fontWeight: 600,
                }}
              >
                6 Month Contract
              </Typography>
              <Typography
                sx={{
                  color: "#111927",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
                mt={0.5}
              >
                S${RoomsData?.selectedRoom?.six_month_cont} / month
              </Typography>
            </>
          )}
          {RoomsData?.contractLength === 2 && (
            <>
              <Typography
                sx={{
                  color: "#6C737F",
                  fontSize: "18px",
                  fontWeight: 600,
                }}
              >
                12 Month Contract
              </Typography>
              <Typography
                sx={{
                  color: "#111927",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
                mt={0.5}
              >
                S${RoomsData?.selectedRoom?.twelve_month_cont} / month
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          backgroundColor: "#FCFCFD",
          border: "1px solid #E5E7EB",
          borderTop: "none",
          padding: "20px",
        }}
      >
        <FormIcon style={{ marginRight: "10px" }} />
        <Typography variant="h7_1">Services</Typography>
      </Stack>
      <Grid
        container
        xs={12}
        gap="2.8%"
        sx={{
          backgroundColor: "white",
          padding: "20px",
          borderBottom: "1px solid #E5E7EB",
          borderRight: "1px solid #E5E7EB",
          borderLeft: "1px solid #E5E7EB",
        }}
      >
        <Grid xs={12} sm={5.8} my={2}>
          <Typography
            sx={{
              color: "#6C737F",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Flight Number
          </Typography>
          <Typography
            sx={{
              color: "#111927",
              fontSize: "16px",
              fontWeight: 600,
            }}
            mt={0.5}
          >
            {ServicesValue?.fligt_no}
          </Typography>
        </Grid>
        <Grid xs={12} sm={5.8} my={2}>
          <Typography
            sx={{
              color: "#6C737F",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Landing Services (Optional)
          </Typography>

          {Boolean(landingServices) ? (
            <Typography
              sx={{
                color: "#111927",
                fontSize: "16px",
                fontWeight: 600,
              }}
              mt={0.5}
            >
              Included
            </Typography>
          ) : (
            <Typography
              sx={{
                color: "#111927",
                fontSize: "16px",
                fontWeight: 600,
              }}
              mt={0.5}
            >
              Not Included
            </Typography>
          )}
        </Grid>
        <Divider
          sx={{
            borderTop: "1px solid #F3F4F6",
            width: "100%",
            marginTop: "10px",
          }}
        />
        <Grid xs={12} sm={5.8} my={5}>
          <Typography
            sx={{
              color: "#6C737F",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Arrival Date
          </Typography>
          <Typography
            sx={{
              color: "#111927",
              fontSize: "16px",
              fontWeight: 600,
            }}
            mt={0.5}
          >
            {moment(ServicesValue?.arrival_date?.$d).format("MMM DD, YYYY")}
          </Typography>
        </Grid>
        <Grid xs={12} sm={5.8} my={5}>
          <Typography
            sx={{
              color: "#6C737F",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Arrival Time
          </Typography>
          <Typography
            sx={{
              color: "#111927",
              fontSize: "16px",
              fontWeight: 600,
            }}
            mt={0.5}
          >
            {moment(ServicesValue?.arrival_time?.$d).format("hh:mm A")}
          </Typography>
        </Grid>
      </Grid>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          backgroundColor: "#FCFCFD",
          border: "1px solid #E5E7EB",
          borderTop: "none",
          padding: "20px",
        }}
      >
        <FormIcon style={{ marginRight: "10px" }} />
        <Typography variant="h7_1">Personal Details</Typography>
      </Stack>
      <Grid
        container
        xs={12}
        gap="2.8%"
        sx={{
          backgroundColor: "white",
          padding: "20px",
          borderBottom: "1px solid #E5E7EB",
          borderRight: "1px solid #E5E7EB",
          borderLeft: "1px solid #E5E7EB",
        }}
      >
        <Grid xs={12} sm={5.8} my={2}>
          <Typography
            sx={{
              color: "#6C737F",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Name
          </Typography>
          <Typography
            sx={{
              color: "#111927",
              fontSize: "16px",
              fontWeight: 600,
            }}
            mt={0.5}
          >
            {Details?.name}
          </Typography>
        </Grid>
        <Grid xs={12} sm={5.8} my={2}>
          <Typography
            sx={{
              color: "#6C737F",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Nationality
          </Typography>

          <Typography
            sx={{
              color: "#111927",
              fontSize: "16px",
              fontWeight: 600,
            }}
            mt={0.5}
          >
            {Details?.nationality}
          </Typography>
        </Grid>
        <Divider
          sx={{
            borderTop: "1px solid #F3F4F6",
            width: "100%",
            marginTop: "10px",
          }}
        />
        <Grid xs={12} sm={5.8} my={5}>
          <Typography
            sx={{
              color: "#6C737F",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Passport number
          </Typography>
          <Typography
            sx={{
              color: "#111927",
              fontSize: "16px",
              fontWeight: 600,
            }}
            mt={0.5}
          >
            {Details?.passport_no}
          </Typography>
        </Grid>
        <Grid xs={12} sm={5.8} my={5}>
          <Typography
            sx={{
              color: "#6C737F",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Gender
          </Typography>
          <Typography
            sx={{
              color: "#111927",
              fontSize: "16px",
              fontWeight: 600,
            }}
            mt={0.5}
          >
            {Details?.gender}
          </Typography>
        </Grid>
        <Divider
          sx={{
            borderTop: "1px solid #F3F4F6",
            width: "100%",
            marginTop: "10px",
          }}
        />
        <Grid xs={12} sm={5.8} my={5}>
          <Typography
            sx={{
              color: "#6C737F",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Date of Birth
          </Typography>
          <Typography
            sx={{
              color: "#111927",
              fontSize: "16px",
              fontWeight: 600,
            }}
            mt={0.5}
          >
            {moment(Details?.dob?.$d).format("MMM DD, YYYY")}
          </Typography>
        </Grid>
        <Grid xs={12} sm={5.8} my={5}>
          <Typography
            sx={{
              color: "#6C737F",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            School
          </Typography>
          <Typography
            sx={{
              color: "#111927",
              fontSize: "16px",
              fontWeight: 600,
            }}
            mt={0.5}
          >
            {Details?.school}
          </Typography>
        </Grid>
      </Grid>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          backgroundColor: "#FCFCFD",
          border: "1px solid #E5E7EB",
          borderTop: "none",
          padding: "20px",
        }}
      >
        <FormIcon style={{ marginRight: "10px" }} />
        <Typography variant="h7_1">Contact Details</Typography>
      </Stack>
      <Grid
        container
        xs={12}
        gap="2.8%"
        sx={{
          backgroundColor: "white",
          padding: "20px",
          borderBottom: "1px solid #E5E7EB",
          borderRight: "1px solid #E5E7EB",
          borderLeft: "1px solid #E5E7EB",
        }}
      >
        <Grid xs={12} sm={5.8} my={2}>
          <Typography
            sx={{
              color: "#6C737F",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Contact number
          </Typography>
          <Typography
            sx={{
              color: "#111927",
              fontSize: "16px",
              fontWeight: 600,
            }}
            mt={0.5}
          >
            {Details?.contact_no}
          </Typography>
        </Grid>
        <Grid xs={12} sm={5.8} my={2}>
          <Typography
            sx={{
              color: "#6C737F",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            WeChat
          </Typography>

          <Typography
            sx={{
              color: "#111927",
              fontSize: "16px",
              fontWeight: 600,
            }}
            mt={0.5}
          >
            {Details?.wechat}
          </Typography>
        </Grid>
        <Divider
          sx={{
            borderTop: "1px solid #F3F4F6",
            width: "100%",
            marginTop: "10px",
          }}
        />
        <Grid xs={12} sm={5.8} my={5}>
          <Typography
            sx={{
              color: "#6C737F",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            WhatsApp
          </Typography>
          <Typography
            sx={{
              color: "#111927",
              fontSize: "16px",
              fontWeight: 600,
            }}
            mt={0.5}
          >
            {Details?.whatsapp}
          </Typography>
        </Grid>
        <Grid xs={12} sm={5.8} my={5}>
          <Typography
            sx={{
              color: "#6C737F",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Line
          </Typography>
          <Typography
            sx={{
              color: "#111927",
              fontSize: "16px",
              fontWeight: 600,
            }}
            mt={0.5}
          >
            {Details?.line}
          </Typography>
        </Grid>
        <Divider
          sx={{
            borderTop: "1px solid #F3F4F6",
            width: "100%",
            marginTop: "10px",
          }}
        />
        <Grid xs={12} sm={5.8} my={5}>
          <Typography
            sx={{
              color: "#6C737F",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            KakaoTaalk
          </Typography>
          <Typography
            sx={{
              color: "#111927",
              fontSize: "16px",
              fontWeight: 600,
            }}
            mt={0.5}
          >
            {Details?.kakaotalk}
          </Typography>
        </Grid>
      </Grid>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          backgroundColor: "#FCFCFD",
          border: "1px solid #E5E7EB",
          borderTop: "none",
          padding: "20px",
        }}
      >
        <FormIcon style={{ marginRight: "10px" }} />
        <Typography variant="h7_1">Emergency Contacts</Typography>
      </Stack>
      <Grid
        container
        xs={12}
        gap="2.8%"
        sx={{
          backgroundColor: "white",
          padding: "20px",
          borderBottom: "1px solid #E5E7EB",
          borderRight: "1px solid #E5E7EB",
          borderLeft: "1px solid #E5E7EB",
        }}
      >
        {Emergency_contact?.map((item, index) => (
          <>
            <Grid xs={12}>
              <Typography variant="h7_1" color="text_blue">
                Contact {index + 1}
              </Typography>
            </Grid>
            <Grid xs={6} sm={5.8} my={2}>
              <Typography
                sx={{
                  color: "#6C737F",
                  fontSize: "18px",
                  fontWeight: 600,
                }}
              >
                Contact name
              </Typography>
              <Typography
                sx={{
                  color: "#111927",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
                mt={0.5}
              >
                {item?.Emergency_name}
              </Typography>
            </Grid>
            <Grid xs={6} sm={5.8} my={2}>
              <Typography
                sx={{
                  color: "#6C737F",
                  fontSize: "18px",
                  fontWeight: 600,
                }}
              >
                Contact number
              </Typography>
              <Typography
                sx={{
                  color: "#111927",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
                mt={0.5}
              >
                {item?.Emergency_contact_no}
              </Typography>
            </Grid>
            <Grid xs={12} sm={5.8} my={2}>
              <Typography
                sx={{
                  color: "#6C737F",
                  fontSize: "18px",
                  fontWeight: 600,
                }}
              >
                Email
              </Typography>
              <Typography
                sx={{
                  color: "#111927",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
                mt={0.5}
              >
                {item?.Emergency_email}
              </Typography>
            </Grid>
            <Grid xs={12} sm={5.8} my={2}>
              <Typography
                sx={{
                  color: "#6C737F",
                  fontSize: "18px",
                  fontWeight: 600,
                }}
              >
                WeChat
              </Typography>

              <Typography
                sx={{
                  color: "#111927",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
                mt={0.5}
              >
                {item?.Emergency_wechat}
              </Typography>
            </Grid>
            <Grid xs={12} sm={5.8} my={2}>
              <Typography
                sx={{
                  color: "#6C737F",
                  fontSize: "18px",
                  fontWeight: 600,
                }}
              >
                WhatsApp
              </Typography>
              <Typography
                sx={{
                  color: "#111927",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
                mt={0.5}
              >
                {item?.Emergency_whatsapp}
              </Typography>
            </Grid>
            <Grid xs={12} sm={5.8} my={2}>
              <Typography
                sx={{
                  color: "#6C737F",
                  fontSize: "18px",
                  fontWeight: 600,
                }}
              >
                Line
              </Typography>
              <Typography
                sx={{
                  color: "#111927",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
                mt={0.5}
              >
                {item?.Emergency_line}
              </Typography>
            </Grid>
            <Grid xs={12} sm={5.8} my={2}>
              <Typography
                sx={{
                  color: "#6C737F",
                  fontSize: "18px",
                  fontWeight: 600,
                }}
              >
                KakaoTaalk
              </Typography>
              <Typography
                sx={{
                  color: "#111927",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
                mt={0.5}
              >
                {item?.Emergency_kakaotalk}
              </Typography>
            </Grid>
            {Emergency_contact?.length > 1 && (
              <Divider
                sx={{
                  borderTop: "1px solid #F3F4F6",
                  width: "100%",
                  marginTop: "10px",
                }}
              />
            )}
          </>
        ))}
      </Grid>
      <Box
        sx={{
          borderRadius: "8px",
          border: "1px solid #FEE6A1",
          padding: "20px 16px 20px 16px",
          backgroundColor: "#FFFAEB",
          marginTop: "25px",
          width: "100%",
        }}
      >
        <Typography
          sx={{ fontSize: "18px", fontWeight: 600, color: "#B54708" }}
        >
          NOTE* (Any Allergies)
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            color: "#B54708",
            marginTop: "10px",
          }}
        >
          {Details?.note}
        </Typography>
      </Box>

      <Box sx={{mt : 5, padding : '10px', display : 'flex', justifyContent : 'space-between', alignItems : 'center'}}>
        <Box sx={{display : 'flex', gap : '5px'}}>
          <Typography sx={{fontWeight : 700, fontSize : '16px'}}>Status</Typography>
          <Typography>:</Typography>
          <Typography sx={{ fontSize: '16px' }}>
  {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
</Typography>

        </Box>

        <Box sx={{gap : '10px', display : 'flex'}}>
        <Button onClick={() => {
            handleAcceptRejectApplication('pending')
        }} endIcon={<HourglassEmptyIcon />} disabled={status === 'pending'} variant='contained'>Pending</Button>

        <Button onClick={() => {
          handleAcceptRejectApplication('rejected')
        }} endIcon={<ClearIcon />} disabled={status === 'rejected'} variant='contained'>Reject</Button>

        <Button onClick={() => {
            handleAcceptRejectApplication('success')
        }} endIcon={<CheckIcon />} disabled={status === 'success'} variant='contained'>Accept</Button>
        </Box>
      </Box>
    </Box>
  );
}
