import React, { useEffect } from "react";
import {
  Typography,
  Box,
  Divider,
  Button,
  TextField,
  InputLabel,
  Card,
  CardContent
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { ReactComponent as FormIcon } from "../../../Icons/CommonFormIcon.svg";

export default function EditCheckList() {

    const { id } = useParams()
  const navigate = useNavigate();

  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"], 
    ],
  };

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    watch,
    reset,
  } = useForm({
    mode: "onTouched",
  });


  const fetchCheckListDataById = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}get-check-list-by-id/${id}`)
        console.log('get check list by id', response)
        if(response.status === 200){
            setValue('item', response?.data?.data?.item)
            setValue('price', response?.data?.data?.price)
        }
    } catch (error) {
        console.log(error)
    }
  }

  useEffect(() => {
    fetchCheckListDataById()
  },[])

  const onSubmit = async (data) => {
    console.log(data);

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}edit-check-list/${id}`,
        data
      );
      console.log(response);
      if(response.status === 201){
          navigate('/Admin/checklist')
      }
      // reset()
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Card
          sx={{
            width: "80%",
            border: "1px solid #E5E7EB",
            borderRadius: "6px",
          }}
        >
          <CardContent style={{ padding: 0, margin: 0 }}>
          <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                backgroundColor: "#FCFCFD",
                padding: 2,
                border: "1px solid #E5E7EB",
                borderRadius: "6px 6px 0px 0px",
              }}
            >
              <FormIcon style={{ marginRight: "10px" }} />
              <Typography variant="h6_1">Edit check list</Typography>
            </Box>
        
        <form onSubmit={handleSubmit(onSubmit)}>

        <Box style={{ padding: "0px 17px 17px", margin : '20px 0px 0px 0px' }}>
              <InputLabel
                sx={{
                  mb: 1,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
                {errors?.item ? 'Item*' : 'Item'}
              </InputLabel>
              <Controller
                name="item"
                rules={{ required: true,
                 }}
                control={control}
                render={({ field }) => (
                  <TextField
                    error={Boolean(errors?.item) ? true : false}
                    {...field}
                    placeholder="Enter item name"
                    sx={{ 
                      mt: 0, 
                      backgroundColor : 'rgb(249, 250, 251)',
                      borderRadius: 0, 
                      "& .MuiInputBase-input": {
                        color: errors?.item ? "red" : "black",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: errors?.item ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: errors?.item ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: errors?.item ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                      },
                    }}
                  />
                )}
              />
              {errors?.item ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>Please enter item name</Typography> : null }
              </Box>

              <Box style={{ padding: "0px 17px 17px", margin : '60px 0px 0px 0px' }}>
              <InputLabel
                sx={{
                  mb: 1,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
                {errors?.price ? 'Amount*' : 'Amount'}
              </InputLabel>
              <Controller
                name="price"
                rules={{ required: true, 
                     pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter a valid number",
                  },
                 }}
                control={control}
                render={({ field }) => (
                  <TextField
                    error={Boolean(errors?.price) ? true : false}
                    {...field}
                    placeholder="Enter amount"
                    sx={{ 
                      mt: 0, 
                      backgroundColor : 'rgb(249, 250, 251)',
                      borderRadius: 0, 
                      "& .MuiInputBase-input": {
                        color: errors?.price ? "red" : "black",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: errors?.price ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: errors?.price ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: errors?.price ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                      },
                    }}
                  />
                )}
              />
              {errors?.price ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>{errors.price.message || 'Please enter amount'}</Typography> : null }
              </Box>
            
              <Divider style={{marginTop : '15px'}}/>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "0px 17px 13px",
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{
                  padding: "10px 20px 10px 20px",
                  mt: 2,
                  width: "20%",
                  fontSize: 20,
                  backgroundColor: "rgb(14, 94, 132)",
                  ":hover": { bgcolor: "rgb(14, 94, 132)" },
                }}
              >
                Update
              </Button>
            </Box>
            
        </form>
        </CardContent>
        </Card>
      </Box>
    </>
  );
}
