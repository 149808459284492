import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepButton,
  setStepperCount,
  useMediaQuery
} from "@mui/material";
import { ReactComponent as StepperCorrectIcon } from "../../../../Icons/StepperCorrectIcon.svg";
import Room from "./Room";
import Services from "./Services";
import Information from "./Information";
import Review from "./Review";
import Payment from "./Payment";
import Confirmation from "./Confirmation";
import axios from "../../../../Redux/AxiosInstance/index";
import { DocumentTitleChange } from "../../../../CommonComponents/Title";
import { useSelector } from "react-redux";

const steps = [
  "Information",
  "Room",
  "Services",
  "Review",
  "Payment",
  "Confirmation",
];

export default function ApplicationDetails({setFlowCount, email, setEmail}) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [stepperCount, setStepperCount] = useState(1);
  const [trigger, setTrigger] = useState(null);
  const [RoomsData, setRoomsData] = useState({});
  console.log(stepperCount, 'stepperCount');

  const data = useSelector((state) => state.ApplicationData.data)
  
  const roomData = useSelector((state) => state.ApplicationDatas.RoomsData)


  const [verfiyMail, setVerifyMail] = useState(false)
  const [checked, setChecked] = useState(true)
  // const [email, setEmail] = useState('')

  const initial_Emergency_contact = {
    Emergency_name: "",
    Emergency_contact_no: "",
    Emergency_email: "",
    Emergency_whatsapp: "",
    Emergency_wechat: "",
    Emergency_line: "",
    Emergency_kakaotalk: "",
  };
  // const [Emergency_contact, setEmergency_contact] = useState([
  //   initial_Emergency_contact,
  // ]);
  // const initialDetails = {
  //   name: "",
  //   dob: "",
  //   nationality: "",
  //   gender: "",
  //   passport_no: "",
  //   school: "",
  //   contact_no: "",
  //   wechat: "",
  //   whatsapp: "",
  //   line: "",
  //   kakaotalk: "",
  //   note: "",
  //   ipaFile: "",
  // };

  const [PersonalData, setPersonalData] = useState({
    Details: {
      name: "",
      dob: "",
      nationality: "",
      gender: "",
      passport_no: "",
      school: "",
      contact_no: "",
      wechat: "",
      whatsapp: "",
      line: "",
      kakaotalk: "",
      note: "",
      ipaFile: "",
      agreement : true
    },
    Emergency_contact: [initial_Emergency_contact],
  });

  console.log(PersonalData, 'personal data from index js')
  
  const [ServicesValue, setServicesValue] = useState({
    fligt_no: "",
    arrival_date: "",
    arrival_time: "",
    landingServices: [],
    otherServices: [],
  });

  console.log('serviceValue', ServicesValue)

  console.log(RoomsData, 'Rooms Data')

  useEffect(() => {
    if (data?.stepperCount) {
      setStepperCount(data.stepperCount)
    }
    if (data?.RoomsData) {
      setRoomsData(data.RoomsData);
    }
    if (data?.ServicesValue) {
      setServicesValue(data.ServicesValue);
    }
    if (data?.PersonalData?.Details) {
      setPersonalData(prevState => ({
        ...prevState,
        Details: data.PersonalData.Details,
        Emergency_contact : data.PersonalData.Emergency_contact
      }));
    }
  }, [data]);

  const [payment, setPayment] = useState(false)

  const HandleSubmitForm = async () => {
    try {
      const formData = new FormData();
      formData.append("RoomsData", JSON.stringify(RoomsData));
      formData.append("PersonalData", JSON.stringify(PersonalData));
      formData.append("ServicesValue", JSON.stringify(ServicesValue));
      formData.append("ipaFile", PersonalData?.Details?.ipaFile[0]);

      const response = await axios.post("AddApplicationForm", formData);
      if (response) {
        console.log(response.data, 'data saved!')
      }
      return response;
    } catch (err) {
      return err;
    }
  };

  const HandleSubmitSteps = async () => {
    try {
      // const formData = {
      //   stepperCount : stepperCount + 1,
      //   email : email,
      //   RoomsData : roomData,
      //   PersonalData : JSON.stringify(PersonalData),
      //   ServicesValue : JSON.stringify(ServicesValue),
      //   ipaFile : PersonalData?.Details?.ipaFile[0],
      //   payment : payment
      // }
      const formData = new FormData()
      formData.append("stepperCount", stepperCount + 1)
      formData.append("email", email)
      formData.append("RoomsData", JSON.stringify(RoomsData));
      formData.append("PersonalData", JSON.stringify(PersonalData));
      formData.append("ServicesValue", JSON.stringify(ServicesValue));
      formData.append("ipaFile", PersonalData?.Details?.ipaFile[0]);
      formData.append("payment", payment)
     

      const response = await axios.post("add-application-step-data", formData);
      if (response) {
        console.log(response.data, 'data saved!')
      }
      return response;
    } catch (err) {
      return err;
    }
  };

  // useEffect(() => (
  //   HandleSubmitSteps
  // ), [RoomsData, PersonalData, ServicesValue])

  const StepperIcon = (index) => {
    if (stepperCount > index + 1) {
      return <StepperCorrectIcon />;
    } else if (stepperCount == index + 1) {
      return (
        <Typography
          width="36px"
          height="36px"
          backgroundColor="text_blue"
          borderRadius="100px"
          color="white"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {index + 1}
        </Typography>
      );
    } else {
      return (
        <Typography
          width="36px"
          height="36px"
          backgroundColor="white"
          border=".5px solid #737373"
          borderRadius="100px"
          color="#737373"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {index + 1}
        </Typography>
      );
    }
  };

  return (
    <>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          padding="42px 60px"
        >
          <Stack gap="10px">
            <Typography variant="h4_1">Hostel application process</Typography>
            <Typography variant="h9_4" color="neutral_4">
              Fill in the required details
            </Typography>
          </Stack>
          <Typography variant="h9_4" color="neutral_4">
            Saved
          </Typography>
        </Stack>
        <Box
          px={8}
          py={3}
          mb={5}
          sx={{
            width: "100%",
            borderTop: ".5px solid #1A2461",
            borderBottom: ".5px solid #1A2461",
            overflowX: {xs : 'auto', lg : 'visible'}, // Adjust for mobile overflow
          }}
        >
          <Stepper nonLinear activeStep={1} orientation={isMobile ? 'horizontal' : 'horizontal'}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton color="inherit" icon={StepperIcon(index)}>
                  <Typography
                    variant="h8_1"
                    color={
                      stepperCount == index + 1
                        ? "text_blue"
                        : stepperCount > index + 1
                        ? "green"
                        : "neutral_4"
                    }
                  >
                    {" "}
                    {label}
                  </Typography>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
        {stepperCount === 2 && (
          <Room HandleSubmitSteps={HandleSubmitSteps} PersonalData={PersonalData} setStepperCount={setStepperCount} setRoomsData={setRoomsData} RoomsData={RoomsData} email={email} setFlowCount={setFlowCount} />
        )}
        {stepperCount === 3 && (
          <Services
            setStepperCount={setStepperCount}
            setServicesValue={setServicesValue}
            ServicesValue={ServicesValue}
            email={email}
          />
        )}
        {stepperCount === 1 && (
          <Information
            setFlowCount={setFlowCount}
            setStepperCount={setStepperCount}
            setPersonalData={setPersonalData}
            PersonalData={PersonalData}
            verfiyMail={verfiyMail} 
            setVerifyMail={setVerifyMail}
            email={email} 
            setEmail={setEmail}
            HandleSubmitSteps={HandleSubmitSteps}
          />
        )}
        {stepperCount === 4 && (
          <Review
            setStepperCount={setStepperCount}
            setPersonalData={setPersonalData}
            ServicesValue={ServicesValue}
            PersonalData={PersonalData}
            RoomsData={RoomsData}
            checked = {checked} 
            setChecked={setChecked}
            email={email}
            // HandleSubmitForm={HandleSubmitForm}
            trigger={trigger}
            HandleSubmitSteps={HandleSubmitSteps}
          />
        )}
      {stepperCount === 5 && (<Payment setStepperCount={setStepperCount} HandleSubmitForm={HandleSubmitForm} HandleSubmitSteps={HandleSubmitSteps} email={email} payment={payment} setPayment={setPayment} />)}
        {stepperCount === 6 && (
          <Confirmation setStepperCount={setStepperCount} />
        )}
      </Box>
    </>
  );
}
