import React, { useState, useRef, useEffect } from "react";
import {
  Typography,
  Box,
  Divider,
  Button,
  Grid,
  TableContainer,
  Table,
  TextField,
  InputLabel,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Stack,
  Card,
  CardContent
} from "@mui/material";
import { ReactComponent as UploadFileIcon } from "../../../Icons/UploadFileIcon.svg";
import { ReactComponent as ImgIcon } from "../../../Icons/ImgIcon.svg";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { ReactComponent as FormIcon } from "../../../Icons/CommonFormIcon.svg";

export default function NewsEdit() {
  //const navigate = useNavigate();
  const { id } = useParams(); // Get the news ID from the URL parameters
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    register,
    watch,
  } = useForm({
    mode: "onTouched",
  });
  
  const Imgwatch = watch("img");
  const imgFile = useRef();

  const [Newsdata, setNewsdata] = useState({
    title: "",
    description: "",
    img: "",
  });

  const navigate = useNavigate();

  const modules = {
    toolbar: [
      ["bold", "italic", "underline"], // toggled buttons
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"], // superscript/subscript
    ],
  };

  // const modules = {
  //   toolbar: [
  //     ["bold", "italic", "underline"],
  //     [{ list: "ordered" }, { list: "bullet" }],
  //     ["link"],
  //   ],
  // };

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    img: null,
  });

 // const imgFile = useRef();

  // Fetch the existing news data
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}getNewsbyId/${id}`
        );
        const data = response.data.data;
        console.log(data);
        setValue('title', data?.title)
        setValue('description', data?.description)
        setValue('img', data?.img)
        
        setFormData({
          title: data.title,
          description: data.description,
          img: data.img,
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchNews();
  }, [id]);

  // const handleChange = (e) => {
  //   const { name, value, files } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: files ? files[0] : value,
  //   }));
  // };

  // const handleQuillChange = (value) => {
  //   console.log(value)
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     description: value,
  //   }));
  // };

  // const handleClearImage = () => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     img: null,
  //   }));
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const form = new FormData();
  //   form.append("title", formData.title);
  //   form.append("description", formData.description);
  //   if (formData.img && typeof formData.img === "object") {
  //     form.append("img", formData.img);
  //   }

  //   console.log(form, 'form')
  //   try {
  //     if(formData.title !== '' && formData.description !== '' && formData.img !== null){
        
  //     const response = await axios.put(
  //       `${process.env.REACT_APP_API_URL}editNews/${id}`,
  //       form,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     navigate("/Admin/NewsList");
  //     console.log(response);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  console.log(errors)
 

  
  const onSubmit = async (data) => {
    console.log(data, 'data');
    setNewsdata({
      title: data?.title,
      description: data?.description,
      img: data?.img,
    });

    const formData = new FormData();

    formData.append("title", data?.title);
    formData.append("description", data?.description);
    formData.append("img", data?.img);

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}editNews/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      navigate("/Admin/NewsList");
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const checkQuillContent = (content) => {
    console.log(content)
    const isEmpty = content === "<p><br></p>" || content === "";
    return !isEmpty;
  };

  return (
    // <Box sx={{ display: "flex", justifyContent: "center" }}>
    //   <Card sx={{ width: 650, border:'1px solid #E5E7EB', borderRadius:"6px"}}>
    //     <CardContent style={{ padding: 0, margin: 0 }}>
    //     <Box
    //         sx={{
    //           display: "flex",
    //           justifyContent: "flex-start",
    //           alignItems: "center",
    //           backgroundColor: "#FCFCFD",
    //           padding: 2,
    //           border:'1px solid #E5E7EB',
    //           borderRadius:'6px 6px 0px 0px'
              
    //         }}
    //       >
    //         <FormIcon style={{ marginRight: "10px" }} />
    //         <Typography variant="h6_1">News Edit</Typography>
    //       </Box>

    //   <form onSubmit={handleSubmit}>
    //   <Box style={{ padding: "25px 17px 17px 17px" }}>
    //         <InputLabel sx={{
    //               mb: 1,
    //               fontSize: "18px",
    //               fontWeight: 600,
    //               color: "black",
    //             }}>{formData?.title ? 'Title' : 'Title*'}</InputLabel>
    //         <TextField
    //           name="title"
    //           sx={{ 
    //             mt: 0, 
    //             backgroundColor : 'rgb(249, 250, 251)',
    //             borderRadius: 0, 
    //             "& .MuiInputBase-input": {
    //               color: formData?.title ? "black" : "red",
    //             },
    //             "& .MuiOutlinedInput-root": {
    //               "& fieldset": {
    //                 borderColor: formData?.title ? "rgba(0, 0, 0, 0.23)" : "#ff5e74",
    //               },
    //               "&:hover fieldset": {
    //                 borderColor: formData?.title ? "rgba(0, 0, 0, 0.23)" : "#ff5e74",
    //               },
    //               "&.Mui-focused fieldset": {
    //                 borderColor: formData?.title ? "rgba(0, 0, 0, 0.23)" : "#ff5e74",
    //               },
    //             },
    //           }}
    //           value={formData.title}
    //           onChange={handleChange}
    //           required
    //         />
    //         {formData?.title ? null:<Typography style={{color : '#ff5e74', margin : '0.3rem'}}>Please enter title</Typography> }
    //         </Box>
         
    //         <Box style={{ padding: "0px 17px 17px" }}>
    //         <InputLabel sx={{
    //               mb: 1,
    //               fontSize: "18px",
    //               fontWeight: 600,
    //               color: "black",
    //             }}>{formData?.description !== '<p><br></p>' ? 'Description' : 'Description*'}</InputLabel>
    //         <ReactQuill
    //           style={{ height: "180px" }}
    //           modules={modules}
    //           value={formData.description}
    //           onChange={handleQuillChange}
    //           maxLength={3000}
    //         />
            
    //         </Box>

    //         <Box style={{ marginTop : '2rem', marginLeft : '1rem'}}>
    //         {(formData?.description !== '<p><br></p>') ? null:<Typography style={{color : '#ff5e74', margin : '0.3rem'}}>Please enter description</Typography> }
    //         </Box>
         
    //         <Box
    //         sx={{
    //           display: "flex",
    //           justifyContent: "flex-start",
    //           alignItems: "center",
    //           backgroundColor: "#FCFCFD",
    //           padding: 2,
    //           border:'1px solid #E5E7EB',
    //           marginTop : 7
    //         }}
    //       >
    //         <FormIcon style={{ marginRight: "10px" }} />
    //         <Typography sx={{
    //               fontSize: "20px",
    //               fontWeight: 600,
    //               color: "black",
    //             }}>News Image</Typography>
    //         </Box>

    //         {formData.img ? (
    //           <Box style={{ padding: "20px 17px 17px" }}>
    //           <Stack
    //             direction="row"
    //             justifyContent="space-between"
    //             alignItems="center"
    //             padding="23px"
    //             borderRadius="8px"
    //             sx={{ border: ".5px solid #1A2461" }}
    //           >
    //             <Typography
    //               variant="h9_1"
    //               sx={{ display: "flex", alignItems: "center", gap: "10px" }}
    //             >
    //               <ImgIcon />
    //               {typeof formData.img === "object"
    //                 ? formData.img.name
    //                 : formData.img}
    //             </Typography>
    //             <ClearIcon
    //               onClick={handleClearImage}
    //               sx={{ color: "#1A2461", cursor: "pointer" }}
    //             />
    //           </Stack>
    //           </Box>
    //         ) : (
    //           <Box
    //             xs={12}
    //             onClick={() => imgFile.current.click()}
    //             sx={{
    //               border: (formData?.img == null)
    //                   ? "1px dashed red"
    //                   : "1px dashed #1A2461",
    //               height: "200px",
    //               borderRadius: "8px",
    //               backgroundColor: "#F9FBFF",
    //               margin: '20px 17px 17px'
    //             }}
    //           >
    //             <Stack
    //               direction="column"
    //               alignItems="center"
    //               justifyContent="center"
    //               height="100%"
    //             >
    //               <Stack
    //                 direction="row"
    //                 alignItems="center"
    //                 justifyContent="center"
    //                 sx={{
    //                   height: "100px",
    //                   width: "100px",
    //                   borderRadius: "100px",
    //                   backgroundColor: "#F2F2F2",
    //                 }}
    //               >
    //                 <UploadFileIcon fill="#1A2461" />
    //               </Stack>
    //               <Stack mt={2} direction="row" alignItems="center" gap="5px">
    //                 <Typography variant="h9_1">Click to upload</Typography>
    //               </Stack>
    //               <Typography variant="h9_5">
    //                 Allowed formats - JPEG, PNG
    //               </Typography>
    //             </Stack>
    //           </Box>
    //         )}
    //         <input
    //           type="file"
    //           accept="image/*"
    //           name="img"
    //           onChange={handleChange}
    //           ref={imgFile}
    //           hidden
    //         />
         
    //      <Divider style={{marginTop : '35px'}}/>
    //         <Box
    //           sx={{
    //             display: "flex",
    //             justifyContent: "flex-end",
    //             padding: "0px 17px 13px",
    //           }}
    //         >
    //           <Button 
    //            variant="contained"
    //            type="submit"
    //            sx={{
    //              padding: "10px 20px 10px 20px",
    //              mt: 2,
    //              width: "20%",
    //              fontSize: 20,
    //              backgroundColor: "rgb(14, 94, 132)",
    //              ":hover": { bgcolor: "rgb(14, 94, 132)" },
    //            }}>
    //             Update
    //           </Button>
    //           </Box>
          
    //   </form>
    //   </CardContent>
    //   </Card>
    // </Box>

    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Card sx={{ width: '80%', border:'1px solid #E5E7EB', borderRadius:"6px"}}>
        <CardContent style={{ padding: 0, margin: 0 }}>
        <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              backgroundColor: "#FCFCFD",
              padding: 2,
              border:'1px solid #E5E7EB',
              borderRadius:'6px 6px 0px 0px'
              
            }}
          >
            <FormIcon style={{ marginRight: "10px" }} />
            <Typography variant="h6_1">News Edit</Typography>
          </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          
        <Box style={{ padding: "25px 17px 17px 17px" }}>
              <InputLabel sx={{
                  mb: 1,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                }}>{errors?.title ? 'Title*' : 'Title'}</InputLabel>
              <Controller
                name="title"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <TextField
                    error={Boolean(errors?.title) ? true : false}
                    {...field}
                    placeholder='Enter title'
                    sx={{ 
                        mt: 0, 
                        backgroundColor : 'rgb(249, 250, 251)',
                        borderRadius: 0, 
                        "& .MuiInputBase-input": {
                          color: errors?.title ? "red" : "black",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: errors?.title ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                          },
                          "&:hover fieldset": {
                            borderColor: errors?.title ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: errors?.title ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                          },
                        },
                      }}
                  />
                )}
              />
              {errors?.title ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>Please enter title</Typography> : null}
              </Box>
           
              <Box style={{ padding: "0px 17px 17px" }}>
              <InputLabel sx={{
                mb: 1,
                fontSize: "18px",
                fontWeight: 600,
                color: "black",
              }}>{errors?.description ? 'Description*' : 'Description'}</InputLabel>
              <Controller
                name="description"
                rules={{ validate: value => checkQuillContent(value), required : true }}
                control={control}
                render={({ field }) => (
                  <ReactQuill
                    style={{ height: "180px", marginBottom: '1rem' }}
                    modules={modules}
                    maxLength={3000}
                    {...field}
                  />
                )}
              />
              
            </Box>
            <Box style={{ marginTop : '2rem', marginLeft : '1rem'}}>
            {errors?.description ? <Typography style={{ color: '#ff5e74', margin: '0.3rem' }}>Please enter description</Typography> : null}
            </Box>
            <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              backgroundColor: "#FCFCFD",
              padding: 2,
              border:'1px solid #E5E7EB',
              marginTop : 7
            }}
          >
            <FormIcon style={{ marginRight: "10px" }} />
            <Typography sx={{
                  fontSize: "20px",
                  fontWeight: 600,
                  color: "black",
                }}>News Image</Typography>
            </Box>

              {Boolean(Imgwatch) ? (
                  <Box style={{ padding: "20px 17px 17px" }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  padding="23px"
                  borderRadius="8px"
                  sx={{ border: ".5px solid #1A2461" }}
                >
                  <Typography
                    variant="h9_1"
                    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                  >
                    <ImgIcon />
                    {Imgwatch instanceof File ? Imgwatch.name : Imgwatch}
                  </Typography>
                  <ClearIcon
                    onClick={() => setValue("img", null)}
                    sx={{ color: "#1A2461" }}
                  />
                </Stack>
                </Box>
              ) : (
                <Box
                  xs={12}
                  onClick={() => imgFile.current.click()}
                  sx={{
                    border: Boolean(errors.img)
                      ? "1px dashed red"
                      : "1px dashed #1A2461",
                    height: "200px",
                    borderRadius: "8px",
                    backgroundColor: "#F9FBFF",
                    margin: '20px 17px 17px'
                  }}
                >
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "100px",
                        backgroundColor: "#F2F2F2",
                      }}
                    >
                      <UploadFileIcon fill="#1A2461" />
                    </Stack>
                    <Stack mt={2} direction="row" alignItems="center" gap="5px">
                      <Typography variant="h9_1">Click to upload</Typography>
                    </Stack>
                    <Typography variant="h9_5">
                      Allowed formats - JPEG, PNG
                    </Typography>
                  </Stack>
                  <Box sx={{marginTop : '0.3rem'}}>
                      {errors?.img ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>Please select an Image</Typography> : null}
                    </Box>
                </Box>
              )}
              <Controller
                name="img"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      field.onChange(e.target.files[0]);
                      e.target.value = null;
                    }}
                    ref={(e) => {
                      imgFile.current = e;
                    }}
                    hidden
                  />
                )}
              />
            <Divider style={{marginTop : '35px'}}/>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "0px 17px 13px",
              }}
            >
              <Button 
               variant="contained"
               type="submit"
               sx={{
                 padding: "10px 20px 10px 20px",
                 mt: 2,
                 width: "20%",
                 fontSize: 20,
                 backgroundColor: "rgb(14, 94, 132)",
                 ":hover": { bgcolor: "rgb(14, 94, 132)" },
               }}>
                Update
              </Button>
              </Box>
           
        </form>
        </CardContent>
      </Card>
      </Box>
  );
}
