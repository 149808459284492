import React, { useState } from 'react'
import { Typography, Box, Grid, Stack, TextField, Button, InputAdornment } from '@mui/material'
import { ReactComponent as PasswordIcon } from '../Icons/PasswordIcon.svg';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useForm, Controller } from 'react-hook-form';


export default function ResetPassword({ setDisplayScreen }) {

    const [passowordEyeNew, setPassowordEyeNew] = useState(true)
    const [passowordEyeRepeat, setPassowordEyeRepeat] = useState(true)

    const { handleSubmit, formState: { errors }, control, watch } = useForm({
        mode: "onTouched"
    })

    const newPassword = watch("new_password")

    const onSubmit = (data) => {
        console.log(data);
    }



    return (
        <Grid xs={12} sm={8} md={5} lg={3.5} p={6} sx={{ border: ".5px solid #1A2461", borderRadius: "20px", height: "auto" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack>
                    <Typography mb={.5} variant="h4_1">Reset Password </Typography>
                    <Typography variant="h9_4">Set your new password to your account</Typography>

                    <Controller
                        control={control}
                        name="new_password"
                        rules={{
                            required: "New Password is Required",
                        }}
                        render={({ field }) => <TextField
                            {...field}
                            error={Boolean(errors?.new_password) ? true : false}
                            sx={{ mt: 5, "& .MuiInputBase-root": { color: '#003893' } }}
                            placeholder="New Password"
                            type={passowordEyeNew ? "password" : "text"}
                            InputProps={{ startAdornment: <InputAdornment position="start"><PasswordIcon /></InputAdornment>, endAdornment: <InputAdornment onClick={() => setPassowordEyeNew(!passowordEyeNew)} position="end">{passowordEyeNew ? <RemoveRedEyeIcon sx={{ color: "#007ECA" }} /> : <VisibilityOffIcon sx={{ color: "#007ECA" }} />}</InputAdornment> }}
                        />}
                    />
                    {Boolean(errors?.new_password?.message) && <Typography sx={{ color: "red", mt: 1 }} variant="h9_4">{errors?.new_password?.message}</Typography>}


                    <Controller
                        control={control}
                        name="repeat_password"
                        rules={{
                            required: "Repeat Password is Required",
                            validate: (value) => value === newPassword || "Password does not match"
                        }}
                        render={({ field }) => <TextField
                            {...field}
                            error={Boolean(errors?.repeat_password) ? true : false}
                            sx={{ mt: 3, "& .MuiInputBase-root": { color: '#003893' } }}
                            placeholder="Repeat Password"
                            type={passowordEyeRepeat ? "password" : "text"}
                            InputProps={{ startAdornment: <InputAdornment position="start"><PasswordIcon /></InputAdornment>, endAdornment: <InputAdornment onClick={() => setPassowordEyeRepeat(!passowordEyeRepeat)} position="end">{passowordEyeRepeat ? <RemoveRedEyeIcon sx={{ color: "#007ECA" }} /> : <VisibilityOffIcon sx={{ color: "#007ECA" }} />}</InputAdornment> }}
                            disabled={Boolean(newPassword) ? false : true}
                        />}
                    />
                    {Boolean(errors?.repeat_password?.message) && <Typography sx={{ color: "red", mt: 1 }} variant="h9_4">{errors?.repeat_password?.message}</Typography>}

                    <Button type="submit" sx={{ mt: 3, width: "100%", backgroundColor: "#003893", '&:hover': { backgroundColor: "#003893" }, fontSize: "16px", fontWeight: 700, color: "#FFFFFF", padding: "10px 20px", borderRadius: "8px", letterSpacing: ".7px" }}>Reset Password</Button>
                </Stack>
            </form>
        </Grid >
    )
}
