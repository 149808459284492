// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";


// export const storeRoomsDataAndSendAPI = createAsyncThunk(
//     'applicationData/storeRoomsDataAndSendAPI',
//     async (roomsData, { dispatch, getState }) => {
//         const state = getState();
//         const email = state.applicationData.PersonalData.email;
       
//         dispatch(storeRoomsData(roomsData));

//         try {
//             const response = await axios.post('/add-application-step-data', {RoomsData : roomsData, email : email});
//             return response.data;
//         } catch (error) {
//             console.error('Error sending rooms data:', error);
//             throw error;
//         }
//     }
// );


// const initialState = {
//     RoomsData : {},
//     ServicesValue : {},
//     PersonalData : {}
// }

// const ApplicationData = createSlice({
//     name : 'ApplicationData',
//     initialState,
//     reducers : {
//         storeRoomsData : (state, action) => {
//             state.RoomsData = action.payload
//         },
//         storeServicesValue : (state, action) => {
//             state.ServicesValue = action.payload
//         },
//         storePersonalData : (state, action) => {
//             state.PersonalData = action.payload
//         },
//         removeRoomsData : (state, action) => {
//             state.RoomsData = {}
//         },
//         removeServicesValue : (state, action) => {
//             state.ServicesValue = {}
//         },
//         removePersonalData : (state, action) => {
//             state.PersonalData = {}
//         },
//         removeAllApplicationData : (state, action) => {
//             state.RoomsData = {}
//             state.ServicesValue = {}
//             state.PersonalData = {}
//         }
//     }
// })


// export const { 
//     storeRoomsData, 
//     storeServicesValue, 
//     storePersonalData, 
//     removeRoomsData,  
//     removeServicesValue,
//     removePersonalData,
//     removeAllApplicationData
// } = ApplicationData.actions


// export default ApplicationData.reducer

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Correct way to access state within a thunk
export const storeRoomsDataAndSendAPI = createAsyncThunk(
    'applicationData/storeRoomsDataAndSendAPI',
    async (roomsData, { dispatch, getState }) => {
        console.log(roomsData, 'api call')
        const state = getState();
        const email = state.applicationData.PersonalData.email;

        dispatch(storeRoomsData(roomsData));

        try {
            const response = await axios.post('/add-application-step-data', { RoomsData: roomsData, email: email });
            return response.data;
        } catch (error) {
            console.error('Error sending rooms data:', error);
            throw error;
        }
    }
);

const initialState = {
    RoomsData: {},
    ServicesValue: {},
    PersonalData: {}
};

const ApplicationData = createSlice({
    name: 'applicationData',
    initialState,
    reducers: {
        storeRoomsData: (state, action) => {
            state.RoomsData = action.payload;
        },
        storeServicesValue: (state, action) => {
            state.ServicesValue = action.payload;
        },
        storePersonalData: (state, action) => {
            state.PersonalData = action.payload;
        },
        removeRoomsData: (state) => {
            state.RoomsData = {};
        },
        removeServicesValue: (state) => {
            state.ServicesValue = {};
        },
        removePersonalData: (state) => {
            state.PersonalData = {};
        },
        removeAllApplicationData: (state) => {
            state.RoomsData = {};
            state.ServicesValue = {};
            state.PersonalData = {};
        }
    }
});

export const { 
    storeRoomsData, 
    storeServicesValue, 
    storePersonalData, 
    removeRoomsData,  
    removeServicesValue,
    removePersonalData,
    removeAllApplicationData
} = ApplicationData.actions;

export default ApplicationData.reducer;
