import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Divider,
  Button,
  TableContainer,
  Table,
  IconButton,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  TablePagination
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ReactComponent as DeleteIcon } from "../../Icons/DeleteTable.svg";
import { ReactComponent as EditIcon } from "../../Icons/EditIcon.svg";
import AlertModal from "../../CommonComponents/AlertModal";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import LinearProgress from '@mui/material/LinearProgress';
import CachedIcon from '@mui/icons-material/Cached';

export default function Services() {
  const navigate = useNavigate();

    const [page, setPage] = useState(0); 
    const [rowsPerPage, setRowsPerPage] = useState(5); 
    const [totalItems, setTotalItems] = useState(0);

    const [pageOthers, setPageOthers] = useState(0); 
    const [rowsPerPageOthers, setRowsPerPageOthers] = useState(5); 
    const [totalItemsOthers, setTotalItemsOthers] = useState(0);

  const [value, setValue] = useState(() => {
    const localValue = localStorage.getItem("serve");
    return localValue !== null ? localValue : '1';
  });

  const [serviceData, setServiceData] = useState([]);
  const [otherService, setOtherService] = useState([]);
  const [loading, setLoading] = useState(false);

  const [deleteModal, setDeleteModal] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  let deleteData = {
    label: (value == 1)
      ? "Confirm Delete Service"
      : "Confirm Delete Other Service",
    descriptionOne: (value == 1)
      ? "Please confirm that you wish to delete the service."
      : "Please confirm that you wish to delete the other service.",
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#912018",
      text: "Confirm",
    },
  };

  useEffect(() => {
    localStorage.setItem("serve", value);
  }, [value]);

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}getAllServices?page=${
          page + 1
        }&limit=${rowsPerPage}`
      );
      console.log("Get All Services:", response.data.data);
      setTotalItems(response.data.pagination.totalItems || 0)
      setServiceData(response.data.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchOtherServiceData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}get-other-services?page=${
          pageOthers + 1
        }&limit=${rowsPerPageOthers}`
      );
      console.log("Get All Other Services:", response.data.data);
      setTotalItemsOthers(response.data.pagination.totalItems || 0)
      setOtherService(response.data.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchData();
  // }, [page, rowsPerPage]);

  // useEffect(() => {
  //   fetchOtherServiceData();
  // },[pageOthers, rowsPerPageOthers])

  useEffect(() => {
    setLoading(true);
    switch (value) {
      case '1':
        fetchData();
        break;
      case '2':
        fetchOtherServiceData();
        break;
      default:
        setLoading(false);
    }
  }, [value, page, rowsPerPage, pageOthers, rowsPerPageOthers]);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page change
  };

   // Handle page change
   const handleChangePageOthers = (event, newPage) => {
    setPageOthers(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPageOthers = (event) => {
    setRowsPerPageOthers(parseInt(event.target.value, 10));
    setPageOthers(0); // Reset to first page when rows per page change
  };

 

  const handleClose = () => {
    setDeleteModal(null);
    setDeleteId(null);
  };

  const deleteOtherService = async () => {
    try {
      if (value === 1) {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}deleteService/${deleteId}`
        );
        fetchData();
        setDeleteModal(null);
        setDeleteId(null);
      } else if (value === 2) {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}delete-other-service/${deleteId}`
        );
        fetchOtherServiceData();
        setDeleteModal(null);
        setDeleteId(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNavigate = () => {
    if(value == 1){
      navigate("/Admin/add-service")
    } else if(value == 2){
      navigate("/Admin/add-other-service")
    }
  }

  const handleFetchDatas = () => {
    if(value === 1){
      fetchData()
    }else {
      fetchOtherServiceData()
    }
  }


  return (
    <>
      {deleteModal && (
        <AlertModal
          modalDatas={deleteData}
          confirmFunction={deleteOtherService}
          closeFunction={handleClose}
        />
      )}

      <Box >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography sx={{ fontSize: "22px", fontWeight: 600 }}>
            Services
          </Typography>

              <Box sx={{display : 'flex', gap : 3, alignItems : 'center'}}>
              <Typography variant="h7_1" sx={{cursor : 'pointer'}} title='Reload' onClick={() => handleFetchDatas()}><CachedIcon /></Typography>
              <Button
                variant="contained"
                onClick={handleNavigate}
              >
                Add
              </Button>
              </Box>
          {/* <Button onClick={() => navigate('/Admin/add-service')} variant="contained">Add</Button> */}
        </Stack>
        <Divider sx={{ mb: 2, mt: 2 }} />
        <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab sx={{ textTransform: 'none', fontSize : '1.1rem' }} label="Landing services" value="1" />
            <Tab sx={{ textTransform: 'none', fontSize : '1.1rem'  }} label="Other chargeable services" value="2" />
            
          </TabList>
        </Box>
        
        <TabPanel value="1" sx={{pt : 1, px : 0}}>
            <TableContainer sx={{
                mt : 3.5,
                borderRadius: "5px",
                border: "1px solid #e5e5e5",
                boxShadow: "0px 0px 30px 20px #f4f4f4",
              }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                      <Typography variant="h9_1" color="#333333">
                        Title
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Description
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Amount
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Actions
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {loading && (
              <TableRow>
                <TableCell colSpan={5} sx={{p :0, borderBottom : '0px'}}>
              <LinearProgress color="primary" />
              </TableCell>
              </TableRow>
            )}
                  {serviceData.length === 0 ? (<>
                    <TableRow>
                <TableCell colSpan={5} sx={{p :0, borderBottom : '0px'}}>
              No data found
              </TableCell>
              </TableRow>
                  </>) : (
                  serviceData.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{border: "0px",
                      backgroundColor: index % 2 === 0 ? "#f8f8f8" : "white"}}
                    >
                      <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                        <Typography variant="h9_4" color="#333333">
                          {item?.title || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                        <Typography variant="h9_4" color="#333333">
                       {stripHtml(item?.description || "-").slice(0, 20)}...
                       {/* {item?.description || "-"} */}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                        <Typography variant="h9_4" color="#333333">
                        S$  {item?.amount || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                        <IconButton
                          onClick={() =>
                            navigate(`/Admin/edit-service/${item._id}`)
                          }
                          sx={{ marginRight: "10px" }}
                        >
                          <EditIcon fill="black" />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setDeleteId(item._id);
                            setDeleteModal(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )))}
                </TableBody>
              </Table>
              <TablePagination
          component="div"
          count={totalItems} 
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
            </TableContainer>
        </TabPanel>
        
       
        <TabPanel value="2" sx={{pt : 1, px : 0}}>
            <TableContainer sx={{
                mt : 3.5,
                borderRadius: "5px",
                border: "1px solid #e5e5e5",
                boxShadow: "0px 0px 30px 20px #f4f4f4",
              }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                      <Typography variant="h9_1" color="#333333">
                        Title
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                      <Typography variant="h9_1" color="#333333">
                        Description
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Amount
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Actions
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {loading && (
              <TableRow>
                <TableCell colSpan={5} sx={{p :0, borderBottom : '0px'}}>
              <LinearProgress color="primary" />
              </TableCell>
              </TableRow>
            )}
            {otherService.length === 0 ? (<>
              <TableRow>
                <TableCell colSpan={5} sx={{p :0, borderBottom : '0px'}}>
              <LinearProgress color="primary" />
              </TableCell>
              </TableRow>
            </>) : (
                  otherService.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{border: "0px",
                      backgroundColor: index % 2 === 0 ? "#f8f8f8" : "white"}}
                    >
                      <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                        <Typography variant="h9_4" color="#333333">
                          {item?.title || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                        <Typography variant="h9_4" color="#333333">
                        {stripHtml(item?.description || "-").slice(0, 20)}...
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                        <Typography variant="h9_4" color="#333333">
                        S$  {item?.amount || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                        <IconButton
                          onClick={() =>
                            navigate(`/Admin/edit-other-service/${item._id}`)
                          }
                          sx={{ marginRight: "10px" }}
                        >
                          <EditIcon fill="black" />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setDeleteId(item._id);
                            setDeleteModal(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={totalItemsOthers} 
                page={pageOthers}
                onPageChange={handleChangePageOthers}
                rowsPerPage={rowsPerPageOthers}
                onRowsPerPageChange={handleChangeRowsPerPageOthers}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </TableContainer>
        </TabPanel>
        </TabContext>
    </Box> 
       
        {/* <Box sx={{ display: "flex", gap: "20px" }}>
          <Box
            onClick={() => setShowServices(true)}
            sx={{
              width: "100%",
              borderTop: showServices ? "3px solid #27bac4" : "3px solid gray",
              borderRadius: "0px",
              textAlign: "left",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
              padding: "3px",
              transition: "ease-in",
              transitionDuration: "250ms",
            }}
          >
            <Typography
              sx={{
                pt: 0,
                color: showServices ? "#27bac4" : "gray",
                fontWeight: 400,
                fontSize: "18px",
              }}
            >
              Landing Services
            </Typography>
          </Box>

          <Box
            onClick={() => setShowServices(false)}
            sx={{
              width: "100%",
              borderTop: showServices ? "3px solid gray" : "3px solid #27bac4",
              borderRadius: "0px",
              textAlign: "left",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
              padding: "3px",
              transition: "ease-in",
              transitionDuration: "250ms",
            }}
          >
            <Typography
              sx={{
                pt: 0,
                color: showServices ? "gray" : "#27bac4",
                fontWeight: 400,
                fontSize: "18px",
              }}
            >
              Other Chargeable Services
            </Typography>
          </Box>
        </Box>

        {showServices ? (
          <>
            
          </>
        ) : (
          <>
           
          </>
        )} */}
      </Box>
    </>
  );
}
