import React, { useState, useEffect } from 'react'
import { Typography, Stack, Box, Grid, Divider, Radio, Select, MenuItem, InputLabel, Button, Checkbox, TextField, IconButton } from '@mui/material'
import PaynowIcon from "../../../../Icons/PaynowIcon.svg"
import VisaIcon from "../../../../Icons/Visa.svg"
import axios from 'axios'
import { useSelector } from 'react-redux'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function Payment({ setStepperCount }) {
  const data = useSelector((state) => state.AdminData.admin)
  const [allData, setAllData] = useState({})

  const [RoomsData, setRoomsData] = useState({})
  const [mainsAmounts, setMainAmounts] = useState([])


  const fetchData = async () => {
      await axios.get(`${process.env.REACT_APP_API_URL}get-paiduser-by-id/${data.id}`)
      .then((response) => {
          console.log(response)
          if(response.status === 200){
              setAllData(response.data.data)
              setRoomsData(response.data.data.RoomsData)
          }
      })
      .catch((error) => console.log(error))
  }
  
  const fetchMainAmount = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}get-main-amounts`)
    .then((response) => {
      console.log(response)
      setMainAmounts(response?.data?.data)
    }).catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
      if(data.id){
          fetchData()
          fetchMainAmount()
      }
  }, [])

  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };


  const roomRentTotal = (RoomsData?.contractLength === 1) ? (RoomsData?.selectedRoom?.six_month_cont * 6) : (RoomsData?.selectedRoom?.twelve_month_cont * 12)
  
  const otherFeeTotal = mainsAmounts?.reduce((sum, item) => sum + item.amount, 0)

  const TOTAL_AMOUNT_TO_PAY = roomRentTotal + otherFeeTotal

  const formattedTotal = TOTAL_AMOUNT_TO_PAY.toLocaleString('en-US');


  const handleSubmit = async () => {
     await axios.get(`${process.env.REACT_APP_API_URL}get-tenancy-payment/${data.id}`)
     .then((response) => {
       console.log(response)
     }).catch((error) => {
       console.log(error)
     })
  }


  return (
    <>
      <Box px={{ md: 10, xs: 3 }}>
      <Typography sx={{pb : 4, ":hover" : {cursor : 'pointer'}}} onClick={() => setStepperCount(1)}><ArrowBackIcon sx={{fontSize : '30px'}}/></Typography>
        <Typography variant="h6_1">Payment</Typography>
        <Grid mt={3} mb={6} container rowGap={7}>
          <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
            <Divider />
            <Stack my={1} direction="row" justifyContent="space-between" alignItems="center">
              <Stack gap="8px" width='50%'>
                <Typography variant="h6_1">Room Rent</Typography>
                <Typography variant='h9_4' >{RoomsData?.selectedRoom?.HostelName}</Typography>
                <Typography variant='h9_4' >{RoomsData?.selectedRoom?.room_type_name}</Typography>
              </Stack>
              <Typography variant="h6_1">{`S$ ${RoomsData?.contractLength === 1 ? RoomsData?.selectedRoom?.six_month_cont : RoomsData?.selectedRoom?.twelve_month_cont} * ${RoomsData?.contractLength === 1 ? '6' : '12'}`}</Typography>
            </Stack>
            <Divider />
            {mainsAmounts.map((main, index) => (
              <>
              <Stack my={1} direction="row" justifyContent="space-between" alignItems="center">
              <Stack gap="8px" width='50%'>
                <Typography variant="h6_1">{main?.title}</Typography>
                <Typography variant='h9_4' >{stripHtml(main?.description || '-')}</Typography>
              </Stack>
              <Typography variant="h6_1">S$ {main?.amount}</Typography>
            </Stack>
            <Divider />
            </>
            ))}
            
            {/* <Stack my={1} direction="row" justifyContent="space-between" alignItems="center">
              <Stack gap="8px" width='50%'>
                <Typography variant="h6_1">Fixed fee</Typography>
                <Typography variant='h9_4' >A fixed additional fee for the room</Typography>
              </Stack>
              <Typography variant="h6_1">S$ 50</Typography>
            </Stack>
            <Divider /> */}
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack gap="8px" width='50%'>
                <Typography variant="h6_1">Total</Typography>
              </Stack>
              <Typography variant="h6_1">S$ {formattedTotal}</Typography>
            </Stack>
            <Stack mt={10} direction="column" justifyContent="space-between" alignItems="center" gap="10px">
              <Typography variant="h6_1">Pay Via</Typography>
              <Stack gap="8px" direction={{xs : 'column', lg : 'row'}} justifyContent="space-between" alignItems="center">
                <Stack alignItems="center" justifyContent="center" height="44px" width="200px" border=".5px solid #1A2461" sx={{ borderRadius: "8px" }}>
                  <Box margin="0 auto" component="img" src={VisaIcon}></Box>
                </Stack>
                <Stack alignItems="center" justifyContent="center" height="44px" width="200px" border=".5px solid #1A2461" sx={{ borderRadius: "8px" }}>
                  <Box margin="0 auto" component="img" src={PaynowIcon}></Box>
                </Stack>
                <Stack alignItems="center" justifyContent="center" height="44px" width="200px" border=".5px solid #1A2461" sx={{ borderRadius: "8px" }}>
                  <Box margin="0 auto" component="img" src={VisaIcon}></Box>
                </Stack>
                <Button onClick={() => {
                  setStepperCount(3)
                  handleSubmit()
                  }}>Paid</Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>

    </>
  )
}
