import React from "react";
import {
  Typography,
  Box,
  Divider,
  Button,
  Grid,
  TextField,
  InputLabel,
  Stack,
  Card,
  CardContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import axios from "axios";
// import {ReactComponent as AddImage} from '../../../Icons/Add.svg'
import { width } from "@mui/system";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { ReactComponent as FormIcon } from "../../../Icons/CommonFormIcon.svg";

export default function AnnouncementAdd() {
  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onTouched",
  });

  const onSubmit = async (data) => {
    console.log(data?.dateTime?.$d);

    const datas = {
      title: data?.title,
      subtitle: data?.subtitle,
      date: moment(data?.dateTime?.$d).format("DD-MM-YYYY,hh:mm A"),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}addAnnouncement`,
        datas
      );
      console.log(response);
      navigate("/Admin");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Card sx={{ width: '80%', border:'1px solid #E5E7EB', borderRadius:"6px"}}>
        <CardContent style={{ padding: 0, margin: 0 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              backgroundColor: "#FCFCFD",
              padding: 2,
              border:'1px solid #E5E7EB',
              borderRadius:'6px 6px 0px 0px'
              
            }}
          >
            <FormIcon style={{ marginRight: "10px" }} />
            <Typography variant="h6_1">Announcement Details</Typography>
          </Box>

          {/* <Divider /> */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box style={{ padding: "25px 17px 17px 17px" }}>
              <InputLabel
                sx={{
                  mb: 1,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
                {errors?.title ? 'Title*' : 'Title'}
              </InputLabel>
              <Controller
                name="title"
                rules={{ required: true }}
                control={control}
                label
                render={({ field }) => (
                  <TextField
                    placeholder="Enter your title"
                    sx={{ 
                      mt: 0, 
                      backgroundColor : 'rgb(249, 250, 251)',
                      borderRadius: 0, 
                      "& .MuiInputBase-input": {
                        color: errors?.title ? "red" : "black",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: errors?.title ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: errors?.title ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: errors?.title ? "#ff5e74" : "rgba(0, 0, 0, 0.23)",
                        },
                      },
                    }}
                    error={Boolean(errors?.title) ? true : false}
                    {...field}
                  />
                )}
              />
              {errors?.title ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>Please enter title</Typography> : null }
            </Box>

            <Box style={{ padding: "0px 17px 17px" }}>
              <InputLabel
                sx={{
                  mb: 1,
                  mt: 3,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
               {errors?.subtitle ? 'Subtitle*' : 'Subtitle'}
              </InputLabel>
              <Controller
                name="subtitle"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder="Enter your subtitle"
                    sx={{ 
                      mt: 0, 
                      backgroundColor : 'rgb(249, 250, 251)',
                      borderRadius: 0, 
                      "& .MuiInputBase-input": {
                        color: errors?.subtitle ? "red" : "black",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: errors?.subtitle ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: errors?.subtitle ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: errors?.subtitle ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                      },
                    }}
                    error={Boolean(errors?.subtitle) ? true : false}
                    {...field}
                  />
                )}
              />
              {errors?.subtitle ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>Please enter subtitle</Typography> : null }
            </Box>

            <Box style={{ padding: "0px 17px 17px" }}>
              <InputLabel
                sx={{
                  mb: 1,
                  mt: 3,
                  fontSize: "18px",
                  fontWeight: 600,
                  color: 'black',
                }}
              >
                {errors?.dateTime ? 'Date & Time*' : 'Date & Time'}
              </InputLabel>
              <Controller
                name="dateTime"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <DateTimePicker
                    {...field}
                    // label="Select Date and Time"

                    renderInput={(props) => (
                      <TextField
                        {...props}
                        error={Boolean(errors?.dateTime) ? true : false}
                        fullWidth
                        inputMode="none"
                      />
                    )}
                    sx={{
                      backgroundColor : 'rgb(249, 250, 251)',
                      width: "100%",
                      ".MuiSvgIcon-root": { fill: "#1A2461" },
                      "& .MuiInputBase-input": {
                        color: errors?.dateTime ? "red" : "black",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: errors?.dateTime ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: errors?.dateTime ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: errors?.dateTime ? "red" : "rgba(0, 0, 0, 0.23)",
                        },
                      },
                    }}
                  />
                )}
              />
              {errors?.dateTime ? <Typography style={{color : '#ff5e74', margin : '0.3rem'}}>Please select Date and Time</Typography> : null }
            </Box>
            <Divider style={{marginTop : '15px'}}/>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "0px 17px 13px",
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{
                  padding: "10px 20px 10px 20px",
                  mt: 2,
                  width: "20%",
                  fontSize: 20,
                  backgroundColor: "rgb(14, 94, 132)",
                  ":hover": { bgcolor: "rgb(14, 94, 132)" },
                }}
              >
                Confirm
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
}
