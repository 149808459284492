import React, { useRef } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {
  Button,
  CardActionArea,
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Stack,
  useMediaQuery,
  IconButton
} from "@mui/material";
import { ReactComponent as ShareNewsIcon } from "../Icons/shareNewsIcon.svg";
import { ReactComponent as FavouritesNewsIcon } from "../Icons/favouritesNewsIcon.svg";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ReactComponent as LeftArrowIcon } from "../Icons/leftArrow.svg";
import { ReactComponent as RightArrowIcon } from "../Icons/rightArrow.svg";

const NewsCarousel = ({ NewsData }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(min-width:600px) and (max-width:960px)');
  const carouselRef = useRef(null);

  const truncateDescription = (description) => {
    if (typeof description !== "string") return "";
    const words = description.split(" ");
    
    
    if (words.length > 15 && words.length < Infinity ) {
    
      return words.slice(0, 9).join(" ") + "...";
    }else if(words.length < 15){
      return words.slice(0, 5).join(' ') + "...";
    }
    return description;
  };

  const styles = {
    controlDots: {
      margin: "138px 0px !important",
    },
    selectedDot: {
      width: "36px",
      height: "8px",
      borderRadius: "24px",
      background: "#434343",
      margin: "0 4px",
    },
    UnselectedDot: {
      width: "8px",
      height: "8px",
      borderRadius: "24px",
      background: "#FFFFFF",
      border: "1px solid #434343",
      margin: "0 4px",
    },
  };

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.decrement();
    }
  };

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.increment();
    }
  };

  return (
      <>
    <Stack px={5} mb={5} direction="row" justifyContent="space-between">
          <Box></Box>
          <Typography
            p={2}
            variant="h6_1"
            color="primary"
            border="1px solid #27BAC4"
            padding="10px 50px"
            borderRadius="50px"
            letterSpacing="1.1px"
          >
            NEWS
          </Typography>
          <Stack
            columnGap="20px"
            direction="row"
            justifyContent="space-between"
          >
            <Box
              sx={{
                width: "40px",
                height: "40px",
                borderRadius: "100px",
                backgroundColor: "#2DB7C2",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handlePrev}
            >
              <LeftArrowIcon />
            </Box>
            <Box
              sx={{
                width: "40px",
                height: "40px",
                borderRadius: "100px",
                backgroundColor: "#2DB7C2",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleNext}
            >
              <RightArrowIcon />
            </Box>
          </Stack>
        </Stack>
       
     
    
    <Box mt={5} className="Newscaurosel" sx={{ pt: 7,pb: 3,background: "#EFF4FC", display: 'flex', justifyContent: 'center', overflow : 'hidden', pr : {xs : 3, lg : 0}}}>
      <Carousel
        id="news"
        ref={carouselRef}
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        useKeyboardArrows
        centerMode={!isMobile}
        centerSlidePercentage={isMobile ? 100 : isTablet ? 100 : 33.33}
        emulateTouch
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          const indicatorStyle = isSelected
            ? styles.selectedDot
            : styles.UnselectedDot;
          return (
            <li
              style={{
                ...indicatorStyle,
                display: "inline-block",
                cursor: "pointer",
                marginTop : 20,
                marginBottom : -16,
              }}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
      >
        {NewsData?.map((itm, index) => (
          <Card
            key={index}
            sx={{
              
              maxWidth: isMobile ? 350 : isTablet ? 680 :390,
              maxHeight : '100%',
              p: 1.5,
              boxShadow : '0px 4px 20px 0px rgba(0, 0, 0, 0.15)',
              //border: ".5px solid #737373",
              borderRadius: "20px",
              textAlign: "start",
              position: "relative",
              margin: isMobile ? 'auto auto' : isTablet ? '0px 30px' : '0 10px',
              height : {xs : '380px', sm : '510px',lg : '410px'}
            }}
          >
            <Stack
              direction="row"
              columnGap="10px"
              sx={{
                position: "absolute",
                zIndex: "1",
                right: "25px",
                top: "25px",
              }}
            >
              <ShareNewsIcon />
              <FavouritesNewsIcon />
            </Stack>
            <CardActionArea>
              <CardMedia
                sx={{ borderRadius: "20px", height: {xs : '193px', sm : '320px', lg : '193px'}, width :'402px' }}
                component="img"
                image={process.env.REACT_APP_FILE_PATH + itm?.img}
                alt="news image"
              />
              <CardContent>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{height : {xs : '45px', lg : '65px'}}}
                >
                  <Typography
                    gutterBottom
                    variant="h7_1"
                    component="div"
                    color="#007ECA"
                  >
                    {itm?.title}
                  </Typography>
                  <Typography
                    variant="h9_4"
                    color="#007ECA"
                    sx={{
                      width: "42px",
                      height: "24px",
                      border: ".5px solid #007ECA",
                      padding: "0 10px 26px 10px",
                      borderRadius: "80px",
                    }}
                  >
                    tag
                  </Typography>
                </Stack>
                <Typography variant="h9_4">
                  {Boolean(itm?.description)
                    ? parse(truncateDescription(itm?.description))
                    : ""}
                </Typography>
              </CardContent>
            </CardActionArea>
            <Stack direction="row" justifyContent="end" alignItems='end'>
              <Button
                onClick={() => navigate(`/news/${itm?._id}`)}
                variant="contained"
                sx={{
                  width: "80px",
                  height: "40px",
                  padding: "10px 30px",
                  backgroundColor: "#007eca",
                  borderRadius: "80px",
                  color: "white",
                  textTransform: "none",
                  letterSpacing: "1px",
                  fontWeight: 700,
                  fontSize: "16",
                }}
              >
                More
              </Button>
            </Stack>
          </Card>
        ))}
      </Carousel>
    </Box>
    </>
  );
};

export default NewsCarousel;
