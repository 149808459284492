import React from "react";
import { Typography, Box } from "@mui/material";
import { ReactComponent as LogoIcon } from "../Icons/logo.svg";
import Menu from "./Menu";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        height: "120px",
        backgroundColor: "black",
        backgroundColor: "primary.main",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        px: { md: "60px", xs: "20px" },
      }}
    >
      <Box>
        <LogoIcon style={{ cursor: "pointer" }} onClick={() => navigate("/")} />
      </Box>
      <Menu />
    </Box>
  );
}
