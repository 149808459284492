// import React, { useState } from 'react'
// import { Box, IconButton, Typography, Grid, Stack } from "@mui/material"
// import { ReactComponent as DetailsIcon } from "../../../Icons/Account/Details.svg"
// import { ReactComponent as ApplicationIcon } from "../../../Icons/Account/Application.svg"
// import { ReactComponent as ChecklistIcon } from "../../../Icons/Account/Checklist.svg"
// import { ReactComponent as LikedIcon } from "../../../Icons/Account/Liked.svg"
// import { ReactComponent as PreferenceIcon } from "../../../Icons/Account/Preference.svg"
// import Details from './Details'
// import Application from './Application'
// import Checklist from './CheckList'
// import Liked from './Liked'
// import Preference from './Preference'


// export default function Account() {

//     const [renderBlock, setRenderBlock] = useState(1)

//     return (
//         <Box>
//             <Grid container px={{ md: 7, xs: 4 }} py={3}>
//                 <Grid xs={12} pb={3} borderBottom='.5px solid #737373'>
//                     <Typography variant="h6_1">Account</Typography>
//                 </Grid>
//                 <Grid lg={2.1} md={3} sm={4} xs={12} height="100vh" borderRight='.5px solid #737373'>
//                     <Stack direction="column" gap={3} py={4} px={2}>
//                         <Stack onClick={() => { setRenderBlock(1) }} direction="row" alignItems="center" gap="10px" sx={{ height: "36px", width: "170px", backgroundColor: renderBlock == 1 ? "#F5F8FA" : "", borderRadius: "8px", color: renderBlock == 1 ? "#1A2461" : "#737373" }}>
//                             <IconButton><DetailsIcon fill={renderBlock == 1 ? "#1A2461" : "#737373"} /></IconButton>
//                             <Typography variant="h9_1">My details</Typography>
//                         </Stack>
//                         <Stack onClick={() => { setRenderBlock(2) }} direction="row" alignItems="center" gap="10px" sx={{ height: "36px", width: "170px", backgroundColor: renderBlock == 2 ? "#F5F8FA" : "", borderRadius: "8px", color: renderBlock == 2 ? "#1A2461" : "#737373" }}>
//                             <IconButton><ApplicationIcon fill={renderBlock == 2 ? "#1A2461" : "#737373"} /></IconButton>
//                             <Typography variant="h9_1">Application</Typography>
//                         </Stack>
//                         <Stack onClick={() => { setRenderBlock(3) }} direction="row" alignItems="center" gap="10px" sx={{ height: "36px", width: "170px", backgroundColor: renderBlock == 3 ? "#F5F8FA" : "", borderRadius: "8px", color: renderBlock == 3 ? "#1A2461" : "#737373" }}>
//                             <IconButton><ChecklistIcon fill={renderBlock == 3 ? "#1A2461" : "#737373"} /></IconButton>
//                             <Typography variant="h9_1">Check list</Typography>
//                         </Stack>
//                         <Stack onClick={() => { setRenderBlock(4) }} direction="row" alignItems="center" gap="10px" sx={{ height: "36px", width: "170px", backgroundColor: renderBlock == 4 ? "#F5F8FA" : "", borderRadius: "8px", color: renderBlock == 4 ? "#1A2461" : "#737373" }}>
//                             <IconButton><LikedIcon fill={renderBlock == 4 ? "#1A2461" : "#737373"} /></IconButton>
//                             <Typography variant="h9_1">Liked</Typography>
//                         </Stack>
//                         <Stack onClick={() => { setRenderBlock(5) }} direction="row" alignItems="center" gap="10px" sx={{ height: "36px", width: "170px", backgroundColor: renderBlock == 5 ? "#F5F8FA" : "", borderRadius: "8px", color: renderBlock == 5 ? "#1A2461" : "#737373" }}>
//                             <IconButton><PreferenceIcon fill={renderBlock == 5 ? "#1A2461" : "#737373"} /></IconButton>
//                             <Typography variant="h9_1">Preference</Typography>
//                         </Stack>
//                     </Stack>
//                 </Grid>
//                 <Grid py={4} pl={3} lg={9.9} md={9} sm={8} xs={12}>
//                     {renderBlock === 1 && <Details />}
//                     {renderBlock === 2 && <Application />}
//                     {renderBlock === 3 && <Checklist />}
//                     {renderBlock === 4 && <Liked />}
//                     {renderBlock === 5 && <Preference />}
//                 </Grid>
//             </Grid>
//         </Box >
//     )
// }

import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography, Grid, Stack, Drawer, useMediaQuery, Button, Divider } from "@mui/material";
import { ReactComponent as DetailsIcon } from "../../../Icons/Account/Details.svg";
import { ReactComponent as ApplicationIcon } from "../../../Icons/Account/Application.svg";
import { ReactComponent as ChecklistIcon } from "../../../Icons/Account/Checklist.svg";
import { ReactComponent as LikedIcon } from "../../../Icons/Account/Liked.svg";
import { ReactComponent as PreferenceIcon } from "../../../Icons/Account/Preference.svg";
import Details from './Details';
import Application from './Application';
import Checklist from './CheckList';
import Liked from './Liked';
import Preference from './Preference';
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as LogoIcon } from "../../../Icons/logo.svg";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

export default function Account() {
    const navigate = useNavigate()
    const [renderBlock, setRenderBlock] = useState(() => {
        const savedRenderBlock = localStorage.getItem('theBlock');
        return savedRenderBlock !== null ? parseInt(savedRenderBlock, 10) : 1;
      });
    const [drawerOpen, setDrawerOpen] = useState(false);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

    useEffect(() => {
        localStorage.setItem('theBlock', renderBlock)
    },[renderBlock])

    const [menuData, setMenuData] = useState([
        {
            id : 1,
            name : 'My details',
            icon : <DetailsIcon fill={renderBlock === 1 ? "#1A2461" : "#737373"}/>
        },
        {
            id : 2,
            name : 'Application',
            icon : <ApplicationIcon fill={renderBlock === 2 ? "#1A2461" : "#737373"} />
        },
        {
            id : 3,
            name : 'Check list',
            icon : <ChecklistIcon fill={renderBlock === 3 ? "#1A2461" : "#737373"} />
        },
        {
            id : 4,
            name : 'Liked',
            icon : <LikedIcon fill={renderBlock === 4 ? "#1A2461" : "#737373"} />
        },
        {
            id : 5,
            name : 'Preference',
            icon : <PreferenceIcon fill={renderBlock === 5 ? "#1A2461" : "#737373"} />
        }
    ])

    useEffect(() => {
        setMenuData(menuData.map(item => ({
          ...item,
          icon: React.cloneElement(item.icon, { fill: renderBlock === item.id ? "#1A2461" : "#737373" })
        })));
      }, [renderBlock]);

    const menuItems = (
        <Stack direction={{ md: "column", xs: "row" }} gap={3} py={4} px={2} flexWrap="wrap">

            {menuData.map((item) => (
                <Stack key={item.id} onClick={() => { setRenderBlock(item.id); setDrawerOpen(false); }} direction="row" alignItems="center" gap="10px" sx={{ height: "36px", width: { md: "170px", xs: "100%" }, cursor : 'pointer',backgroundColor: renderBlock === item.id ? "#F5F8FA" : "", borderRadius: "8px", color: renderBlock === item.id ? "#1A2461" : "#737373" }}>
                <IconButton>{item.icon}</IconButton>
                <Typography variant="h9_1">{item.name}</Typography>
            </Stack>
            ))}
            

            {/* <Stack onClick={() => { setRenderBlock(1); setDrawerOpen(false); }} direction="row" alignItems="center" gap="10px" sx={{ height: "36px", width: { md: "170px", xs: "100%" }, backgroundColor: renderBlock === 1 ? "#F5F8FA" : "", borderRadius: "8px", color: renderBlock === 1 ? "#1A2461" : "#737373" }}>
                <IconButton><DetailsIcon fill={renderBlock === 1 ? "#1A2461" : "#737373"} /></IconButton>
                <Typography variant="h9_1">My details</Typography>
            </Stack>
            
            <Stack onClick={() => { setRenderBlock(3); setDrawerOpen(false); }} direction="row" alignItems="center" gap="10px" sx={{ height: "36px", width: { md: "170px", xs: "100%" }, backgroundColor: renderBlock === 3 ? "#F5F8FA" : "", borderRadius: "8px", color: renderBlock === 3 ? "#1A2461" : "#737373" }}>
                <IconButton><ChecklistIcon fill={renderBlock === 3 ? "#1A2461" : "#737373"} /></IconButton>
                <Typography variant="h9_1">Check list</Typography>
            </Stack>
            <Stack onClick={() => { setRenderBlock(4); setDrawerOpen(false); }} direction="row" alignItems="center" gap="10px" sx={{ height: "36px", width: { md: "170px", xs: "100%" }, backgroundColor: renderBlock === 4 ? "#F5F8FA" : "", borderRadius: "8px", color: renderBlock === 4 ? "#1A2461" : "#737373" }}>
                <IconButton><LikedIcon fill={renderBlock === 4 ? "#1A2461" : "#737373"} /></IconButton>
                <Typography variant="h9_1">Liked</Typography>
            </Stack>
            <Stack onClick={() => { setRenderBlock(5); setDrawerOpen(false); }} direction="row" alignItems="center" gap="10px" sx={{ height: "36px", width: { md: "170px", xs: "100%" }, backgroundColor: renderBlock === 5 ? "#F5F8FA" : "", borderRadius: "8px", color: renderBlock === 5 ? "#1A2461" : "#737373" }}>
                <IconButton><PreferenceIcon fill={renderBlock === 5 ? "#1A2461" : "#737373"} /></IconButton>
                <Typography variant="h9_1">Preference</Typography>
            </Stack> */}
        </Stack>
    );

    return (
        <Box>
            <Grid container px={{ md: 7, xs: 2 }} py={3}>
                <Grid xs={12} pb={3} borderBottom='.5px solid #737373'>
                    <Typography variant="h6_1">Account</Typography>
                </Grid>
                {isMobile ? (
                    <>
                        <Box sx={{display : 'flex', gap: '0px', alignItems : 'center', paddingLeft : 0, borderBottom:'.5px solid #737373', width : '100%',pb:1,pt:1}}>
                        <Button onClick={() => setDrawerOpen(true)}><MenuIcon style={{color : '#27bac4', fontSize : '2rem'}}/></Button>
                        <Typography sx={{fontSize : '1.25rem'}}>Menu</Typography>
                        </Box>
                        
                        <Drawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                        <Box sx={{backgroundColor : '#27bac4', p:2, display:'flex', alignItems : 'center', justifyContent : 'space-between'}}>
        <LogoIcon style={{ cursor: "pointer" }} onClick={() => navigate("/")} />
        <Button onClick={() => setDrawerOpen(false)}><CloseIcon sx={{fontSize : '2rem', color : 'white'}}/></Button>
      </Box>
                            {menuItems}
                        </Drawer>
                    </>
                ) : (
                    <Grid item lg={2.1} md={3} sm={4} xs={12} height={{ md: "100vh", xs: "auto" }} borderRight={{ md: '.5px solid #737373', xs: 'none' }}>
                        {menuItems}
                    </Grid>
                )}
                <Grid py={4} pl={{ md: 3, xs: 0 }} item lg={9.9} md={9} sm={12} xs={12}>
                    {renderBlock === 1 && <Details />}
                    {renderBlock === 2 && <Application />}
                    {renderBlock === 3 && <Checklist />}
                    {renderBlock === 4 && <Liked />}
                    {renderBlock === 5 && <Preference />}
                </Grid>
            </Grid>
        </Box>
    );
}

