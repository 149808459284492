import React from "react";
import AnnouncementImg from "../../Icons/AnnouncementBg.svg";
import { ReactComponent as BellIcon } from "../../Icons/BellIcon.svg";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { AnnounceMentList } from "../../Data";

export default function Announcement({ announcementData }) {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Box mt={22}>
      <Stack alignItems="center" sx={{ mb: 10 }}>
        <Typography
          p={2}
          width="fit-content"
          variant="h6_1"
          color="primary"
          border="1px solid #27BAC4"
          padding="10px 50px"
          borderRadius="50px"
          letterSpacing="1.1px"
        >
          ANNOUNCEMENTS
        </Typography>
      </Stack>
      <Grid
        mt={6}
        py={5}
        gap={10}
        display="flex"
        justifyContent="center"
        container
        sx={{ backgroundColor: "#EFF4FC" }}
      >
        <Grid xs={12} md={5}>
          <Box sx={{width : {xs : '100%'}}} component="img" src={AnnouncementImg}></Box>
          {/* <img src={AnnouncementImg} /> */}
        </Grid>
        <Grid
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={5}
        >
          {/* <Stack> */}
          <Stack
            sx={{
              backgroundColor: "white",
              border: "0.5px solid #27BAC4",
              borderRadius: "20px",
              height: "60vh",
              width: {xs : '90%', lg : '600px'},
              background: "linear-gradient(to top, white, transparent)",
              zIndex: 5,
            }}
          >
            <Typography
              p={2}
              variant="h6_2"
              color="primary"
              textAlign="start"
              borderBottom="0.5px solid #27BAC4"
            >
              Announcements
            </Typography>
            <Stack
              rowGap="20px"
              p={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3 }}
              sx={{
                overflowY: "scroll",
                overflowX: "scroll",
                "&::-webkit-scrollbar": { display: "none" },
              }}
            >
              {announcementData?.map((itm, index) => (
                <>
                  <Stack
                    direction={{
                      xs: "column",
                      sm: "column",
                      md: "column",
                      lg: "row",
                      xl: "row",
                    }}
                    justifyContent="space-between"
                    height={{
                      xs: "70px",
                      sm: "80%",
                      md: "70px",
                      lg: "60px",
                      xl: "53px",
                    }}
                    sx={{p : {xs : 0, sm : 2, lg : 0}}}
                  >
                    <Stack direction="row" columnGap={{xs : '10px', sm : '40px', lg : '10px'}} alignItems="center">
                      <Box
                        sx={{
                          width: {xs : '45px', sm : '70px', lg : '45px'},
                          height: {xs : '45px', sm : '70px', lg : '45px'},
                          borderRadius: "100px",
                          backgroundColor: "button",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <BellIcon width='100%' height='45%'/>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        textAlign="start"
                      >
                        <Typography variant="h6_1" color="text_blue">
                          {itm?.title}
                        </Typography>
                        <Typography variant="h9_2" color="text_blue">
                          {itm?.subtitle}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      alignItems={{ xs: "start", lg: "end" }}
                      sx={{ px: { xs: "54px", sm : '110px',lg: "0px" } }}
                    >
                      <Typography variant="h9_2" color="#606060">
                        {itm?.time}
                      </Typography>
                      <Typography variant="h9_2" color="#606060">
                        {itm?.date}
                      </Typography>
                    </Stack>
                  </Stack>
                  {/* </>)} */}
                </>
              ))}
            </Stack>
          </Stack>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              rowGap: {xs : '15px', sm : '25px', lg : '15px'},
              columnGap: {
                xs: "5px",
                sm: "5px",
                md: "5px",
                lg: "10px",
                xl: "15px",
              },
              px: { xs: 0.95, sm: 5.5, md: 2.5, lg: 2.5 },
              mt: {xs : 3, sm : 8, lg : 3},
              pb: 5,
            }}
          >
            
            {AnnounceMentList.map((item, index) => (
              <Box
                key={item.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "0.5px solid #27BAC4",
                  backgroundColor: "#DDF1F0",
                  p: { xs: 1, sm: 1, md: 2, lg: 2 },
                  borderRadius: "10px",
                  width: { xs: "180px", sm: "200px", md: "220px", lg: "250px" },
                }}
              >
                {/* <Box component="img" sx={{backgroundColor : '#27BAC4', p:1, borderRadius : '10px'}} src={item?.icon}></Box> */}
                {typeof item.icon === "string" ? (
                  <Box
                    component="img"
                    sx={{
                      backgroundColor: "#27BAC4",
                      p: 1,
                      borderRadius: "10px",
                    }}
                    src={item.icon}
                  />
                ) : (
                  <item.icon
                    sx={{
                      backgroundColor: "#27BAC4",
                      fontSize: "3rem",
                      p: 1,
                      borderRadius: "10px",
                      color: "white",
                    }}
                  />
                )}
                <Typography sx={{ color: "#1A2461" }}>
                  <span dangerouslySetInnerHTML={{ __html: item?.name }} />
                </Typography>
                <Box component="img" src={item?.arrow}></Box>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
