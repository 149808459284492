import React, { useState } from 'react'
import { Typography, Box, Divider, Button, Stack, ListItem, List, ListItemText, CardActionArea, CardActions, Grid, Card, CardContent, CardMedia } from "@mui/material"
import { ReactComponent as MealIcon } from "../../Icons/Hostel/MealIcon.svg"
import Meals from "../../Icons/Hostel/Meals.svg"
import { ReactComponent as LifeStyleIcon } from "../../Icons/Hostel/LifeStyleIcon.svg"
import LifeStyle from "../../Icons/Hostel/lifeStyle.svg"
import { ReactComponent as PublicFacilityIcon } from "../../Icons/Hostel/PublicFacilityIcon.svg"
import PublicFacility from "../../Icons/Hostel/PublicFacility.svg"
import { ReactComponent as FacilitiesIcon } from "../../Icons/Hostel/FacilitiesIcon.svg"
import Facilities from "../../Icons/Hostel/Facilities.svg"
import { ReactComponent as ChargeableServiceIcon } from "../../Icons/Hostel/ChargeableServiceIcon.svg"
import ChargeableService from "../../Icons/Hostel/ChargeableService.svg"
import { ReactComponent as CounsellingIcon } from "../../Icons/Hostel/CounsellingIcon.svg"
import Counselling from "../../Icons/Hostel/Counselling.svg"
import Views1 from "../../Icons/Hostel/Views1.svg"
import Views2 from "../../Icons/Hostel/Views2.svg"
import { ReactComponent as DownArrowIcon } from "../../Icons/DownArrow.svg"


export default function Rules() {
    const [rules, setRules] = useState([
        {
            id : 1,
            description : 'All VingaHall students must comply with Singaporean laws and regulations.' 
        },
        {
            id : 2,
            description : 'Entry of non-residents into the VingaHall is prohibited.' 
        },
        {
            id : 3,
            description : `Unauthorized opening or reading of others' mail, unauthorized use or handling of others' belongings, borrowing of money or items without permission, must be returned promptly.` 
        },
        {
            id : 4,
            description : 'According to Singaporean law, ﬁghting and theft are considered illegal activities.' 
        },
        {
            id : 5,
            description : 'Smoking and alcohol consumption are strictly prohibited within the VingaHall. Offenders will be ﬁned S$300 per instance, and repeated offenders will be reported to the authorities.' 
        },
        {
            id : 6,
            description : 'Unauthorized installation of electrical wiring, cooking appliances, etc., within the VingaHall is strictly prohibited according to Singaporean law. Offenders will be ﬁned S$200 per instance. In the event of any short circuit or accidents, students will be liable for compensation and all consequences.' 
        },
        {
            id : 7,
            description : 'Eating is prohibited in the rooms. Please use the dining area (BK) or the ground ﬂoor study hall (RG) for meals. Rooms must be kept clean and tidy. Offenders will be ﬁned S$50 per instance.' 
        },
        {
            id : 8,
            description : 'Conserve water and electricity. Ensure air-conditioning and lights are turned off when leaving the VingaHall, and taps are closed after use. Offenders will be ﬁned S$100 per instance.' 
        },
        {
            id : 9,
            description : 'All provided furniture and items must be used properly and without damage. Any damage resulting from improper use must be compensated at the original price.' 
        },
        {
            id : 10,
            description : 'Posting items on the walls of rooms and bathrooms is not allowed. Any damage to the walls will incur repainting costs, to be borne by the student.' 
        },
        {
            id : 11,
            description : 'Avoid chasing, wrestling, or making loud noises in the VingaHall to avoid disturbing others.' 
        },
        {
            id : 12,
            description : 'In case of illness or other emergencies requiring stay within the VingaHall, please contact the VingaHall administrator and provide details.' 
        },
    ])

    return (
        <>
            <Box my={6} display="flex" justifyContent='center'>
                <Typography variant="h6_1" color="primary" border="1px solid #27BAC4" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px" width="fit-content">VINGA HALL RULES</Typography>
            </Box>
            <Box sx={{ backgroundColor: "#C8E2F3", p: { md: 7, xs: 3 }, pt: 11 }}>
                {/* <Typography variant="h6_1" color="#FFFFFF" sx={{ backgroundColor: "#27BAC4", p: 1, px: 4, borderRadius: "40px 40px 0 0", whiteSpace: "nowrap" }}>Management terms ( BK & RG )</Typography> */}
                
                <Box sx={{px : 8, background : 'white', pt : 3, pb : 3}}>
                            {rules.map((rule, i) => (
                                <Typography key={rule.id} variant="h7_2" sx={{ display: "list-item", listStyleType: "decimal",  marginBottom: 2 }}>{rule.description}</Typography>
                            ))}                            
                        </Box>
                
                {/* <Grid container justifyContent="center" mt={.5} p={4} rowGap={2} columnGap={3} marginBottom='0px' sx={{ backgroundColor: "#FFFFFF" }}>
                    <Grid md={9}>
                        
                    </Grid>
                    <Grid md={2} >
                        <Stack height="100%" alignItems="end" justifyContent="center">
                            <Box variant='contained' sx={{ height: '40px', backgroundColor: "#FFFFFF", border: ".5px solid #9E9E9E", borderRadius: "8px", color: "#9E9E9E", display: "flex", flexDirection: "row", alignItems: "center", p: "10px", columnGap: "10px" }}>Show all  <DownArrowIcon fill="#9E9E9E" /></Box>
                        </Stack>
                    </Grid>
                </Grid> */}
            </Box>
        </>
    )
}
