import React, { useEffect, useState } from "react";
import CustomCarousel from "../../Components/Carousel";
import Header from "../../Components/Header";
import { Box, Stack, Button, Typography, useMediaQuery } from "@mui/material";
import { Steps } from "../../Data/index";
import Announcement from "../../Components/Main/Announcement";
import Hostels from "../../Components/Main/Hostels";
import News from "../../Components/Main/News";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { DocumentTitleChange } from "../../CommonComponents/Title";

export default function Main() {
  const isMobile = useMediaQuery('(max-width:600px)');
  const { HostelData, AnnouncementData } = useSelector(
    (state) => state.CommonData
  );
  const [hostelData, setHostelData] = useState(HostelData);
  const [announcementData, setAnnouncementData] = useState(AnnouncementData);

  // const getAllHostel = async () => {

  //     try {
  //         const response = await axios.get(`${process.env.REACT_APP_API_URL}getAllhostel`);
  //         console.log(response);
  //         setHostelData(response?.data?.data)
  //     } catch (error) {
  //         console.log(error);
  //     }
  // }

  // const getAllAnnouncement = async () => {

  //     try {
  //         const response = await axios.get(`${process.env.REACT_APP_API_URL}getAllAnnouncement`)
  //         console.log(response?.data?.data);
  //         setAnnouncementData(response?.data?.data)
  //     } catch (error) {
  //         console.log(error);
  //     }
  // }

  // useEffect(() => {
  //     DocumentTitleChange('Home')
  // },[])

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <CustomCarousel btndisplay={true} />
        <Stack
          rowGap="50px"
          sx={{
            position: "absolute",
            top: {xs : 0, sm : 180, md : 0},
            transform: "translateY(50%)",
            left: {xs : 30, sm : 60},
          }}
          direction="column"
          justifyContent="center"
          textAlign="left"
          color="white"
        >
          <Stack rowGap="10px">
            <Typography variant="h3">Vinga Hall</Typography>
            <Typography variant="h1">Learning & Living</Typography>
            <Typography variant="h4_2">
              Your second home during your Singapore life
            </Typography>
          </Stack>
          <Button
            variant="contained"
            sx={{
              width: "230px",
              height: "80px",
              padding: "10px 30px",
              backgroundColor: "#007eca",
              borderRadius: "80px",
              color: "white",
              textTransform: "none",
              letterSpacing: "1%",
              fontSize: "24px",
            }}
          >
            Apply Now
          </Button>
        </Stack>
        {/* <Box display="flex" justifyContent="center">
          <Stack
            direction="row"
            columnGap={4}
            sx={{
              boxShadow: "0px 11px 30px 0px rgba(0,0,0,0.1)",
              height: "200px",
              position: "absolute",
              bottom: "-100px",
              backgroundColor: "white",
              padding: "40px",
              borderRadius: "24px",
            }}
          >
            {Steps?.map((item, index) => (
              <>
                <Stack
                  gap="10px"
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  alignContent='center'
                  maxWidth="172px"
                  textAlign="center"
                  
                >
                  <Box style={{marginTop : ((item?.name == 'Step 3') ? '31px' : (item?.name == 'Step 4') ? '39px' : '20px') }}>
                  <Typography variant="h9_3" color="button" >
                    {item?.name}
                  </Typography>
                  </Box>
                  <Box display="flex" justifyContent="center" alignContent='center'>
                    <Box component="img" src={item?.icon}></Box>
                  </Box>
                  <Box>
                  <Typography sx={{fontSize : '18px'}}> <span dangerouslySetInnerHTML={{ __html: item.Description }} /></Typography>
                  </Box>
                </Stack>
              </>
            ))}
          </Stack>
        </Box> */}

{/* {!isMobile && ( */}
        <Box display="flex" justifyContent="center">
          <Stack
            direction={{xs : 'column', sm : 'column', md : 'column' , lg : 'row'}}
            columnGap={4}
            sx={{
              boxShadow: {xs : 'none', lg : "0px 11px 30px 0px rgba(0,0,0,0.1)"},
              height: "200px",
              position: "absolute",
              bottom: {xs : '-100px', lg : '-100px'},
              backgroundColor: "white",
              padding: "40px",
              borderRadius: "24px",
            }}
          >
            {Steps?.map((item, index) => (
              <>
                <Stack
                  gap="10px"
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  alignContent='center'
                  maxWidth="172px"
                  textAlign="center"
                  // sx={{
                  //   boxShadow: "0px 11px 30px 0px rgba(0,0,0,0.1)",
                  // }}
                >
                  <Box style={{marginTop : ((item?.name == 'Step 3') ? '31px' : (item?.name == 'Step 4') ? '39px' : '20px') }}>
                  <Typography variant="h9_3" color="button" >
                    {item?.name}
                  </Typography>
                  </Box>
                  <Box display="flex" justifyContent="center" alignContent='center'>
                    <Box component="img" src={item?.icon}></Box>
                  </Box>
                  <Box>
                  <Typography sx={{fontSize : '18px'}}> <span dangerouslySetInnerHTML={{ __html: item.Description }} /></Typography>
                  </Box>
                </Stack>
              </>
            ))}
          </Stack>
        </Box>
       {/* )} */}

      {/* New Box for Mobile Devices */}
      {/* {isMobile && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{
            padding: "20px",
            backgroundColor: "white",
            boxShadow: "0px 11px 30px 0px rgba(0,0,0,0.1)",
            borderRadius: "24px",
          }}
        >
          {Steps?.map((item, index) => (
            <Box
              key={index}
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={{
                marginBottom: "20px",
                width: "100%",
                maxWidth: "300px", // Adjust as needed
              }}
            >
              <Box>
                <Typography variant="h9_3" color="button" sx={{ fontSize: '16px' }}>
                  {item?.name}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Box
                  component="img"
                  src={item?.icon}
                  sx={{
                    width: "50px",
                    height: "50px",
                  }}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: '14px' }}>
                  <span dangerouslySetInnerHTML={{ __html: item.Description }} />
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      )} */}
      </Box>
      <Box sx={{mt :{xs : '1150px', lg : '0px'}}}>
      <Announcement announcementData={AnnouncementData} />
      </Box>
      <Hostels hostelData={HostelData} />
      <News />
    </>
  );
}

// import React, { useEffect, useState, lazy, Suspense, useRef } from "react";
// import CustomCarousel from "../../Components/Carousel";
// import Header from "../../Components/Header";
// import { Box, Stack, Button, Typography, useMediaQuery } from "@mui/material";
// import { Steps } from "../../Data/index";
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
// import { DocumentTitleChange } from "../../CommonComponents/Title";

// // Lazy load components
// const Announcement = lazy(() => import("../../Components/Main/Announcement"));
// const Hostels = lazy(() => import("../../Components/Main/Hostels"));
// const News = lazy(() => import("../../Components/Main/News"));

// export default function Main() {
//   const isMobile = useMediaQuery('(max-width:600px)');
//   const { HostelData, AnnouncementData } = useSelector(
//     (state) => state.CommonData
//   );
//   const [hostelData, setHostelData] = useState(HostelData);
//   const [announcementData, setAnnouncementData] = useState(AnnouncementData);

//   const [isAnnouncementVisible, setIsAnnouncementVisible] = useState(false);
//   const [isHostelsVisible, setIsHostelsVisible] = useState(false);
//   const [isNewsVisible, setIsNewsVisible] = useState(false);

//   const announcementRef = useRef(null);
//   const hostelsRef = useRef(null);
//   const newsRef = useRef(null);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       (entries) => {
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             switch (entry.target) {
//               case announcementRef.current:
//                 setIsAnnouncementVisible(true);
//                 observer.unobserve(announcementRef.current);
//                 break;
//               case hostelsRef.current:
//                 setIsHostelsVisible(true);
//                 observer.unobserve(hostelsRef.current);
//                 break;
//               case newsRef.current:
//                 setIsNewsVisible(true);
//                 observer.unobserve(newsRef.current);
//                 break;
//               default:
//                 break;
//             }
//           }
//         });
//       },
//       {
//         root: null,
//         rootMargin: "0px",
//         threshold: 0.1,
//       }
//     );

//     if (announcementRef.current) observer.observe(announcementRef.current);
//     if (hostelsRef.current) observer.observe(hostelsRef.current);
//     if (newsRef.current) observer.observe(newsRef.current);

//     return () => {
//       if (announcementRef.current) observer.unobserve(announcementRef.current);
//       if (hostelsRef.current) observer.unobserve(hostelsRef.current);
//       if (newsRef.current) observer.unobserve(newsRef.current);
//     };
//   }, []);

//   return (
//     <>
//       <Box sx={{ position: "relative" }}>
//         <CustomCarousel btndisplay={true} />
//         <Stack
//           rowGap="50px"
//           sx={{
//             position: "absolute",
//             top: { xs: 0, sm: 180, md: 0 },
//             transform: "translateY(50%)",
//             left: { xs: 30, sm: 60 },
//           }}
//           direction="column"
//           justifyContent="center"
//           textAlign="left"
//           color="white"
//         >
//           <Stack rowGap="10px">
//             <Typography variant="h3">Vinga Hall</Typography>
//             <Typography variant="h1">Learning & Living</Typography>
//             <Typography variant="h4_2">
//               Your second home during your Singapore life
//             </Typography>
//           </Stack>
//           <Button
//             variant="contained"
//             sx={{
//               width: "230px",
//               height: "80px",
//               padding: "10px 30px",
//               backgroundColor: "#007eca",
//               borderRadius: "80px",
//               color: "white",
//               textTransform: "none",
//               letterSpacing: "1%",
//               fontSize: "24px",
//             }}
//           >
//             Apply Now
//           </Button>
//         </Stack>

//         <Box display="flex" justifyContent="center">
//           <Stack
//             direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
//             columnGap={4}
//             sx={{
//               boxShadow: {
//                 xs: "none",
//                 lg: "0px 11px 30px 0px rgba(0,0,0,0.1)",
//               },
//               height: "200px",
//               position: "absolute",
//               bottom: { xs: "-100px", lg: "-100px" },
//               backgroundColor: "white",
//               padding: "40px",
//               borderRadius: "24px",
//             }}
//           >
//             {Steps?.map((item, index) => (
//               <Stack
//                 key={index}
//                 gap="10px"
//                 direction="column"
//                 justifyContent="center"
//                 alignItems="center"
//                 alignContent="center"
//                 maxWidth="172px"
//                 textAlign="center"
//               >
//                 <Box
//                   // style={{
//                   //   marginTop:
//                   //     item?.name === "Step 3"
//                   //       ? "31px"
//                   //       : item?.name === "Step 4"
//                   //       ? "39px"
//                   //       : "20px",
//                   // }}
//                   sx={{pt : 2.5}}
//                 >
//                   <Typography variant="h9_3" color="button">
//                     {item?.name}
//                   </Typography>
//                 </Box>
//                 <Box display="flex" justifyContent="center" alignContent="center">
//                   <Box component="img" sx={{width : '50px', height : '50px'}} src={item?.icon}></Box>
//                 </Box>
//                 <Box>
//                   <Typography sx={{ fontSize: "18px" }}>
//                     <span dangerouslySetInnerHTML={{ __html: item.Description }} />
//                   </Typography>
//                 </Box>
//               </Stack>
//             ))}
//           </Stack>
//         </Box>
//       </Box>

//       <Box ref={announcementRef} sx={{ mt: { xs: "1150px", lg: "0px" } }}>
//         {isAnnouncementVisible && (
//           <Suspense fallback={<div></div>}>
//             <Announcement announcementData={announcementData} />
//           </Suspense>
//         )}
//       </Box>

//       <Box ref={hostelsRef}>
//         {isHostelsVisible && (
//           <Suspense fallback={<div></div>}>
//             <Hostels hostelData={hostelData} />
//           </Suspense>
//         )}
//       </Box>

//       <Box ref={newsRef}>
//         {isNewsVisible && (
//           <Suspense fallback={<div></div>}>
//             <News />
//           </Suspense>
//         )}
//       </Box>
//     </>
//   );
// }

