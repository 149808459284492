import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    admin : JSON.parse(localStorage.getItem('admin')) || null
}

const AdminSlice = createSlice({
    name : 'Admin',
    initialState,
    reducers : {
        storeData : (state, action) => {
            state.admin = action.payload
            localStorage.setItem('admin', JSON.stringify(action.payload));
        },
        removeData : (state) => {
            state.admin = null
            localStorage.removeItem('admin');
        }
    }
})

export const { storeData, removeData } = AdminSlice.actions

export default AdminSlice.reducer