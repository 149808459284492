import React, { useEffect, useState } from 'react'
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Stack, Grid } from "@mui/material"
import { ReactComponent as LocationIcon } from "../../Icons/location.svg"
import { ReactComponent as FavouritesIcon } from "../../Icons/favouritesIcon.svg"
import { ReactComponent as BusIcon } from "../../Icons/BusIcon.svg"
import { ReactComponent as WalkIcon } from "../../Icons/WalkIcon.svg"
import { ReactComponent as TrainIcon } from "../../Icons/TrainIcon.svg"
import MapWithDirections from './MapWithDirections'
import startIconn from "../../Icons/start.svg"



export default function Access({ hostelData }) {

    const [AccessTableData, setAccessTableData] = useState([])
    const [mrtStation, setMrtStation] = useState([])
    const [busStation, setBusStation] = useState([])
    const [activeData, setActiveData] = useState({})
    const [loading, setLoading] = useState(false)
    const [wait, setWait] = useState(false)

    console.log(activeData, 'activeData')

    useEffect(() => {
        if(hostelData && hostelData.tracks){
            setAccessTableData(hostelData.tracks)
            setActiveData(hostelData.tracks[0])
            setMrtStation(hostelData.nearbyMrtStation)
            setBusStation(hostelData.nearbyBusStation)
        }
    }, [hostelData])


    const handleActiveData = (data) => {
        if(loading === false && wait === false){
            setActiveData(data)
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
                setWait(true)
                setTimeout(() => {
                    setWait(false)
                }, 1000)
            }, 2000)
        }
    }

    return (
        <>
            <Box mt={6} mb={4} display="flex" justifyContent='center'>
                <Typography variant="h6_1" color="primary" border="1px solid #27BAC4" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px" width="fit-content">ACCESS</Typography>
            </Box>
            {/* <Stack rowGap={3}>
                <Typography variant="h6_1" color='#222222'> Vinga Hall @ RG (Boarding House)</Typography>
                <TableContainer sx={{ borderRadius: "12px", }}>
                    <Table sx={{ minWidth: 650, background: "#FAFAFA", }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h9_1" color='#333333'>Room Type</Typography>
                                </TableCell>
                                <TableCell> <Typography variant="h9_1" color='#333333'>6 Month Contract</Typography></TableCell>
                                <TableCell> <Typography variant="h9_1" color='#333333'>12 Month Contract</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {AccessTableData.map((item, index) => (
                                <TableRow key={item.name} sx={{ borderTop: ".5px solid #737373" }} >
                                    <TableCell ><Typography variant="h7_3" color='#333333'>{item?.Room_Type || "-"}</Typography></TableCell>
                                    <TableCell ><Typography variant="h7_1" color='#333333'>{item?.six_Month_Contract || "_"}</Typography></TableCell>
                                    <TableCell ><Typography variant="h7_1" color='#333333'>{item?.twelve_Month_Contract || "-"}</Typography></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>


                <Typography mt={4} variant="h6_1" color='#222222'>Vinga Hall @ RG (Room for Rent)</Typography>
                <TableContainer sx={{ borderRadius: "12px", }}>
                    <Table sx={{ minWidth: 650, background: "#FAFAFA", }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h9_1" color='#333333'>Room Type</Typography>
                                </TableCell>
                                <TableCell> <Typography variant="h9_1" color='#333333'>6 Month Contract</Typography></TableCell>
                                <TableCell> <Typography variant="h9_1" color='#333333'>12 Month Contract</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {RoomRent.map((item, index) => (
                                <TableRow key={item.name} sx={{ borderTop: ".5px solid #737373" }} >
                                    <TableCell ><Typography variant="h7_3" color='#333333'>{item?.Room_Type || "-"}</Typography></TableCell>
                                    <TableCell ><Typography variant="h7_1" color='#333333'>{item?.six_Month_Contract || "-"}</Typography></TableCell>
                                    <TableCell ><Typography variant="h7_1" color='#333333'>{item?.twelve_Month_Contract || "-"}</Typography></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack> */}
            <Grid container mb={6} px={{ md: 7, xs: 3 }} columnGap={7} justifyContent="center" rowGap={5}>
                <Grid xs={12} sm={8} md={5.5}>
                    <TableContainer sx={{ borderRadius: "4px", border : '1px solid #dedede'}}>
                        <Table sx={{ minWidth: 350,background: "#FAFAFA", border : '1px solid #dedede' }} aria-label="simple table">
                            <TableHead sx={{ height: "50px",border : '1px solid #dedede', position : 'sticky', top : 0, zIndex : 5}}>
                                <TableRow sx={{border : '1px solid #dedede'}}>
                                    <TableCell sx={{ height: "70px", padding: "0px 10px",border : '1px solid #dedede'}}> <Typography variant="h9_1" color='#333333'>PLACES</Typography></TableCell>
                                    <TableCell sx={{ height: "70px", padding: "0px 10px",border : '1px solid #dedede'}}>
                                        <Stack sx={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: '5px', }}>
                                            <BusIcon fill="#27BAC4" />
                                            <Typography variant="h9_1" color='#333333'>PUBLIC TRANSPORT</Typography>
                                        </Stack>
                                    </TableCell>
                                    
                                    <TableCell sx={{ height: "50px", padding: "0px 10px", border : '1px solid #dedede'}}>
                                        <Stack sx={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: '5px', }}>
                                            <WalkIcon fill="#27BAC4" /> <Typography variant="h9_1" color='#333333'>WALK</Typography>
                                        </Stack>
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody sx={{border : '1px solid #dedede'}}>
                                {AccessTableData.map((item, index) => (
                                    <TableRow key={index} sx={{ borderTop: ".5px solid #737373", pointerEvents: (loading || wait) ? 'none' : 'auto' ,backgroundColor : (item.name === activeData.name) ? '#effafc' : '' ,":hover" : {cursor : 'pointer', background : (item.name === activeData.name) ? '#effafc' : '#f7f7f7'} }} onClick={() => handleActiveData(item)}>
                                        <TableCell sx={{border : '1px solid #dedede'}}><Typography variant="h7_3" color='#333333'>{item?.name || "-"}</Typography></TableCell>
                                        <TableCell sx={{border : '1px solid #dedede'}}><Typography variant="h7_1" color='#333333'>{item?.publicTransport || "_"}</Typography></TableCell>
                                        <TableCell sx={{border : '1px solid #dedede'}}><Typography variant="h7_1" color='#333333'>{item?.walk || "-"}</Typography></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid xs={12} sm={8} md={5.5}>
                    <Stack sx={{ border: "0.5px solid #27BAC4", borderRadius: '4px' }}>
                        <Box padding="10px 20px" borderBottom="0.5px solid #27BAC4">
                            <Typography variant="h6_2" color="#27BAC4">Nearby MRT Station</Typography>
                        </Box>
                        {mrtStation.map((mrt, i) => (
                            <Stack direction="row" sx={{background : (activeData.name === mrt.name) ? '#effafc' : '', ':hover' : {cursor : 'pointer'}, pointerEvents: (loading || wait) ? 'none' : 'auto'}} justifyContent="space-between" alignItems="center" p={3} onClick={() => handleActiveData(mrt)}>
                            <Stack direction="row" alignItems="center" gap="10px" >
                                <Stack alignItems="center" justifyContent="center" sx={{ width: "48px", height: "48px", backgroundColor: "#007ECA", borderRadius: "100px" }}><TrainIcon fill="#FFFFFF" /></Stack>
                                <Typography variant='h7_1'>{mrt?.name}</Typography>
                            </Stack>
                            <Stack direction="column" alignItems="center">
                                <WalkIcon fill="#27BAC4" />
                                <Typography variant='h9_4'>~{mrt?.walk}</Typography>
                            </Stack>
                        </Stack>
                        ))}
                        
                        <Box padding="10px 20px" borderBottom="0.5px solid #27BAC4" borderTop="0.5px solid #27BAC4">
                            <Typography variant="h6_2" color="#27BAC4">Nearby Bus Station</Typography>
                        </Box>
                        {busStation.map((bus, i) => (
                            <Stack direction="row" sx={{pointerEvents: (loading || wait) ? 'none' : 'auto', background : (activeData.name === bus.name) ? '#effafc' : '', ':hover' : {cursor : 'pointer'}}} justifyContent="space-between" alignItems="center" px={3} py={1.5} onClick={() => handleActiveData(bus)}>
                            <Stack direction="row" alignItems="center" gap="10px">
                                <Stack alignItems="center" justifyContent="center" sx={{ width: "48px", height: "48px", backgroundColor: "#007ECA", borderRadius: "100px" }}><TrainIcon fill="#FFFFFF" /></Stack>
                                <Typography variant='h7_1'>{bus?.name}</Typography>
                            </Stack>
                            <Stack direction="column" alignItems="center">
                                <WalkIcon fill="#27BAC4" />
                                <Typography variant='h9_4'>~{bus?.walk}</Typography>
                            </Stack>
                        </Stack>
                        ))}
                        
                    </Stack>
                    <Stack direction="row" alignItems="center" gap="16px" my={3}>
                        <Box sx={{ width: "24px", height: "24px" }} component='img' src={startIconn}></Box>
                        <Typography variant="h9_3">{hostelData?.location}</Typography>
                    </Stack>
                    <Stack>
                        {/* <Box>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d1994.3833909336715!2d103.85076783851768!3d1.3154767996680283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e9!4m5!1s0x31da19c35e80c029%3A0x8c25bf68added926!2sCambridge%20Road%2C%20Pek%20Kio%20Market%20%26%20Food%20Centre%2C%20Singapore!3m2!1d1.3162194999999999!2d103.8502213!4m5!1s0x31da19c5ab984b7f%3A0x8b98195c30fa507c!2s99%20Rangoon%20Rd%2C%20Singapore%20218384!3m2!1d1.31454!2d103.85371699999999!5e0!3m2!1sen!2sin!4v1718192918816!5m2!1sen!2sin" width="100%" height="490" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </Box> */}
                        {(hostelData && hostelData.Location && hostelData.Location.latitude && hostelData.Location.longitude && hostelData.tracks && activeData && activeData.coOrdinates && activeData.coOrdinates.latitude && activeData.coOrdinates.longitude) && (
                            (loading === true) ? (<>
                            <Box sx={{display : 'flex', justifyContent : 'center', alignItems : 'center', height : '100%'}}>
                            <Typography>Loading...</Typography>
                            </Box>
                                </>) : (
                                    <>
                                    <MapWithDirections hostelData={hostelData} start={activeData.coOrdinates.latitude} end={activeData.coOrdinates.longitude}/>
                                    </>
                                )
                        )}
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}
