import React, { useEffect, useState, useRef } from 'react'
import { Typography, Stack, Box, Grid, Divider, Radio, Select, MenuItem, InputLabel, Button, Checkbox, TextField } from '@mui/material'
import axios from 'axios'
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { ReactComponent as UploadFileIcon } from "../../../../Icons/UploadFileIcon.svg";
import { ReactComponent as ImgIcon } from "../../../../Icons/ImgIcon.svg";
import ClearIcon from "@mui/icons-material/Clear";
import { ReactComponent as FormIcon } from "../../../../Icons/CommonFormIcon.svg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom'


export default function Agreement({ setStepperCount }) {

    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues,
        watch,
        setValue,
      } = useForm({
        mode: "onTouched"
      });

    const navigate = useNavigate()
    const data = useSelector((state) => state.AdminData.admin)
    const [allData, setAllData] = useState({})
    const [checked, setChecked] = useState(false)

    
  const Imgwatch = watch("img");

  const imgFile = useRef();
  
  const [bgImg, setBgImg] = useState([]);

  

  useEffect(() => {
    if (Boolean(Imgwatch)) {
      setBgImg((prev) => []);

      Array.from(Imgwatch)?.map((item, index) =>
        setBgImg((prev) => [...prev, { id: index + 1, img: item }])
      );
    }
  }, [Imgwatch]);

   

    const fetchData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}get-paiduser-by-id/${data.id}`)
        .then((response) => {
            console.log(response, 'paid user')
            if(response.status === 200){
                if(response?.data?.data.tenancyAgreement){
                  setValue('name', response?.data?.data?.tenancyAgreement.name)
                  setValue('passport', response?.data?.data?.tenancyAgreement.passport_no)
                  setValue('finnumber', response?.data?.data?.tenancyAgreement.fin_no)
                  setValue('img', [response?.data?.data?.tenancyAgreement.signature])
                  setChecked(response.data.data.tenancyAgreement.agreement)
                } else {
                  setValue('name', response?.data?.data?.PersonalData?.Details?.name)
                  setValue('passport', response?.data?.data?.PersonalData?.Details?.passport_no)
                }
                setAllData(response.data.data)
            }
        })
        .catch((error) => console.log(error))
    }

    // const fetchTenancyAgreement = async () => {
    //     await axios.get(`${process.env.REACT_APP_API_URL}get-tenancy-agreement/${data.id}`)
    //     .then((response) => {
    //       console.log(response)
    //     }).catch((error) => {
    //       console.log(error)
    //     })
    // }


    useEffect(() => {
        if(data?.id){
            fetchData()
           
        }
    }, [])


    const handleChange = () => {
        setChecked(!checked)
    }

    console.log(data.id)

    const onSubmit = async (dataa) => {
        console.log(dataa)
        const sendObj = new FormData()
        sendObj.append('name', dataa?.name)
        sendObj.append('fin_no', dataa?.finnumber)
        sendObj.append('passport_no', dataa?.passport)
        sendObj.append('signature', dataa?.img[0])
        sendObj.append('agreement', checked)
        await axios.put(`${process.env.REACT_APP_API_URL}add-tenancy-agreement/${data.id}`, sendObj, {
          headers: {
              'Content-Type': 'multipart/form-data',
          },
      })
        .then((response) => {
          console.log(response)
          if(response.status == 200){
              setStepperCount(2)
          }
        }).catch((error) => {
          console.log(error)
        })
        
    }

    const deleteFirstImage = (id) => {
        let updatedFiles = Array.from(Imgwatch);
        updatedFiles = updatedFiles.filter((file, index) => index !== id);
        setValue("img", updatedFiles);
      };


    return (

        <Box px={{ md: 10, xs: 3 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
            <Typography sx={{pb : 4, ":hover" : {cursor : 'pointer'}}} onClick={() => navigate('/account')}><ArrowBackIcon sx={{fontSize : '30px'}}/></Typography>
            <Typography variant="h6_1">Tenancy Agreement</Typography>
            <Grid mt={3} mb={6} container rowGap={7}>
                <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                    <Divider />
                    <Stack direction="row" alignItems="center" sx={{ fontSize: "20px", fontWeight: 500 }} gap={1}>
                        THIS AGREEMENT is made on the<Typography variant='h7_1'>17th</Typography>day of <Typography variant='h7_1'> March, 2023</Typography>
                    </Stack>
                    <Grid mt={3} container justifyContent="start" columnGap={4}>
                        <Grid xs={12} sm={4} rowGap={2}>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Typography color="#1A2461" variant='h7_1'>PARTY A</Typography>
                                <InputLabel sx={{ marginBottom: "0px" }} variant="standard">(“the Landlord/Licensor”)</InputLabel>
                            </Stack>
                            <Stack mt={3} direction="column" alignItems="start" gap={1}>
                                <Typography color="#1A2461" variant='h7_1'>DR Pacific Holdings Pte Ltd</Typography>
                                <InputLabel sx={{ marginBottom: "0px", color: "#1A2461 !important" }} variant="standard">Vinga Hall <br />
                                    Address: No. 99 Rangoon Road Singapore 218384<br />
                                    UEN: 200821037N</InputLabel>
                            </Stack>
                        </Grid>
                        <Grid xs={12} sm={4}>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Typography color="#1A2461" variant='h7_1'>PARTY B</Typography>
                                <InputLabel sx={{ marginBottom: "0px" }} variant="standard">(“the Student”)</InputLabel>
                            </Stack>

                            <InputLabel
                            sx={{
                                mt : 2,
                                 mb: 1,
                                fontSize: "18px",
                                fontWeight: 600,
                                color: "black",
                            }}
                            >
                            {errors?.name ? "Full name*" : "Full name"}
                            </InputLabel>
                            <Controller 
                            name='name'
                            control={control}
                            rules={{required : true}}
                            render={({ field }) => (
                                <TextField
                                placeholder="Enter name"
                                error={Boolean(errors?.name) ? true : false} 
                                {...field}
                                sx={{
                                    width: "100%",
                                    backgroundColor: "rgb(249, 250, 251)",
                                    borderRadius: 0,
                                    "& .MuiInputBase-input": {
                                      color: errors?.name ? "red" : "black",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: errors?.name
                                          ? "#ff5e74"
                                          : "rgba(0, 0, 0, 0.23)",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: errors?.name
                                          ? "#ff5e74"
                                          : "rgba(0, 0, 0, 0.23)",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: errors?.name
                                          ? "#ff5e74"
                                          : "rgba(0, 0, 0, 0.23)",
                                      },
                                    },
                                  }}
                                />
                            )}
                            />
                             {errors?.name ? (
                                <Typography
                                    style={{ color: "#ff5e74", margin: "0.3rem" }}
                                >
                                    Please enter name
                                </Typography>
                                ) : null}
                            
                            
                            <InputLabel
                            sx={{
                                mt : 2,
                                 mb: 1,
                                fontSize: "18px",
                                fontWeight: 600,
                                color: "black",
                            }}
                            >
                            {errors?.passport ? "Passport Number*" : "Passport Number"}
                            </InputLabel>
                            <Controller 
                            name='passport'
                            control={control}
                            rules={{required : true}}
                            render={({ field }) => (
                                <TextField
                                placeholder="Enter Passport Number"
                                error={Boolean(errors?.passport) ? true : false} 
                                {...field}
                                sx={{
                                    width: "100%",
                                    backgroundColor: "rgb(249, 250, 251)",
                                    borderRadius: 0,
                                    "& .MuiInputBase-input": {
                                      color: errors?.passport ? "red" : "black",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: errors?.passport
                                          ? "#ff5e74"
                                          : "rgba(0, 0, 0, 0.23)",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: errors?.passport
                                          ? "#ff5e74"
                                          : "rgba(0, 0, 0, 0.23)",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: errors?.passport
                                          ? "#ff5e74"
                                          : "rgba(0, 0, 0, 0.23)",
                                      },
                                    },
                                  }}
                                />
                            )}
                            />
                             {errors?.passport ? (
                                <Typography
                                    style={{ color: "#ff5e74", margin: "0.3rem" }}
                                >
                                    Please enter Passport number
                                </Typography>
                                ) : null}

                            <InputLabel
                            sx={{
                                mt : 2,
                                 mb: 1,
                                fontSize: "18px",
                                fontWeight: 600,
                                color: "black",
                            }}
                            >
                            {errors?.finnumber ? "FIN number*" : "FIN number"}
                            </InputLabel>
                            <Controller 
                            name='finnumber'
                            control={control}
                            rules={{required : true}}
                            render={({ field }) => (
                                <TextField
                                placeholder="Enter FIN Number"
                                error={Boolean(errors?.finnumber) ? true : false} 
                                {...field}
                                sx={{
                                    width: "100%",
                                    backgroundColor: "rgb(249, 250, 251)",
                                    borderRadius: 0,
                                    "& .MuiInputBase-input": {
                                      color: errors?.finnumber ? "red" : "black",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: errors?.finnumber
                                          ? "#ff5e74"
                                          : "rgba(0, 0, 0, 0.23)",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: errors?.finnumber
                                          ? "#ff5e74"
                                          : "rgba(0, 0, 0, 0.23)",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: errors?.finnumber
                                          ? "#ff5e74"
                                          : "rgba(0, 0, 0, 0.23)",
                                      },
                                    },
                                  }}
                                />
                            )}
                            />
                             {errors?.finnumber ? (
                                <Typography
                                    style={{ color: "#ff5e74", margin: "0.3rem" }}
                                >
                                    Please enter FIN number
                                </Typography>
                                ) : null}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }}  >
                    <Stack textAlign="center" sx={{ border: "1px solid #1A2461", width: "100%", borderRadius: "8px", padding: "40px 60px" }}>
                        <Stack gap={1}>
                            <Typography variant="h6_1">Agreement Terms</Typography>
                            <Typography color="#1A2461" variant="h9_4">The Student will check-in to Vinga Hall, address: 99 Rangoon Road, Singapore 218384, bothparties come into agreed terms as follows:</Typography>
                        </Stack>
                        <Stack mt={5} gap="10px" direction="column" alignItems="start" textAlign="start">
                            <Typography variant='h6_1'>1. PARTY A (Landlord) responsibilities</Typography>
                            <Typography color="#1A2461" variant="h9_4">(1) Party A allows the Student to stay in Vinga Hall address, and promises to monitor on the Student’s activities during the stay,<br />
                                (2) Party A agrees to provide the Student, a room with bed, mattress, wardrobe, bookshelves and writing desk, to ensure the Student stays in a clean, comfortable and conducive environment.<br />
                                (3) Party A reserves the right to allocate and to change room for the Student anytime.<br />
                                (4) Party A offers free Wifi & LAN internet facilities inside the room.<br />
                                (5) Party A provides free air-con, electricity and water supply.<br />
                                (6) Party A provides free laundry services twice weekly.<br />
                                (7) Party A provides daily general room cleaning services and twice weekly specific cleaning.<br />
                                (8) Party A will bring new student to get familiar with the living environment. If the Student is sick, Party A will take initiative to take care of the Student and to inform the Parents, or guardianinSingapore. If needed, assists to bring the Student to see doctor. All transport and doctor consulatation expenses will be borned by the Student.<br />
                                (9) Party A will report to the Parents or guardian in Singapore daily, through Wechat groupchat at around 10pm regarding the Student’s status.<br />
                                (10) Party A will promptly reply the Parents or guardian in Singapore any enquiry related totheStudent’s living condition or his/her status.<br />
                                (11) Party A will manage cash allowance, if the Parents opted for the service, and will distributethe allowance weekly on time with the agreed sum.<br />
                                (12) Party A will provide airticket advanced booking service free of charge.<br />
                                (13) Party A will provide the Student with counseling services on living overseas or educationrelated matters. Facilitate the Student to successfully completed his/her study and to maintain a healthy lifestyle.<br />
                                (14) Party A will provide local school transfer consultation service free of charge, and to give advice to the Student on overseas study matters.<br />
                                (15) Party A will also provide migration consultation service free of charge.</Typography>
                        </Stack>
                    </Stack>
                    <Stack mt={2} gap="5px" direction="row" alignItems="center" justifyContent="start" >
                        
                        <Checkbox
                        checked={checked}
                        onChange={handleChange} 
                        />
                        <Typography variant='h9_3' >I have read the contract and I agree with the terms and conditions</Typography>
                    </Stack>
                </Grid>
            </Grid>
            
            <Box>
                <InputLabel sx={{fontSize : '20px', fontWeight : 700, color : 'rgba(26, 36, 97, 1)'}}>Signed by</InputLabel>
                {Boolean(Imgwatch?.length > 0) ? (
                Array.from(Imgwatch)?.map((item, index) => (
                  <Box style={{ padding: "0px 17px 17px" }}>
                    <Stack
                      mt={2}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      padding="23px"
                      borderRadius="8px"
                      sx={{ border: ".5px solid #1A2461" }}
                    >
                      <Typography
                        variant="h9_1"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <ImgIcon />
                        {item?.name || item?.originalname}
                      </Typography>
                      <ClearIcon
                        onClick={() => deleteFirstImage(index)}
                        sx={{ color: "#1A2461" }}
                      />
                    </Stack>
                  </Box>
                ))
              ) : (
                <Box
                  xs={12}
                  onClick={() => imgFile.current.click()}
                  sx={{
                    border: Boolean(errors.img)
                      ? "1px dashed red"
                      : "1px dashed #1A2461",
                    height: "200px",
                    borderRadius: "8px",
                    backgroundColor: "#F9FBFF",
                    margin: "20px 17px 17px",
                  }}
                >
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "100px",
                        backgroundColor: "#F2F2F2",
                      }}
                    >
                      <UploadFileIcon fill="#1A2461" />
                    </Stack>
                    <Stack mt={2} direction="row" alignItems="center" gap="5px">
                      <Typography variant="h9_1">Click to upload</Typography>
                    </Stack>
                    <Typography variant="h9_5">
                      Allowed formats - JPEG, PNG
                    </Typography>
                  </Stack>
                  <Box sx={{ marginTop: "0.3rem" }}>
                    {errors?.img ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please select an Image
                      </Typography>
                    ) : null}
                  </Box>
                </Box>
              )}
              <Controller
                name="img"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      field.onChange(e.target.files);
                    }}
                    ref={(e) => {
                      imgFile.current = e;
                    }}
                    hidden
                    multiple
                  />
                )}
              />
            </Box>

            <Stack my={5} direction="row" alignItems="center" justifyContent="start">
                <Button disabled={!checked} type='submit' variant="standard">Continue</Button>
            </Stack>
            </form>
        </Box >
    )
}
