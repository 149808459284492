// import React, { useState, useEffect } from 'react'
// import { useNavigate } from 'react-router-dom'
// import { Typography, Box, Menu, MenuItem, ListItemIcon, Button } from '@mui/material'
// import UserCircleIcon from "../Icons/UserCircleIcon.svg"
// import { ReactComponent as UserIcon } from "../Icons/UserIcon.svg"
// import { ReactComponent as LogoutIcon } from "../Icons/LogoutIcon.svg"
// import UKIcon from "../Icons/UKIcon.svg"
// import { ReactComponent as DownArrowIcon } from "../Icons/DownArrow.svg"
// import { useSelector, useDispatch } from 'react-redux'
// import { removeData } from '../Redux/Feature/AdminSlice'


// export default function HeaderTop() {

//     const navigate = useNavigate()
//     const data = useSelector((state) => state.AdminData.admin)
//     const dispatch = useDispatch()
//     const [anchorlEl, setanchorlEl] = useState(false)
//     const [user, setUser] = useState(false)
//     // const open = Boolean(anchorlEl)

//     useEffect(() => {
//         if(data?.isAdmin === false){
//             setUser(true)
//         }
//     },[])

//     const handleLogout = () => {
//         dispatch(removeData())
//         setUser(false)
//         setanchorlEl(null)
//         navigate('/')
//     }

//     return (
//         <>
        
//             <>
//             <Box height="70px" sx={{ backgroundColor: "#003893", display: "flex", alignItems: "center", justifyContent: "end", gap: "20px", px: 3 }}>

// {/* before LOgin */}

// {/* <Box sx={{ width: "100px", height: "40px", borderRadius: "8px", paddding: "10px", backgroundColor: "#FFFFFF", display: "flex", alignItems: "center", gap: "10px", justifyContent: "center" }}>
// <Box component="img" src={UserCircleIcon}></Box>
// <Typography variant="h9_3" color="#017BCC">Login</Typography>
// </Box> */}

// {/* after Login */}

// {user ? (

// <Box onClick={(e) => { setanchorlEl(e.currentTarget) }} px={1.3} sx={{ width: "fit-content", height: "40px", borderRadius: "8px", paddding: "10px !important", backgroundColor: "#FFFFFF", display: "flex", alignItems: "center", gap: "10px", justifyContent: "center", cursor : 'pointer' }}>
//     <Box component="img" src={UserCircleIcon}></Box>
//     <Typography variant="h9_3" color="#017BCC" >{data?.userName || 'username'}</Typography>
//     <DownArrowIcon fill="#017BCC" />
// </Box> 
// ) : (
// <Button onClick={() => navigate('/login')}  variant='contained' sx={{fontWeight : 700, px : 2, fontSize : '1rem',background : 'white', color:"#017BCC", ":hover" : {color : '#017BCC', background : 'white'}}}>Login</Button>
// )}

// <Box sx={{ width: "100px", height: "40px", borderRadius: "8px", paddding: "10px", backgroundColor: "#FFFFFF", display: "flex", alignItems: "center", gap: "10px", justifyContent: "center" }}>
//     <Box component="img" src={UKIcon}></Box>
//     <Typography variant="h9_3" color="#017BCC" sx={{ fontWeight: 700 }}>EN</Typography>
//     <DownArrowIcon fill="#017BCC" />
// </Box>
// </Box>

// <Menu open={anchorlEl} anchorEl={anchorlEl} onClose={() => setanchorlEl(null)} sx={{ '& .MuiAvatar-root': { p: 2 }, mt: 1 }}>
// <MenuItem gap="10px" onClick={() => { 
//     navigate('account') 
//     setanchorlEl(null) 
//     }}>
//     <ListItemIcon>
//         <UserIcon />
//     </ListItemIcon>
//     <Typography color="#017BCC" variant="h7_3">Account</Typography>
// </MenuItem>
// <MenuItem onClick={() => {
//     handleLogout()
//     setanchorlEl(null)
//     }}>
//     <ListItemIcon>
//         <LogoutIcon />
//     </ListItemIcon>
//     <Typography color="#017BCC" variant="h7_3" >Logout</Typography>
// </MenuItem>
// </Menu>
//             </>
       
            
//         </>
//     )
// }

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Menu, MenuItem, ListItemIcon, Button } from '@mui/material';
import UserCircleIcon from "../Icons/UserCircleIcon.svg";
import { ReactComponent as UserIcon } from "../Icons/UserIcon.svg";
import { ReactComponent as LogoutIcon } from "../Icons/LogoutIcon.svg";
import UKIcon from "../Icons/UKIcon.svg";
import { ReactComponent as DownArrowIcon } from "../Icons/DownArrow.svg";
import { useSelector, useDispatch } from 'react-redux';
import { removeData } from '../Redux/Feature/AdminSlice';

export default function HeaderTop() {
    const navigate = useNavigate();
    const data = useSelector((state) => state.AdminData.admin);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [user, setUser] = useState(false);

    useEffect(() => {
        if (data?.isAdmin === false) {
            setUser(true);
        }
    }, [data]);

    const handleLogout = () => {
        dispatch(removeData());
        setUser(false);
        setAnchorEl(null);
        navigate('/');
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Box height="70px" sx={{ backgroundColor: "#003893", display: "flex", alignItems: "center", justifyContent: "end", gap: "20px", px: 3 }}>
                {/* After Login */}
                {user ? (
                    <Box
                        onClick={handleMenuClick}
                        px={1.3}
                        sx={{
                            width: "fit-content",
                            height: "40px",
                            borderRadius: "8px",
                            padding: "10px !important",
                            backgroundColor: "#FFFFFF",
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            justifyContent: "center",
                            cursor: 'pointer'
                        }}>
                        <Box component="img" src={UserCircleIcon}></Box>
                        <Typography variant="h9_3" color="#017BCC">{data?.userName || 'username'}</Typography>
                        <DownArrowIcon fill="#017BCC" />
                    </Box>
                ) : (
                    <Button onClick={() => navigate('/login')} variant='contained' sx={{ fontWeight: 700, px: 2, fontSize: '1rem', background: 'white', color: "#017BCC", ":hover": { color: '#017BCC', background: 'white' } }}>Login</Button>
                )}

                <Box sx={{ width: "100px", height: "40px", borderRadius: "8px", padding: "10px", backgroundColor: "#FFFFFF", display: "flex", alignItems: "center", gap: "10px", justifyContent: "center" }}>
                    <Box component="img" src={UKIcon}></Box>
                    <Typography variant="h9_3" color="#017BCC" sx={{ fontWeight: 700 }}>EN</Typography>
                    <DownArrowIcon fill="#017BCC" />
                </Box>
            </Box>

            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                sx={{ '& .MuiAvatar-root': { p: 2 }, mt: 1 }}
            >
                <MenuItem gap="10px" onClick={() => {
                    navigate('/account');
                    handleMenuClose();
                }}>
                    <ListItemIcon>
                        <UserIcon />
                    </ListItemIcon>
                    <Typography color="#017BCC" variant="h7_3">Account</Typography>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <Typography color="#017BCC" variant="h7_3">Logout</Typography>
                </MenuItem>
            </Menu>
        </>
    );
}
