import { Divider, Typography,TableContainer,
  Table,
  IconButton,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination
 } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DeleteIcon } from "../../../Icons/DeleteTable.svg";
import { ReactComponent as EditIcon } from "../../../Icons/EditIcon.svg";
import AlertModal from "../../../CommonComponents/AlertModal";
import axios from 'axios';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import LinearProgress from '@mui/material/LinearProgress';
import CachedIcon from '@mui/icons-material/Cached';

function ChargesMain() {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);

  const [pageMain, setPageMain] = useState(0);
  const [rowsPerPageMain, setRowsPerPageMain] = useState(5);
  const [totalItemsMain, setTotalItemsMain] = useState(0);

    const [deposits, setDeposits] = useState([])
    const [mainAmounts, setMainAmounts] = useState([])

    const [value, setValue] = useState(() => {
        const localValue = localStorage.getItem("charges");
        return localValue !== null ? JSON.parse(localValue) : 'deposit';
    });

    const navigate = useNavigate()


    const [deleteModal, setDeleteModal] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
    const [loading, setLoading] = useState(false)
    
    useEffect(() => {
        localStorage.setItem("charges", JSON.stringify(value));
      }, [value]);

      const [navData, setNavData] = useState([
        {
          id: 1,
          name: 'Deposits',
          show: 'deposit',
        },
        {
          id: 2,
          name: 'Main',
          show: 'main',
        },
      ]);

      const stripHtml = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
      };

      let deleteData = {
        label: value === 'deposit'
          ? "Confirm Delete Deposits"
          : "Confirm Delete Main Amount",
        descriptionOne: value === 'deposit'
          ? "Please confirm that you wish to delete the deposit."
          : "Please confirm that you wish to delete the main amounts.",
        buttonOne: {
          variant: "outlined",
          color: "#0E5E84",
          backgroundColor: "#FFFFFF",
          text: "Cancel",
        },
        buttonTwo: {
          variant: "contained",
          color: "#FFFFFF",
          backgroundColor: "#912018",
          text: "Confirm",
        },
      };

      const handleClose = () => {
        setDeleteModal(null);
        setDeleteId(null);
      };

      const fetchDepositData = async () => {
        setLoading(true)
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}get-deposits?page=${
          page + 1
        }&limit=${rowsPerPage}`
          );
          setTotalItems(response.data.pagination.totalItems || 0)
          setDeposits(response.data.data || []);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      };
    
      const fetchMainAmountData = async () => {
        setLoading(true)
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}get-main-amounts?page=${
              page + 1
            }&limit=${rowsPerPage}`
          );
          setTotalItemsMain(response.data.pagination.totalItems || 0)
          setMainAmounts(response.data.data || []);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      };
    
      // useEffect(() => {
      //   fetchDepositData();
      // }, [page, rowsPerPage]);

      // useEffect(() => {
      //   fetchMainAmountData();
      // }, [pageMain, rowsPerPageMain])

      useEffect(() => {
        setLoading(true);
        switch (value) {
          case 'deposit':
            fetchDepositData();
            break;
          case 'main':
            fetchMainAmountData();
            break;
          default:
            setLoading(false);
        }
      }, [value, page, rowsPerPage, pageMain, rowsPerPageMain]);
    

      // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page change
  };

  const handleChangePageMain = (event, newPage) => {
    setPageMain(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPageMain = (event) => {
    setRowsPerPageMain(parseInt(event.target.value, 10));
    setPageMain(0); // Reset to first page when rows per page change
  };


      const deleteOtherService = async () => {
        try {
          if (value === 'deposit') {
            await axios.delete(
              `${process.env.REACT_APP_API_URL}delete-deposit/${deleteId}`
            );
            fetchDepositData();
            setDeleteModal(null);
            setDeleteId(null);
          } else if (value === 'main') {
            await axios.delete(
              `${process.env.REACT_APP_API_URL}delete-main-amount/${deleteId}`
            );
            fetchMainAmountData();
            setDeleteModal(null);
            setDeleteId(null);
          }
        } catch (error) {
          console.log(error);
        }
      };

      const handleChange = (event, newValue) => {
        setValue(newValue);
      };

      const renderDeposits = () => {
        return (
          <>
          <TableContainer sx={{
          borderRadius: "5px",
          border: "1px solid #e5e5e5",
          boxShadow: "0px 0px 30px 20px #f4f4f4",
        }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                      <Typography variant="h9_1" color="#333333">
                        Title
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Description
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Amount
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Actions
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {loading && (
              <TableRow>
                <TableCell colSpan={5} sx={{p :0, borderBottom : '0px'}}>
              <LinearProgress color="primary" />
              </TableCell>
              </TableRow>
            )}
                  {deposits.length === 0 ? (
                    <TableRow>
                <TableCell colSpan={5} sx={{p :0, borderBottom : '0px'}}>
              No data found
              </TableCell>
              </TableRow>
                  ) : (
                  deposits.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        border: "0px",
                        backgroundColor: index % 2 === 0 ? "#f8f8f8" : "white", 
                      }}
                    >
                      <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                        <Typography variant="h9_4" color="#333333">
                          {item?.title || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                        <Typography variant="h9_4" color="#333333">
                       {stripHtml(item?.description || "-").slice(0, 20)}...
                       {/* {item?.description || "-"} */}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                        <Typography variant="h9_4" color="#333333">
                        S$  {item?.amount || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                        <IconButton
                          onClick={() =>
                            navigate(`/Admin/edit-deposit/${item._id}`)
                          }
                          sx={{ marginRight: "10px" }}
                        >
                          <EditIcon fill="black" />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setDeleteId(item._id);
                            setDeleteModal(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )))}
                </TableBody>
              </Table>

              <TablePagination
                component="div"
                count={totalItems} 
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />

            </TableContainer>
              </>
        )
      }

      const renderMainAmount = () => {
        return (
          <TableContainer sx={{
            borderRadius: "5px",
            border: "1px solid #e5e5e5",
            boxShadow: "0px 0px 30px 20px #f4f4f4",
          }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                      <Typography variant="h9_1" color="#333333">
                        Title
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Description
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Amount
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Actions
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
              <TableBody>
              {loading && (
              <TableRow>
                <TableCell colSpan={5} sx={{p :0, borderBottom : '0px',height : '10%'}}>
              <LinearProgress color="primary" />
              </TableCell>
              </TableRow>
            )}
            {mainAmounts.length === 0 ? (
              <TableRow>
              <TableCell colSpan={5} sx={{p :0, borderBottom : '0px'}}>
           No data found
            </TableCell>
            </TableRow>
            ) : (
                  mainAmounts.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        border: "0px",
                        backgroundColor: index % 2 === 0 ? "#f8f8f8" : "white", 
                      }}
                    >
                      <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                        <Typography variant="h9_4" color="#333333">
                          {item?.title || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                        <Typography variant="h9_4" color="#333333">
                       {stripHtml(item?.description || "-").slice(0, 20)}...
                       {/* {item?.description || "-"} */}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                        <Typography variant="h9_4" color="#333333">
                        S$  {item?.amount || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                        <IconButton
                          onClick={() =>
                            navigate(`/Admin/edit-main-amount/${item._id}`)
                          }
                          sx={{ marginRight: "10px" }}
                        >
                          <EditIcon fill="black" />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setDeleteId(item._id);
                            setDeleteModal(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )))}
                </TableBody>
                </Table>

              <TablePagination
                component="div"
                count={totalItemsMain} 
                page={pageMain}
                onPageChange={handleChangePageMain}
                rowsPerPage={rowsPerPageMain}
                onRowsPerPageChange={handleChangeRowsPerPageMain}
                rowsPerPageOptions={[5, 10, 25]}
              />

            </TableContainer>
        )
      }

      const handleFetchDatas = () => {
          if(value === 'deposit'){
            fetchDepositData()
          }else{
            fetchMainAmountData()
          }
      }


  return (
    <Box>
      {deleteModal && (
        <AlertModal
          modalDatas={deleteData}
          confirmFunction={deleteOtherService}
          closeFunction={handleClose}
        />
      )}
      <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{fontSize : '22px', fontWeight : 700}}>Charges</Typography>

              <Box sx={{display : 'flex', gap : 3, alignItems : 'center'}}>
              <Typography variant="h7_1" sx={{cursor : 'pointer'}} title='Reload' onClick={() => handleFetchDatas()}><CachedIcon /></Typography>
              <Button
                variant="contained"
                onClick={() => navigate(value === 'deposit' ? "/Admin/add-deposit" : '/Admin/add-main-amount')}
              >
                Add
              </Button>
              </Box>
            </Box>
        
        <Divider sx={{mt : 2, mb : 1}}/>

        {/* <Box sx={{ display: "flex", gap: "20px" }}>
        {navData.map((item) => (
                <Box
                    key={item.id}
                    onClick={() => setShowCharges(item.show)}
                    sx={{
                    width: "100%",
                    borderTop: showCharges === item.show ? "3px solid #27bac4" : "3px solid gray",
                    borderRadius: "0px",
                    textAlign: "left",
                    cursor: "pointer",
                    "&:hover": {
                        backgroundColor: "#f0f0f0",
                    },
                    padding: "3px",
                    transition: "ease-in",
                    transitionDuration: "250ms",
                    }}
                >
                    <Typography
                    sx={{
                        pt: 0,
                        color: showCharges === item.show ? "#27bac4" : "gray",
                        fontWeight: 400,
                        fontSize: "18px",
                    }}
                    >
                    {item.name}
                    </Typography>
                </Box>
                ))}
        </Box> */}
                
            
            <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab sx={{ textTransform: 'none', fontSize : '1.1rem' }} label="Landing services" value="deposit" />
            <Tab sx={{ textTransform: 'none', fontSize : '1.1rem'  }} label="Other chargeable services" value="main" />
            
          </TabList>
        </Box>
        
        <TabPanel value="deposit" sx={{pt : 4, px : 0}}>
        {value === 'deposit' && renderDeposits()}
        </TabPanel>

        <TabPanel value="main" sx={{pt : 4, px : 0}}>
        {value === 'main' && renderMainAmount()}
        </TabPanel>
        </TabContext>
        </Box>        
                
    </Box>
  )
}

export default ChargesMain
