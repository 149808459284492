import React, { useState } from 'react'
import { Typography, Stack, Box, Button, Divider, Grid, Select, InputLabel, IconButton, TextField, MenuItem, Checkbox } from '@mui/material'
import { ReactComponent as CorrectIcon } from "../../../Icons/Account/CorrectIcon.svg"
import { useForm, Controller } from 'react-hook-form'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';


export default function CheckoutForm() {

    const { control, handleSubmit ,formState: { errors } } = useForm({
        mode: "onTouched"
    })

    
    const onSubmit = (data) => {
        console.log(data)
    }

    const [checkbox, setCheckBox] = useState(false)

    const handleCheckChange = () => {
        setCheckBox(!checkbox)
    }

    return (
        <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" padding={{xs : '24px 26px', lg : '42px 60px'}} sx={{ borderBottom: ".5px solid #1A2461" }}>
                <Stack gap="10px">
                    <Typography variant="h4_1">Checkout Form</Typography>
                    <Typography variant="h9_4" color="neutral_4">Fill in the required details</Typography>
                </Stack>
            </Stack>
            <Grid px={{ md: 10, xs: 3 }} mt={3} mb={6} container rowGap={7} columnGap={5}>
                <Grid xs={12} pb={2} borderBottom=".5px solid #737373">
                    <Typography variant='h6_1'>Checkout Notice</Typography>
                </Grid>
                <Grid xs={12} sm={5} md={4} alignItems="center">
                    <Stack gap="15px">
                        <Stack direction="row" gap="5px">
                            <Typography variant='h7_1' color="#1A2461">PARTY A</Typography>
                            <Typography variant='h9_3' color="#737373">(“the Landlord/Licensor”)</Typography>
                        </Stack>
                        <Typography variant='h9_3' color="#1A2461">Dated: 30.05.2025</Typography>
                        <Typography variant='h7_1' color="#1A2461">DR Pacific Holdings Pte Ltd</Typography>
                        <Typography variant='h9_3' color="#1A2461">Vinga Hall<br />
                            Address: No. 99 Rangoon Road Singapore 218384<br />
                            UEN: 200821037N</Typography>
                    </Stack>
                </Grid>
                <Grid xs={12} sm={5} md={4} alignItems="center">
                    <Stack gap="15px">
                        <Stack direction="row" gap="5px">
                            <Typography variant='h7_1' color="#1A2461">PARTY A</Typography>
                            <Typography variant='h9_3' color="#737373">(“the Landlord/Licensor”)</Typography>
                        </Stack>
                        <Typography variant='h9_3' color="#1A2461">Dated: 30.05.2025</Typography>

                        <InputLabel
                      sx={{
                        mb: 0,
                        fontSize: "16px",
                        fontWeight: 400,
                        color: "rgba(115, 115, 115, 1)",
                      }}
                    >
                      {errors?.name ? "Full Name*" : "Full Name"}
                    </InputLabel>
                    <Controller
                                name="name"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField
                                        placeholder="Enter name"
                                        error={Boolean(errors.name)}
                                        {...field}
                                        sx={{
                                            width: "100%",
                                            backgroundColor: "rgb(249, 250, 251)",
                                            borderRadius: 0,
                                            "& .MuiInputBase-input": {
                                                color: Boolean(errors.name) ? "red" : "black",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                    borderColor: Boolean(errors.name)
                                                        ? "#ff5e74"
                                                        : "rgba(0, 0, 0, 0.23)",
                                                },
                                                "&:hover fieldset": {
                                                    borderColor: Boolean(errors.name)
                                                        ? "#ff5e74"
                                                        : "rgba(0, 0, 0, 0.23)",
                                                },
                                                "&.Mui-focused fieldset": {
                                                    borderColor: Boolean(errors.name)
                                                        ? "#ff5e74"
                                                        : "rgba(0, 0, 0, 0.23)",
                                                },
                                            },
                                        }}
                                    />
                                )}
                            />
                        {errors?.name ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0rem" }}
                      >
                        Please enter name
                      </Typography>
                    ) : null}

                    <InputLabel   
                      sx={{
                        mb: 0,
                        fontSize: "16px",
                        fontWeight: 400,
                        color: "rgba(115, 115, 115, 1)",
                      }}
                    >
                      {errors?.room_no ? "Room number*" : "Room number"}
                    </InputLabel>
                    <Controller
                                name="room_no"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField
                                        placeholder="Enter room number"
                                        error={Boolean(errors.room_no)}
                                        {...field}
                                        sx={{
                                            width: "100%",
                                            backgroundColor: "rgb(249, 250, 251)",
                                            borderRadius: 0,
                                            "& .MuiInputBase-input": {
                                                color: Boolean(errors.room_no) ? "red" : "black",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                    borderColor: Boolean(errors.room_no)
                                                        ? "#ff5e74"
                                                        : "rgba(0, 0, 0, 0.23)",
                                                },
                                                "&:hover fieldset": {
                                                    borderColor: Boolean(errors.room_no)
                                                        ? "#ff5e74"
                                                        : "rgba(0, 0, 0, 0.23)",
                                                },
                                                "&.Mui-focused fieldset": {
                                                    borderColor: Boolean(errors.room_no)
                                                        ? "#ff5e74"
                                                        : "rgba(0, 0, 0, 0.23)",
                                                },
                                            },
                                        }}
                                    />
                                )}
                            />
                        {errors?.room_no ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0rem" }}
                      >
                        Please room number
                      </Typography>
                    ) : null}


                    <InputLabel   
                      sx={{
                        mb: 0,
                        fontSize: "16px",
                        fontWeight: 400,
                        color: "rgba(115, 115, 115, 1)",
                      }}
                    >
                      {errors?.contact_no ? "Contact number*" : "Contact number"}
                    </InputLabel>
                    <Controller
                                name="contact_no"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField
                                        placeholder="Enter contact number"
                                        error={Boolean(errors.contact_no)}
                                        {...field}
                                        sx={{
                                            width: "100%",
                                            backgroundColor: "rgb(249, 250, 251)",
                                            borderRadius: 0,
                                            "& .MuiInputBase-input": {
                                                color: Boolean(errors.contact_no) ? "red" : "black",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                    borderColor: Boolean(errors.contact_no)
                                                        ? "#ff5e74"
                                                        : "rgba(0, 0, 0, 0.23)",
                                                },
                                                "&:hover fieldset": {
                                                    borderColor: Boolean(errors.contact_no)
                                                        ? "#ff5e74"
                                                        : "rgba(0, 0, 0, 0.23)",
                                                },
                                                "&.Mui-focused fieldset": {
                                                    borderColor: Boolean(errors.contact_no)
                                                        ? "#ff5e74"
                                                        : "rgba(0, 0, 0, 0.23)",
                                                },
                                            },
                                        }}
                                    />
                                )}
                            />
                        {errors?.contact_no ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0rem" }}
                      >
                        Please contact number
                      </Typography>
                    ) : null}

                    </Stack>
                </Grid>
                <Grid xs={12} sm={5} md={4} alignItems="end">
                    <Stack gap="15px">
                        <Stack direction={{xs : 'column', lg : 'row'}} gap="15px" alignItems={{xs : 'start', lg : 'end'}}>
                            <Stack direction="row" gap="5px" alignItems="end">
                                <Typography variant='h7_1' color="#1A2461">PARTY A</Typography>
                                <Typography variant='h9_3' color="#1A2461">requires</Typography>
                            </Stack>
                            <Stack direction="row" gap="5px" alignItems="end">
                                <Typography variant='h7_1' color="#1A2461">PARTY B</Typography>
                                <Typography variant='h9_3' color="#1A2461">to move out of Vinga Hall by</Typography>
                            </Stack>
                        </Stack>
                        <Typography variant='h9_3' color="#1A2461">Dated: 30.05.2025</Typography>
                    </Stack>
                    <Stack mt={2} direction="row" gap="5px" alignItems="end">
                        <Typography variant='h7_1' color="#1A2461">PARTY A</Typography>
                        <Typography variant='h9_3' color="#1A2461">requires</Typography>
                    </Stack>
                    <Grid>
                        <Grid >
                        <InputLabel
                      sx={{
                        mt: 2,
                        fontSize: "16px",
                        fontWeight: 400,
                        color: "rgba(115, 115, 115, 1)",
                      }}
                    >
                      {errors?.date_time ? "Date and Time*" : "Date and Time"}
                    </InputLabel>
                            <Controller
                            name='date_time'
                            control={control}
                            rules ={{required : true}}
                            render = {({field}) => (
                                <DateTimePicker 
                                error={Boolean(errors.date_time)}
                                {...field}
                                sx={{
                                    width: "100%",
                                    backgroundColor: "rgb(249, 250, 251)",
                                    borderRadius: 0,
                                    "& .MuiInputBase-input": {
                                        color: Boolean(errors.date_time) ? "red" : "black",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: Boolean(errors.date_time)
                                                ? "#ff5e74"
                                                : "rgba(0, 0, 0, 0.23)",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: Boolean(errors.date_time)
                                                ? "#ff5e74"
                                                : "rgba(0, 0, 0, 0.23)",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: Boolean(errors.date_time)
                                                ? "#ff5e74"
                                                : "rgba(0, 0, 0, 0.23)",
                                        },
                                    },
                                }} fullWidth />
                            )}
                            />                            
                            {errors?.date_time ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem 0rem 0rem 0.3rem" }}
                      >
                        Please select date and time
                      </Typography>
                    ) : null}
                        </Grid>
                        {/* <Grid xs={5.5} >
                            <InputLabel sx={{ mt: 3 }} variant="standard">Time</InputLabel>
                            <TimeField sx={{ width: "100%" }} />
                        </Grid> */}
                    </Grid>
                    <Stack mt={2} gap="10px" direction="row" alignItems="center" justifyContent="start" >
                        <Checkbox checked={checkbox} onChange={handleCheckChange}/>
                        <Typography variant='h9_1' color="text_blue">I understand and agree this notice</Typography>
                    </Stack>
                    <InputLabel
                      sx={{
                        mt: 2,
                        fontSize: "16px",
                        fontWeight: 400,
                        color: "rgba(115, 115, 115, 1)",
                      }}
                    >
                      {errors?.sign ? "Signed by*" : "Signed by"}
                    </InputLabel>
                    <Controller 
                    name='sign'
                    control={control}
                    rules={{required : true}}
                    render={({field}) => (
                        <TextField 
                        error={Boolean(errors.sign)}
                                {...field}
                                sx={{
                                    width: {xs : '100%', lg : '260px'},
                                    backgroundColor: "#e6f2fa",
                                    borderRadius: 0,
                                    "& .MuiInputBase-input": {
                                        color: Boolean(errors.sign) ? "red" : "black",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: Boolean(errors.sign)
                                                ? "#ff5e74"
                                                : "rgba(0, 0, 0, 0.23)",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: Boolean(errors.sign)
                                                ? "#ff5e74"
                                                : "rgba(0, 0, 0, 0.23)",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: Boolean(errors.sign)
                                                ? "#ff5e74"
                                                : "rgba(0, 0, 0, 0.23)",
                                        },
                                    },
                                }}
                        // sx={{ width: {xs : '100%', lg : '260px'}, '.MuiInputBase-input': { backgroundColor: "#e6f2fa" } }} 
                        variant="filled" />
                    )}
                    />
                    {errors?.sign ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem 0rem 0rem 0.3rem" }}
                      >
                        Please sign
                      </Typography>
                    ) : null}
                    
                    <Stack mt={5} direction="row" alignItems="center" justifyContent="start">
                        <Button disabled={!checkbox} type='submit' variant="standard" >Continue</Button>
                    </Stack>
                </Grid>
            </Grid>
            </form>
        </Box >
    )
}
