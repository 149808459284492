import { Typography, Stack, Box, Button, Divider, Grid, Select, InputLabel, IconButton, TextField, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ReactComponent as ContractIcon } from "../../../Icons/Account/Contract.svg"
import { ReactComponent as SandClockIcon } from "../../../Icons/Account/SandClock.svg"
import { ReactComponent as PuzzlePiecesIcon } from "../../../Icons/Account/PuzzlePieces.svg"
import { ReactComponent as TickIcon } from "../../../Icons/Account/TickIcon.svg"
import { ReactComponent as ApprovedIcon } from "../../../Icons/Account/ApprovedIcon.svg"
import { ReactComponent as PendingIcon } from "../../../Icons/Account/Vector.svg"
import { ReactComponent as RightArrowIcon } from "../../../Icons/rightArrow.svg"
import { ReactComponent as DownloadIcon } from "../../../Icons/Account/Download.svg"
import { ReactComponent as ShareIcon } from "../../../Icons/ShareIcon.svg"
import { ReactComponent as RejectedIcon } from "../../../Icons/Account/RejectedIcon.svg"
import { ReactComponent as CallIcon } from "../../../Icons/Account/Call.svg"
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useSelector } from 'react-redux'


export default function Application() {
    const navigate = useNavigate()
    const [approoval, setApprooval] = useState('pending')
    const [signed, setSigned] = useState('unsigned')
    const data = useSelector((state) => state.AdminData.admin)
    const [allData, setAllData] = useState({})
    const [services, setServices] = useState()
    const [otherServices, setOtherServices] = useState()
    //approoval == pending, success, rejected 
    //signed == singed, unsigned


    const fetchData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}get-paiduser-by-id/${data.id}`)
        .then((response) => {
            console.log(response)
            if(response.status === 200){
                setApprooval(response.data.data.applicationStatus)
                setSigned(response.data.data.signedStatus)
                setAllData(response.data.data)
            }
        })
        .catch((error) => console.log(error))
    }

    const fetchServicesData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}getAllServices`)
        .then((response) => {
            console.log(response)
            setServices(response.data.data)
        })
        .catch((error) => console.log(error))
    }

    const fetchOtherServicesData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}get-other-services`)
        .then((response) => {
            console.log(response.data)
            setOtherServices(response.data.data)
        })
        .catch((error) => console.log(error))
    }


    useEffect(() => {
        if(data.id){
            fetchData()
            fetchServicesData()
            fetchOtherServicesData()
        }
    },[])

    const handleApplyContract = () => {
        if(approoval === 'rejected'){
            navigate('/application')
        }else if (approoval === 'success'){
            console.log('Pdf downloaded')
        }
    }

    const handleContactInvoice = () => {
        if(approoval === 'rejected'){
            navigate('/contactus')
        }else if (approoval === 'success' && signed === 'signed') {
            console.log('Pdf downloaded!')
        }
    }

    return (
        <Box>
            <Stack mb={1} direction="row" alignItems="center" justifyContent='start' gap="100px">
                <Typography variant="h7_1">Application</Typography>
            </Stack>
            <Divider />
            <Stack mt={2} direction="column" flexWrap='wrap' gap="20px" sx={{ border: approoval === 'rejected' ? ".5px solid red" : ".5px solid #737373", borderRadius: "8px", padding: "20px 30px", width : '100%' }}>
                <Stack direction="row" flexWrap='wrap' justifyContent="space-between" alignItems="center" width='100%'>
                    <Stack direction="row" flexWrap='wrap' width='100%' display='flex' justifyContent={{xs : 'space-between', sm : 'space-between', md : 'space-between', lg : 'space-between', xl : 'space-between'}} alignItems="center" gap='20px'>
                        
                            <Stack sx={{display : 'flex', flexDirection : 'row', alignItems : 'center', gap : {xs : '10px', lg : '20px'}}}>
                            <ContractIcon />
                            <Typography variant="h7_2">Vinga Hall <br /> Application Status</Typography>
                            </Stack>
                        <Box sx={{display : 'flex',justifyContent : 'space-between', gap: '30px'}}>
                            
                            
                            <Stack>
                            {approoval === 'pending' && (
                                <Stack mt={1} direction="row" justifyContent="center" alignItems="center" gap="8px" sx={{ width: "120px", height: "50px", border: "1px solid #C38236", backgroundColor: "rgba(195, 130, 54, 0.1)", borderRadius: "30px", padding: "10px", color: "#C38236" }}>
                                <Typography sx={{ fontWeight: 700 }} variant="h9_5">Pending <br /> Approval</Typography>
                                <PendingIcon />
                            </Stack>
                            )}
                            
                            {approoval === 'success' && (
                                <Stack mt={1} direction="row" justifyContent="center" alignItems="center" gap="8px" sx={{ width: "120px", height: "30px", border: "1px solid #52AD33", backgroundColor: "#eef7eb", borderRadius: "30px", padding: "10px", color: "#52AD33" }}>
                                <Typography sx={{ fontWeight: 700 }} variant="h9_5">Approved</Typography>
                                <ApprovedIcon />
                            </Stack>
                            )}
                            
                            
                            {approoval === 'rejected' && (
                                <Stack mt={1} direction="row" justifyContent="center" alignItems="center" gap="8px" sx={{ width: "120px", height: "30px", border: "1px solid rgba(200, 25, 25, 1)", backgroundColor: "rgba(200, 25, 25, 0.1)", borderRadius: "30px", padding: "10px", color: "rgba(200, 25, 25, 1)" }}>
                                <Typography sx={{ fontWeight: 700 }} variant="h9_5">Rejected</Typography>
                                <RejectedIcon />
                            </Stack>
                            )}
                            </Stack>

                            <Stack>
                                {(approoval === 'success' && signed === 'unsigned') && (
                                    <Button onClick={() => navigate('/hostelApplication')} endIcon={<RightArrowIcon />} variant='contained' sx={{ fontSize: "16px", fontWeight: 700 }}>Read & Sign</Button>
                                )}
                                {(approoval === 'success' && signed === 'signed') && (
                                    <Button endIcon={<RightArrowIcon />} disabled variant='contained' sx={{ fontSize: "16px", fontWeight: 700, backgroundColor: "#99caeb",
                                    '&.Mui-disabled': {
                                        backgroundColor: '#99caeb',
                                        color: '#FFFFFF',
                                      }, }}>Signed</Button>
                                )}
                            </Stack>
                        </Box>
                    </Stack>

                    {/*Status Pending */}

                    {/* <Stack direction="row" alignItems="center" gap="8px" sx={{ border: "1px solid #C38236", backgroundColor: "#f9f3eb", borderRadius: "30px", padding: "10px 20px", color: "#C38236" }}>
                        <Typography sx={{ fontWeight: 700 }} variant="h9_5">Pending  <br /> Approval</Typography>
                        <SandClockIcon />
                    </Stack> */}

                    {/*Status Rejected */}

                    {/* <Stack direction="row" alignItems="center" gap="8px" sx={{ border: "1px solid #C81919", backgroundColor: "#fae8e8", borderRadius: "30px", padding: "10px 20px", color: "#C81919" }}>
                        <Typography sx={{ fontWeight: 700 }} variant="h9_5">Rejected</Typography>
                        <RejectedIcon />
                    </Stack> */}

                    {/*Status Approved */}

                    {/* <Button endIcon={<RightArrowIcon />} variant='contained' sx={{ fontSize: "16px", fontWeight: 700 }}>Read & Sign</Button> */}

                    {/*Status Signed */}

                    {/* <Button endIcon={<RightArrowIcon />} variant='contained' sx={{ fontSize: "16px", fontWeight: 700, backgroundColor: "#99caeb" }}>Signed</Button> */}

                </Stack>
                <Typography color="#003893" variant="h7_1">Applied for</Typography>
                <Stack direction={{xs : "column",sm : 'column', md : 'column', lg: 'row', xl : 'row'}} justifyContent="start" gap="20px">
                    <Stack direction={{xs : "column",sm : 'column', md : 'column', lg: 'row', xl : 'row'}} justifyContent="space-between" gap="7px" sx={{ width: "fit-content", padding: "20px", borderRadius: "20px", border: ".5px solid #737373" }}>
                        <Box component="img" sx={{ width: { sm: 128, xs: "100%" }, height: { sm: 128, xs: 'auto' }, objectFit: "cover", border: ".5px solid #1A2461", borderRadius: "8px" }} src="https://images.pexels.com/photos/271624/pexels-photo-271624.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"></Box>
                        <Stack xs={12} direction="column" gap="7px">
                            <Typography variant="h7_1" color="#007ECA">{allData?.RoomsData?.selectedRoom?.HostelName}</Typography>
                            <Typography variant="h9_4" color="#003893">{allData?.RoomsData?.selectedRoom?.room_type_name}</Typography>
                            {/* <Typography variant="h9_4" color="#003893">With window</Typography> */}
                        </Stack>
                    </Stack >
                    <Stack direction="column" gap="7px" sx={{ width: {xs : '100%', md : 'fit-content'}, height: "fit-content", padding: "20px", borderRadius: "20px", border: ".5px solid #737373" }}>
                        <Stack direction="row" alignItems="center" gap="10px">
                            <PuzzlePiecesIcon />
                            <Typography variant="h9_1" color="#003893">Services</Typography>
                        </Stack>
                        {services?.filter((service) => allData?.ServicesValue?.landingServices.includes(service._id)).map((serve) => (
                            <Stack direction="row" alignItems="center" gap="10px">
                            <TickIcon fill="#1A2461" />
                            <Typography variant="h9_4" color="#1A2461">{serve.title}</Typography>
                        </Stack>
                        ))}
                        
                        {/* <Stack direction="row" alignItems="center" gap="10px">
                            <TickIcon fill="#1A2461" />
                            <Typography variant="h9_4" color="#1A2461">Counselling</Typography>
                        </Stack> */}
                    </Stack>

                    <Stack direction="column" gap="7px" sx={{ width: {xs : '100%', md : 'fit-content'}, height: "fit-content", padding: "20px", borderRadius: "20px", border: ".5px solid #737373" }}>
                        <Stack direction="row" alignItems="center" gap="10px">
                            <PuzzlePiecesIcon />
                            <Typography variant="h9_1" color="#003893">Other Chargeable Services</Typography>
                        </Stack>
                        {otherServices?.filter((service) => allData?.ServicesValue?.otherServices.includes(service._id)).map((service) => (
                            <Stack direction="row" alignItems="center" gap="10px">
                            <TickIcon fill="#1A2461" />
                            <Typography variant="h9_4" color="#1A2461">{service.title}</Typography>
                        </Stack>
                        ))}
                        
                        {/* <Stack direction="row" alignItems="center" gap="10px">
                            <TickIcon fill="#1A2461" />
                            <Typography variant="h9_4" color="#1A2461">Counselling</Typography>
                        </Stack> */}
                    </Stack>
                </Stack>
            </Stack>
            
            {(((approoval === 'success' || approoval === 'rejected') && signed === 'signed') || approoval === 'rejected') && (<>
            <Stack mt={4} direction="row" justifyContent="space-between" alignItems="center" sx={{ border: ".5px solid #1A2461", padding: {xs : '15px' , lg:'23px'}, borderRadius: "8px" }}>
                <Stack direction="column" gap="10px">
                    <Typography variant='h9_1'>{approoval === 'rejected' ? 'Reapply for Vinga Hall' : 'Download Contract'}</Typography>
                    <Typography variant='h9_2'>{approoval === 'rejected' ? 'Application rejected? Maybe a mistake' : 'Download the approved contract as PDF'}</Typography>
                </Stack>
                <Button onClick={handleApplyContract} endIcon={approoval === 'rejected' ? <RightArrowIcon fill="#FFFFFF"/> : <DownloadIcon fill="#FFFFFF" />} variant='contained' sx={{ fontSize: "16px", fontWeight: {xs :500, lg :700, }}}>{approoval === 'rejected' ? 'Apply' : 'Contract'}</Button>
            </Stack>

            <Stack mt={4} direction="row" justifyContent="space-between" alignItems="center" sx={{ border: ".5px solid #1A2461", padding: {xs : '15px' , lg:'23px'}, borderRadius: "8px" }}>
                <Stack direction="column" gap="10px">
                    <Typography variant='h9_1'>{approoval === 'rejected' ? 'Contact Us' : 'Download Invoice'}</Typography>
                    <Typography variant='h9_2'>{approoval === 'rejected' ? 'Application keeps getting rejected? Contact us to know the reason' : 'Download the invoice as PDF'}</Typography>
                </Stack>
                <Stack direction="row" gap="10px">
                    <Button onClick={handleContactInvoice} endIcon={approoval === 'rejected' ? <CallIcon fill="#FFFFFF"/> :<DownloadIcon fill="#FFFFFF" />} variant='contained' sx={{ fontSize: "16px", fontWeight: 700, }}>{approoval === 'rejected' ? 'Contact' : 'Invoice'}</Button>
                    {approoval === 'rejected' ? null : <Button startIcon={<ShareIcon fill="#FFFFFF" />} variant='contained' sx={{ fontSize: "16px", fontWeight: 700, width: "fit-content", padding: "6px 7px 6px 15px", minWidth: "10px" }}></Button>}
                </Stack>
            </Stack>
            </>)}
        </Box >
    )
}
