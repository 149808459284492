import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Divider,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Stack,
  IconButton,
  TablePagination
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";
import moment from "moment/moment";
import { ReactComponent as DeleteIcon } from "../../../Icons/DeleteTable.svg";
import { ReactComponent as EditIcon } from "../../../Icons/EditIcon.svg";
import AlertModal from "../../../CommonComponents/AlertModal";
import LinearProgress from '@mui/material/LinearProgress';
import CachedIcon from '@mui/icons-material/Cached';

export default function AnnouncementList() {

  const [page, setPage] = useState(0); // Initialize to 0 for zero-based indexing
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page
  const [totalItems, setTotalItems] = useState(0); // State to hold the total count of items
  const [loading, setLoading] = useState(false);


  let deleteData = {
    label: "Confirm Delete Announcement ",
    descriptionOne: "Please confirm that you wish to delete the Announcement.",
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#912018",
      text: "Confirm",
    },
  };

  const navigate = useNavigate();

  const [announcementData, setAnnouncementData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const getAllAnnouncement = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}getAllAnnouncement?page=${
          page + 1
        }&limit=${rowsPerPage}`
      );
      console.log(response?.data?.data);
      setTotalItems(response.data.pagination.totalItems || 0)
      setAnnouncementData(response?.data?.data || []);
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page change
  };

  const handleClose = () => {
    setDeleteModal(null);
    setDeleteId(null);
  };

  const deleteAnnouncement = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}announcement/${deleteId}`
      );
      getAllAnnouncement();
      setDeleteModal(null);
      setDeleteId(null);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllAnnouncement();
  }, [page, rowsPerPage]);


  return (
    <>
      {deleteModal && (
        <AlertModal
          modalDatas={deleteData}
          confirmFunction={deleteAnnouncement}
          closeFunction={handleClose}
        />
      )}
      <Stack direction="row" justifyContent="space-between" alignItems='center'>
        <Typography variant="h7_1">Announcement</Typography>
        <Box sx={{display : 'flex', gap : 3, alignItems : 'center'}}>
        <Typography variant="h7_1" sx={{cursor : 'pointer'}} title='Reload' onClick={() => getAllAnnouncement()}><CachedIcon /></Typography>
        <Button
          onClick={() => navigate("AnnouncementAdd")}
          variant="contained"
          sx={{
            padding: "5px 20px",
            borderRadius: "8px",
            fontSize: "16px",
            fontWeight: 500,
          }}
        >
          Add
        </Button>
        </Box>
      </Stack>
      {/* <Divider sx={{ mb: 3, mt: 2 }} /> */}
      <TableContainer sx={{
        mt : 3.5,
          borderRadius: "5px",
          border: "1px solid #e5e5e5",
          boxShadow: "0px 0px 30px 20px #f4f4f4",
        }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                <Typography variant="h9_1" color="#333333">
                  Title
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                <Typography variant="h9_1" color="#333333">
                  Description
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                <Typography variant="h9_1" color="#333333">
                  Date
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                <Typography variant="h9_1" color="#333333">
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {loading && (
              <TableRow>
                <TableCell colSpan={5} sx={{p :0, borderBottom : '0px'}}>
              <LinearProgress color="primary" />
              </TableCell>
              </TableRow>
            )}
            {announcementData.length == 0 ? (<>
              <TableRow>
              <TableCell colSpan={5} sx={{borderBottom: "0.5px solid #dcdddc", py: 2.7}}>
            {!loading && (
            <Typography>No data found</Typography>
            )}
            </TableCell>
            </TableRow>
            </>) : (
            announcementData.map((item, index) => (
              <TableRow key={item._id} sx={{
                border: "0px",
                backgroundColor: index % 2 === 0 ? "#f8f8f8" : "white", 
              }}>
                <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                  <Typography variant="h9_4" color="#333333">
                    {item?.title || "-"}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                  <Typography variant="h9_4" color="#333333">
                    {item?.subtitle || "_"}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                  <Typography variant="h9_4" color="#333333">
                    {item?.date || "-"}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                  <IconButton
                    onClick={() =>
                      navigate(`/Admin/edit-announcement/${item._id}`)
                    }
                    sx={{ marginRight: "10px" }}
                  >
                    <EditIcon fill="black" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setDeleteId(item._id);
                      setDeleteModal(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>
        
        <TablePagination
          component="div"
          count={totalItems} 
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </TableContainer>
    </>
  );
}
