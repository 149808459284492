// import React, { useEffect, useState } from "react";
// import { MapContainer, TileLayer } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";
// import "leaflet-routing-machine";
// import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
// import './Map.css';
// import startIconn from "../../Icons/start.svg"
// import endIconn from "../../Icons/end.svg"

// // Fix default marker icons issue with Leaflet
// import icon from "leaflet/dist/images/marker-icon.png";
// import iconShadow from "leaflet/dist/images/marker-shadow.png";

// let DefaultIcon = L.icon({
//   iconUrl: icon,
//   shadowUrl: iconShadow,
// });

// // Custom start and end icons
// const startIcon = L.icon({
//   iconUrl: startIconn,
//   iconSize: [30, 41],
//   iconAnchor: [12, 41],
//   popupAnchor: [1, -34],
//   shadowUrl: iconShadow,
//   shadowSize: [41, 41],
// });

// const endIcon = L.icon({
//   iconUrl: endIconn,
//   iconSize: [40, 41],
//   iconAnchor: [12, 41],
//   popupAnchor: [1, -34],
//   shadowUrl: iconShadow,
//   shadowSize: [41, 41],
// });


// L.Marker.prototype.options.icon = DefaultIcon;

// const MapWithDirections = ({ hostelData, start, end }) => {
//   const [endLocation, setEndLocation] = useState({
//     latitude: hostelData?.Location?.latitude || 1.31457131200707,
//     longitude: hostelData?.Location?.longitude || 103.853740530139,
//   });


//   useEffect(() => {
//     if (
//       hostelData &&
//       hostelData.Location &&
//       hostelData.Location.latitude &&
//       hostelData.Location.longitude
//     ) {
//       setEndLocation({
//         latitude: hostelData.Location.latitude,
//         longitude: hostelData.Location.longitude,
//       });
//     }
//   }, [hostelData]);

//   useEffect(() => {
//     // Initialize the map\
//     if(endLocation.latitude && endLocation.longitude && start && end){
//     const map = L.map("map").setView([start, end], 10); // Set zoom to 13

//     // Add the TileLayer (OpenStreetMap in this case)
//     L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
//       attribution:
//         '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
//     }).addTo(map);

//     // Initialize routing control and store the reference
//     const routingControl = L.Routing.control({
//       waypoints: [
//         L.latLng(start ? start : endLocation?.latitude, end ? end : endLocation?.longitude),
//         L.latLng(endLocation?.latitude, endLocation?.longitude),
//       ],
//       routeWhileDragging: false,
//       showAlternatives: false,
//       lineOptions: {
//         styles: [{ color: "blue", weight: 5 }],
//       },
//       createMarker: (i, waypoint, n) => {
//         const icon = i === 0 ? startIcon : endIcon; // Use startIcon for the first point and endIcon for the second point
//         return L.marker(waypoint.latLng, { icon });
//       },
//     }).addTo(map);

//     // Cleanup function
//     return () => {
//       map.remove(); // Remove the map
//       // if (routingControl) {
//       //   map.removeControl(routingControl); // Remove the routing control
//       // }
//     };
//   }
//   }, [endLocation.latitude, endLocation.longitude, start, end]);

//   return (
    
//     <div id="map" style={{ height: "500px", width: "100%" }}></div>
//   );
// };

// export default MapWithDirections;

import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import './Map.css';
import startIconn from "../../Icons/start.svg"
import endIconn from "../../Icons/end.svg"

// Fix default marker icons issue with Leaflet
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

// Custom start and end icons
const startIcon = L.icon({
  iconUrl: endIconn,
  iconSize: [40, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: iconShadow,
  shadowSize: [41, 41],
});

const endIcon = L.icon({
  iconUrl: startIconn,
  iconSize: [30, 41],
  iconAnchor: [25, 10],
  popupAnchor: [1, -34],
  shadowUrl: iconShadow,
  shadowSize: [41, 41],
});


L.Marker.prototype.options.icon = DefaultIcon;

const MapWithDirections = ({ hostelData, start, end }) => {
  const [endLocation, setEndLocation] = useState({
    latitude: hostelData?.Location?.latitude || 1.31457131200707,
    longitude: hostelData?.Location?.longitude || 103.853740530139,
  });

  const [mapInstance, setMapInstance] = useState(null);

  useEffect(() => {
    if (
      hostelData &&
      hostelData.Location &&
      hostelData.Location.latitude &&
      hostelData.Location.longitude
    ) {
      setEndLocation({
        latitude: hostelData.Location.latitude,
        longitude: hostelData.Location.longitude,
      });
    }
  }, [hostelData]);

  useEffect(() => {
    // Only initialize the map once
    if (!mapInstance) {
      const map = L.map("map").setView([start || endLocation.latitude, end || endLocation.longitude], 0); // Default zoom 13

      // Add the TileLayer (OpenStreetMap)
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);

      // Store map instance
      setMapInstance(map);
    }
  }, [mapInstance, start, end, endLocation]);

  useEffect(() => {
    if (mapInstance && endLocation.latitude && endLocation.longitude && start && end) {
      // Remove the previous routing control if it exists
      let routingControl = null;

      routingControl = L.Routing.control({
        waypoints: [
          L.latLng(start, end),
          L.latLng(endLocation.latitude, endLocation.longitude),
        ],
        routeWhileDragging: false,
        showAlternatives: false,
        lineOptions: {
          styles: [{ color: "blue", weight: 5 }],
        },
        createMarker: (i, waypoint) => {
          const icon = i === 0 ? startIcon : endIcon; // Use startIcon for the first point, endIcon for the second
          return L.marker(waypoint.latLng, { icon });
        },
      }).addTo(mapInstance);

      // Cleanup routing control when the component is unmounted
      return () => {
        if (routingControl) {
          mapInstance.removeControl(routingControl);
        }
      };
    }
  }, [mapInstance, start, end, endLocation]);

  return (
    <div id="map" style={{ height: "500px", width: "100%" }}></div>
  );
};

export default MapWithDirections;