import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  Grid,
  Divider,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Checkbox,
  TextField,
  IconButton,
  Card,
  CardContent,
  useMediaQuery
} from "@mui/material";
import { ReactComponent as EditIcon } from "../../../../Icons/EditIcon.svg";
import moment from "moment";
import { useSelector } from "react-redux";
import { ReactComponent as FormIcon } from "../../../../Icons/CommonFormIcon.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";

export default function Review({
  setStepperCount,
  ServicesValue,
  PersonalData,
  RoomsData,
  trigger,
  setPersonalData,
  checked,
  setChecked,
  email
}) {
  // const initial_Emergency_contact = { Emergency_name: "", Emergency_contact_no: "", Emergency_email: "", Emergency_whatsapp: "", Emergency_wechat: "", Emergency_line: "", Emergency_kakaotalk: "" }
  // const [Emergency_contact, setEmergency_contact] = useState([initial_Emergency_contact])
  // const [Details, setDetails] = useState({ name: "", dob: "", nationality: "", gender: "", passport_no: "", school: "", contact_no: "", wechat: "", whatsapp: "", line: "", kakaotalk: "", note: "", ipaFile: "" })
  const { ServicesData, OtherServiceData } = useSelector((state) => state.CommonData);
  const isMobile = useMediaQuery('(max-width : 600px)')
  const { Details, Emergency_contact } = PersonalData;
  console.log(Details.agreement, "personal data");
  console.log(ServicesData, "service data");
  console.log(OtherServiceData, "other service data");

  const {
    otherServices,
    fligt_no,
    arrival_date,
    arrival_time,
    landingServices,
  } = ServicesValue;

  // console.log(ServicesData);

  // useEffect(() => {
  //   if (trigger) {
  //     setStepperCount(5);
  //   }
  // }, [trigger]);

  useEffect(() => {
    window.scrollTo({
      top : 0,
      left : 0,
      behavior : 'smooth'
    })
  }, [])

  // const [checked, setChecked] = useState(false);

  // useEffect(() => {
  //   setChecked(Details?.agreement)
  // },[])

  useEffect(() => {
    setPersonalData((prevState) => ({
      ...prevState,
      Details: {
        ...prevState.Details,
        agreement: checked,
      },
    }));
  }, [checked]);

  const checkBoxChange = (e) => {
    setChecked(e.target.checked);
  };

  const handleSubmit = async () => {
    const formData = new FormData()
    formData.append("email", email)
    formData.append("agreement", checked)
  
    await axios.post(`${process.env.REACT_APP_API_URL}add-application-step-data`, formData)
    .then((response) => console.log(response))
    .catch((error) => console.log(error))
  }

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card
          sx={{ width: "90%", border: "0px solid #E5E7EB", boxShadow: "none" }}
        >
          <CardContent style={{ padding: 0, margin: 0 }}>
            <Box
              sx={{
                color: "black",
                margin: "10px 40px 10px 0px",
                cursor: "pointer",
                "&:hover": {
                  color: "black",
                },
              }}
            >
              <ArrowBackIcon
                sx={{
                  border: "1px solid grey",
                  padding: "2px 3px",
                  fontSize: "2rem",
                  borderRadius: "3px",
                }}
                onClick={() => {
                  setStepperCount(3);
                }}
              />
            </Box>
            <Typography
              style={{
                fontSize: "1.7rem",
                fontWeight: 700,
                margin: "30px 0px 40px 0px",
              }}
            >
              Applicant Information
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card
          sx={{
            width: "90%",
            border: "1px solid #E5E7EB",
            borderRadius: "6px",
            mb: "30px",
          }}
        >
          <CardContent style={{ padding: 0, margin: 0 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#FCFCFD",
                padding: 2,
                border: "1px solid #E5E7EB",
                borderRadius: "6px 6px 0px 0px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <FormIcon style={{ marginRight: "10px" }} />
                <Typography variant="h6_1">Services</Typography>
              </Box>
              <Button
                onClick={() => {
                  setStepperCount(3);
                }}
                startIcon={<EditIcon fill="#FFFFFF" />}
                sx={{
                  height: "40px",
                  width: "100px",
                  fontSize: "16px",
                  fontWeight: 500,
                }}
                variant="standard"
              >
                Edit
              </Button>
            </Box>

            <Box style={{ margin: isMobile ? "5px":"10px" }}>
              <Typography
                variant="h7_3"
                sx={{ fontWeight: 700, margin: "0px 0px 0px 15px" }}
              >
                Flight Details
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: isMobile ? "10px 20px 10px 20px" : "20px 50px 20px 50px",
                gap : '10px',
                flexWrap : 'wrap'
              }}
            >
              <Box>
                <InputLabel sx={{ marginBottom: "10px" }} variant="standard">
                  Flight Number
                </InputLabel>
                <Typography variant="h7_1" color="text_blue">
                  {ServicesValue?.fligt_no}
                </Typography>
              </Box>

              <Box sx={{width : '30%'}}>
                <Typography variant="h7_2">
                  Landing Services (Optional)
                </Typography>
                <br />
                {ServicesData.map((service, index) => (
                  <>
                  <Box sx={{display : 'flex', gap : '5px'}}>
                  <Typography sx={{color : 'rgba(26, 36, 97, 1)'}}>{service.title} -</Typography>
                  <Typography sx={{color : landingServices?.includes(service._id) ? 'rgba(82, 173, 51, 1)' : 'gray', fontWeight : 700}}>{landingServices?.includes(service._id) ? " Included" : " Not Included"}</Typography>
                  </Box>
                  </>
                ))}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: isMobile ? "10px 20px 10px 20px" : "20px 50px 20px 50px",
                gap : '10px'

              }}
            >
              <Box>
                <InputLabel sx={{ marginBottom: "10px" }} variant="standard">
                  Arrival Date
                </InputLabel>
                <Typography variant="h7_1" color="text_blue">
                  {moment(ServicesValue?.arrival_date?.$d).format(
                    "MMM DD, YYYY"
                  )}
                </Typography>
              </Box>

              <Box sx={{width : '30%'}}>
                <Typography variant="h7_2">
                  Other Chargeable Services (Optional)
                </Typography>
                <br />
                {OtherServiceData.map((service, index) => (
                  <>
                  <Box sx={{display : 'flex', gap : '5px'}}>
                  <Typography sx={{color : 'rgba(26, 36, 97, 1)'}}>{service?.title} -</Typography>
                  <Typography sx={{color : otherServices?.includes(service._id) ? 'rgba(82, 173, 51, 1)' : 'gray', fontWeight : 700}}>{otherServices?.includes(service._id) ? "Included" : "Not Included"}</Typography>
                  </Box>
                  </>
                ))}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: isMobile ? "10px 20px 10px 20px" : "20px 50px 20px 50px",
              }}
            >
              <Box>
                <InputLabel
                  sx={{ marginBottom: "10px" }}
                  mt={3}
                  variant="standard"
                >
                  Arrival Time
                </InputLabel>
                <Typography variant="h7_1" color="text_blue">
                  {moment(ServicesValue?.arrival_time?.$d).format("hh:mm A")}
                </Typography>
              </Box>
            </Box>

            {/* <Stack direction="row" alignItems="center" gap={1}>
                                <Typography variant='h9_3' color="text_blue">Tuition Arrangement  -</Typography>
                                <Typography lineHeight="37px" variant='h9_1' color="neutral_7">Not Included</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Typography variant='h9_3' color="text_blue">English IELTS, SAT Training  -</Typography>
                                <Typography lineHeight="37px" variant='h9_1' color="neutral_7">Not Included</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Typography variant='h9_3' color="text_blue">University Course Training  -</Typography>
                                <Typography lineHeight="37px" variant='h9_1' color="neutral_7">Not Included</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Typography variant='h9_3' color="text_blue">Counselling  -</Typography>
                                <Typography lineHeight="37px" variant='h9_1' color="green">Included</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Typography variant='h9_3' color="text_blue">Guardian Services  -</Typography>
                                <Typography lineHeight="37px" variant='h9_1' color="neutral_7">Not Included</Typography>
                            </Stack> */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#FCFCFD",
                padding: 2,
                border: "1px solid #E5E7EB",
                borderRadius: "6px 6px 0px 0px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <FormIcon style={{ marginRight: "10px" }} />
                <Typography variant="h6_1">Personal Details</Typography>
              </Box>
              <Button
                onClick={() => {
                  setStepperCount(3);
                }}
                startIcon={<EditIcon fill="#FFFFFF" />}
                sx={{
                  height: "40px",
                  width: "100px",
                  fontSize: "16px",
                  fontWeight: 500,
                }}
                variant="standard"
              >
                Edit
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: isMobile ? "20px" : "20px 50px 20px 50px",
              }}
            >
              <Box>
                <InputLabel sx={{ marginBottom: "10px" }} variant="standard">
                  Name
                </InputLabel>
                <Typography variant="h7_1" color="text_blue">
                  {Details?.name}
                </Typography>
              </Box>

              <Box sx={{width : '30%'}}>
                <InputLabel sx={{ marginBottom: "10px" }} variant="standard">
                  Nationality
                </InputLabel>
                <Typography variant="h7_1" color="text_blue">
                  {Details?.nationality}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: isMobile ? "20px" : "20px 50px 20px 50px",
              }}
            >
              <Box>
                <InputLabel sx={{ marginBottom: "10px" }} variant="standard">
                  Passport number
                </InputLabel>
                <Typography variant="h7_1" color="text_blue">
                  {Details?.passport_no}
                </Typography>
              </Box>

              <Box sx={{width : '30%'}}>
                <InputLabel sx={{ marginBottom: "10px" }} variant="standard">
                  Gender
                </InputLabel>
                <Typography variant="h7_1" color="text_blue">
                  {Details?.gender}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: isMobile ? "20px" : "20px 50px 20px 50px",
              }}
            >
              <Box>
                <InputLabel sx={{ marginBottom: "10px" }} variant="standard">
                  Date of Birth
                </InputLabel>
                <Typography variant="h7_1" color="text_blue">
                  {moment(Details?.dob?.$d).format("MMM DD, YYYY")}
                </Typography>
              </Box>

              <Box sx={{width : '30%'}}>
                <InputLabel sx={{ marginBottom: "10px" }} variant="standard">
                  School
                </InputLabel>
                <Typography variant="h7_1" color="text_blue">
                  {Details?.school}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                backgroundColor: "#FCFCFD",
                padding: 2,
                border: "1px solid #E5E7EB",
                borderRadius: "6px 6px 0px 0px",
              }}
            >
              <FormIcon style={{ marginRight: "10px" }} />
              <Typography variant="h6_1">Contact</Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: isMobile ? "20px" : "20px 50px 20px 50px",
              }}
            >
              <Box>
                <InputLabel sx={{ marginBottom: "10px" }} variant="standard">
                  Contact number
                </InputLabel>
                <Typography variant="h7_1" color="text_blue">
                  {Details?.contact_no}
                </Typography>
              </Box>

              <Box sx={{width : '30%'}}>
                <InputLabel sx={{ marginBottom: "10px" }} variant="standard">
                  WeChat
                </InputLabel>
                <Typography variant="h7_1" color="text_blue">
                  {Details?.wechat}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: isMobile ? "20px" : "20px 50px 20px 50px",
              }}
            >
              <Box>
                <InputLabel sx={{ marginBottom: "10px" }} variant="standard">
                  WhatsApp
                </InputLabel>
                <Typography variant="h7_1" color="text_blue">
                  {Details?.whatsapp}
                </Typography>
              </Box>

              <Box sx={{width : '30%'}}>
                <InputLabel sx={{ marginBottom: "10px" }} variant="standard">
                  Line
                </InputLabel>
                <Typography variant="h7_1" color="text_blue">
                  {Details?.line}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: isMobile ? "15px" : "20px 50px 20px 50px",
              }}
            >
              <Box>
                <InputLabel sx={{ marginBottom: "10px" }} variant="standard">
                  KakaoTaalk
                </InputLabel>
                <Typography variant="h7_1" color="text_blue">
                  {Details?.kakaotalk}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#FCFCFD",
                padding: 2,
                border: "1px solid #E5E7EB",
                borderRadius: "6px 6px 0px 0px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <FormIcon style={{ marginRight: "10px" }} />
                <Typography variant="h6_1">Emergency Contact</Typography>
              </Box>
              <Button
                onClick={() => {
                  setStepperCount(3);
                }}
                startIcon={<EditIcon fill="#FFFFFF" />}
                sx={{
                  height: "40px",
                  width: "100px",
                  fontSize: "16px",
                  fontWeight: 500,
                }}
                variant="standard"
              >
                Edit
              </Button>
            </Box>

            {Emergency_contact?.map((item, index) => (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px",
                  }}
                >
                  <Card sx={{ width: "95%", border: "1px solid #E5E7EB" }}>
                    <CardContent sx={{ padding: 3, marging: 0 }}>
                      <Typography variant="h7_3">
                        Contact {index + 1}
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          m: isMobile ? 0 : 3,
                          flexDirection : isMobile ? "column" : "row",
                          gap : 3,
                          mt : isMobile ? 2 : 0 
                        }}
                      >
                        <Box display={"flex"} sx={{flexDirection:"column",justifyContent:"flex-start"}}>
                          <InputLabel
                            sx={{ marginBottom: "10px" }}
                            variant="standard"
                          >
                            Contact name
                          </InputLabel>
                          <Typography variant="h7_1" color="text_blue">
                            {item?.Emergency_name}
                          </Typography>
                        </Box>
                        <Box sx={{width : isMobile ? "100%" : '28%'}}>
                          <InputLabel
                            sx={{ marginBottom: "10px" }}
                            variant="standard"
                          >
                            Contact mobile number
                          </InputLabel>
                          <Typography variant="h7_1" color="text_blue">
                            {item?.Emergency_contact_no}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          m: isMobile ? 0 : 3,
                          flexDirection : isMobile ? "column" : "row",
                          gap : 3,
                          mt : isMobile ? 2 : 0 
                        }}
                      >
                        <Box>
                          <InputLabel
                            sx={{ marginBottom: "10px" }}
                            variant="standard"
                          >
                            Email
                          </InputLabel>
                          <Typography variant="h7_1" color="text_blue">
                            {item?.Emergency_email}
                          </Typography>
                        </Box>

                        <Box sx={{width : isMobile ? "100%" : '28%'}}>
                          <InputLabel
                            sx={{ marginBottom: "10px" }}
                            variant="standard"
                          >
                            WhatsApp
                          </InputLabel>
                          <Typography variant="h7_1" color="text_blue">
                            {item?.Emergency_whatsapp}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          m: isMobile ? 0 : 3,
                          flexDirection : isMobile ? "column" : "row",
                          gap : 3,
                          mt : isMobile ? 2 : 0 
                        }}
                      >
                        <Box>
                          <InputLabel
                            sx={{ marginBottom: "10px" }}
                            variant="standard"
                          >
                            WeChat
                          </InputLabel>
                          <Typography variant="h7_1" color="text_blue">
                            {item?.Emergency_wechat}
                          </Typography>
                        </Box>

                        <Box sx={{width : isMobile ? "100%" :'28%'}}>
                          <InputLabel
                            sx={{ marginBottom: "10px" }}
                            variant="standard"
                          >
                            LINE
                          </InputLabel>
                          <Typography variant="h7_1" color="text_blue">
                            {item?.Emergency_line}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          m: isMobile ? 0 : 3,
                          flexDirection : isMobile ? "column" : "row",
                          gap : 3,
                          mt : isMobile ? 2 : 0 
                        }}
                      >
                        <Box>
                          <InputLabel
                            sx={{ marginBottom: "10px" }}
                            variant="standard"
                          >
                            KakaoTalk
                          </InputLabel>
                          <Typography variant="h7_1" color="text_blue">
                            {item?.Emergency_kakaotalk}
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </>
            ))}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#FCFCFD",
                padding: 2,
                border: "1px solid #E5E7EB",
                borderRadius: "6px 6px 0px 0px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <FormIcon style={{ marginRight: "10px" }} />
                <Typography variant="h6_1" color="#AD3333">
                  NOTE
                </Typography>
              </Box>
              <Button
                onClick={() => {
                  setStepperCount(3);
                }}
                startIcon={<EditIcon fill="#FFFFFF" />}
                sx={{
                  height: "40px",
                  width: "100px",
                  fontSize: "16px",
                  fontWeight: 500,
                }}
                variant="standard"
              >
                Edit
              </Button>
            </Box>

            <Box sx={{ m: 3 }}>
              <Typography>{Details?.note}</Typography>
            </Box>

            <Divider />
            <Stack
              margin="10px"
              gap="10px"
              direction="row"
              alignItems="center"
              justifyContent="start"
            >
              <Checkbox checked={checked} onChange={checkBoxChange} />
              <Typography variant="h9_1" color="text_blue">
                I agree all the provided Information are correct
              </Typography>
            </Stack>

            <Stack
              sx={{
                border: "1px solid #e5e5e5",
                padding: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
              direction="row"
              alignItems="center"
              justifyContent="start"
            >
              <Button
                disabled={!checked}
                variant="standard"
                onClick={() => {
                  setStepperCount(5);
                  handleSubmit()
                  // HandleSubmitSteps()
                }}
              >
                Continue
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
