import React from 'react'
import { Box, IconButton, Typography, Grid, Stack, TextField, InputLabel, Button } from "@mui/material"
import { useForm, Controller } from 'react-hook-form'

export default function ContactUs() {
    const { control, formState: { errors }, handleSubmit } = useForm({
        mode: "onTouched"
    })

    return (
        <Box>
            <Grid container columnGap={7} px={{ md: 7, xs: 4 }} py={3} justifyContent="center">
                <Grid xs={12} pb={3} borderBottom='.5px solid #737373'>
                    <Typography variant="h6_1">Contact US</Typography>
                </Grid>
                <Grid xs={12} pb={3} mt={3} textAlign="center">
                    <Typography variant="h6_1">Send us your queries & we will definitely get back</Typography>
                </Grid>
                <Grid container xs={12} sm={5.5} alignItems="center">
                    <Box>
                        <Box mb={1.3} sx={{ padding: "10px", borderRadius: "100px", border: ".5px solid #737373", backgroundColor: "#f1f1f1", width: "fit-content" }}>
                            <Typography variant="h6_1">VINGA INTERNATIONAL</Typography>
                        </Box>
                        <Typography variant='h9_4' color="#737373">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam enim nunc, malesuada eu eleifend ut, porttitor vel enim. Praesent consequat velit at feugiat tincidunt. Aliquam mauris diam, sollicitudin et risus eu, feugiat interdum neque. Integer eget fermentum dolor. Sed non ullamcorper ex. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Curabitur metus ligula, gravida eget augue ac, congue malesuada nulla.</Typography>
                    </Box>
                </Grid>
                <Grid xs={12} sm={5.5}>
                    <form onSubmit={handleSubmit()}>
                        <InputLabel sx={{ mt: 3, mb: 1 }} >Name</InputLabel>
                        <Controller
                            name="name"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => <TextField error={Boolean(errors?.name) ? true : false} {...field} />}
                        />
                        <InputLabel sx={{ mt: 3, mb: 1 }} >Email</InputLabel>
                        <Controller
                            name="email"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => <TextField error={Boolean(errors?.email) ? true : false} {...field} />}
                        />
                        <InputLabel sx={{ mt: 3, mb: 1 }} >Mobile Number</InputLabel>
                        <Controller
                            name="mobile_no"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => <TextField error={Boolean(errors?.mobile_no) ? true : false} {...field} />}
                        />
                        <InputLabel sx={{ mt: 3, mb: 1 }} >Message/Query</InputLabel>
                        <Controller
                            name="message"
                            control={control}
                            render={({ field }) => <TextField error={Boolean(errors?.message) ? true : false} {...field} />}
                        />
                        <Button type="submit" sx={{ mt: 5 }} variant="standard"> Send Message</Button>
                    </form>
                </Grid>
            </Grid>
        </Box >
    )
}
