import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Divider,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Stack,
  TablePagination
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";
import moment from "moment";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import LinearProgress from '@mui/material/LinearProgress';
import CachedIcon from '@mui/icons-material/Cached';



export default function Application() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);

  const [pageUnpaid, setPageUnpaid] = useState(0);
  const [rowsPerPageUnpaid, setRowsPerPageUnpaid] = useState(5);
  const [totalItemsUnpaid, setTotalItemsUnpaid] = useState(0);

  const [pagePaid, setPagePaid] = useState(0);
  const [rowsPerPagePaid, setRowsPerPagePaid] = useState(5);
  const [totalItemsPaid, setTotalItemsPaid] = useState(0);

  const [pageRejected, setPageRejected] = useState(0);
  const [rowsPerPageRejected, setRowsPerPageRejected] = useState(5);
  const [totalItemsRejected, setTotalItemsRejected] = useState(0);

  const [pendingData, setPendingData] = useState([]);
  const [unpaidData, setUnpaidData] = useState([]);
  const [paidData, setPaidData] = useState([]);
  const [rejectedData, setRejectedData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingUnpaid, setLoadingUnpaid] = useState(false);
  const [loadingPaid, setLoadingPaid] = useState(false);
  const [loadingRejected, setLoadingRejected] = useState(false);

  const getPendingApplication = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}get-pending-application?page=${
          page + 1
        }&limit=${rowsPerPage}`
      );
      setTotalItems(response.data.pagination.totalItems || 0);
      setPendingData(response?.data?.data || []);
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };

  const getUnpaidApplication = async () => {
    setLoadingUnpaid(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}get-unpaid-roomrent-application?page=${
          pageUnpaid + 1
        }&limit=${rowsPerPageUnpaid}`
      );
      setTotalItemsUnpaid(response.data.pagination.totalItems || 0);
      setUnpaidData(response?.data?.data || []);
      setLoadingUnpaid(false)
    } catch (error) {
      console.log(error);
      setLoadingUnpaid(false)
    }
  };

  const getPaidApplication = async () => {
    setLoadingPaid(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}get-paid-roomrent-application?page=${
          pagePaid + 1
        }&limit=${rowsPerPagePaid}`
      );
      setTotalItemsPaid(response.data.pagination.totalItems || 0);
      setPaidData(response?.data?.data || []);
      setLoadingPaid(false)
    } catch (error) {
      console.log(error);
      setLoadingPaid(false)
    }
  };

  const getRejectedApplication = async () => {
    setLoadingRejected(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}get-rejected-application?page=${
          pageRejected + 1
        }&limit=${rowsPerPageRejected}`
      );
      setTotalItemsRejected(response.data.pagination.totalItems || 0);
      setRejectedData(response?.data?.data || []);
      setLoadingRejected(false)
    } catch (error) {
      console.log(error);
      setLoadingRejected(false)
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePageUnpaid = (event, newPage) => {
    setPageUnpaid(newPage);
  };

  const handleChangeRowsPerPageUnpaid = (event) => {
    setRowsPerPageUnpaid(parseInt(event.target.value, 10));
    setPageUnpaid(0);
  };

  const handleChangePagePaid = (event, newPage) => {
    setPagePaid(newPage);
  };

  const handleChangeRowsPerPagePaid = (event) => {
    setRowsPerPagePaid(parseInt(event.target.value, 10));
    setPagePaid(0);
  };

  const handleChangePageRejected = (event, newPage) => {
    setPageRejected(newPage);
  };

  const handleChangeRowsPerPageRejected = (event) => {
    setRowsPerPageRejected(parseInt(event.target.value, 10));
    setPageRejected(0);
  };


  const [value, setValue] = useState(() => {
    return localStorage.getItem('tabvalue') || '1';
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    localStorage.setItem('tabvalue', value);
  }, [value]);

  useEffect(() => {
    setLoading(true);
    switch (value) {
      case '1':
        getPendingApplication();
        break;
      case '2':
        getUnpaidApplication();
        break;
      case '3':
        getPaidApplication();
        break;
      case '4':
        getRejectedApplication();
        break;
      default:
        setLoading(false);
    }
  }, [value, page, rowsPerPage, pageUnpaid, rowsPerPageUnpaid, pagePaid, rowsPerPagePaid, pageRejected, rowsPerPageRejected]);


  const tableContainer = { borderRadius: "5px", border: "1px solid #e5e5e5", boxShadow: "0px 0px 30px 20px #f4f4f4"}
  const tableCell = { borderBottom: "0.5px solid #dcdddc", py: 2.7 }
  const tableBodyCell = { minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h7_1">Applications</Typography>
        {/* <Button onClick={() => navigate("AnnouncementAdd")} variant="contained">Add</Button> */}
      </Stack>
      <Divider sx={{ mb: 3, mt: 2 }} />
      <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab sx={{ textTransform: 'none', fontSize : '1.1rem' }} label="Pending" value="1" />
            <Tab sx={{ textTransform: 'none', fontSize : '1.1rem' }} label="Unpaid - Room rent" value="2" />
            <Tab sx={{ textTransform: 'none', fontSize : '1.1rem' }} label="Paid - Room rent" value="3" />
            <Tab sx={{ textTransform: 'none', fontSize : '1.1rem' }} label="Rejected" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{pt : 4, px : 0}}>
        <TableContainer sx={tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={tableCell}>
                <Typography variant="h9_1" color="#333333">
                  Name
                </Typography>
              </TableCell>
              <TableCell sx={tableCell}>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Gender
                </Typography>
              </TableCell>
              <TableCell sx={tableCell}>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Hostel name
                </Typography>
              </TableCell>
              <TableCell sx={tableCell}>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Date & Time
                </Typography>
              </TableCell>
              <TableCell sx={{...tableCell, cursor : 'pointer'}} onClick={() => getPendingApplication()}>
                {" "}
                <Typography variant="h9_1" color="#333333"><CachedIcon /></Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell colSpan={5} sx={{p :0, borderBottom : '0px'}}>
              <LinearProgress color="primary" />
              </TableCell>
              </TableRow>
            )}
          {pendingData.length === 0 ? (
            <TableRow>
              <TableCell colSpan={5} sx={tableBodyCell}>
            <Typography>No data found</Typography>
            </TableCell>
            </TableRow>
          ) : (
            pendingData
              .map((item, index) => (
              <TableRow
                key={item.name}
                sx={{
                  border: "0px",
                  backgroundColor: index % 2 === 0 ? "#f8f8f8" : "white", 
                }}
                onClick={() => navigate(`/Admin/ApplicationView/${item?._id}`)}
              >
                <TableCell sx={tableBodyCell}>
                  <Typography variant="h9_4" color="#333333">
                    {item?.PersonalData?.Details?.name || "-"}
                  </Typography>
                </TableCell>
                <TableCell sx={tableBodyCell}>
                  <Typography variant="h9_4" color="#333333">
                    {item?.PersonalData?.Details?.gender || "-"}
                  </Typography>
                </TableCell>
                <TableCell sx={tableBodyCell}>
                  <Typography variant="h9_4" color="#333333">
                    {item?.RoomsData?.selectedRoom?.HostelName || "_"}
                  </Typography>
                </TableCell>
                <TableCell sx={tableBodyCell}>
                  <Typography variant="h9_4" color="#333333">
                    {moment(item?.createdAt).format("MMM DD YYYY , hh:mm A") || "-"}
                  </Typography>
                </TableCell>
                <TableCell sx={tableBodyCell}>
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate(`/Admin/ApplicationView/${item?._id}`)
                    }
                  >
                    Review
                  </Button>
                </TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>
        
        <TablePagination
          component="div"
          count={totalItems} 
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </TableContainer>
        </TabPanel>
        
        <TabPanel value="2" sx={{pt : 4, px : 0}}>
        <TableContainer sx={tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={tableCell}>
                <Typography variant="h9_1" color="#333333">
                  Name
                </Typography>
              </TableCell>
              <TableCell sx={tableCell}>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Gender
                </Typography>
              </TableCell>
              <TableCell sx={tableCell}>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Hostel name
                </Typography>
              </TableCell>
              <TableCell sx={tableCell}>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Date & Time
                </Typography>
              </TableCell>
              <TableCell sx={{...tableCell, cursor : 'pointer'}} onClick={() => getUnpaidApplication()}>
                {" "}
                <Typography variant="h9_1" color="#333333"><CachedIcon /></Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {loadingUnpaid && (
              <TableRow>
                <TableCell colSpan={5} sx={{p :0, borderBottom : '0px'}}>
              <LinearProgress color="primary" />
              </TableCell>
              </TableRow>
            )}
          {unpaidData.length === 0 ? (<>
          <TableRow>
            <TableCell colSpan={5} sx={tableBodyCell}>
              <Typography>No data found</Typography>
            </TableCell>
          </TableRow>
          </>) : (
            unpaidData.map((item, index) => (
              <TableRow
                key={item.name}
                sx={{
                  border: "0px",
                  backgroundColor: index % 2 === 0 ? "#f8f8f8" : "white", 
                }}
                onClick={() => navigate(`/Admin/ApplicationView/${item?._id}`)}
              >
                <TableCell sx={tableBodyCell}>
                  <Typography variant="h9_4" color="#333333">
                    {item?.PersonalData?.Details?.name || "-"}
                  </Typography>
                </TableCell>
                <TableCell sx={tableBodyCell}>
                  <Typography variant="h9_4" color="#333333">
                    {item?.PersonalData?.Details?.gender || "-"}
                  </Typography>
                </TableCell>
                <TableCell sx={tableBodyCell}>
                  <Typography variant="h9_4" color="#333333">
                    {item?.RoomsData?.selectedRoom?.HostelName || "_"}
                  </Typography>
                </TableCell>
                <TableCell sx={tableBodyCell}>
                  <Typography variant="h9_4" color="#333333">
                    {moment(item?.createdAt).format("MMM DD YYYY , hh:mm A") || "-"}
                  </Typography>
                </TableCell>
                <TableCell sx={tableBodyCell}>
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate(`/Admin/ApplicationView/${item?._id}`)
                    }
                  >
                    Review
                  </Button>
                </TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          count={totalItemsUnpaid} 
          page={pageUnpaid}
          onPageChange={handleChangePageUnpaid}
          rowsPerPage={rowsPerPageUnpaid}
          onRowsPerPageChange={handleChangeRowsPerPageUnpaid}
          rowsPerPageOptions={[5, 10, 25]}
        />

      </TableContainer>
        </TabPanel>
        
        
        <TabPanel value="3" sx={{pt : 4, px : 0}}>
        <TableContainer sx={tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={tableCell}>
                <Typography variant="h9_1" color="#333333">
                  Name
                </Typography>
              </TableCell>
              <TableCell sx={tableCell}>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Gender
                </Typography>
              </TableCell>
              <TableCell sx={tableCell}>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Hostel name
                </Typography>
              </TableCell>
              <TableCell sx={tableCell}>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Date & Time
                </Typography>
              </TableCell>
              <TableCell sx={{...tableCell, cursor : 'pointer'}} onClick={() => getPaidApplication()}>
                {" "}
                <Typography variant="h9_1" color="#333333"><CachedIcon /></Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {loadingPaid && (
              <TableRow>
                <TableCell colSpan={5} sx={{p :0, borderBottom : '0px'}}>
              <LinearProgress color="primary" />
              </TableCell>
              </TableRow>
            )}
          {paidData.length === 0 ? (<>
          <TableRow>
            <TableCell colSpan={5} sx={tableBodyCell}>
              <Typography>No data found</Typography>
            </TableCell>
          </TableRow>
          </>) : (
            paidData.map((item, index) => (
              <TableRow
                key={item.name}
                sx={{
                  border: "0px",
                  backgroundColor: index % 2 === 0 ? "#f8f8f8" : "white", 
                }}
                onClick={() => navigate(`/Admin/ApplicationView/${item?._id}`)}
              >
                <TableCell sx={tableBodyCell}>
                  <Typography variant="h9_4" color="#333333">
                    {item?.PersonalData?.Details?.name || "-"}
                  </Typography>
                </TableCell>
                <TableCell sx={tableBodyCell}>
                  <Typography variant="h9_4" color="#333333">
                    {item?.PersonalData?.Details?.gender || "-"}
                  </Typography>
                </TableCell>
                <TableCell sx={tableBodyCell}>
                  <Typography variant="h9_4" color="#333333">
                    {item?.RoomsData?.selectedRoom?.HostelName || "_"}
                  </Typography>
                </TableCell>
                <TableCell sx={tableBodyCell}>
                  <Typography variant="h9_4" color="#333333">
                    {moment(item?.createdAt).format("MMM DD YYYY , hh:mm A") || "-"}
                  </Typography>
                </TableCell>
                <TableCell sx={tableBodyCell}>
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate(`/Admin/ApplicationView/${item?._id}`)
                    }
                  >
                    view
                  </Button>
                </TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          count={totalItemsPaid} 
          page={pagePaid}
          onPageChange={handleChangePagePaid}
          rowsPerPage={rowsPerPagePaid}
          onRowsPerPageChange={handleChangeRowsPerPagePaid}
          rowsPerPageOptions={[5, 10, 25]}
        />

      </TableContainer>     
        </TabPanel>
        
        <TabPanel value="4" sx={{pt : 4, px : 0}}>
        <TableContainer sx={tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={tableCell}>
                <Typography variant="h9_1" color="#333333">
                  Name
                </Typography>
              </TableCell>
              <TableCell sx={tableCell}>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Gender
                </Typography>
              </TableCell>
              <TableCell sx={tableCell}>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Hostel name
                </Typography>
              </TableCell>
              <TableCell sx={tableCell}>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Date & Time
                </Typography>
              </TableCell>
              <TableCell sx={{...tableCell, cursor : 'pointer'}} onClick={() => getRejectedApplication()}>
                {" "}
                <Typography variant="h9_1" color="#333333"><CachedIcon /></Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {loadingRejected && (
              <TableRow>
                <TableCell colSpan={5} sx={{p :0, borderBottom : '0px'}}>
              <LinearProgress color="primary" />
              </TableCell>
              </TableRow>
            )}
          {rejectedData.length === 0 ?
            (<>
              <TableRow>
                <TableCell colSpan={5} sx={tableBodyCell}>
                  <Typography>No data found</Typography>
                </TableCell>
              </TableRow>
            </>) : (
            rejectedData.map((item, index) => (
              <TableRow
                key={item.name}
                sx={{
                  border: "0px",
                  backgroundColor: index % 2 === 0 ? "#f8f8f8" : "white", 
                }}
                onClick={() => navigate(`/Admin/ApplicationView/${item?._id}`)}
              >
                <TableCell sx={tableBodyCell}>
                  <Typography variant="h9_4" color="#333333">
                    {item?.PersonalData?.Details?.name || "-"}
                  </Typography>
                </TableCell>
                <TableCell sx={tableBodyCell}>
                  <Typography variant="h9_4" color="#333333">
                    {item?.PersonalData?.Details?.gender || "-"}
                  </Typography>
                </TableCell>
                <TableCell sx={tableBodyCell}>
                  <Typography variant="h9_4" color="#333333">
                    {item?.RoomsData?.selectedRoom?.HostelName || "_"}
                  </Typography>
                </TableCell>
                <TableCell sx={tableBodyCell}>
                  <Typography variant="h9_4" color="#333333">
                    {moment(item?.createdAt).format("MMM DD YYYY , hh:mm A") || "-"}
                  </Typography>
                </TableCell>
                <TableCell sx={tableBodyCell}>
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate(`/Admin/ApplicationView/${item?._id}`)
                    }
                  >
                    Review
                  </Button>
                </TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          count={totalItemsRejected} 
          page={pageRejected}
          onPageChange={handleChangePageRejected}
          rowsPerPage={rowsPerPageRejected}
          onRowsPerPageChange={handleChangeRowsPerPageRejected}
          rowsPerPageOptions={[5, 10, 25]}
        />

      </TableContainer>
        </TabPanel>
      </TabContext>
    </Box> 
    </>
  );
}
