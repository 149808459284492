import React, { useState } from 'react'
import { Typography, Grid, Stack, TextField, Button, InputAdornment } from '@mui/material'
import { ReactComponent as MailIcon } from '../Icons/MailIcon.svg';
import { ReactComponent as LeftArrowLgIcon } from '../Icons/LeftArrowLg.svg';
import { emailRegex } from '../Utils';
import { useForm, Controller } from 'react-hook-form';

export default function ForgotPassword({ setDisplayScreen }) {

    const { handleSubmit, formState: { errors }, control } = useForm({
        mode: "onTouched"
    })
    const onSubmit = (data) => {
        console.log(data);
        setDisplayScreen("resetpassword")
    }
    console.log(errors);

    return (
        <Grid xs={12} sm={8} md={5} lg={3.5} p={6} sx={{ border: ".5px solid #1A2461", borderRadius: "20px", height: "auto" }}>
            <form onSubmit={handleSubmit(onSubmit)}>

                <Stack >
                    <Typography onClick={() => setDisplayScreen("login")} sx={{ display: "flex", alignItems: "center", gap: "15px" }} variant="h9_1" color="#003893"><LeftArrowLgIcon />Back to Login</Typography>
                    <Typography mt={4} mb={.5} variant="h4_1">Forgot password </Typography>
                    <Typography variant="h9_4">Enter registered email to send reset link</Typography>
                    <Controller
                        control={control}
                        name="email"
                        rules={{
                            required: "Email is Required",
                            validate: (value) => emailRegex.test(value) == true || "Invalid Email"
                        }}
                        render={({ field }) => <TextField
                            {...field}
                            error={Boolean(errors?.email) ? true : false}
                            sx={{ mt: 4, "& .MuiInputBase-root": { color: '#003893' } }}
                            placeholder="Email"
                            type="text"
                            InputProps={{ startAdornment: <InputAdornment position="start"><MailIcon /></InputAdornment> }}
                        />}
                    />
                    {Boolean(errors?.email?.message) && <Typography sx={{ color: "red", my: 1 }} variant="h9_4">{errors?.email?.message}</Typography>}

                    <Button type="submit" sx={{ mt: 3.5, width: "100%", backgroundColor: "#003893", '&:hover': { backgroundColor: "#003893" }, fontSize: "16px", fontWeight: 700, color: "#FFFFFF", padding: "10px 20px", borderRadius: "8px", letterSpacing: ".7px" }}>Send Link</Button>
                </Stack>
            </form>
        </Grid>
    )
}
