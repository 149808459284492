import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Divider,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser";
import { ReactComponent as DeleteIcon } from "../../../Icons/DeleteTable.svg";
import { ReactComponent as EditIcon } from "../../../Icons/EditIcon.svg";
import AlertModal from "../../../CommonComponents/AlertModal";
import LinearProgress from '@mui/material/LinearProgress';
import CachedIcon from '@mui/icons-material/Cached';

// Helper function to strip HTML tags
const stripHtmlTags = (html) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
};

export default function NewsList() {
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  let deleteData = {
    label: "Confirm Delete News ",
    descriptionOne: "Please confirm that you wish to delete the News.",
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#912018",
      text: "Confirm",
    },
  };

  const navigate = useNavigate();
  const [NewsData, setNewsData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState("");

  const getAllNews = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}getAllNews?page=${
          page + 1
        }&limit=${rowsPerPage}`
      );
      setTotalItems(response.data.pagination.totalItems || 0)
      setNewsData(response?.data?.data || []);
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };

  const handleClose = () => {
    setDeleteModal(null);
    setDeleteId(null);
  };

  const deleteNews = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}deleteNews/${deleteId}`
      );
      getAllNews();
      setDeleteModal(null);
      setDeleteId(null);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSeeMore = (description) => {
    setSelectedDescription(description);
  };

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page change
  };

  useEffect(() => {
    getAllNews();
  }, [page, rowsPerPage]);

  return (
    <>
      {deleteModal && (
        <AlertModal
          modalDatas={deleteData}
          confirmFunction={deleteNews}
          closeFunction={handleClose}
        />
      )}

      <Stack direction="row" justifyContent="space-between" alignItems='center'>
        <Typography variant="h7_1">News</Typography>

        <Box sx={{display : 'flex', gap : 3, alignItems : 'center'}}>
        <Typography variant="h7_1" sx={{cursor : 'pointer'}} title='Reload' onClick={() => getAllNews()}><CachedIcon /></Typography>
        <Button onClick={() => navigate("/Admin/NewsAdd")} variant="contained">
          Add
        </Button>
        </Box>
      </Stack>
      
      <TableContainer sx={{
        mt : 3.5,
          borderRadius: "5px",
          border: "1px solid #e5e5e5",
          boxShadow: "0px 0px 30px 20px #f4f4f4",
        }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Image
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                <Typography variant="h9_1" color="#333333">
                  Title
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Description
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {loading && (
              <TableRow>
                <TableCell colSpan={5} sx={{p :0, borderBottom : '0px'}}>
              <LinearProgress color="primary" />
              </TableCell>
              </TableRow>
            )}
            {NewsData.length == 0 ? (<>
              <TableRow>
              <TableCell colSpan={5} sx={{borderBottom: "0.5px solid #dcdddc", py: 2.7}}>
            <Typography>No data found</Typography>
            </TableCell>
            </TableRow>
            </>) : (
            NewsData.map((item, index) => (
              <TableRow
                key={item.name}
                sx={{border: "0px",
                backgroundColor: index % 2 === 0 ? "#f8f8f8" : "white"}}
              >
                <TableCell colSpan={1} sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                  <Box
                    component="img"
                    src={process.env.REACT_APP_FILE_PATH + item?.img}
                    sx={{ width: "250px", borderRadius : '5px' }}
                  ></Box>
                </TableCell>
                <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                  <Typography variant="h9_4" color="#333333">
                    {item?.title || "-"}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                  <Button
                    onClick={() => handleSeeMore(item?.description)}
                    variant="contained"
                    // sx={{ ml: 1 }}
                  >
                    See Description
                  </Button>
                </TableCell>
                <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
                  <IconButton
                    onClick={() => navigate(`/Admin/edit-news/${item._id}`)}
                    sx={{ marginRight: "10px" }}
                  >
                    <EditIcon fill="black" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setDeleteId(item._id);
                      setDeleteModal(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          count={totalItems} 
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </TableContainer>

      {/* Dialog for full description */}
      <Dialog
        open={!!selectedDescription}
        onClose={() => setSelectedDescription("")}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Full Description</DialogTitle>
        <Divider></Divider>
        <DialogContent>
          <Typography variant="body1">{parse(selectedDescription)}</Typography>
        </DialogContent>
        <Divider></Divider>
        <DialogActions>
          <Button
            onClick={() => setSelectedDescription("")}
            color="primary"
            variant="contained"
            sx={{ margin: "10px", padding: "10px 20px" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
