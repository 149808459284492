import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function PageNotFound() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // This will navigate to the previous page
  };

  return (
    <Box sx={{display:'flex', flexDirection : 'column', justifyContent : 'center', alignItems : 'center'}}>
        <Box sx={{height : '90vh', objectFit : 'fill', mt : 4.5, borderRadius : '30px', position : 'relative', filter : 'blur(3px)'}} component='img' src='https://cdn.dribbble.com/users/465625/screenshots/16421846/media/5e93cae5c3e0bd0eeb5c21a29f3dce64.png'></Box>
        <Box sx={{position : 'absolute'}}>
        <Typography sx={{fontSize:'4rem', color : 'white'}}>Page Not Found</Typography>
        <Box sx={{display : 'flex', justifyContent : 'center', alignItems : 'center', gap : '20px'}}>
      {/* <Typography sx={{color : 'white'}}>Page Not Found</Typography> */}
      <Button variant="contained" sx={{backgroundColor : '#27BAC4'}} onClick={handleGoBack}>
        Go Back
      </Button>
      </Box>
      </Box>
    </Box>
  );
}

export default PageNotFound;
