import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data : {}
}

console.log(initialState.data, 'data from redux application')

const ApplicationSlice = createSlice({
    name : 'application',
    initialState,
    reducers : {
        storeData : (state, action) => {
            state.data = action.payload
        },
        removeData : (state, action) => {
            state.data = {}
        }
    }
})

export const { storeData, removeData } = ApplicationSlice.actions
export default ApplicationSlice.reducer