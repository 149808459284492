import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Bgimg from "../Icons/bgimg.svg";
import { ReactComponent as CarouselLeftArrow } from "../Icons/carouselLeftArrow.svg";
import { ReactComponent as CarouselRightArrow } from "../Icons/carouselRightArrow.svg";
import { IconButton } from "@mui/material";

export default function HostelCaurosel({ btndisplay, images }) {
  // const images = [
  //     {
  //         src: Bgimg,
  //     },
  //     {
  //         src: "https://images.pexels.com/photos/5137969/pexels-photo-5137969.jpeg?auto=compress&cs=tinysrgb&w=600",
  //     },
  //     {
  //         src: "https://images.pexels.com/photos/7968277/pexels-photo-7968277.jpeg?auto=compress&cs=tinysrgb&w=600",
  //     },
  // ];

  // console.log(process.env.REACT_APP_FILE_PATH + images[0]?.src);

  const CustomPrevArrow = ({ onClick }) => (
    <IconButton
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "50%",
        left: btndisplay ? "15px" : "1000000px",
        zIndex: 2,
        border: "none",
        opacity: "50%",
        cursor: "pointer",
        transform: "translateY(-50%)",
      }}
    >
      <CarouselLeftArrow />
    </IconButton>
  );

  const CustomNextArrow = ({ onClick }) => (
    <IconButton
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "50%",
        right: btndisplay ? "15px" : "1000000px",
        zIndex: 2,
        opacity: "50%",
        border: "none",
        cursor: "pointer",
        transform: "translateY(-50%)",
      }}
    >
      <CarouselRightArrow />
    </IconButton>
  );

  // const styles = {
  //     '.carousel .control-dots': {
  //         margin: '118px 0 !important'
  //     },

  //     '.carousel.control - dots.dot.selected': {
  //         width: '36px',
  //         height: '8px',
  //         borderRadius: '24px',
  //     }
  // }
  const styles = {
    controlDots: {
      margin: "118px 0 !important",
    },
    selectedDot: {
      width: "36px",
      height: "8px",
      borderRadius: "24px",
      background: "white",
      margin: "118px 8px",
    },
    UnselectedDot: {
      width: "8px",
      height: "8px",
      borderRadius: "24px",
      background: "white",
      opacity: "0.5",
      margin: "118px 8px",
    },
  };

  const Hostelstyles = {
    controlDots: {
      margin: "118px 0 !important",
    },
    selectedDot: {
      width: "12px",
      height: "4px",
      borderRadius: "24px",
      background: "white",
      margin: "0 4px",
    },
    UnselectedDot: {
      width: "4px",
      height: "4px",
      borderRadius: "24px",
      background: "white",
      margin: "0 4px",
    },
  };

  return (
    <>
      <Carousel
        showThumbs={false}
        // infiniteLoop={true}
        // autoPlay={true}
        // interval={2000}
        showStatus={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && <CustomPrevArrow onClick={onClickHandler} />
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && <CustomNextArrow onClick={onClickHandler} />
        }
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          const indicatorStyle = btndisplay
            ? isSelected
              ? styles.selectedDot
              : styles.UnselectedDot
            : isSelected
            ? Hostelstyles.selectedDot
            : Hostelstyles.UnselectedDot;
          return (
            <li
              style={{
                ...indicatorStyle,
                display: "inline-block",
                cursor: "pointer",
              }}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
      >
        {images?.map((itm, index) => (
          <div
            key={index}
            style={{
              position: "relative",
              height: "100%",
              width: "100%",
              overflow: "hidden",
            }}
          >
            <img
              src={process.env.REACT_APP_FILE_PATH + itm?.src}
              alt={`Slide ${index}`}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                borderRadius: btndisplay ? "" : "24px",
              }}
            />
            {btndisplay && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  background:
                    "linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0))",
                }}
              />
            )}
          </div>
        ))}
      </Carousel>
    </>
  );
}
