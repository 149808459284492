import React from 'react'
import { Typography, Stack, Box, Grid, Divider, Radio, Select, MenuItem, InputLabel, Button, Checkbox, TextField, IconButton } from '@mui/material'
import { ReactComponent as BankIcon } from "../../../../Icons/Account/BankIcon.svg"
import { ReactComponent as RightArrowIcon } from "../../../../Icons/rightArrow.svg"

import { useForm, Controller } from 'react-hook-form'


export default function DepositReturn({ setStepperCount }) {


    const { control, formState: { errors }, handleSubmit, getValues, watch } = useForm({
        mode: "onTouched"
    })

    const onSubmit = (data) => {
        console.log(data);
        setStepperCount(4)
    }

    const Acc_no = watch("acc_no")


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box px={{ md: 15, xs: 3 }}>
                    <Typography variant="h6_1">Deposit Return</Typography>
                    <Grid mt={3} mb={6} container rowGap={7} columnGap={10} >
                        <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                            <Divider />
                            <Stack my={1} direction="row" justifyContent="space-between" alignItems="center">
                                <Stack gap="8px">
                                    <Typography variant="h6_1">Deposit Amount</Typography>
                                    <Typography variant='h9_4' >1-month rent deposit</Typography>
                                </Stack>
                                <Typography variant="h6_1">S$ 3800</Typography>
                            </Stack>
                            <Divider />
                            <Stack my={1} direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6_1">Total</Typography>
                                <Typography variant="h4_1">S$ 11,450</Typography>
                            </Stack>
                            <Stack mt={2}>
                                <Typography sx={{ display: "flex", alignItems: "center", gap: "10px" }} variant="h6_1"><BankIcon />Bank Details</Typography>
                                <Typography variant='h9_4' >Enter your bank details to get the deposit amount</Typography>
                            </Stack>
                        </Grid>
                        <Grid xs={12} sm={5.5}>
                        <InputLabel
                  sx={{
                    mt: 0,
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "rgba(115, 115, 115, 1)",
                  }}
                >
                  {errors?.name ? "Name*" : "Name"}
                </InputLabel>
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <TextField 
                                placeholder="Enter name"
                      error={Boolean(errors.name)}
                      {...field}
                      sx={{
                        width: "100%",
                        backgroundColor: "rgb(249, 250, 251)",
                        borderRadius: 0,
                        "& .MuiInputBase-input": {
                          color: Boolean(errors.name) ? "red" : "black",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: Boolean(errors.name)
                              ? "#ff5e74"
                              : "rgba(0, 0, 0, 0.23)",
                          },
                          "&:hover fieldset": {
                            borderColor: Boolean(errors.name)
                              ? "#ff5e74"
                              : "rgba(0, 0, 0, 0.23)",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: Boolean(errors.name)
                              ? "#ff5e74"
                              : "rgba(0, 0, 0, 0.23)",
                          },
                        },
                      }}
                    />
                  }
                />
                {errors?.name ? (
                  <Typography style={{ color: "#ff5e74", margin: "0.3rem" }}>
                    Please enter name
                  </Typography>
                ) : null}
                        
                        </Grid>
                        <Grid xs={12} sm={5.5}>
                        <InputLabel
                  sx={{
                    mt: 0,
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "rgba(115, 115, 115, 1)",
                  }}
                >
                  {errors?.bank_name ? "Bank Name*" : "Bank Name"}</InputLabel>
                            <Controller
                                name="bank_name"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <TextField 
                                placeholder="Enter bank name"
                      error={Boolean(errors.bank_name)}
                      {...field}
                      sx={{
                        width: "100%",
                        backgroundColor: "rgb(249, 250, 251)",
                        borderRadius: 0,
                        "& .MuiInputBase-input": {
                          color: Boolean(errors.bank_name) ? "red" : "black",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: Boolean(errors.bank_name)
                              ? "#ff5e74"
                              : "rgba(0, 0, 0, 0.23)",
                          },
                          "&:hover fieldset": {
                            borderColor: Boolean(errors.bank_name)
                              ? "#ff5e74"
                              : "rgba(0, 0, 0, 0.23)",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: Boolean(errors.bank_name)
                              ? "#ff5e74"
                              : "rgba(0, 0, 0, 0.23)",
                          },
                        },
                      }}
                    />
                  }
                />
                {errors?.bank_name ? (
                  <Typography style={{ color: "#ff5e74", margin: "0.3rem" }}>
                    Please enter bank name
                  </Typography>
                ) : null}
                        </Grid>

                        <Grid xs={12} sm={5.5}>
                            <InputLabel
                  sx={{
                    mt: 0,
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "rgba(115, 115, 115, 1)",
                  }}
                >
                  {errors?.acc_no ? "Account Number*" : "Account Number"}
                </InputLabel>
                            <Controller
                                name="acc_no"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <TextField 
                                placeholder="Enter account number"
                                error={Boolean(errors.acc_no)}
                                {...field}
                                sx={{
                                  width: "100%",
                                  backgroundColor: "rgb(249, 250, 251)",
                                  borderRadius: 0,
                                  "& .MuiInputBase-input": {
                                    color: Boolean(errors.acc_no) ? "red" : "black",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: Boolean(errors.acc_no)
                                        ? "#ff5e74"
                                        : "rgba(0, 0, 0, 0.23)",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: Boolean(errors.acc_no)
                                        ? "#ff5e74"
                                        : "rgba(0, 0, 0, 0.23)",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: Boolean(errors.acc_no)
                                        ? "#ff5e74"
                                        : "rgba(0, 0, 0, 0.23)",
                                    },
                                  },
                                }}
                              />
                            }
                          />
                          {errors?.acc_no ? (
                            <Typography style={{ color: "#ff5e74", margin: "0.3rem" }}>
                              Please enter account number
                            </Typography>
                          ) : null}
                        </Grid>
                        <Grid xs={12} sm={5.5}>
                        <InputLabel
                                sx={{
                                    mt: 0,
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    color: "rgba(115, 115, 115, 1)",
                                }}
                                >
                                {errors?.re_acc_no ? "Repeat Account Number*" : "Repeat Account Number"}
                                </InputLabel>
                            <Controller
                                name="re_acc_no"
                                control={control}
                                rules={{
                                    required: "Required Field!",
                                    validate: (value) => getValues("acc_no") === value || "Account Number does not match"
                                }}
                                render={({ field }) => <TextField  
                                error={Boolean(errors?.re_acc_no) ? true : false} 
                                {...field} 
                                disabled={!Boolean(Acc_no)} 
                                sx={{
                                    width: "100%",
                                    backgroundColor: "rgb(249, 250, 251)",
                                    borderRadius: 0,
                                    "& .MuiInputBase-input": {
                                      color: Boolean(errors.re_acc_no) ? "red" : "black",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: Boolean(errors.re_acc_no)
                                          ? "#ff5e74"
                                          : "rgba(0, 0, 0, 0.23)",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: Boolean(errors.re_acc_no)
                                          ? "#ff5e74"
                                          : "rgba(0, 0, 0, 0.23)",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: Boolean(errors.re_acc_no)
                                          ? "#ff5e74"
                                          : "rgba(0, 0, 0, 0.23)",
                                      },
                                    },
                                  }}
                                />}
                            />
                            {Boolean(errors?.re_acc_no?.message) && <InputLabel sx={{ color: "#ff5e74", mt: 1 }} variant="standard">{errors?.re_acc_no?.message}</InputLabel>}

                        </Grid>

                        <Grid xs={12} sm={5.5}>
                        <InputLabel
                  sx={{
                    mt: 0,
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "rgba(115, 115, 115, 1)",
                  }}
                >
                  {errors?.branch_name ? "Branch Name*" : "Branch Name"}
                </InputLabel>
                            <Controller
                                name="branch_name"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <TextField 
                                    placeholder="Enter branch name"
                                error={Boolean(errors.branch_name)}
                                {...field}
                                sx={{
                                  width: "100%",
                                  backgroundColor: "rgb(249, 250, 251)",
                                  borderRadius: 0,
                                  "& .MuiInputBase-input": {
                                    color: Boolean(errors.branch_name) ? "red" : "black",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: Boolean(errors.branch_name)
                                        ? "#ff5e74"
                                        : "rgba(0, 0, 0, 0.23)",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: Boolean(errors.branch_name)
                                        ? "#ff5e74"
                                        : "rgba(0, 0, 0, 0.23)",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: Boolean(errors.branch_name)
                                        ? "#ff5e74"
                                        : "rgba(0, 0, 0, 0.23)",
                                    },
                                  },
                                }}
                              />
                            }
                          />
                          {errors?.branch_name ? (
                            <Typography style={{ color: "#ff5e74", margin: "0.3rem" }}>
                              Please enter branch name
                            </Typography>
                          ) : null}

                        </Grid>
                        <Grid xs={12} sm={5.5}>
                            <InputLabel
                                sx={{
                                    mt: 0,
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    color: "rgba(115, 115, 115, 1)",
                                }}
                                >
                                {errors?.ifsc_code ? "IFSC Code*" : "IFSC Code"}
                                </InputLabel>
                            <Controller
                                name="ifsc_code"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <TextField 
                                placeholder="Enter branch name"
                                error={Boolean(errors.ifsc_code)}
                                {...field}
                                sx={{
                                  width: "100%",
                                  backgroundColor: "rgb(249, 250, 251)",
                                  borderRadius: 0,
                                  "& .MuiInputBase-input": {
                                    color: Boolean(errors.ifsc_code) ? "red" : "black",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: Boolean(errors.ifsc_code)
                                        ? "#ff5e74"
                                        : "rgba(0, 0, 0, 0.23)",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: Boolean(errors.ifsc_code)
                                        ? "#ff5e74"
                                        : "rgba(0, 0, 0, 0.23)",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: Boolean(errors.ifsc_code)
                                        ? "#ff5e74"
                                        : "rgba(0, 0, 0, 0.23)",
                                    },
                                  },
                                }}
                              />
                            }
                          />
                          {errors?.ifsc_code ? (
                            <Typography style={{ color: "#ff5e74", margin: "0.3rem" }}>
                              Please enter IFSC code
                            </Typography>
                          ) : null}
                        </Grid>
                        <Grid xs={12} sm={5.5}>
                        <InputLabel
                                sx={{
                                    mt: 0,
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    color: "rgba(115, 115, 115, 1)",
                                }}
                                >
                                {errors?.country ? "Country*" : "Country"}
                                </InputLabel>
                            <Controller
                                name="country"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <Select 
                                {...field} sx={{
                                    width: "100%",
                                    backgroundColor: "rgb(249, 250, 251)",
                                    borderRadius: 0,
                                    "& .MuiInputBase-input": {
                                      color: Boolean(errors.country) ? "red" : "black",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: Boolean(errors.country)
                                          ? "#ff5e74"
                                          : "rgba(0, 0, 0, 0.23)",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: Boolean(errors.country)
                                          ? "#ff5e74"
                                          : "rgba(0, 0, 0, 0.23)",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: Boolean(errors.country)
                                          ? "#ff5e74"
                                          : "rgba(0, 0, 0, 0.23)",
                                      },
                                    },
                                  }} error={Boolean(errors?.country) ? true : false}  >
                                    <MenuItem value={1}>Singapore</MenuItem>
                                </Select>}
                            />
                            {errors?.country ? (
                            <Typography style={{ color: "#ff5e74", margin: "0.3rem" }}>
                              Please select country
                            </Typography>
                          ) : null}
                        </Grid>

                    </Grid>


                    <Stack my={5} direction="row" alignItems="center" justifyContent="end">
                        {/* <Button variant="standard" onClick={() => { setStepperCount(4) }}>Back</Button> */}
                        <Button type="submit" endIcon={<RightArrowIcon />} variant='contained' sx={{ fontSize: "16px", fontWeight: 700 }}>Continue</Button>
                    </Stack>
                </Box>
            </form>
        </>
    )
}
