import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Divider,
  Button,
  Stack,
  CardActionArea,
  CardActions,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { ReactComponent as FavouritesIcon } from "../../../Icons/favouritesIcon.svg";
import { ReactComponent as ShareIcon } from "../../../Icons/ShareIcon.svg";
import NewsBgImg from "../../../Icons/NewsBgImg.svg";
import { ReactComponent as ShareNewsIcon } from "../../../Icons/shareNewsIcon.svg";
import { ReactComponent as FavouritesNewsIcon } from "../../../Icons/favouritesNewsIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser";

export default function NewsReview() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [NewsData, setNewsData] = useState(null);
  const [NewsDataAll, setNewsDataAll] = useState(null);

  const getNewsById = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}getNewsbyId/${id}`
      );
      console.log(response?.data?.data);
      setNewsData(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllNews = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}getAllNews`
      );
      console.log(response?.data?.data);
      setNewsDataAll(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const truncateDescription = (description) => {
    if (typeof description !== "string") return "";
    const words = description.split(" ");
    if (words.length > 15 && words.length < Infinity ) {
    
      return words.slice(0, 9).join(" ") + "...";
    }else if(words.length < 15){
      return words.slice(0, 5).join(' ') + "...";
    }
    return description;
  };

  useEffect(() => {
    getNewsById();
    getAllNews();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  const images = [
    {
      src: "https://images.pexels.com/photos/5137969/pexels-photo-5137969.jpeg?auto=compress&cs=tinysrgb&w=600",
    },
    {
      src: "https://images.pexels.com/photos/5137969/pexels-photo-5137969.jpeg?auto=compress&cs=tinysrgb&w=600",
    },
    {
      src: "https://images.pexels.com/photos/7968277/pexels-photo-7968277.jpeg?auto=compress&cs=tinysrgb&w=600",
    },
  ];

  return (
    <>
      <Box px={{ md: 7, xs: 3 }} pt={7}>
        <Typography variant="h4_1" color="primary">
          {NewsData?.title}
        </Typography>
        <Divider sx={{ mt: 2 }} />
        <Stack py={2} direction="row" columnGap="20px">
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#27BAC4",
              borderRadius: "80px",
              padding: "10px 15px",
              width: "87px",
              height: "40px",
            }}
            startIcon={<FavouritesIcon fill="#FFFFFF" />}
          >
            Like
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#27BAC4",
              borderRadius: "80px",
              padding: "10px 15px",
              width: "87px",
              height: "40px",
            }}
            startIcon={<ShareIcon fill="#FFFFFF" />}
          >
            Share
          </Button>
        </Stack>
        <Divider sx={{ mb: 4 }} />

        <Box
          component="img"
          src={process.env.REACT_APP_FILE_PATH + NewsData?.img}
          sx={{
            width: "100%",
            height: { md: "526px", xs: 180, sm : '100%' },
            objectFit: "cover",
            borderRadius: "24px",
          }}
        ></Box>
        <Stack mb={5} rowGap={4} mt={3} textAlign="start">
          <Typography variant="h7_2">
            {Boolean(NewsData?.description) ? parse(NewsData?.description) : ""}
          </Typography>
          {/* <Typography variant="h7_2">
                        Nunc fringilla risus id nulla pretium, non congue elit porttitor. Curabitur fermentum eu mauris sed dignissim. Nunc in risus mi. Duis sit amet est et massa euismod laoreet. Praesent dolor metus, blandit eu massa ut, congue vulputate mauris. Praesent sit amet pharetra sem, sit amet rutrum magna.
                        Nullam venenatis eros eget suscipit gravida. Duis posuere interdum iaculis. Nulla viverra velit aliquet quam ornare dignissim. Duis condimentum pretium turpis, vel condimentum justo porta in. Donec gravida tellus erat, at tempus sapien cursus eu. Pellentesque sollicitudin non leo sit amet scelerisque. Pellentesque vitae augue blandit, aliquet ipsum sit amet, vulputate augue. Vestibulum tincidunt ullamcorper nisi, ac porttitor metus consectetur eget.
                        Vestibulum bibendum et velit sit amet iaculis. Suspendisse eu nisl ipsum. Maecenas quis pretium erat, eget sagittis est. Nunc consectetur interdum orci, non cursus arcu lobortis ut. Vestibulum sed rhoncus mauris. Sed quis nisi elementum, efficitur orci id, condimentum ante. Fusce ut massa eget mauris accumsan interdum vitae vel sem. Integer sit amet purus nec odio vestibulum commodo ut malesuada diam. Duis diam orci, venenatis sit amet libero at, tempus congue dui.
                    </Typography>
                    <Typography variant="h7_2">
                        Donec magna nisl, vulputate vel suscipit et, malesuada non lacus
                        Donec magna nisl, vulputate vel suscipit et, malesuada non lacus. Mauris at enim aliquet eros ultricies elementum nec at ex. Nam facilisis augue eget enim vulputate, in laoreet ex congue. Fusce quis vestibulum orci. Nullam urna risus, malesuada vitae lacus ut, luctus commodo enim. Proin ac egestas mi. Phasellus fringilla volutpat est at tempus. Sed a bibendum sapien, sed feugiat lacus. Sed fringilla accumsan consectetur. Vivamus consectetur congue tincidunt. Curabitur at dui nisl. Donec vel viverra ligula, eu facilisis nisi. Vestibulum consequat justo non nunc tempus, ac ullamcorper eros convallis. Fusce posuere in urna eu placerat. Praesent vel iaculis lacus. Praesent tincidunt cursus eros, ac interdum sem vehicula eu.
                    </Typography> */}
          <Divider />
        </Stack>
        <Stack alignItems="center">
          <Typography
            p={2}
            sx={{ width: "fit-content" }}
            variant="h6_1"
            color="primary"
            border="1px solid #27BAC4"
            padding="10px 50px"
            borderRadius="50px"
            letterSpacing="1.1px"
          >
            MORE NEWS
          </Typography>
        </Stack>
        <Grid
          container
          gap="30px"
          mt={5}
          pt={5}
          pb={10}
          justifyContent="center"
          marginBottom="0px"
        >
          {NewsDataAll?.map((itm, index) => (
            <Grid>
              <Card
                sx={{
                  maxWidth: 412,
                  p: 2,
                  boxShadow : '0px 4px 20px 0px rgba(0, 0, 0, 0.15)',
                  borderRadius: "20px",
                  textAlign: "start",
                  position: "relative",
                }}
              >
                <Stack
                  direction="row"
                  columnGap="10px"
                  sx={{
                    position: "absolute",
                    zIndex: "1",
                    right: "25px",
                    top: "25px",
                  }}
                >
                  <ShareNewsIcon />
                  <FavouritesNewsIcon />
                </Stack>
                <CardActionArea>
                  <CardMedia
                    sx={{ borderRadius: "20px", height: "200px" }}
                    component="img"
                    image={process.env.REACT_APP_FILE_PATH + itm?.img}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        gutterBottom
                        variant="h7_1"
                        component="div"
                        color="#007ECA"
                      >
                        {itm?.title}
                      </Typography>
                      <Typography
                        variant="h9_4"
                        color="#007ECA"
                        sx={{
                          width: "42px",
                          height: "24px",
                          border: ".5px solid #007ECA",
                          padding: "0 10px 26px 10px",
                          borderRadius: "80px",
                        }}
                      >
                        tag
                      </Typography>
                    </Stack>
                    <Typography variant="h9_4">
                      {" "}
                      {Boolean(itm?.description)
                        ? parse(truncateDescription(itm?.description))
                        : "-"}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <Stack direction="row" justifyContent="end">
                  <Button
                    onClick={() => navigate(`/news/${itm?._id}`)}
                    variant="contained"
                    sx={{
                      width: "80px",
                      height: "40px",
                      padding: "10px 30px",
                      backgroundColor: "#007eca",
                      borderRadius: "80px",
                      color: "white",
                      textTransform: "none",
                      letterSpacing: "1px",
                      fontWeight: 700,
                      fontSize: "16",
                    }}
                  >
                    More
                  </Button>
                </Stack>
              </Card>
            </Grid>
          ))}
          {/* <Grid>
                        <Card sx={{ maxWidth: 412, p: 2, border: ".5px solid #737373", borderRadius: "20px", textAlign: "start", position: "relative" }}>
                            <Stack direction="row" columnGap="10px" sx={{ position: "absolute", zIndex: "1", right: "25px", top: '25px' }}>
                                <ShareNewsIcon />
                                <FavouritesNewsIcon />
                            </Stack> <CardActionArea>
                                <CardMedia sx={{ borderRadius: "20px", height: "200px" }}
                                    component="img"
                                    image={itm[1]?.src}
                                    alt="green iguana"
                                />
                                <CardContent >
                                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                                        <Typography gutterBottom variant="h7_1" component="div" color="#007ECA">
                                            Title of News
                                        </Typography>
                                        <Typography variant="h9_4" color="#007ECA" sx={{ width: "42px", height: "24px", border: ".5px solid #007ECA", padding: "0 10px 26px 10px", borderRadius: "80px" }}>tag</Typography>
                                    </Stack>
                                    <Typography variant="h9_4">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commod
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <Stack direction="row" justifyContent="end">
                                <Button variant="contained" sx={{ width: "80px", height: "40px", padding: "10px 30px", backgroundColor: "#007eca", borderRadius: "80px", color: "white", textTransform: "none", letterSpacing: "1px", fontWeight: 700, fontSize: "16" }}>More</Button>
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid>
                        <Card sx={{ maxWidth: 412, p: 2, border: ".5px solid #737373", borderRadius: "20px", textAlign: "start", position: "relative" }}>
                            <Stack direction="row" columnGap="10px" sx={{ position: "absolute", zIndex: "1", right: "25px", top: '25px' }}>
                                <ShareNewsIcon />
                                <FavouritesNewsIcon />
                            </Stack>
                            <CardActionArea>
                                <CardMedia sx={{ borderRadius: "20px", height: "200px" }}
                                    component="img"
                                    image={itm[2]?.src}
                                    alt="green iguana"
                                />
                                <CardContent >
                                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                                        <Typography gutterBottom variant="h7_1" component="div" color="#007ECA">
                                            Title of News
                                        </Typography>
                                        <Typography variant="h9_4" color="#007ECA" sx={{ width: "42px", height: "24px", border: ".5px solid #007ECA", padding: "0 10px 26px 10px", borderRadius: "80px" }}>tag</Typography>
                                    </Stack>
                                    <Typography variant="h9_4">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commod
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <Stack direction="row" justifyContent="end">
                                <Button variant="contained" sx={{ width: "80px", height: "40px", padding: "10px 30px", backgroundColor: "#007eca", borderRadius: "80px", color: "white", textTransform: "none", letterSpacing: "1px", fontWeight: 700, fontSize: "16" }}>More</Button>
                            </Stack>
                        </Card>
                    </Grid> */}
        </Grid>
      </Box>
    </>
  );
}
