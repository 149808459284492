import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/system'
import { Divider, Typography, Card, CardContent } from '@mui/material'
import { ReactComponent as FormIcon } from "../../../Icons/CommonFormIcon.svg";
import moment from "moment";

function ViewContact() {
    const {id} = useParams()
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)

    const fetchContactUsById = async () => {
        setLoading(true)
        await axios.get(`${process.env.REACT_APP_API_URL}get-contact-us-by-id/${id}`)
        .then((response) => {
            console.log(response)
            setData(response?.data?.data)
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }

    useEffect(() => {
        if(id) {
            fetchContactUsById()
        }
    }, [id])

    console.log(data, 'data from query')

    if(loading){
        return <Typography>Loading...</Typography>
    }

  return (
    <Box>
        <Typography sx={{fontSize : '1.2rem', fontWeight : 700}}>View Contact</Typography>
        <Divider sx={{mt : 2, mb : 3}}/>

        <Box sx={{display : 'flex', gap : 3, flexWrap : 'wrap'}}>

        <Box sx={{border : '1px solid #b6b6b6', py : 2, px : 3, borderRadius : '10px', width : '23%', position : 'relative', overflow : 'hidden'}}>
            <Typography sx={{color : 'gray'}}>Name</Typography>
            <Typography sx={{fontSize : '1.2rem'}}>{data?.name}</Typography>
            {/* <Box sx={{ width : '100px', height : '100px', top : 0, right : -15,zIndex : -1,background : 'green', position : 'absolute', borderRadius : '50%'}}></Box> */}
        </Box>
        

        <Box sx={{border : '1px solid #b6b6b6', py : 2, px : 3, borderRadius : '10px', width : '23%', position : 'relative'}}>
            <Typography sx={{color : 'gray'}}>Email</Typography>
            <Typography sx={{fontSize : '1.2rem'}}>{data?.email}</Typography>
            {/* <Box sx={{p : 3, background : 'green', overflow : 'hidden', position : 'absolute'}}></Box> */}
        </Box>
       

        <Box sx={{border : '1px solid #b6b6b6', py : 2, px : 3, borderRadius : '10px', width : '23%'}}>
            <Typography sx={{color : 'gray'}}>Contact</Typography>
            <Typography sx={{fontSize : '1.2rem'}}>{data?.contactNo}</Typography>
        </Box>

        <Box sx={{border : '1px solid #b6b6b6', py : 2, px : 3, borderRadius : '10px', width : '23%'}}>
            <Typography sx={{color : 'gray'}}>Nationality</Typography>
            <Typography sx={{fontSize : '1.2rem'}}>{data?.nationality}</Typography>
        </Box>

        </Box>

        <Box sx={{display : 'flex', gap : 3, flexWrap : 'wrap', mt : 3}}>

        <Box sx={{border : '1px solid #b6b6b6', py : 2, px : 3, borderRadius : '10px', width : '23%'}}>
            <Typography sx={{color : 'gray'}}>Date of Birth</Typography>
            <Typography sx={{fontSize : '1.2rem'}}>{moment(data?.dateOfBirth).format('DD-MM-YYYY')}</Typography>
        </Box>

        <Box sx={{border : '1px solid #b6b6b6', py : 2, px : 3, borderRadius : '10px', width : '23%'}}>
            <Typography sx={{color : 'gray'}}>Line</Typography>
            <Typography sx={{fontSize : '1.2rem'}}>{data?.line}</Typography>
        </Box>

        <Box sx={{border : '1px solid #b6b6b6', py : 2, px : 3, borderRadius : '10px', width : '23%'}}>
            <Typography sx={{color : 'gray'}}>WeChat</Typography>
            <Typography sx={{fontSize : '1.2rem'}}>{data?.weChat || '-'}</Typography>
        </Box>

        <Box sx={{border : '1px solid #b6b6b6', py : 2, px : 3, borderRadius : '10px', width : '23%'}}>
            <Typography sx={{color : 'gray'}}>WhatsApp</Typography>
            <Typography sx={{fontSize : '1.2rem'}}>{data?.whatsApp || '-'}</Typography>
        </Box>

        </Box>

        <Box sx={{mt : 3}}>
        <Box sx={{border : '1px solid #b6b6b6', py : 2, px : 3, borderRadius : '10px', width : '98%'}}>
            <Typography sx={{color : 'gray'}}>Remarks</Typography>
            <Typography sx={{fontSize : '1.2rem'}}>{data?.remarks || '-'}</Typography>
        </Box>
        </Box>


    </Box>
  )
}

export default ViewContact